import React from "react";
import { Tabs, Tab, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import { ResponsiveContainer, LineChart, BarChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line, Bar } from "recharts";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Service from "Service";

const CustomTooltip = ({ active, payload, label }) => {

    if (payload && payload.length > 0) {
        const items = [];

        items.push(<div>{label}</div>)
        for (const [index, value] of payload.entries()) {

            if (value.payload.ACT_DATE !== undefined && index === 0) {
                items.push(<div>{"Date"} : {value.payload.ACT_DATE}</div>)
            }
            items.push(<div style={{ "color": value.color }}> {value.name} : {value.value}</div>)
        }

        return (
            <div className="recharts-default-tooltip">
                <div className="recharts-tooltip-item-list">
                    {items}
                </div>
            </div>
        );
    } else {
        return '';
    }
};

class TrendWidget extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFrequency: "Daily",
            xKey: "interval",
            xName: "interval"
        };
    };

    componentWillReceiveProps(nextProps) {
        if (
            (nextProps.match.params.tenantId && nextProps.match.params.tenantId !== this.props.match.params.tenantId) ||
            (nextProps.match.params.siteId && nextProps.match.params.siteId !== this.props.match.params.siteId) ||
            (nextProps.match.params.houseId && nextProps.match.params.houseId !== this.props.match.params.houseId)
        ) {
            this.state.selectedFrequency = "Daily";
            this.getData(nextProps.match.params.tenantId, nextProps.match.params.siteId, nextProps.match.params.houseId);
        }
    }

    componentDidMount = () => {
        this.setState({
            isDataLoaded: false,
            selectedFrequency: "Daily",
            xKey: "interval",
            xName: "interval"
        });
        this.getData(this.props.match.params.tenantId, this.props.match.params.siteId, this.props.match.params.houseId);

    }

    prepareColumnDefinition = () => {
        let frequencyField = "";
        if (this.state.selectedFrequency == 'Daily') {
            frequencyField = "Hour";
        }

        if (this.state.selectedFrequency == 'Weekly') {
            frequencyField = "Day";
        }

        if (this.state.selectedFrequency == 'Monthly') {
            frequencyField = "Date";
        }

        if (this.state.selectedFrequency == 'Yearly') {
            frequencyField = "Month";
        }

        if (this.state.selectedFrequency == 'All') {
            frequencyField = "Year";
        }

        this.state.temperatureColumns = [
            { text: "Actual Date", dataField: "ACT_DATE", sort: true, filter: textFilter({ placeholder: "Type to filter" }) },
            { text: frequencyField, dataField: "TS_DATE", Sort: true, filter: textFilter({ placeholder: "Type to filter" }) },
            { text: "Temp Low", dataField: "TempLow", Sort: true, filter: textFilter({ placeholder: "Type to filter" }) },
            { text: "Temp Avg", dataField: "TempAvg", Sort: true, filter: textFilter({ placeholder: "Type to filter" }) },
            { text: "Temp High", dataField: "TempHigh", Sort: true, filter: textFilter({ placeholder: "Type to filter" }) },
            { text: "Temp Target", dataField: "TempTarget", Sort: true, filter: textFilter({ placeholder: "Type to filter" }) },
            { text: "Temp Out", dataField: "TempOut", Sort: true, filter: textFilter({ placeholder: "Type to filter" }) }
        ];

        this.state.mortalityColumns = [
            { text: "Actual Date", dataField: "ACT_DATE", sort: true, filter: textFilter({ placeholder: "Type to filter" }) },
            { text: frequencyField, dataField: "TS_DATE", Sort: true, filter: textFilter({ placeholder: "Type to filter" }) },
            { text: "Mortality Count", dataField: "MortalityCount", Sort: true, filter: textFilter({ placeholder: "Type to filter" }) }
        ];

        this.state.feedColumns = [
            { text: "Actual Date", dataField: "ACT_DATE", sort: true, filter: textFilter({ placeholder: "Type to filter" }) },
            { text: frequencyField, dataField: "TS_DATE", Sort: true, filter: textFilter({ placeholder: "Type to filter" }) },
            { text: "Feed Used", dataField: "FeedUse", Sort: true, filter: textFilter({ placeholder: "Type to filter" }) }
        ];

        this.state.waterColumns = [
            { text: "Actual Date", dataField: "ACT_DATE", sort: true, filter: textFilter({ placeholder: "Type to filter" }) },
            { text: frequencyField, dataField: "TS_DATE", Sort: true, filter: textFilter({ placeholder: "Type to filter" }) },
            { text: "Water Used (gal)", dataField: "Wateruse", Sort: true, filter: textFilter({ placeholder: "Type to filter" }) }
        ];

        this.state.populationColumns = [
            { text: "Actual Date", dataField: "ACT_DATE", sort: true, filter: textFilter({ placeholder: "Type to filter" }) },
            { text: frequencyField, dataField: "TS_DATE", Sort: true, filter: textFilter({ placeholder: "Type to filter" }) },
            { text: "Starting Population", dataField: "StartingPopulation", Sort: true, filter: textFilter({ placeholder: "Type to filter" }) },
            { text: "Current Population", dataField: "CurrentPopulation", Sort: true, filter: textFilter({ placeholder: "Type to filter" }) }
        ];

        this.state.feedBinColumns = [
            { text: "Actual Date", dataField: "ACT_DATE", sort: true, filter: textFilter({ placeholder: "Type to filter" }) },
            { text: frequencyField, dataField: "TS_DATE", Sort: true, filter: textFilter({ placeholder: "Type to filter" }) },
            { text: "FeedInventory", dataField: "FeedInventory", Sort: true, filter: textFilter({ placeholder: "Type to filter" }) },
        ];

        this.state.eggCountColumns = [
            { text: "Actual Date", dataField: "ACT_DATE", sort: true, filter: textFilter({ placeholder: "Type to filter" }) },
            { text: frequencyField, dataField: "TS_DATE", Sort: true, filter: textFilter({ placeholder: "Type to filter" }) },
            { text: "Egg Count", dataField: "EggCount", Sort: true, filter: textFilter({ placeholder: "Type to filter" }) },
        ];

    }

    changeFrequency = (e, frequency) => {
        this.setState({ selectedFrequency: frequency });

        setTimeout(() => {
            this.getData(this.props.match.params.tenantId, this.props.match.params.siteId, this.props.match.params.houseId);
        }, 100);
    };

    getTemperatureResponse = (response) => {
      setTimeout(() => {
        this.props.appProps.hideLoader()
        this.setState({
          temperatureData: response.body || [],
          isTempDataLoaded: true,
        })
      }, 500)
    }

    getMortalityCountResponse = (response) => {
      setTimeout(() => {
        this.props.appProps.hideLoader()
        this.setState({
          mortalityData: response.body || [],
          isMortalityDataLoaded: true,
        })
      }, 500)
    }

    getFeedUseResponse = (response) => {
      setTimeout(() => {
        this.props.appProps.hideLoader()
        this.setState({
          feedData: response.body || [],
          isFeedDataLoaded: true,
        })
      }, 500)
    }

    getWaterUseResponse = (response) => {
      setTimeout(() => {
        this.props.appProps.hideLoader()
        this.setState({
          waterData: response.body || [],
          isWaterDataLoaded: true,
        })
      }, 500)
    }

    getPopulationResponse = (response) => {
      setTimeout(() => {
        this.props.appProps.hideLoader()
        this.setState({
          populationData: response.body || [],
          isPupulationDataLoaded: true,
        })
      }, 500)
    }

    getEggCountResponse = (response) => {
      setTimeout(() => {
        this.props.appProps.hideLoader()
        this.setState({
          eggCountData: response.body || [],
          isEggCountDataLoaded: true,
        })
      }, 500)
    }

    getFeedBinResponse = (response) => {
      setTimeout(() => {
        this.props.appProps.hideLoader()
        this.setState({
          feedBinData: response.body || [],
          isFeedBinDataLoaded: true,
        })
      }, 500)
    }

    getData = (tenant, site, house) => {
        this.getTrendData('Temperature', this.getTemperatureResponse, tenant, site, house);
        this.getTrendData('MortalityCount', this.getMortalityCountResponse, tenant, site, house);
        this.getTrendData('FeedUse', this.getFeedUseResponse, tenant, site, house);
        this.getTrendData('WaterUse', this.getWaterUseResponse, tenant, site, house);
        this.getTrendData('ChickenCount', this.getPopulationResponse, tenant, site, house);
        this.getTrendData('EggCount', this.getEggCountResponse, tenant, site, house);
        this.getTrendData('FeedBinInventory', this.getFeedBinResponse, tenant, site, house);
    };

    getTrendData = (trendName, responseCallback, tenant, site, house) => {
        this.props.appProps.showLoader();
        this.prepareColumnDefinition();

        let temperatureUrl = '{apiUrl}/trends?tenantid=' + tenant +
            '&site=' + site +
            '&houseid=' + house +
            '&frequency=' + this.state.selectedFrequency + '&name=' + trendName;

        Service.getRequest(
            temperatureUrl,
            responseCallback,
            this.props.appProps.handleServiceError
        );

    };

    render() {

        let frequencys = [
            { description: "Daily", code: "D", tooltip: "Last 24 hours" },
            { description: "Weekly", code: "W", tooltip: "Last 7 Days" },
            { description: "Monthly", code: "M", tooltip: "Last 30 Days" },
            { description: "Yearly", code: "Y", tooltip: "Last 12 Months" },
            { description: "All", code: "A", tooltip: "All Time" }
        ];


        return (
            <>
                <div className="text-right pr-3 frequency-btn">
                    <ToggleButtonGroup type="checkbox" value={this.state.selectedFrequency}>
                        {frequencys.map((item, index) => {
                            return (
                                <ToggleButton key={"trendFrequencyButton_" + index} variant="primary" value={item.description} onChange={(e) => this.changeFrequency(e, item.description)}>{item.code}</ToggleButton>
                            );
                        })}
                    </ToggleButtonGroup>
                </div>

                <div class="row col-sm-12 trend-widget">


                    {/* TEMPERATURE */}
                    <div class="col-sm-6 spacer">
                        <div class="trend-section">
                            <div class="trend-section-header">Temperature</div>
                            <div class="trend-section-body">
                                <Tabs>
                                    <Tab eventKey={"LineChart"} title={<i className={"fa fa-line-chart text-primary"}></i>}>
                                        <ResponsiveContainer height={200} width="99%">
                                            <LineChart data={this.state.temperatureData}
                                                margin={{ top: 10, right: 20, left: 20, bottom: 10 }}>
                                                <XAxis name="Date" dataKey="TS_DATE"/>
                                                <YAxis domain={['auto', 'auto']}/>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <Tooltip content={<CustomTooltip />} />
                                                <Legend />
                                                <Line key="1" type="monotone" name="Low" dataKey="TempLow" stroke="Yellow" activeDot={{ r: 6 }}></Line>
                                                <Line key="2" type="monotone" name="Avg" dataKey="TempAvg" stroke="Green" activeDot={{ r: 6 }}></Line>
                                                <Line key="3" type="monotone" name="High" dataKey="TempHigh" stroke="Red" activeDot={{ r: 6 }}></Line>
                                                <Line key="4" type="monotone" name="Target" dataKey="TempTarget" stroke="Blue" activeDot={{ r: 6 }}></Line>

                                            </LineChart>
                                        </ResponsiveContainer>
                                    </Tab>
                                    <Tab eventKey={"Grid"} title={<i className={"fa fa-table text-primary"}></i>} width={1500} height={600}>
                                        {this.state.isTempDataLoaded && (
                                            <div className="trend-table">
                                                <BootstrapTable
                                                    bootstrap4={true}
                                                    keyField={this.state.xKey}
                                                    data={this.state.temperatureData}
                                                    columns={this.state.temperatureColumns}
                                                    bordered={false}
                                                    filter={filterFactory()}
                                                    noDataIndication="No Data"
                                                    hover
                                                    condensed
                                                />
                                            </div>
                                        )}

                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>

                    {/* MORTALITY COUNT */}
                    <div class="col-sm-6 spacer">
                        <div class="trend-section">
                            <div class="trend-section-header">Mortality Count</div>
                            <div class="trend-section-body">

                                <Tabs>
                                    <Tab eventKey={"LineChart"} title={<i className={"fa fa-line-chart text-primary"}></i>}>
                                        <ResponsiveContainer height={200} width="99%">
                                            <LineChart data={this.state.mortalityData}
                                                margin={{ top: 10, right: 20, left: 20, bottom: 10 }}>
                                                <XAxis name="Date" dataKey="TS_DATE" />
                                                <YAxis domain={['auto', 'auto']} />
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <Tooltip content={<CustomTooltip />} />
                                                <Legend />
                                                <Line key="1" type="monotone" name="Mortality Count" dataKey="MortalityCount" activeDot={{ r: 6 }}></Line>
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </Tab>
                                    <Tab eventKey={"Grid"} title={<i className={"fa fa-table text-primary"}></i>} width={1500} height={600}>
                                        {this.state.isMortalityDataLoaded && (
                                            <div className="trend-table">
                                                <BootstrapTable
                                                    bootstrap4={true}
                                                    keyField={this.state.xKey}
                                                    data={this.state.mortalityData}
                                                    columns={this.state.mortalityColumns}
                                                    bordered={false}
                                                    filter={filterFactory()}
                                                    noDataIndication="No Data"
                                                    hover
                                                    condensed
                                                />
                                            </div>
                                        )}

                                    </Tab>
                                </Tabs>

                            </div>
                        </div>
                    </div>

                    {/* FEED USED */}
                    <div class="col-sm-6 spacer">
                        <div class="trend-section">
                            <div class="trend-section-header">Feed Used</div>
                            <div class="trend-section-body">

                                <Tabs>
                                    <Tab eventKey={"LineChart"} title={<i className={"fa fa-line-chart text-primary"}></i>}>
                                        <ResponsiveContainer height={200} width="99%">
                                            <LineChart data={this.state.feedData}
                                                margin={{ top: 10, right: 20, left: 20, bottom: 10 }}>
                                                <XAxis name="Date" dataKey="TS_DATE" />
                                                <YAxis domain={['auto', 'auto']} />
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <Tooltip content={<CustomTooltip />} />
                                                <Legend />
                                                <Line key="1" type="monotone" name="Feed Used" dataKey="FeedUse" activeDot={{ r: 6 }}></Line>
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </Tab>
                                    <Tab eventKey={"Grid"} title={<i className={"fa fa-table text-primary"}></i>} width={1500} height={600}>
                                        {this.state.isFeedDataLoaded && (
                                            <div className="trend-table">
                                                <BootstrapTable
                                                    bootstrap4={true}
                                                    keyField={this.state.xKey}
                                                    data={this.state.feedData}
                                                    columns={this.state.feedColumns}
                                                    bordered={false}
                                                    filter={filterFactory()}
                                                    noDataIndication="No Data"
                                                    hover
                                                    condensed
                                                />
                                            </div>
                                        )}

                                    </Tab>
                                </Tabs>

                            </div>
                        </div>
                    </div>

                    {/* WATER USED */}
                    <div class="col-sm-6 spacer">
                        <div class="trend-section">
                            <div class="trend-section-header">Water Used</div>
                            <div class="trend-section-body">

                                <Tabs>
                                    <Tab eventKey={"LineChart"} title={<i className={"fa fa-line-chart text-primary"}></i>}>
                                        <ResponsiveContainer height={200} width="99%">
                                            <LineChart data={this.state.waterData}
                                                margin={{ top: 10, right: 20, left: 20, bottom: 10 }}>
                                                <XAxis name="Date" dataKey="TS_DATE" />
                                                <YAxis domain={['auto', 'auto']} />
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <Tooltip content={<CustomTooltip />} />
                                                <Legend />
                                                <Line key="1" type="monotone" name="Water Used" dataKey="Wateruse" activeDot={{ r: 6 }}></Line>
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </Tab>
                                    <Tab eventKey={"Grid"} title={<i className={"fa fa-table text-primary"}></i>} width={1500} height={600}>
                                        {this.state.isWaterDataLoaded && (
                                            <div className="trend-table">
                                                <BootstrapTable
                                                    bootstrap4={true}
                                                    keyField={this.state.xKey}
                                                    data={this.state.waterData}
                                                    columns={this.state.waterColumns}
                                                    bordered={false}
                                                    filter={filterFactory()}
                                                    noDataIndication="No Data"
                                                    hover
                                                    condensed
                                                />
                                            </div>
                                        )}

                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>

   

                    {/* FeedBin */}
                    <div class="col-sm-6 spacer">
                        <div class="trend-section">
                            <div class="trend-section-header">Feed Bin</div>
                            <div class="trend-section-body">

                                <Tabs>
                                    <Tab eventKey={"LineChart"} title={<i className={"fa fa-line-chart text-primary"}></i>}>
                                        <ResponsiveContainer height={200} width="99%">
                                            <BarChart
                                                data={this.state.feedBinData}
                                                margin={{ top: 10, right: 20, left: 20, bottom: 10 }}>
                                                <XAxis name="Date" dataKey="TS_DATE" />
                                                <YAxis domain={['auto', 'auto']} />
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <Tooltip content={<CustomTooltip />} />
                                                <Legend />
                                                <Bar key="1" name="Feed Inventory" dataKey="FeedInventory" fill="Blue" maxBarSize={15} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Tab>
                                    <Tab eventKey={"Grid"} title={<i className={"fa fa-table text-primary"}></i>} width={1500} height={600}>
                                        {this.state.isFeedBinDataLoaded && (
                                            <div className="trend-table">
                                                <BootstrapTable
                                                    bootstrap4={true}
                                                    keyField={this.state.xKey}
                                                    data={this.state.feedBinData}
                                                    columns={this.state.feedBinColumns}
                                                    bordered={false}
                                                    filter={filterFactory()}
                                                    noDataIndication="No Data"
                                                    hover
                                                    condensed
                                                />
                                            </div>
                                        )}

                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>

                    {/* EggCount */}
                    <div class="col-sm-6 spacer">
                        <div class="trend-section">
                            <div class="trend-section-header">EggCount</div>
                            <div class="trend-section-body">

                                <Tabs>
                                    <Tab eventKey={"LineChart"} title={<i className={"fa fa-line-chart text-primary"}></i>}>
                                        <ResponsiveContainer height={200} width="99%">
                                            <BarChart
                                                data={this.state.eggCountData}
                                                margin={{ top: 10, right: 20, left: 20, bottom: 10 }}>
                                                <XAxis name="Date" dataKey="TS_DATE" />
                                                <YAxis domain={['auto', 'auto']} />
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <Tooltip content={<CustomTooltip />} />
                                                <Legend />
                                                <Bar key="1" name="Egg Count" dataKey="EggCount" fill="Purple" maxBarSize={15} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Tab>
                                    <Tab eventKey={"Grid"} title={<i className={"fa fa-table text-primary"}></i>} width={1500} height={600}>
                                        {this.state.isEggCountDataLoaded && (
                                            <div className="trend-table">
                                                <BootstrapTable
                                                    bootstrap4={true}
                                                    keyField={this.state.xKey}
                                                    data={this.state.eggCountData}
                                                    columns={this.state.eggCountColumns}
                                                    bordered={false}
                                                    filter={filterFactory()}
                                                    noDataIndication="No Data"
                                                    hover
                                                    condensed
                                                />
                                            </div>
                                        )}

                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>

                    {/* POPULATION */}
                    <div class="col-sm-12 spacer">
                        <div class="trend-section">
                            <div class="trend-section-header">Population</div>
                            <div class="trend-section-body">

                                <Tabs>
                                    <Tab eventKey={"LineChart"} title={<i className={"fa fa-line-chart text-primary"}></i>}>
                                        <ResponsiveContainer height={200} width="99%">
                                            <BarChart
                                                data={this.state.populationData}
                                                margin={{ top: 10, right: 20, left: 20, bottom: 10 }}>
                                                <XAxis name="Date" dataKey="TS_DATE" />
                                                <YAxis domain={['auto', 'auto']} />
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <Tooltip content={<CustomTooltip />} />
                                                <Legend />
                                                <Bar key="1" name="Starting Population" dataKey="StartingPopulation" fill="Purple" maxBarSize={15} />
                                                <Bar key="2" name="Current Population" dataKey="CurrentPopulation" fill="Pink" maxBarSize={15} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Tab>
                                    <Tab eventKey={"Grid"} title={<i className={"fa fa-table text-primary"}></i>} width={1500} height={600}>
                                        {this.state.isPupulationDataLoaded && (
                                            <div className="trend-table">
                                                <BootstrapTable
                                                    bootstrap4={true}
                                                    keyField={this.state.xKey}
                                                    data={this.state.populationData}
                                                    columns={this.state.populationColumns}
                                                    bordered={false}
                                                    filter={filterFactory()}
                                                    noDataIndication="No Data"
                                                    hover
                                                    condensed
                                                />
                                            </div>
                                        )}

                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        );
    }
}

export default TrendWidget;
