import React, { Component } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";

// links that appear in navbar - they are separated from this component (navbar) so that we can redner them on responsive in sidebar as well
import HeaderLinks from "components/header/HeaderLinks.jsx";
import Service from "Service";
import logo from "assets/img/logo-icon.png";
import $ from "jquery";
// we import here the routes for dashboard pages (links that appear in sidebar) to set navbar's name

class Header extends Component {
  constructor(props) {
    super(props);
    this.handleMinimizeSidebar = this.handleMinimizeSidebar.bind(this);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
  }
  // makeBrand() {
  //   var name;
  //   //var dashRoutes = JSON.parse(sessionStorage.getItem(StorageId.Session.DashRoutes)).route;
  //   dashRoutes &&
  //     dashRoutes.map((prop, key) => {
  //       if (prop.collapse) {
  //         prop.views.map((prop, key) => {
  //           if (prop.path === this.props.location.pathname) {
  //             name = prop.name;
  //           }
  //           return null;
  //         });
  //       } else {
  //         if (prop.redirect) {
  //           if (prop.path === this.props.location.pathname) {
  //             name = prop.name;
  //           }
  //         } else {
  //           if (prop.path === this.props.location.pathname) {
  //             name = prop.name;
  //           }
  //         }
  //       }
  //       return null;
  //     });
  //   //return name;
  //   return "Rembrandt";
  // }
  // function that makes the sidebar from normal to mini and vice-versa
  handleMinimizeSidebar() {
    document.body.classList.toggle("sidebar-mini");
    setTimeout(() => {
      this.props.appProps.setAdjustMainContent(true);
    }, 200);
  }
  userDropdownToggle = () => {
    //$(".nav-user-dropdown").click();
    //$("#responsive-navbar-nav > a.dropdown-toggle").click();
    //$("#responsive-navbar-nav > .dropdown").toggleClass("show");
  }

  // function for responsive that hides/shows the sidebar
  mobileSidebarToggle(e) {
    document.documentElement.classList.toggle("nav-open");
  }
  render() {
    let tenantName = Service.getTokenData("TenantName");
    return (
      <Navbar expand="lg">
        <Navbar.Brand>
          <div className="navbar-minimize pr-1 d-none d-md-inline">
            <button
              id="minimizeSidebar"
              className="btn btn-default btn-fill btn-round btn-icon"
              onClick={this.handleMinimizeSidebar}
            >
              <i className="fa fa-navicon pt-1 visible-on-sidebar-regular" />
              <i className="fa fa-navicon pt-1 visible-on-sidebar-mini" />
            </button>
          </div>
          <Navbar.Toggle aria-controls="responsive-navbar-nav navbar-toggler" onClick={this.mobileSidebarToggle} className="mr-2">
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </Navbar.Toggle>
          <img src={logo} alt="Barn 365" style={{ height: "35px", marginTop: "-0.5em", display: "inline" }} className="d-md-none mr-2" />
          {tenantName === "ALL" ? "Barn 365" : tenantName}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav navbar-toggler" className="justify-content-end mr-2"  id="responsive-navbar-nav-mobile">
            <HeaderLinks  {...this.props} />
        </Navbar.Toggle>
        {window.matchMedia(`(min-width: 960px)`).matches &&
        <Navbar.Collapse aria-controls="responsive-navbar-nav navbar-toggler" id="responsive-navbar-nav" className="justify-content-end">
          <HeaderLinks  {...this.props} />
        </Navbar.Collapse>}
      </Navbar>

    );
  }
}

export default Header;
