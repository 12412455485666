const Constant = {
  //ApiBaseUrl: "http://127.0.0.1:3030/api",
  //ApiBaseUrl: "https://www.barn365.com/api",
  //ApiBaseUrl: "https://dev.barn365.com/api",
  ApiBaseUrl: "/api",
  PowerBIBaseUrl: "https://app.powerbi.com/reportEmbed?reportId=",
  Comma:",",
  EqualTo:"=",
  Colon:":",
  DatasourceType: {
    Api: 1
  },
  AllHouse: { id: "1", name: "ALL" },
  Radix : 10, //Decimal System
  GlobalState: {
    Tenant: "tenant",
    Site: "site",
    House: "house"
  },

  Action: {
    Add: "Add",
    Edit: "Edit",
    Delete: "Delete"
  },
    LogType: {
      Information: "info",
      Success: "success",
      Warning: "warning",
      Error: "error",
      Default: "info"
    },
  Notification: {
    Type: {
      Dynamic: "dynamic",
      Information: "info",
      Success: "success",
      Warning: "warning",
      Error: "error",
      Default: "info"
    },

    Position: {
      TopLeft: "tl",
      TopCenter: "tc",
      TopRight: "tr",
      BottomLeft: "bl",
      BottomCenter: "bc",
      BottomRight: "br",
      Default: "tc"
    },

    AutoDismiss: {
      Default: 5,
      Never: 0
    }
  }
};

export default Constant;
