import React, { Component } from "react";

import FormSelect from "components/controls/FormSelect";
import FormInput from "components/controls/FormInput";
import FormTextArea from "components/controls/FormTextArea";

class ApiSource extends Component {
  constructor(props) {
    super(props);
    // this is our app state
    this.state = {
      datasource: this.props.datasource
    };
  }

  handleInputChange = event => {
    this.props.handleInputChange(event);
  };

  // render the dashboard
  render() {
    return (
      <div>
        <FormInput
          id="datasource.url"
          type="text"
          name="URL"
          value={this.props.datasource.url}
          handleInputChange={this.handleInputChange}
        />
        <FormInput
          id="datasource.refresh"
          type="text"
          name="Refresh In Seconds"
          value={this.props.datasource.refresh}
          handleInputChange={this.handleInputChange}
        />
        <FormSelect
          id="datasource.method"
          type="select"
          name="Method"
          options={["GET", "POST", "PUT", "DELETE"]}
          value={this.props.datasource.method}
          handleInputChange={this.handleInputChange}
        />
        <FormTextArea
          id="datasource.body"
          type="text"
          name="Body"
          value={this.props.datasource.body}
          handleInputChange={this.handleInputChange}
        />
      </div>
    );
  }
}
export default ApiSource;
