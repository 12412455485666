import React, { Component } from "react";
import Card from "components/card/Card";
import PanelMode from "constants/PanelMode";
import Helper from "Helper";
import FrequencyButtons from "../common/FrequencyButtons";

export class DefaultPanel extends Component {
  render() {
    let panelStyle = {};
    if (this.props.mode === PanelMode.Normal) {
      if (this.props.panel.position.h === 0)
      {
        panelStyle = {
          position: "relative",
          width: "100%",
          height: "auto",
          maxHeight: "calc(100vh - 200px)",
          paddingBottom: "1em"
        };
      }
      //TODO: Remove Hardcoding after Sasi Change
      else if (this.props.panel.id=="PnlSJYio7OTM"){
        panelStyle = {
          overflow: "auto",
          position: "fixed",
          height: "auto",
          width: "99%",
          padding: "0em"
        };
      }
      else{
      panelStyle = {
        overflow: "auto",
        position: "relative",
        height: "auto",
        width: "99%",
        padding: "0em"
      };
    }
    } else if (this.props.mode === PanelMode.Edit) {
      panelStyle = {
        //overflow: "auto",
        maxHeight: "30vh",
        //width: "98%",
        paddingTop: "1em",
        marginBottom: "2em"
      };
    } else if (this.props.mode === PanelMode.View) {
      panelStyle = {
        //overflow: "auto",
        minHeight: "5vh",
        position: "absolute",
        //width: "98%",
        padding: "1em"
      };
    }
    return (
      <Card
        title={this.props.panel.title}
        category={this.props.panel.subtitle}
        data={this.props.data}
        content={
          <div className="card-body" style={panelStyle}>
              {this.props.panel.trend && this.props.panel.trend.show === true && 
                <FrequencyButtons
                frequency = {this.props.panel.trend.frequency}
                onChangePanelFrequency = {this.props.onChangePanelFrequency}
                />
              }
              {this.props.panel.widgets &&
                this.props.panel.widgets.map((item, index) => {
                  return this.props.renderWidget(
                    this.props.panel,
                    this.props.panel.widgets[index],
                    index
                  );
                })}
            
          </div>
        }
        footerBar={
          this.props.panel.footer.icon !== "" ||
          this.props.panel.footer.text !== ""
        }
        stats={
          <div>
            {this.props.panel.footer.icon && (
              <i
                className={Helper.getWidgetFieldValue(
                  this.props.data,
                  this.props.panel.footer.icon,
                  false
                )}
              />
            )}{" "}
            {Helper.getWidgetFieldValue(
              this.props.data,
              this.props.panel.footer.text,
              false
            )}
          </div>
        }
      />
    );
  }
}

export default DefaultPanel;
