import React, { Component } from "react";
import Helper from "Helper";

export class FeedWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      binData: []
    };
  }

  renderFeedBar = (bin) => {
    let total=bin.Capacity; 
    let weight=bin.Weight;
    let percent=bin.Percent
    
    if (weight === undefined) {
      return <div />;
    }

    let color = "warning";
    percent = percent * 100;
    percent = percent.format(2);

    return (
      <div className="pr-5">
        <div className="progress">
          <div
            className={"progress-bar progress-bar-" + color}
            role="progressbar"
            aria-valuenow={percent}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: percent + "%" }}
          />
          <div className="text-left pr-2" style={{ position: "absolute", zIndex: "10", paddingLeft: "0.2em", paddingTop: "0.8em" }}>
            {weight.format()}
          </div>
        </div>
      </div>
    );
  };

  prepareBins(bins) {
    let preparedBins = [];
    bins.forEach((bin, index) => {
      //Check for missing bin
      this.addMissingBins(preparedBins, bin.Bin);
      preparedBins.push(bin);
    });
    return preparedBins;
  }

  addMissingBins(preparedBins, expectedBin) {
    if (preparedBins.length + 1 < expectedBin) {
      preparedBins.push({
        Bin: preparedBins.length + 1
      });
      this.addMissingBins(preparedBins, expectedBin)
    } 

  }

  render() {
    let feedData = Helper.getWidgetFieldValue(
      this.props.data,
      this.props.panel.datasources[0].path,
      true
    );

    var maxBins = 0;
    var maxBinFeedDataIndex = 0;

    //Find Max No of Bins
    if (feedData !== null && feedData !== undefined) {
      feedData.forEach((feed, index) => {
        var maxIndex = feed.bins[feed.bins.length-1].Bin;
        if (maxIndex > maxBins) {
          maxBins = maxIndex;
          maxBinFeedDataIndex = index;
        }
      });
    }

    var binArray = [];
    for (var i = 0; i < maxBins; i++) {
      binArray.push(i);
    }

    return (
      <>
        {feedData && feedData[maxBinFeedDataIndex] && (
          <table id="feedTable" className="table table-striped" style={{ minWidth: "1000px", position: "relative" }}>
            <thead>
              <tr>
                <th className="text-left" style={{ width: "150px" }}>Site</th>
                <th className="text-left" style={{ width: "100px" }}>House</th>
                {binArray.map((value, index) => {
                  return (
                    <th className="text-left" key={index}>Bin {index + 1} (lbs) </th>
                  );
                })}
                {/* {feedData[maxBinFeedDataIndex].bins.map((item, index) => {
                  return (
                    <th className="text-left" key={index}>Bin {index + 1} (lbs) </th>
                  );
                })} */}
              </tr>
            </thead>
            <tbody>
              {feedData.map((binData, index) => {
                return (
                  binData && (
                    <tr key={binData.SiteId + "-" + index}>
                      <td className="text-left">{binData.SiteName}</td>
                      <td className="text-left">{binData.HouseName}</td>
                      
                      {this.prepareBins(binData.bins).map((bin, index) => {
                        return (
                          <td key={index}>
                            {this.renderFeedBar(bin)}
                          </td>
                        );
                      })}
                    </tr>
                  )
                );
              })}
            </tbody>
          </table>
        )}
      </>

    );
  }
}

export default FeedWidget;
