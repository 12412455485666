import React, { Component } from "react";
export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  componentDidCatch(error, errorInfo) {
    this.props.appProps.hideLoader();
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }
  unstable_handleError() {
    this.props.appProps.hideLoader();
  }
  render() {
    if (this.state.errorInfo) {
      return (<div>
        <h2>Something went wrong</h2>
        <details style={{ whiteSpace: 'pre-wrap' }}>
          {this.state.error && this.state.error.toString()}
          <br />
          {this.state.errorInfo.componentStack}
        </details>
      </div>);
    }
    // Render children if there's no error
    return this.props.children;
  }
}
