import React, { Component } from "react";
import { Nav, Collapse } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import Service from "Service";
//Images
// backgroundImage for Sidebar
import image from "assets/img/full-screen-image-33.jpg";
// logo for sidebar
import logo from "assets/img/logo-icon.png";


// this is used to create scrollbars on windows devices like the ones from apple devices
//import "perfect-scrollbar/dist/css/perfect-scrollbar.min.css";

import HeaderLinks from "components/header/HeaderLinks";
import Dialog from "components/common/Dialog";
import FormInput from "components/controls/FormInput";
import $ from "jquery";

import dashRoutes from 'routes/dash.jsx';
var ps;

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isWindows: navigator.platform.indexOf("Win") > -1 ? true : false,
      width: window.innerWidth,
      dashroutes: dashRoutes,
      showMainMenuDialog: false,
      menuitem: {},
      showEditMenuDialog: false,
      currentPath: props.location.pathname
    };
    // this.setState({dashroutes:JSON.parse(sessionStorage.getItem('dashroutes'))});
  }

  setAsActivePath(e, pathProp) {
    e.stopPropagation();
    console.log(pathProp.path);
    if (pathProp.collapse === undefined
      || pathProp.collapse === false) {
      this.props.history.push(pathProp.path);
      //window.location.href = "/#"+ pathProp.path;
      document.documentElement.classList.toggle("nav-open");
      //window.location.reload();
    }
    else {
      pathProp.isExpanded = !pathProp.isExpanded;
    }
    this.setState({ currentPath: pathProp.path });
  }

  isActiveRoute(routeName) {
    return window.location.href.endsWith(routeName) || window.location.href.indexOf(routeName + "/") !== -1;
  }

  // verifies if routeName is the one active (in browser input)
  getActiveRouteClass = routeName => {
    return this.isActiveRoute(routeName) === true ? "active" : "";
  };

  isExpanded(path) {
    let returnValue = this.isActiveRoute(path.path);
    if (path.isExpanded === undefined) {
      path.isExpanded = returnValue;
    }
    return path.isExpanded;
  }

  componentDidMount() {
  }
  componentDidUpdate() {
  }

  componentWillUnmount() {
  }

  // function that creates perfect scroll bar for windows users (it creates a scrollbar that looks like the one from apple devices)
  isMac() {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }

  mainAddHandler = path => {
    this.setState({ showMainMenuDialog: true });
  };

  subAddHandler = path => {
    alert("sub add" + this.props.location.pathname);
  };

  editHandler = path => {
    alert("edit" + this.props.location.pathname);
    const pathname = this.props.location.pathname;
    var menuitem = this.state.dashroutes.filter(function (menuitem) {
      return menuitem.path === pathname;
    });
    alert(JSON.stringify(menuitem));
    this.setState({ menuitem: menuitem[0] });
    this.setState({ showEditMenuDialog: true });
  };

  deleteHandler = path => {
    var pathname = this.props.location.pathname;
    var dashroutes = this.state.dashroutes;
    dashroutes = dashroutes.filter(function (menuitem) {
      return menuitem.path !== pathname;
    });
    this.setState({ dashroutes: dashroutes });
  };

  saveHandler = () => {
    Service.saveRoutes(this.state.dashroutes);
  };

  onMainMenuDialogSave = () => {
    this.setState({ showMainMenuDialog: false });
    var menuitem = this.state.menuitem;
    menuitem.path = "/" + menuitem.name;
    menuitem.component = "Dashboard";
    var dashroutes = this.state.dashroutes;
    dashroutes.splice(dashroutes.length - 1, 0, menuitem);
    this.setState({ dashroutes: dashroutes });
  };

  onMainMenuDialogClose = () => {
    this.setState({ showMainMenuDialog: false });
    this.setState({ showEditMenuDialog: false });
  };
  handleInputChange = event => {
    var menuitem = this.state.menuitem;
    let target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    let name = target.name;
    eval(name + "='" + value + "'");
    this.setState({ menuitem: menuitem });
  };

  onEditMenuDialogSave = () => {
    var pathname = this.props.location.pathname;
    var dashroutes = this.state.dashroutes;
    var menuitem = this.state.menuitem;
    dashroutes = dashroutes.map(function (item) {
      if (item.path === pathname) {
        item.path = "/" + menuitem.name;
        item.name = menuitem.name;
      }
      return item;
    });
    this.setState({ showEditMenuDialog: false });
    this.setState({ dashroutes: dashroutes });
    this.props.history.push("/" + menuitem.name);
  };

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    // let menuKey = 0;
    // return (dashRoutes &&
    //   dashRoutes.map((prop, key) => {
    //     menuKey++;
    //     var st = {};
    //     st[prop["state"]] = !this.state[prop.state];
    //     if (prop.collapse && prop.showInNavigation !== false) {
    //       return (
    //         <li className={this.activeRoute(prop.path)} key={"Menu" + menuKey} onClick={(e) => this.setAsActivePath(e, prop)}>
    //           <a>
    //             <i className={prop.icon} />
    //             <p>
    //               {prop.name}
    //               <b
    //                 className={
    //                   this.isExpanded(prop)
    //                     ? "caret rotate-180"
    //                     : "caret"
    //                 }
    //               />
    //             </p>
    //           </a>
    //           <Collapse in={this.isExpanded(prop)}>
    //             <ul className="nav">
    //               {prop.views.map((prop, key) => {
    //                 menuKey++;
    //                 return (
    //                   prop.showInNavigation !== false && <li
    //                     className={this.activeRoute(prop.path)}
    //                     key={menuKey}
    //                     onClick={(e) => this.setAsActivePath(e, prop)}
    //                   >
    //                     <a>
    //                       <span className="sidebar-mini">
    //                         {prop.icon && <i className={prop.icon}></i>}
    //                         {prop.mini && prop.mini}
    //                       </span>
    //                       <span className="sidebar-normal pt-2">
    //                         {prop.name}
    //                       </span>
    //                     </a>
    //                   </li>
    //                 );
    //               })}
    //             </ul>
    //           </Collapse>
    //         </li>
    //       );
    //     } else if (prop.showInNavigation !== false) {
    //       // if (prop.redirect) {
    //       //   return null;
    //       // } else {
    //       return (
    //         <li className={this.activeRoute(prop.path)} key={menuKey} onClick={(e) => this.setAsActivePath(e, prop)}>
    //           <a>
    //             <i className={prop.icon} />
    //             <p>{prop.name} </p>
    //           </a>
    //         </li>
    //       );
    //       //}
    //     }
    //     else {
    //       return "";
    //     }
    //   }));



    return routes.map((prop, key) => {
      
      if (prop.showInNavigation != undefined
            || prop.showInNavigation == false) {
        return null;
      }
      
      //if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li
            className={"nav-item " + this.getActiveRouteClass(prop.path)}
            key={key}
          >
            <a
              className= "nav-link"
              data-toggle="collapse"
              aria-expanded={this.isExpanded(prop)}
              onClick={(e) => this.setAsActivePath(e, prop)}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p> 
                    {prop.name}
                    {prop.views &&
                    <b className="caret" />}
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    {prop.views &&
                    <b className="caret" />}
                  </span>
                </>
              )}
            </a>
            {prop.views &&
            <div className={this.isExpanded(prop)?"collapse show":"collapse"} isOpen={this.isActiveRoute(prop.path)}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </div>
            }
          </li>
        );
      //}
      // return (
      //   <li className={this.activeRoute(prop.path)} key={key}>
      //     <NavLink to={prop.path} activeClassName="">
      //       {prop.icon !== undefined ? (
      //         <>
      //           <i className={prop.icon} />
      //           <p>{prop.name}</p>
      //         </>
      //       ) : (
      //         <>
      //           <span className="sidebar-mini-icon">{prop.mini}</span>
      //           <span className="sidebar-normal">{prop.name}</span>
      //         </>
      //       )}
      //     </NavLink>
      //   </li>
      // );
    });


  };

  render() {
    var dashRoutes = this.state.dashroutes;
    return (
      <div className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}>
        <div className="logo text-center">
          <a href="/" className="simple-text visible-on-sidebar-mini logo-mini">
              <img className="mt-1" src={logo} alt="Barn 365"/>
          </a>
          <a href="/" className="simple-text logo-normal">
            <span className="logoBarn"></span>
          </a>
        </div>

        <div className="sidebar-wrapper" ref="sidebar">
          {/* <div className="user">
            <div className="photo">
              <img src={avatar} alt="Avatar" />
            </div>
            <div className="info">
              <a
                href="#pablo"
                data-toggle="collapse"
                aria-expanded={this.state.openAvatar}
                onClick={() =>
                  this.setState({ openAvatar: !this.state.openAvatar })
                }
              >
                <span>
                  Chet Faker
                  <b className="caret" />
                </span>
              </a>
              <Collapse isOpen={this.state.openAvatar}>
                <ul className="nav">
                  <li>
                    <NavLink to="/admin/user-profile" activeClassName="">
                      <span className="sidebar-mini-icon">MP</span>
                      <span className="sidebar-normal">My Profile</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/user-profile" activeClassName="">
                      <span className="sidebar-mini-icon">EP</span>
                      <span className="sidebar-normal">Edit Profile</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/user-profile" activeClassName="">
                      <span className="sidebar-mini-icon">S</span>
                      <span className="sidebar-normal">Settings</span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div> */}
          <ul className="nav">{this.createLinks(dashRoutes)}</ul>
        </div>
        <div>
          <Dialog
            title={" Add Main Menu "}
            showModal={this.state.showMainMenuDialog}
            successButtonText="Save"
            closeButtonText="Close"
            onSuccessClick={this.onMainMenuDialogSave}
            onCloseClick={this.onMainMenuDialogClose}
          >
            <form>
              <FormInput
                id="datasource.id"
                type="hidden"
                name="ID"
                value={this.props.id}
                handleInputChange={this.handleInputChange}
              />
              <FormInput
                id="menuitem.name"
                type="text"
                name="Name"
                value={this.state.menuitem.name}
                handleInputChange={this.handleInputChange}
              />
              <FormInput
                id="menuitem.icon"
                type="text"
                name="icon"
                value={this.state.menuitem.icon}
                handleInputChange={this.handleInputChange}
              />
            </form>
          </Dialog>
        </div>
        <div>
          <Dialog
            title={" Edit Menu "}
            showModal={this.state.showEditMenuDialog}
            successButtonText="Save"
            closeButtonText="Close"
            onSuccessClick={this.onEditMenuDialogSave}
            onCloseClick={this.onMainMenuDialogClose}
          >
            <form>
              <FormInput
                id="datasource.id"
                type="hidden"
                name="ID"
                value={this.props.id}
                handleInputChange={this.handleInputChange}
              />
              <FormInput
                id="menuitem.name"
                type="text"
                name="Name"
                value={this.state.menuitem.name}
                handleInputChange={this.handleInputChange}
              />
              <FormInput
                id="menuitem.icon"
                type="text"
                name="icon"
                value={this.state.menuitem.icon}
                handleInputChange={this.handleInputChange}
              />
            </form>
          </Dialog>
        </div>
      </div>
    );
  }
}

export default Sidebar;
