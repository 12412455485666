//import JsonPath from "jsonpath";
//import JSONPath from "jsonpath";
import {JSONPath} from 'jsonpath-plus';

class Helper {
  static getWidgetFieldValue(data, value, allowObject) {
    if (
      value &&
      value.startsWith("$.") === true &&
      value.endsWith(".") === true
    ) {
      //TODO:Remove this in PROD
      console.log("Error: Incomplete Path (" + value + ") Syntax!");
      return null;
    }
    //TODO: Use RegEx for better
    if (value && value.startsWith("$.")) {
      value = Helper.getDataFromJsonPathQuery(data, value);
    }

    if(value === undefined || value === null)
    {
       return null;
    }

    let valueType = typeof value;
    //console.log(valueType)
    if (allowObject === false && (valueType === "object" || valueType.includes("object") === true )) {
      //Warn the user
      console.warn(
        "Error: Value (" +
          value +
          ") can't be Object. We expect value. But, getting the object back"
      );
      return null;
    }
    return value;
  }

  static getStringAsVariable(value){
    if(this.isNullOrUndefined(value) === true){
      return null;
    }
    let valueType = typeof(value);
    switch(valueType){
      case "number":
        return value;
      default:
        return "'"+ value + "'";
    }
    return value;
  }

  static getSiteName(site){
    var siteName = "";
    if (this.isNullOrEmpty(site.TenantSiteID) === false){
      siteName = site.TenantSiteID;
    }
    else{
      siteName = site.name;
    }
    return siteName;
  }

  static getHouseName(house){
    var houseName = "";
    if (this.isNullOrEmpty(house.TenantHouseID) === false){
      houseName = house.TenantHouseID;
    }
    else{
      houseName = house.name;
    }
    return houseName;
  }

  static getDataFromJsonPathQuery(data, jsonPathQuery) {
    //return JsonPath.query(data, jsonPathQuery).pop();
    // console.log( jsonPathQuery + " : \n")
    // console.log( "Data:" + JSON.stringify(data))
    var returnValue = null;
    if (data === undefined || jsonPathQuery === undefined || jsonPathQuery === "")
    {
      console.warn( "Path:" + jsonPathQuery + " Data: Not Found / undefined")
      returnValue = null;
    }
    returnValue = JSONPath({ path: jsonPathQuery, json: data });
    if(returnValue !== undefined){
      returnValue = returnValue.pop();
    }
    
    //returnValue = JsonPath.query(data, jsonPathQuery).pop();
    return returnValue;
  }

    static getDataFromJsonPathQueryNoPop(data, jsonPathQuery) {
    //return JsonPath.query(data, jsonPathQuery).pop();
    //console.log( jsonPathQuery + " : \nData:" + JSON.stringify(data))
    if (data === undefined || jsonPathQuery === undefined || jsonPathQuery === "")
    {
      console.warn( "Path:" + jsonPathQuery + " Data: Not Found / undefined")
      return null;
    }
    return JSONPath({ path: jsonPathQuery, json: data });
  }

  static isNullOrUndefined(obj)
  {
      let returnValue = false;
      if(obj === null || obj === undefined)
      {
        returnValue = true;
      }
      return returnValue;
  }

  static isNullOrEmpty(obj)
  {
      let returnValue = false;
      if(obj === null || obj === undefined || obj === "")
      {
        returnValue = true;
      }
      return returnValue;
  }

  static format(value){
    if (value === undefined 
        || value === null)
    {
      value = 0;
    }
    return value.format();
  }

  static ParseJson(value, defaultValue){
    try {
      return JSON.parse(value);
    } catch (error) {
      if (defaultValue === undefined)
        return {};
      else
        return defaultValue;
    }
  }

  static IsIE11OrLess()
  {
     let ver = 100;
     var ua = window.navigator.userAgent;
     //Check for Older version of IE
     var msie = ua.indexOf('MSIE ');
     if (msie > 0) {
         return true;
     }
     //Check for IE11
     var trident = ua.indexOf('Trident/');
     if (trident > 0) {
         // IE 11 => return version number
         return true;
     }
     return false;
  }
}

export default Helper;
