import React from "react";

class FormTextArea extends React.Component {
  handleInputChange = event => {
    this.props.handleInputChange(event);
  };
  render() {
    var groupClassName = "";
    if (this.props.type === "hidden") {
      return (
        <input
          onChange={this.handleInputChange}
          type={this.props.type}
          id={this.props.id.replace(".", "_")}
          name={this.props.id}
          value={this.props.value}
        />
      );
    }
    var placeholder = this.props.placeholder;
    if (this.props.placeholder === undefined) {
      placeholder = this.props.name;
    }
    if (this.props.groupClassName !== undefined) {
      groupClassName = this.props.groupClassName
    }
    return (
      <>
        <div className={"form-group " + this.props.labelWidth + " " + this.props.groupClassName}>
          <label className={"control-label " + (this.props.required === true ? "required" : "") + " " + this.props.labelClass} htmlFor={this.props.id}>{this.props.name}</label>
        </div>
        <div className={"form-group " + this.props.controlWidth + " " + this.props.groupClassName}>
          <textarea
            onChange={this.handleInputChange}
            type={this.props.type}
            className={"form-control " + this.props.className}
            id={this.props.id.replace(".", "_")}
            name={this.props.id}
            placeholder={placeholder}
            value={this.props.value}
            maxLength={this.props.maxLength}
          >

          </textarea>
        </div>
      </>
    );
  }
}

export default FormTextArea;
