import React, { Component } from "react";
import Helper from "Helper";
import StorageId from "constants/StorageId";
import { Link } from "react-router-dom";
import {
  OverlayTrigger,
  Tooltip,
  ButtonGroup,
  Pagination,
  Grid,
  Row,
  Col
} from "react-bootstrap";

export class SiteWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sites : undefined
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.match.params.tenantId && nextProps.match.params.tenantId !== this.props.match.params.tenantId) {
      this.loadCurrentSites(nextProps.match.params.tenantId);
    }
  }


  componentDidMount = () => {
    this.loadCurrentSites(this.props.match.params.tenantId);
  };

  loadCurrentSites = (tenantId) => {
    let tenants = JSON.parse(
      sessionStorage.getItem(StorageId.Session.Tenants)
    );

    let tenant = tenants.filter( t => t.id == tenantId);
    let sites = null;
    if (Helper.isNullOrUndefined(tenant) === false)
      sites = tenant[0].sites;

    if (sites === null){
      return null;
    }
    //Avoid Error when widget is NOT configured
    if (
      sites !== undefined &&
      sites !== null &&
      Array.isArray(sites) === true
    ) {
      sites = sites.sortBy(s => String(s.name));
    }
    this.setState({sites:sites});
  }

  render() {
    return (
      <>
        <div className="flex-grid">
          {this.state.sites && this.state.sites.map((site, key) => {
            let locationUrl = `/Controls/${this.props.match.params.tenantId}/${site.id}`;
            return (
              <div className="flex-col-4 site" key={key}>
                <Link to={locationUrl}>
                  <i className="fa fa-map-marker fa-square-bg" /> <span className="text-primary pl-3">{Helper.getSiteName(site)}</span>
                  </Link>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

export default SiteWidget;
