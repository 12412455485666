import React, { Component } from "react";
import Helper from "Helper";

export class ColumnWidget extends Component {
  // getValue = (value) =>
  // {
  //     if (value
  //             && value.startsWith("$."))
  //     {
  //         console.log(value);
  //         value = JsonPath.query(this.props.data, value).pop();
  //         console.log(JSON.stringify(value));
  //     }
  //     return value;
  // }
  render() {
    //Avoid Error when widget is NOT configured
    if (this.props.widget.columns === undefined)
    {
      return <div className="text-center">Widget configuration is incomplete. Please contact your Administrator</div>
    }
    var columns = this.props.widget.columns.filter(item => {
      return item.icon !== "" || item.text !== "";
    });
    return (
      <div className="flex-grid-nowrap mb-3">
        {columns.map((column, index) => {
          let columnValue = Helper.getWidgetFieldValue(this.props.data, column.value, false);
          switch(typeof(columnValue))
          {
            case "number":
              columnValue = columnValue.format();
              break;
            default:
              break;
          }
          let columnClass = "col-icon";
          if (column.icon === ""){
            if(columns.length > this.props.panel.position.w){
              columnClass = "flex-auto-col";
            }
            else{
              columnClass = "col";
            }
            if(Helper.getWidgetFieldValue(
            this.props.data,
            column.value,
            false
          ) === null){
            return null;
          }
        }

          return (
            <div key={index} className={columnClass}>
              {column.icon !== "" && (
                <div className="text-center">
                  <i
                    className={Helper.getWidgetFieldValue(
                      this.props.data,
                      column.icon,
                      false
                    )}
                  />
                </div>
              )}

              {column.icon === "" && Helper.getWidgetFieldValue(
                      this.props.data,
                      column.value,
                      false
                    ) != null && (
                <div className="text-center">
                  {/* Text */}
                  <div>
                    {Helper.getWidgetFieldValue(
                      this.props.data,
                      column.text,
                      false
                    )}
                  </div>
                  <span>
                    {/* Prefix */}
                    {Helper.getWidgetFieldValue(
                      this.props.data,
                      column.prefix,
                      false
                    )}{" "}
                  </span>
                  <span className="columnValue">
                    {/* Data */}
                    {columnValue}
                  </span>
                  <span className="hidden-xs hidden-sm">
                    {" "}
                    {/* Suffix */}
                    {Helper.getWidgetFieldValue(
                      this.props.data,
                      column.suffix,
                      false
                    )}
                  </span>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default ColumnWidget;
