import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import Helper from "Helper";

const CustomTooltip = ({ active, payload, label }) => {
    
  if(payload && payload.length > 0) {
      console.log(payload);
      const items = [];
      
      items.push(<div>{label}</div>)
      for (const [index, value] of payload.entries()) {

        if(value.payload.ACT_DATE !== undefined && index === 0) {
          items.push(<div>{"Date"} : {value.payload.ACT_DATE}</div>) 
        }
        items.push(<div style={{"color":value.color}}> {value.name} : {value.value}</div>)
      }
      
      return (
          <div className="recharts-default-tooltip">
              <div className="recharts-tooltip-item-list">
                  {items}
              </div>
          </div>
      );
  } else {
      return '';
  }
};

class BarChartWidget extends React.Component {
  render() {
    //Avoid Error when widget is NOT configured
    if (this.props.widget.xAxis === undefined)
    {
        return <div className="text-center">Widget configuration is incomplete. Please contact your Administrator</div>
    }
    
    var data = Helper.getWidgetFieldValue(
      this.props.data,
      this.props.panel.datasources[0].path,
      true
    );
    return (
      <ResponsiveContainer
        width={this.props.widget.width}
        height={this.props.widget.height}
      >
        <BarChart
          data={data}
          //maxBarSize={3}
          margin={{
            top: this.props.widget.top,
            right: this.props.widget.right,
            left: this.props.widget.left,
            bottom: this.props.widget.bottom
          }}
        >
          <XAxis name={this.props.widget.xAxis.nameKey} dataKey={this.props.widget.xAxis.dataKey} />
          <YAxis domain={['auto', 'auto']}/>
          {this.props.widget.showCartesianGrid && (
            <CartesianGrid strokeDasharray="3 3" />
          )}
          {this.props.widget.showTooltip && <Tooltip content={<CustomTooltip />}/>}
          {this.props.widget.showLegend && <Legend />}
          {this.props.widget.yAxis.map((item, index) => {
            if (item.dataKey !== "") {
              return (
                <Bar key={index} name={item.nameKey} dataKey={item.dataKey} fill={item.color} maxBarSize={15} />
              );
            }
          })}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default BarChartWidget;
