import React from "react";
import Service from "../../Service";
import Constant from "../../constants/Constant";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import Dialog from "components/common/Dialog";

class ImportFilesWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileList: [],
      treePathItem: {},
      historyPath: ["/"],
      crudObject: {},
      newFolder: {},
      tenantId: props.match.params.tenantId,
      siteId: props.match.params.siteId,
      showCreateFolderDialog: false,
      columns: [
        {
          dataField: "name",
          text: "Name",
          style: {width: "60%"},
          formatter: (cell, row, rowIndex) => {
            return (
              <div>
                <a
                  onClick={() => {
                    if (row.type === "file") {
                      this.downloadFile(row.url);
                    } else {
                      this.loadFolder(row.path, row.name);
                    }
                  }}
                  className="d-flex align-items-center"
                >
                  <div>
                    <i
                      className={`mr-2 font-24 ${this.getFileIcon(row.path)}`}
                    />
                  </div>
                  <div className="font-weight-bold">{cell}</div>
                </a>
              </div>
            );
          },
          sort: true,
        },
        {
          dataField: "createdDate",
          text: "Create date",
          style: {width: "30%"},
          sort: true,
          formatter: (cell, row, rowIndex) => {
            return <>{row.type !== "folder" && moment(cell).format("LLL")}</>;
          },
          sortFunc: (a, b, order, dataField, rowA, rowB) => {
            if (order === "asc") {
              return new Date(a) - new Date(b);
            }
            return new Date(b) - new Date(a); // desc
          },
        },
        {
          align: "center",
          style: {width: "10%"},
          formatter: (cell, row, rowIndex) => {
            return (
              <>
                {row.name !== "..." && (
                  <a onClick={() => this.deleteRow(row)}>
                    <div>
                      <i className="fa fa-trash">{cell}</i>
                    </div>
                  </a>
                )}
              </>
            );
          },
        },
      ],
    };

    this.uploadFileInput = null;

    this.getFiles = this.getFiles.bind(this);
    this.getFilesResponse = this.getFilesResponse.bind(this);
    this.chooseFiles = this.chooseFiles.bind(this);
    this.onFilesChange = this.onFilesChange.bind(this);
    this.uploadResponseHandle = this.uploadResponseHandle.bind(this);
    this.getFileIcon = this.getFileIcon.bind(this);
  }

  componentDidMount() {
    this.getFiles();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.tenantId !== this.props.match.params.tenantId) {
      this.setState(
        {
          tenantId: nextProps.match.params.tenantId,
        },
        () => {
          this.getFiles();
        }
      );
    } else if (nextProps.match.params.siteId !== this.props.match.params.siteId) {
      this.setState(
        {
          siteId: nextProps.match.params.siteId,
        },
        () => {
          this.getFiles();
        }
      );
    }
  }

  buildTreeFileList(folder) {
    var subFolder = folder.subFolder || [];
    return [
      ...subFolder.map((f) => ({
        ...f,
        type: "folder",
      })),
      ...folder.files.map((f) => ({
        ...f,
        type: "file",
        name: f.path.substring(f.path.lastIndexOf("/") + 1),
      })),
    ];
  }

  buildFileTree(folder, tree) {
    // debugger;
    var treePath = folder.path ? folder.path : "/";
    tree[treePath] = this.buildTreeFileList(folder);
    if (folder.subFolder && folder.subFolder.length) {
      folder.subFolder.forEach((subfolder) => {
        this.buildFileTree(subfolder, tree);
      });
    }

    return tree;
  }

  getFilesResponse(response) {
    var fileTree = this.buildFileTree(response.body.data.fileList, {});
    this.setState({ treePathItem: fileTree });

    const currentFolder =
      this.state.historyPath[this.state.historyPath.length - 1];
    this.loadFolder(currentFolder);
    this.props.appProps.hideLoader();
  }

  downloadFile(url) {
    const link = window.document.createElement("a");
    link.href = url;
    window.document.body.appendChild(link);
    link.click();
    window.document.body.removeChild(link);
  }

  loadFolder(path, name) {
    const historyPath = [...this.state.historyPath];

    let fileList = [];

    fileList.unshift({ name: "...", path: path, type: "folder" });

    if (name !== "...") {
      fileList = [...this.state.treePathItem[path]];
      if (path !== "/") {
        historyPath.push(path);
        fileList.unshift({ name: "...", path: path, type: "folder" });
      }
    } else {
      historyPath.pop();
      const previousPath = historyPath[historyPath.length - 1];
      fileList = [...this.state.treePathItem[previousPath]];
      if (previousPath !== "/") {
        fileList.unshift({ name: "...", path: previousPath, type: "folder" });
      }
    }
    // this.props.appProps.hideLoader();
    this.setState({ fileList, historyPath });
  }

  uploadResponseHandle() {
    this.props.appProps.hideLoader();
    this.getFiles();
  }

  getFiles() {
    this.props.appProps.showLoader();
    const tenantId = this.state.tenantId;
    const siteId = this.state.siteId;
    Service.getRequest(
      `{apiUrl}/user-upload?tenantID=${tenantId}&siteID=${siteId}`,
      this.getFilesResponse,
      this.props.appProps.handleServiceError
    );
  }

  showSuccessMessage = () => {
    this.props.appProps.showNotification(
      "Deleted successfully.",
      Constant.Notification.Type.Success
    );
  };

  onDeleteSuccess = () => {
    this.showSuccessMessage();
    this.getFiles();
  };

  confirmDelete = () => {
    this.props.appProps.showLoader();
    const dataDel = this.state.crudObject;
    const tenantId = this.state.tenantId;
    const siteId = this.state.siteId;
    this.props.appProps.hideAlert();
    Service.deleteRequest(
      `{apiUrl}/user-upload/${dataDel.type}?path=${dataDel.path}&tenantID=${tenantId}&siteID=${siteId}`,
      null,
      this.onDeleteSuccess,
      this.props.appProps.handleServiceError
    );
  };

  deleteRow = (row) => {
    this.setState({ crudObject: row });
    this.props.appProps.showAlert({
      title: "Are you sure?",
      body: "You will not be able to undo this action!",
      confirmBtnText: "Yes, delete it!",
      onConfirm: this.confirmDelete,
    });
  };

  createFolder() {
    this.setState({ showCreateFolderDialog: true });
  }

  onMainMenuDialogClose = () => {
    this.setState({ showCreateFolderDialog: false });
  };

  handleInputChange = (event) => {
    const target = event.target.value.trim();
    const folderNew = {
      name: target,
      path: `${target}`,
      type: "folder",
    };

    this.setState({ newFolder: folderNew });
  };

  onMainMenuDialogSave = () => {
    this.setState({ showCreateFolderDialog: false });
    const folderNew = this.state.newFolder;

    if(!folderNew.path || folderNew.path.length === 0) {
      return;
    }

    const historyPath = [...this.state.historyPath];

    const currentFolder = historyPath[historyPath.length - 1];

    const tenantId = this.state.tenantId;

    const siteId = this.state.siteId;

    Service.uploadRequest(
      "{apiUrl}/user-upload",
      [],
      [
        { key: "folder", value: `${currentFolder}${folderNew.path}` },
        { key: "type", value: "folder" },
        { key: "tenantID", value: tenantId },
        { key: "siteID", value: siteId },
      ],
      this.uploadResponseHandle,
      this.props.appProps.handleServiceError
    );
  };

  chooseFiles() {
    this.uploadFileInput.click();
  }

  onFilesChange(e) {
    e.preventDefault();
    e.stopPropagation();

    this.props.appProps.showLoader();

    const historyPath = [...this.state.historyPath];
    const tenantId = this.state.tenantId;
    const currentFolder = historyPath[historyPath.length - 1];
    const siteId = this.state.siteId;

    Service.uploadRequest(
      "{apiUrl}/user-upload",
      e.target.files,
      [
        { key: "folder", value: currentFolder },
        { key: "type", value: "file" },
        { key: "tenantID", value: tenantId },
        { key: "siteID", value: siteId },
      ],
      this.uploadResponseHandle,
      this.props.appProps.handleServiceError
    );
  }

  getBreadcrumb() {
    var bc = this.state.historyPath.slice(-1).pop();

    if(this.state.historyPath.length <= 1) {
      bc = "/"
    }

    return /^\//.test(bc) ? bc : "/" + bc
  }

  getFileIcon(fileName) {
    var fileExt = fileName.substring(fileName.lastIndexOf(".") + 1);
    var folderExt = fileName.substring().slice(-1);
    if (folderExt === "/") {
      return (fileExt = "fa fa-folder-o");
    }
    switch (fileExt) {
      case "pdf":
        return "fa fa-pdf-o";
      case "doc":
      case "docx":
        return "fa fa-word-o";
      case "ppt":
      case "pptx":
        return "fa fa-powerpoint-o";
      case "xls":
      case "xlsx":
        return "fa fa-excel-o";
      case "txt":
        return "fa fa-text-o";
      case "zip":
      case "rar":
      case "7zip":
        return "fa fa-zip-o";
      case "jpg":
      case "jpeg":
      case "png":
      case "webp":
      case "bmp":
      case "gif":
        return "fa fa-picture-o";
      case "mp3":
      case "wav":
        return "fa fa-sound-o";
      case "mp4":
      case "wmv":
      case "avi":
      case "mkv":
        return "fa fa-video-o";
      default:
        return "fa fa-file-o";
    }
  }

  render() {
    return (
      <div className="p-3">
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h5 className="mb-0">Files</h5>{" "}
                <div>
                  <i>
                    Path:{" "}
                    {this.getBreadcrumb()}
                  </i>
                </div>
              </div>
              <label htmlFor="file">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => this.createFolder()}
                >
                  Create Folder
                </button>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={this.chooseFiles}
                >
                  Upload files
                </button>
                <p>Allow file types: pdf, excel, word, images</p>
                <input
                  name="file"
                  id="file"
                  multiple
                  style={{ display: "none" }}
                  ref={(ref) => {
                    this.uploadFileInput = ref;
                  }}
                  type="file"
                  accept="image/* , .pdf , .doc, .docx, .xls, .csv, .xlsx"
                  onChange={this.onFilesChange}
                />
              </label>
            </div>
            <div>
              <Dialog
                title={" Add new Folder "}
                showModal={this.state.showCreateFolderDialog}
                successButtonText="Save"
                closeButtonText="Close"
                onSuccessClick={this.onMainMenuDialogSave}
                onCloseClick={this.onMainMenuDialogClose}
              >
                <input
                    type="text"
                    onChange={this.handleInputChange}
                    placeholder="Folder name"
                  />
                {/* <form>
                  <FormInput
                    id="menuitem.name"
                    type="text"
                    name="Name"
                    value={this.state.menuitem.name}
                    handleInputChange={this.handleInputChange}
                  />
                </form> */}
              </Dialog>
            </div>
            <div className="mt-3">
              <BootstrapTable
                keyField="path"
                data={this.state.fileList}
                columns={this.state.columns}
                noDataIndication="There is no files to display. Use buttons on right corner to upload files"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ImportFilesWidget;
