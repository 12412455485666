import React from "react"
import HeatMap from 'react-heatmap-grid'

class HeatMapWidget extends React.Component {
     
  render() {
    //Avoid Error when widget is NOT configured
    if (this.props.widget.xLabels === undefined)
    {
        return <div className="text-center">Widget configuration is incomplete. Please contact your Administrator</div>
    }

    return (
        <HeatMap
            xLabels={this.props.data.xLabels}
            yLabels={this.props.data.yLabels}
            data={this.props.data.values}
        />
    );
  }
}

export default HeatMapWidget; 
