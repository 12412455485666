import React, { Component } from "react";
import {
  Nav,
  NavDropdown,
  DropdownItem
} from "react-bootstrap";
import $ from "jquery";
import Service from "Service";
import Role from "constants/Role";

class HeaderLinks extends Component {
  onToggleDashTools = () => {
    $("#dashTools").slideToggle();
  };

  onLogOutClick = () => {
    let UserAuditId = sessionStorage.getItem("UserAuditId");

    //let offset = new Date().getUTCOffset()
    let offset=(new Date() + "").split("GMT")[1].split(" ")[0];
  
    Service.putRequest("{apiUrl}/user-audit",
      UserAuditId,
      {
        utcOffset : offset
      },
      res => {
        window.location.href = "/#/Pages/Logout";
      },
      this.props.appProps.handleServiceError
    );
    
  };

  render() {
    return (
      <NavDropdown
        title={
          <>
            <i className="fa fa-user"></i> {" "}
            <span className="d-none d-md-inline">{Service.getTokenData("Name")}</span>
          </>
        }
        bsPrefix="dropdown-with-icons dropdown nav-user-dropdown"
      >
        {Service.isInRole(Role.SuperAdmin) === true &&
          <NavDropdown.Item eventKey={4.4} onClick={this.onToggleDashTools}>
            <i className="fa fa-wrench" />
            <span className="px-2">Tools</span>
          </NavDropdown.Item>
        }
        <NavDropdown.Item eventKey={4.5} onClick={() => window.location.href = "/#/Pages/ChangePassword"}>
          <i className="fa fa-user" /> <span className="px-2">Profile</span>
        </NavDropdown.Item>
        <NavDropdown.Item eventKey={4.5} onClick={() => this.onLogOutClick()}>
          <i className="pe-7s-close-circle text-danger" /> <span className="px-2 text-danger">Log out</span>
        </NavDropdown.Item>
      </NavDropdown>
    );
  }
}
export default HeaderLinks;
