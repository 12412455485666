import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import Service from "../../Service";

export class ExportTableDataWidget extends React.Component {
    constructor(props) {
        super(props);
        this.defaultFilters= {};
        this.flagTimeOut = false;
        this.tenants = JSON.parse(sessionStorage.getItem("tenants"))

        this.state = {
            page: 1,
            data: [],
            columns: [],
            totalSize: 0,
            sizePerPage: 20,
            sortField: null,
            sortOrder: null,
            defaultSorted: [],
            selectedTable: '',
            tableList: [],
            keyField: '',
            filters: null,
            checkAll: false,
            selectedColumn: {},
            tenantId: props.match.params.tenantId,
            tenantName: this.tenants.find(e => e.id.toString() === props.match.params.tenantId.toString()).name
        };
        this.onTableChange = this.onTableChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.tenantId !== this.props.match.params.tenantId) {
            this.setState({
                tenantId: nextProps.match.params.tenantId,
                tenantName: this.tenants.find(e => e.id.toString() === nextProps.match.params.tenantId.toString()).name,
                page: 1,
                data: [],
                columns: [],
                totalSize: 0,
                sortField: null,
                sortOrder: null,
                defaultSorted: [],
                selectedTable: '',
                keyField: '',
                filters: null,
                checkAll: false,
                selectedColumn: {},
                isEmpty: false,
                tableList: []
            }, () => {
                this.getTableList();
            });
        }
    }

    componentDidMount = () => {
        this.getTableList();
    };

    onTableChange = (type, {page, sizePerPage, filters, sortField, sortOrder, cellEdit}) => {
        this.setState({
            page,
            sizePerPage,
            sortField,
            sortOrder,
            filters
        }, this.getTableData)
    }

    onSelectColumn = (e) => {
        const field = e.target.getAttribute('data-col-name')
        if (e.target.checked) {
            this.setState({
                selectedColumn: {
                    ...this.state.selectedColumn,
                    [field]: e.target.checked
                }
            });
        } else {
            const selectedColumn = this.state.selectedColumn;
            delete selectedColumn[field];
            this.setState({selectedColumn});
        }
    }

    onColumnCheckboxClick = (e) => {
        e.stopPropagation();
    }

    onCheckAll = (e) => {
        let selectedColumn = {};
        if (e.target.checked) {
            selectedColumn = this.state.columns.reduce((p, c) => {
                p[c.dataField] = true;
                return p;
            }, {});
        }

        this.setState({checkAll: e.target.checked, selectedColumn})
    }

    cellValueFormatter = (cell, row, rowIndex) => {
        let cellValue = cell;
        // if (isIsoDate(cellValue) && moment(cellValue).isValid()) {
        //     cellValue = moment(cellValue).format("LLL");
        // }
        return <div className="text-truncate" style={{maxWidth: 200}}>{cellValue}</div>
    }

    headerFormatter = (column, colIndex, {sortElement, filterElement}) => {
        return (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{justifyContent: 'center', display: "flex", padding: "5px 0"}}>
                    <input type="checkbox" onChange={this.onSelectColumn} onClick={this.onColumnCheckboxClick}
                           data-col-index={colIndex} data-col-name={column.dataField}
                           checked={this.state.selectedColumn[column.dataField] || false}/>
                </div>
                <div>
                    {column.text}
                    {sortElement}
                </div>
                <div style={{minWidth: 80}}>
                    {filterElement}
                </div>
            </div>
        );
    }

    getTableDataResponse = (response) => {
        this.props.appProps.hideLoader();
        const {count, pageSize, pageNumber, rows} = response.body.data;
        const data = rows.map((r, i) => ({
            ri: i,
            ...r
        }))
        this.setState({
            isEmpty: rows.length === 0,
            totalSize: (this.state.filters==null||Object.keys(this.state.filters).length==0)?0:count,
            columns: rows[0] ? Object.keys(rows[0]).reduce((p, c) => {
                p.push({
                    dataField: c,
                    text: c,
                    sort: true,
                    formatter: this.cellValueFormatter,
                    filter: textFilter({
                        getFilter: (filter) => {
                            this.defaultFilters[c] = filter;
                        }
                    }),
                    headerFormatter: this.headerFormatter
                });
                return p;
            }, []) : (this.state.columns==[]?[]:this.state.columns),
            keyField: 'ri',
            data:this.state.filters==null||Object.keys(this.state.filters).length==0?[]:data
        })
    }

    getFormattedFilters = () => {
        return this.state.filters ? Object.keys(this.state.filters).reduce((p, c) => {
            p.push(`${c}:${this.state.filters[c].filterVal}`)
            return p;
        }, []).join(",") : null
    }

    getTableData = () => {
        if (!this.state.selectedTable) {
            return;
        }
        this.props.appProps.showLoader();
        const filters = this.getFormattedFilters();
        if(this.flagTimeOut)
            clearTimeout(this.flagTimeOut);
        this.flagTimeOut = setTimeout(()=> {
            Service.getRequest(
                `{apiUrl}/table/${this.state.selectedTable}?tenantId=${this.state.tenantId}&pageSize=${this.state.sizePerPage}&pageNumber=${this.state.page}${this.state.sortField ? `&sortField=${this.state.sortField}` : ""}${this.state.sortOrder ? `&sortOrder=${this.state.sortOrder.toUpperCase()}` : ""}${filters ? `&filters=${filters}` : ""}`,
                this.getTableDataResponse,
                this.props.appProps.handleServiceError
            );
        }, 250);
    };

    getTableListResponse = (response) => {
        this.props.appProps.hideLoader();
        this.setState({
            tableList: response.body.data.tableNames
        })
    }

    getTableList = () => {
        this.props.appProps.showLoader();
        Service.getRequest(
            `{apiUrl}/export-table-tenant-permission/${this.state.tenantId}`,
            this.getTableListResponse,
            this.props.appProps.handleServiceError
        );
    };

    handleTableSelect = (e) => {
        this.setState({
            selectedTable: e.target.value,
            page: 1,
            sortField: '',
            sortOrder: ''
        },this.getTableData)
    };

    exportCsv = () => {
        this.props.appProps.showLoader();

        const payload = {
            tableName: this.state.selectedTable,
            fields: Object.keys(this.state.selectedColumn),
            tenantId: this.state.tenantId,
        }

        const filters = this.getFormattedFilters();
        if (filters) {
            payload.filters = filters
        }

        if (this.state.sortField) {
            payload.sortField = this.state.sortField;
        }

        if (this.state.sortOrder) {
            payload.sortOrder = this.state.sortOrder.toUpperCase();
        }

        Service.downloadCsvRequest(
            `{apiUrl}/table-export`,
            payload,
            `${this.state.selectedTable.toLowerCase().replace(/\s/, '-')}.csv`,
            this.props.appProps,
            this.props.appProps.handleServiceError
        );
    };

    onClickReset = () => {
        const filterKeys = Object.keys(this.state.filters);
        filterKeys.forEach(filterKey =>{
            const defaultFilter = this.defaultFilters[filterKey];
            if(typeof defaultFilter === 'function')
                defaultFilter('');
        });
    }

    render() {

        return (
            <div className="row export-table-data m-0">
                <div className="col-12">
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Select Table</label>
                        <div className="col-sm-5">
                            <select className="form-control form-select" value={this.state.selectedTable}
                                    onChange={this.handleTableSelect}>
                                <option value="">Select a table</option>
                                {this.state.tableList.map((o, i) => (<option key={i} value={o}>{o}</option>))}
                            </select>
                        </div>
                        <div className="col-sm-5 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <button className="btn btn-primary" onClick={this.onClickReset}
                                        disabled={(this.state.filters==null||Object.keys(this.state.filters).length==0)}>Reset
                                </button>
                                <label htmlFor="checkall" className="ml-2">
                                    <input type="checkbox" id="checkall" onChange={this.onCheckAll}
                                           checked={this.state.checkAll}/>
                                    <span className="ml-1">Check All</span>
                                </label>
                            </div>
                            <div>
                                <button className="btn btn-warning"
                                        onClick={this.exportCsv}
                                        disabled={!this.state.data.length || !Object.keys(this.state.selectedColumn).length}>Export
                                    CSV
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3 mb-5">
                    {this.state.columns.length > 0 && <BootstrapTable
                        remote
                        keyField={this.state.keyField}  
                        data={this.state.data}
                        columns={this.state.columns}
                        defaultSorted={this.state.defaultSorted}
                        pagination={paginationFactory({
                            page: this.state.page,
                            sizePerPage: this.state.sizePerPage,
                            totalSize: this.state.totalSize,
                            hideSizePerPage: true
                        })}
                        filter={filterFactory()}
                        onTableChange={this.onTableChange}
                        noDataIndication={<div style={{textAlign:'center', height:'300px',position:'relative'}}>
                            <p style={{position:"absolute",margin:0, top:"40%", left:"40%", fontSize:"40px", color:"#9A9A9A"}}>NO RESULT</p>
                        </div>}
                    />}
                </div>
            </div>
        );
    };
}

export default ExportTableDataWidget;
