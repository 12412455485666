//Import Styles/CSS
import React from "react";
import { Modal, Button } from "react-bootstrap";

class Dialog extends React.Component {
  onCloseClick = () => {
    this.props.onCloseClick();
  };

  onSuccessClick = () => {
    // if (this.props.onSuccessClick !== undefined)
    // {
    //   this.props.onSuccessClick();
    // }
    // this.setState({showModal:false});
    this.props.onSuccessClick();
  };

  // render the dashboard
  render() {
    if (this.props.showModal === false) {
      return null;
    }
    return (
      <Modal dialogClassName={this.props.dialogClassName} show={this.props.showModal} onHide={this.onCloseClick} backdrop={"static"} >
        {this.props.title && (
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
        )}

        <Modal.Body>{this.props.children}</Modal.Body>

        <Modal.Footer>
          {this.props.successButtonText && (
            <Button variant="primary" onClick={this.onSuccessClick}>
              {this.props.successButtonText}
            </Button>
          )}
          {this.props.closeButtonText && (
            <Button onClick={this.onCloseClick}>
              {this.props.closeButtonText}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );

    // <div className="modal fade in show" id="appDialog" style={{ display: "block" }} tabIndex="-1" role="dialog"
    //   aria-labelledby="appDialogTitle">
    //   <div className="modal-dialog modal-dialog-centered" role="document">
    //     <div className="modal-content">
    //       {this.props.title && (
    //       <div className="modal-header">
    //         <h5 className="modal-title" id="appDialogTitle">{this.props.title}</h5>
    //         <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onCloseClick}>
    //           <span aria-hidden="true">&times;</span>
    //         </button>
    //       </div>
    //       )}
    //       <div className="modal-body">
    //         {this.props.children}
    //       </div>
    //       <div className="modal-footer">
    //         {this.props.closeButtonText && (
    //           <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.onCloseClick}>{this.props.closeButtonText}</button>)
    //         }
    //         {
    //           this.props.successButtonText && (
    //             <button type="button" className="btn btn-primary" onClick={this.onSuccessClick}>{this.props.successButtonText}</button>)
    //         }
    //       </div>
    //     </div>
    //   </div>
    // </div>
  }
}
export default Dialog;
