import React from "react";
import Helper from "Helper";
import TimePicker from 'react-time-picker';
import TimeField from 'react-simple-timefield';

class FormTime extends React.Component {

  shouldComponentUpdate(nextProps, nextState){
    //Arrested the re-rendering in the parent.
    return false;
 }

  handleInputChange = value => {
    var target = {
      value: value.nativeEvent.srcElement.defaultValue,
      type: this.props.type,
      name: this.props.id
    }
    var event = {
      target: target
    }
    console.log(event);
    console.log(this.props);
    this.props.handleInputChange(event);
  };
  handleBlur = event => {
    if (this.props.onBlur) this.props.onBlur(event);
  };
  handleFocus = event => {
    if (this.props.onFocus) this.props.onFocus(event);
  };
  render() {
    
    var placeholder = this.props.placeholder;
    if (this.props.placeholder === undefined) {
      placeholder = this.props.name;
    }
    if (this.props.groupClassName !== undefined) {
      var groupClassName = this.props.groupClassName
    }
    return (
      <>
        <div className={"form-group " + this.props.labelWidth + " " + this.props.groupClassName}>
          <label className={"form-label " + (this.props.required===true?"required":"")} htmlFor={this.props.actualControlId}>
            {this.props.name}
          </label>
        </div>
        <div className={"form-group " + this.props.controlWidth + " " + this.props.groupClassName}>
          {(Helper.isNullOrEmpty(this.props.prefix) === false ||
            Helper.isNullOrEmpty(this.props.suffix) === false) && (
              <span className="input-group">
                {this.props.prefix && (
                  <span className="input-group-prepend">{this.props.prefix}</span>
                )}
                <TimeField
                  style={{
                    //border: '2px solid #666',
                    padding: '0.5em',
                    margin: '0.2em 0em',
                    width: '100%',
                    border: '1px solid #DDDDDD',
                    borderRadius: '4px',
                    color: '#66615b',
                    lineHeight: 'normal',
                    fontSize: '14px',
                    outline: 'none'
                  }}
                  value={this.props.value === null || this.props.value === undefined || this.props.value === '' ? '00:00:00' : this.props.value}
                  onChange={this.handleInputChange}
                  showSeconds={true}
                />
                {this.props.suffix && (
                  <span className="input-group-prepend">{this.props.suffix}</span>
                )}
              </span>
            )}

          {(this.props.prefix === undefined || this.props.prefix === "") &&
            (this.props.suffix === undefined ||
              this.props.suffix === null ||
              this.props.suffix === "") && (
                <TimeField
                  style={{
                    //border: '2px solid #666',
                    padding: '0.5em',
                    margin: '0.2em 0em',
                    width: '100%',
                    border: '1px solid #DDDDDD',
                    borderRadius: '4px',
                    color: '#66615b',
                    lineHeight: 'normal',
                    fontSize: '14px',
                    outline: 'none'
                  }}
                  value={this.props.value === null || this.props.value === undefined || this.props.value === '' ? '00:00:00' : this.props.value}
                  onChange={this.handleInputChange}
                  showSeconds={true}
                />
            )}
        </div>
      </>
    );
  }
}

export default FormTime;
