import React from "react";
import Helper from "Helper";

class OnOffWidget extends React.Component {
  getBooleanValue = value => {
    if (
      value === undefined ||
      value == null ||
      value === "null" ||
      value === "" ||
      typeof value === "object"
    ) {
      return false;
    }

    if (typeof value === "string") {
      value = value.toLowerCase();
    }
    if (
      value === "true" ||
      value === 1 ||
      value === "1" ||
      value === true ||
      value === "on" ||
      value === "yes" ||
      value === "good"
    ) {
      return true;
    } else {
      return false;
    }
  };
  render() {
    //Avoid Error when widget is NOT configured
    if (this.props.widget.data === undefined)
    {
      return <div className="text-center">Widget configuration is incomplete. Please contact your Administrator</div>
    }

    var myData = Helper.getDataFromJsonPathQuery(
      this.props.data,
      this.props.widget.data
    );
    var icon = this.props.widget.icon;
    if (myData === undefined || myData === null) {
      return null;
    }
    return (
      <div className="flex-grid-nowrap mb-3">
        {icon !== "" && (
          <div className="onOffCol text-center col-icon">
            <i className={icon} />
          </div>
        )}
        {myData.map((data, key) => {
          return (
            <div key={key} className="onOffCol text-center">
              <div>{data.key.replace(" Status", "").replace("ight", "")}</div>

              {/* {data.key.indexOf("Level") == -1 && (<Switch defaultValue={this.getBooleanValue(data.value)} />)} */}
              {data.key.indexOf("Level") == -1 &&
                (this.getBooleanValue(data.value) ? (
                  <div className="mt-3">
                    <span className="btn-round bg-success ts-1">On</span>
                  </div>
                ) : (
                  <div className="mt-3">
                    <span className="bg-danger btn-round ts-1">Off</span>
                  </div>
                ))}
              {data.key.indexOf("Level") != -1 && (
                <div className="pt-1">
                  <span className="text-size-1_5em">{data.value + " %"}</span>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default OnOffWidget;
