import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";

import Sugar from "sugar";

import { Responsive, WidthProvider } from "react-grid-layout";
import FileDownload from "react-file-download";

// react component used to create charts
// function that returns a color based on an interval of numbers
// react components used to create a SVG / Vector map
import Service from "Service";
import Tools from "views/dashboard/Tools";
import DashHeader from "views/dashboard/DashHeader";
import Panel from "views/dashboard/Panel";
import Dialog from "components/common/Dialog";
import DatasourceContainer from "components/datasource/DatasourceContainer";
import PanelMode from "constants/PanelMode";
// jQuery plugin - used for DataTables.net
import StorageId from "constants/StorageId";
import Constant from "constants/Constant";
import $ from "jquery";
import Helper from "Helper";
import Role from "constants/Role";
import { Print } from "react-easy-print";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class Dashboard extends Component {
  constructor(props) {
    Sugar.extend();
    super(props);

    //Set the Sample Datasources
    var datasourcesData = {};

    //Set the default states
    this.state = {
      itervalIds: [],
      dashboard: { panel: [] },
      datasources: {},
      datasourcesData: datasourcesData,
      showDatasourceDialog: false,
      currentDatasource: {},
      activePanel: null,
      mode: PanelMode.Normal,
      houses: [],
      locations: [],
      house: null,
      location: null,
      tenant: "REMBRANDT",
      name: "",
      panelFrequency: []
      //     ,activePanel: {"id":"PnlSyWmMm7f7"},
      //     mode: PanelMode.Edit
    };
  }

  // initialize component after it has been mounted
  componentDidMount = () => {
    this.props.appProps.showLoader();
    //Get Dashboard
    Service.GetDashboard(
      this.props.layoutJson,
      this.onGetDashboardSuccess,
      this.props.appProps.handleServiceError
    );
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.name !== this.props.name ||
      nextProps.match.params.tenantId !== this.props.match.params.tenantId ||
      nextProps.match.params.siteId !== this.props.match.params.siteId ||
      nextProps.match.params.houseId !== this.props.match.params.houseId
    ) {
      this.props.appProps.showLoader();
      //Get Dashboard
      Service.GetDashboard(
        nextProps.layoutJson,
        this.onGetDashboardSuccess,
        this.props.appProps.handleServiceError
      );
    }
  }

  componentWillUnmount = () => {
    this.stopPolling(this);
  };

  onGetDashboardSuccess = resp => {
    this.loadDashboard(JSON.parse(resp.text));
  };

  loadDashboard = dashboard => {
    //Set dashboard
    if (dashboard === undefined || dashboard === null) {
      dashboard = {
        name: this.props.name,
        panel: []
      };
    } else {
      dashboard.panel = JSON.parse(dashboard.layoutJSON);
    }

    //Set Datasource & datasourcetypes
    var datasources = JSON.parse(
      sessionStorage.getItem(StorageId.Session.Datasources)
    );
    if (datasources === undefined) {
      window.location.href = "/";
    }

    var datasourceTypes = JSON.parse(
      sessionStorage.getItem(StorageId.Session.DatasourceTypes)
    );

    //Set the Locations & Houses for the users
    let tenants = JSON.parse(sessionStorage.getItem(StorageId.Session.Tenants));

    let selectedTenant = {};
    let selectedLocation = undefined;
    let selectedHouse = undefined;
    let locations = [];
    let houses = [];
    if (Helper.isNullOrUndefined(this.props.match.params.tenantId) === false) {
      selectedTenant = tenants
        .filter(
          t =>
            t.id === parseInt(this.props.match.params.tenantId, Constant.Radix)
        )
        .pop();
      locations = selectedTenant.sites;
      if (Helper.isNullOrUndefined(this.props.match.params.siteId) === false) {
        selectedLocation = locations
          .filter(
            s =>
              s.id === parseInt(this.props.match.params.siteId, Constant.Radix)
          )
          .pop();
        if (
          selectedLocation === undefined &&
          this.props.match.params.siteId === Constant.AllHouse.id
        ) {
          selectedLocation = Constant.AllHouse;
        }
        houses = selectedLocation.houses;
        if (
          Helper.isNullOrUndefined(this.props.match.params.houseId) === false
        ) {
          selectedHouse = houses
            .filter(
              s =>
                s.id ===
                parseInt(this.props.match.params.houseId, Constant.Radix)
            )
            .pop();
          Service.log(selectedHouse, "selectedHouse");
        }
      }
    }

    this.props.appProps.setGlobalState(
      Constant.GlobalState.Tenant,
      selectedTenant
    );
    this.props.appProps.setGlobalState(
      Constant.GlobalState.Site,
      selectedLocation
    );
    this.props.appProps.setGlobalState(
      Constant.GlobalState.House,
      selectedHouse
    );

    dashboard.panel = dashboard.panel.sortBy(function (p) {
      return p.position.x;
    });
    dashboard.panel = dashboard.panel.sortBy(function (p) {
      return p.position.y;
    });

    //TODO: Expecting to have atleast one Locations & House
    this.setState({
      dashboard: dashboard,
      name: this.props.name,
      datasources: datasources,
      datasourceTypes: datasourceTypes,
      locations: locations,
      houses: houses,
      house: selectedHouse,
      location: selectedLocation,
      tenants: tenants,
      tenant: selectedTenant
    });
    //Get the data from the backend
    this.getData(dashboard);

  };

  //Data Handling Methods
  //To load the datasource when the user added a new Datasource
  getDatasourceData = dashboard => {
    this.getData(dashboard);
  };

  //To get the data from the Datasource JSON
  getData = dashboardJson => {
    this.stopPolling(this);
    if (
      dashboardJson !== undefined &&
      dashboardJson.panel !== undefined &&
      dashboardJson.panel[0] !== undefined &&
      dashboardJson.panel[0].datasources !== undefined &&
      dashboardJson.panel[0].datasources[0].name !== ""
    ) {
      var itervalIds = [];
      var datasourceArray = [];
      dashboardJson.panel.forEach(panel => {
        this.loadPanelDatasource(panel, datasourceArray, itervalIds);
      });
    } else {
      this.props.appProps.hideLoader();
    }
  };

  loadPanelDatasource = (panel, datasourceArray, itervalIds) => {
    panel.datasources.forEach(panelDatasource => {
      var name = panelDatasource.name;
      //Check if the datasource was added already. If not, add it.
      if (
        name !== "" &&
        (datasourceArray.length === 0 ||
          datasourceArray.findIndex(s => s.name === name) === -1)
      ) {
        //Get the Map Details
        var datasourcesMap = JSON.parse(
          sessionStorage.getItem(StorageId.Session.DsMap)
        );
        var datasource = datasourcesMap[name];
        datasourceArray.push(datasource);

        let frequency = null;
        if (panel.trend && panel.trend.show === true) {
          frequency = this.state.panelFrequency[panel.id];
          if (Helper.isNullOrEmpty(frequency) === true) {
            frequency = panel.trend.frequency;
          }
        }


        //console.log(panel.trend.selectedFrequency + "=" + panel.trend.frequency)
        //console.log("frequency = " + frequency)
        //Get the Data & set the interval
        Service.getDatasourceData(
          name,
          false,
          {
            tenantId: this.props.match.params.tenantId,
            siteId: this.props.match.params.siteId,
            houseId: this.props.match.params.houseId,
            frequency: frequency
          },
          this.onData,
          this.props.appProps.handleServiceError
        );
        if (datasource) {
          if (
            datasource.refresh &&
            datasource.method &&
            datasource.method.toLowerCase() === "get"
          ) {
            var thisComponent = this; //Have to set as this doesn't work in the SetInterval()
            var itervalId = setInterval(
              () =>
                Service.getDatasourceData(
                  name,
                  true,
                  {
                    tenantId: this.props.match.params.tenantId,
                    siteId: this.props.match.params.siteId,
                    houseId: this.props.match.params.houseId,
                    frequency: frequency,
                    isRefresh: true
                  },
                  thisComponent.onData,
                  this.props.appProps.handleServiceError
                ),
              datasource.refresh
            );
            itervalIds.push(itervalId);
          }
        }
      }
      this.setState({ itervalIds: itervalIds });
    });
  };

  stopPolling = comp => {
    if (comp.state.itervalIds && comp.state.itervalIds.length > 0) {
      comp.state.itervalIds.forEach(id => {
        clearInterval(id);
      });
    }
  };

  onData = (name, isRefresh, data) => {
    let scrollPosition = 0;
    if (isRefresh === true) {
      scrollPosition = $("#mainPanel").scrollTop();
    }
    var datasourcesData = this.state.datasourcesData;
    //TODO:Karthi:Remove this after proper error in the Service
    if (data === undefined || data.body === undefined) {
      this.props.appProps.showNotification(
        "Didn't get a proper response from the Server!!!",
        Constant.Notification.Type.Error
      );
    }
    datasourcesData[name] = data.body;
    this.props.appProps.hideLoader();
    this.setState({ datasourcesData: datasourcesData });
    if (isRefresh === true) {
      $("#mainPanel").scrollTop(scrollPosition);
    }
  };

  //Dashboard Methods
  onSaveDashboard = () => {
    this.props.appProps.showLoader();
    Service.saveDashboard(
      this.state.dashboard,
      res => {
        this.props.appProps.showNotification(
          "Saved the dashboard successfully.",
          Constant.Notification.Type.Success
        );
        this.props.appProps.hideLoader();
      },
      this.props.appProps.handleServiceError
    );
  };

  //Panel Methods
  savePanelToDashboard = panel => {
    let dashboard = this.state.dashboard;
    panel.needsConfiguration = false;
    let indexToSave = dashboard.panel.findIndex(p => p.id === panel.id);
    if (indexToSave < 0) {
      this.props.appProps.showNotification(
        "PanelID not found.",
        Constant.Notification.Type.Error
      );
    }

    dashboard.panel[indexToSave] = panel;
    this.setState({
      dashboard: dashboard
    });
    this.props.appProps.showNotification(
      "Saved the panel successfully.",
      Constant.Notification.Type.Success
    );
    this.props.appProps.showNotification(
      "Don't forget to save the Dashboard to persist the changes.",
      Constant.Notification.Type.Warning
    );
    this.getData(dashboard);
  };

  onAddPanel = () => {
    let dashboard = this.state.dashboard;
    let defaultPanel = Service.getEmptyPanel("Default");
    dashboard.panel.append(defaultPanel, 0);
    this.setState({
      dashboard: dashboard,
      activePanel: { id: defaultPanel.id },
      mode: PanelMode.Edit
    });
    this.props.appProps.showNotification(
      "Panel was added successfully.",
      Constant.Notification.Type.Success
    );
    this.props.appProps.showNotification(
      "Don't forget to save the Dashboard to persist the changes.",
      Constant.Notification.Type.Warning
    );
  };

  onPanelGoBack = panel => {
    $("#dashTools").slideUp();
    this.setState({ mode: PanelMode.Normal, activePanel: null });
  };

  onPanelEdit = panel => {
    $("#dashTools").slideDown();
    this.setState({ mode: PanelMode.Edit, activePanel: { id: panel.id } });
  };

  onPanelView = panel => {
    this.setState({
      mode: PanelMode.Fullscreen,
      activePanel: { id: panel.id, position: {} }
    });
  };

  onPanelSave = panel => {
    this.savePanelToDashboard(panel);
  };

  onPanelDelete = panel => {
    let dashboard = this.state.dashboard;
    let indexToDelete = dashboard.panel.findIndex(p => p.id === panel.id);
    dashboard.panel.splice(indexToDelete, 1);
    this.setState({ dashboard: dashboard });
    this.props.appProps.showNotification(
      "Panel was deleted successfully.",
      Constant.Notification.Type.Success
    );
    this.props.appProps.showNotification(
      "Don't forget to save the Dashboard to persist the changes.",
      Constant.Notification.Type.Warning
    );
  };

  //DataSource Methods
  onAddPanelDataSource = (panel, datasource) => {
    if (panel.data === undefined) {
      panel.data = [];
    }

    if (
      datasource.path === undefined ||
      datasource.path === null ||
      datasource.path === "" ||
      datasource.path === "$" ||
      datasource.path.split(".").length < 2
    ) {
      this.props.appProps.showNotification(
        "Select a valid datasource.",
        Constant.Notification.Type.Error
      );
      return;
    } else {
      //TODO: Possible problem
      datasource.name = datasource.path.split(".")[1];
    }

    panel.data.push({ name: datasource.name, path: datasource.path });
    this.savePanelToDashboard(panel);
  };

  onDeletePanelDataSource = (panel, index) => {
    panel.data.removeAt(index);
    this.savePanelToDashboard(panel);
  };

  onAddDataSource = () => {
    let datasource = { id: this.state.datasources.length + 1 };
    this.setState({
      showDatasourceDialog: true,
      currentDatasource: datasource
    });
  };

  onDatasourceDialogSave = () => {
    let datasource = this.state.currentDatasource;
    let datasources = this.state.datasources;
    let indexToSave = datasources.findIndex(p => p.id === datasource.id);
    if (indexToSave < 0) {
      indexToSave = datasources.length;
    }
    datasources[indexToSave] = datasource;

    this.setState({
      showDatasourceDialog: false
    });

    Service.saveDatasources(
      datasource,
      undefined,
      res => {
        sessionStorage.setItem(
          StorageId.Session.Datasources,
          JSON.stringify(res.body)
        );
        sessionStorage.setItem(
          "dsMap",
          JSON.stringify(this.createDsMap(res.body))
        );

        this.setState({ datasources: res.body });
      },
      this.props.appProps.handleServiceError
    );
  };

  onDatasourceDialogClose = () => {
    this.setState({ showDatasourceDialog: false });
  };

  onLayoutChange = layout => {
    if (Service.isInRole(Role.SuperAdmin) === false && Service.isInRole(Role.SuperUser) === true) {
      return;
    }
    let dashboard = this.state.dashboard;

    //let panel = null;
    for (var i = 0; i < layout.length; i++) {
      for (var j = 0; j < dashboard.panel.length; j++) {
        if (dashboard.panel[j].id === layout[i].i) {
          dashboard.panel[j].position = layout[i];
        }
      }
    }
    this.setState({ dashboard: dashboard });
  };

  onWidthChange = (containerWidth, margin,    cols,    containerPadding) => {
    if (sessionStorage.getItem('TempContainerWidth') &&
      sessionStorage.getItem('TempContainerWidth') != containerWidth) {
        setTimeout(() => {
          this.props.appProps.setAdjustMainContent(true);
        },500);
    }
    sessionStorage.setItem('TempContainerWidth', containerWidth);
  };

  onLoadDashboardFromFile = (e, results) => {
    results.forEach(result => {
      const [e] = result;
      this.setState({ dashboard: JSON.parse(e.target.result) });
    });
  };

  onSaveDashboardToFile = () => {
    FileDownload(
      JSON.stringify(this.state.dashboard),
      this.state.dashboard.name + ".json"
    );
  };
  onTenantChanged = selectedTenant => {
    if (this.state.tenant.id === selectedTenant.id) {
      return false;
    }
    this.setState({
      tenant: selectedTenant,
      locations: selectedTenant.sites,
      location: selectedTenant.sites[0],
      houses: selectedTenant.sites[0].houses,
      house: selectedTenant.sites[0].houses[0]
    });

    this.props.appProps.setGlobalState(
      Constant.GlobalState.Tenant,
      selectedTenant
    );
    this.props.appProps.setGlobalState(
      Constant.GlobalState.Site,
      selectedTenant.sites[0]
    );
    this.props.appProps.setGlobalState(
      Constant.GlobalState.House,
      selectedTenant.sites[0].houses[0]
    );

    let url = `/${this.props.name}`;
    //TODO:Use Path for this
    if (this.props.name == "Bin Capacity") {
      url = `/Config/BinCapacity`;
    } else if (this.props.name == "Flock Header") {
      url = `/Flock/Header`;
    } else if (this.props.name == "Flock Detail") {
      url = `/Flock/Detail`;
    }  else if (this.props.name == "Layer Summary") {
      url = `/Flock/LayerSummary`;
    } else if (this.props.name == "Layer Weekly") {
      url = `/Flock/LayerSummaryWeekly`;
    }  else if (this.props.name == "Pullet Summary") {
      url = `/Flock/PulletSummary`;
    } else if (this.props.name == "Pullet Weekly") {
      url = `/Flock/PulletSummaryWeekly`;
    } else if (this.props.name == "Pullet Detail") {
      url = `/Flock/PulletDetail`;
    } else if (this.props.name == "Performance Data") {
      url = '/Grader/PerformanceData'
    } else if (this.props.name == "Grader Daily Count") {
      url = '/Grader/GraderDailyCount'
    } else if (this.props.name == "Breed Standards") {
      url = '/Breed/Standards'
    } else if (this.props.name == "Feed Delivery") {
      url = '/Feed-Delivery'
    } else if (this.props.name == "Feed Delivery Report") {
      url = '/FeedDeliveryReport'
    }  else if (this.props.name == "User Log") {
      url = '/Admin/UserLog'
    } else if(this.props.name == "Bird Scale") {
      url = '/Flock/HouseBirdScale';
    } else if (this.props.name == 'Inventory') {
        url = '/Inventory';
    } else if (this.props.name == 'Export Table') {
        url = '/Exports';
      } else if (this.props.name == 'Tenant Export') {
        url = '/Config/Exports';
      }

    if (selectedTenant.id !== Constant.AllHouse.id) {
      url = `${url}/${selectedTenant.id}`;
    }
    //If its Trends choose default location & house
    if (this.props.name === "Trend") {
      url = `${url}/${selectedTenant.sites[0].id}/${selectedTenant.sites[0].houses[0].id}`;
    } else if (this.props.name === "FeedBin" || this.props.name === "Flock Detail" || this.props.name === "Feed Delivery Report") {
      url = `${url}/${Constant.AllHouse.id}`;
    }
    this.props.history.push(url);
  };

  onLocationChanged = selectedLocation => {
    this.setState({
      location: selectedLocation,
      house: Constant.AllHouse,
      houses: selectedLocation.houses
    });
    this.props.appProps.setGlobalState(
      Constant.GlobalState.Site,
      selectedLocation
    );
    this.props.appProps.setGlobalState(
      Constant.GlobalState.House,
      Constant.AllHouse
    );

    let url = `/${this.props.name}`;
    if (this.props.name == "Flock Detail") {
      url = `/Flock/Detail`;
    }
    
    if (this.props.name == "Layer Summary") {
      url = `/Flock/LayerSummary`;
    } 

    if (this.props.name == "Layer Weekly") {
      url = `/Flock/LayerSummaryWeekly`;
    } 
    
    if (this.props.name == "Pullet Summary") {
      url = `/Flock/PulletSummary`;
    } 

    if (this.props.name == "Pullet Weekly") {
      url = `/Flock/PulletSummaryWeekly`;
    } 

    if (this.props.name == 'Pullet Detail') {
      url = `/Flock/PulletDetail`
    }

    if (this.props.name == "Feed Delivery Report") {
      url = '/FeedDeliveryReport'
    }

    url = `${url}/${this.props.match.params.tenantId}`;
    if (selectedLocation.id !== Constant.AllHouse.id) {
      url = `${url}/${selectedLocation.id}`;
    }

    //If its Trends choose default location & house
    if (this.props.name === "Trend") {
      url = `${url}/${selectedLocation.houses.sortBy("name")[0].id}`;
    }
    this.props.history.push(url);
  };

  onHouseChanged = selectedHouse => {
    this.setState({ house: selectedHouse });
    this.props.appProps.setGlobalState(
      Constant.GlobalState.House,
      selectedHouse
    );

    let url = `/${this.props.name}/${this.props.match.params.tenantId}/${this.state.location.id}`;
    if (selectedHouse.id !== Constant.AllHouse.id) {
      url = `${url}/${selectedHouse.id}`;
    }
    this.props.history.push(url);
  };

  onChangePanelFrequency = (panel, frequency) => {
    panel.trend.frequency = frequency;
    let panelFrequency = this.state.panelFrequency;
    panelFrequency[panel.id] = frequency;
    this.setState({ panelFrequency: panelFrequency });
    var datasourceArray = [];
    var itervals = [];
    this.loadPanelDatasource(panel, datasourceArray, itervals);
  };

  render() {
    //Below logic is needed to re-adjust the layout for the minimized sidebar
    if (this.props.appProps.getAdjustMainContent() === true) {
      setTimeout(() => {
        this.props.appProps.setAdjustMainContent(false);
      }, 500);
    }
    let activePanel = null;
    if (
      this.state.dashboard &&
      this.state.dashboard.panel &&
      this.state.activePanel !== null
    ) {
      //alert(JSON.stringify(this.state.dashboard.panel))
      activePanel = this.state.dashboard.panel
        .filter({ id: this.state.activePanel.id })
        .pop();
      if (activePanel === undefined) {
        console.error(
          "The active panel (" + this.state.activePanel.id + ") is not found."
        );
      }
    }

    var alarms = [];
    var outside = [];
    if (this.state.datasourcesData && this.state.location && this.state.house) {
      var datasourceName = "REMBRANDT_THOMPSON_1_House_32";
      if (
        this.state.datasourcesData[datasourceName] !== undefined &&
        this.state.datasourcesData[datasourceName].hah !== undefined
      ) {
        alarms = this.state.datasourcesData[datasourceName].hah;
      }
      if (
        this.state.datasourcesData[datasourceName] !== undefined &&
        this.state.datasourcesData[datasourceName].hvh !== undefined
      ) {
        outside = this.state.datasourcesData[datasourceName].hvh;
      }
    }
    return (
      <>
        <DashHeader
          tenant={this.state.tenant}
          location={this.state.location}
          house={this.state.house}
          tenants={this.state.tenants}
          locations={this.state.locations}
          houses={this.state.houses}
          onTenantChanged={this.onTenantChanged}
          onLocationChanged={this.onLocationChanged}
          onHouseChanged={this.onHouseChanged}
          alarms={alarms}
          outside={outside}
          name={this.state.name}
        />
        <Print single>
          <div className="main-content mt-3">
            {/* { this.state.dashboard.panel
              && this.state.dashboard.panel.length > 0
              && this.state.loader === true &&
            <div className="text-center  text-info pb-5">
              <i className="fa fa-spin fa-spinner fa-lg"></i> Loading data... Please wait....
            </div>
          } */}
            <Container fluid>
              <Row>
                <Col md={12}>
                  <Tools
                    onSaveDashboard={this.onSaveDashboard}
                    onAddPanel={this.onAddPanel}
                    onAddDataSource={this.onAddDataSource}
                    onLoadDashboardFromFile={this.onLoadDashboardFromFile}
                    onSaveDashboardToFile={this.onSaveDashboardToFile}
                  />

                  {activePanel !== null && (
                    <Panel
                      {...this.props}
                      activePanel={this.state.activePanel}
                      panel={activePanel}
                      mode={this.state.mode}
                      datasourceTypes={this.state.datasourceTypes}
                      datasource={this.state.currentDatasource}
                      data={this.state.datasourcesData}
                      datasources={this.state.datasources}
                      onPanelSave={this.onPanelSave}
                      onPanelEdit={this.onPanelEdit}
                      onPanelView={this.onPanelView}
                      onPanelDelete={this.onPanelDelete}
                      onPanelGoBack={this.onPanelGoBack}
                      onAddDataSource={this.onAddDataSource}
                      onAddPanelDataSource={this.onAddPanelDataSource}
                      onDeletePanelDataSource={this.onDeletePanelDataSource}
                      onLoadDatasourceData={this.getDatasourceData}
                      appProps={this.props.appProps}
                      onChangePanelFrequency={this.onChangePanelFrequency}
                    />
                  )}

                  {activePanel === null &&
                    this.props.appProps.getAdjustMainContent() === false &&
                    this.state.mode !== PanelMode.Edit &&
                    this.state.name !== 'Chatbot' && (
                      <ResponsiveReactGridLayout
                        className="layout"
                        isResizable={true}
                        containerPadding={[0, 0]}
                        onLayoutChange={this.onLayoutChange}
                        onWidthChange={this.onWidthChange}
                        draggableHandle={'.react-grid-item-draggable-handle'}
                        draggableCancel={
                          '.react-grid-item-draggable-handle-cancel'
                        }
                        cols={{ lg: 12, md: 12, sm: 12, xs: 1, xxs: 1 }}
                        rowHeight={30}
                        layouts={this.state.layouts}
                        preventCollision={false}
                      >
                        {this.state.dashboard.panel &&
                          this.state.dashboard.panel.map((item, index) => {
                            //Determine the Widget Visibility Type
                            let panelVisibilityClassName = ''
                            if (
                              Service.isInRole(Role.SuperAdmin) === true ||
                              Service.isInRole(Role.SuperUser) === true
                            ) {
                              panelVisibilityClassName = 'panel'
                            }
                            if (this.state.mode !== PanelMode.Normal) {
                              if (
                                this.state.activePanel &&
                                this.state.activePanel.id === item.id
                              ) {
                                panelVisibilityClassName =
                                  'fullscreen-toggle active'
                              } else {
                                panelVisibilityClassName = 'hideForToggle'
                              }
                            }

                            return (
                              <div
                                id={item.id}
                                key={item.id}
                                data-grid={item.position}
                                data-grid-height={
                                  item.position.h === 0 ? 'auto' : ''
                                }
                                className={panelVisibilityClassName}
                              >
                                <Panel
                                  {...this.props}
                                  activePanel={this.state.activePanel}
                                  panel={item}
                                  mode={this.state.mode}
                                  datasourceTypes={this.state.datasourceTypes}
                                  datasource={this.state.currentDatasource}
                                  data={this.state.datasourcesData}
                                  datasources={this.state.datasources}
                                  onPanelSave={this.onPanelSave}
                                  onPanelEdit={this.onPanelEdit}
                                  onPanelView={this.onPanelView}
                                  onPanelDelete={this.onPanelDelete}
                                  onPanelGoBack={this.onPanelGoBack}
                                  onAddDataSource={this.onAddDataSource}
                                  onAddPanelDataSource={
                                    this.onAddPanelDataSource
                                  }
                                  onDeletePanelDataSource={
                                    this.onDeletePanelDataSource
                                  }
                                  appProps={this.props.appProps}
                                  onChangePanelFrequency={
                                    this.onChangePanelFrequency
                                  }
                                />
                              </div>
                            )
                          })}
                      </ResponsiveReactGridLayout>
                    )}
                  {activePanel === null &&
                    this.props.appProps.getAdjustMainContent() === false &&
                    this.state.mode !== PanelMode.Edit && this.props.name==='Chatbot' && (
                      <>
                        {this.state.dashboard.panel &&
                          this.state.dashboard.panel.map((item, index) => {
                            //Determine the Widget Visibility Type
                            let panelVisibilityClassName = ''
                            if (
                              Service.isInRole(Role.SuperAdmin) === true ||
                              Service.isInRole(Role.SuperUser) === true
                            ) {
                              panelVisibilityClassName = 'panel'
                            }
                            if (this.state.mode !== PanelMode.Normal) {
                              if (
                                this.state.activePanel &&
                                this.state.activePanel.id === item.id
                              ) {
                                panelVisibilityClassName =
                                  'fullscreen-toggle active'
                              } else {
                                panelVisibilityClassName = 'hideForToggle'
                              }
                            }

                            return (
                              <div
                                id={item.id}
                                key={item.id}
                                data-grid={item.position}
                                data-grid-height={
                                  item.position.h === 0 ? 'auto' : ''
                                }
                                className={panelVisibilityClassName}
                              >
                                <Panel
                                  {...this.props}
                                  activePanel={this.state.activePanel}
                                  panel={item}
                                  mode={this.state.mode}
                                  datasourceTypes={this.state.datasourceTypes}
                                  datasource={this.state.currentDatasource}
                                  data={this.state.datasourcesData}
                                  datasources={this.state.datasources}
                                  onPanelSave={this.onPanelSave}
                                  onPanelEdit={this.onPanelEdit}
                                  onPanelView={this.onPanelView}
                                  onPanelDelete={this.onPanelDelete}
                                  onPanelGoBack={this.onPanelGoBack}
                                  onAddDataSource={this.onAddDataSource}
                                  onAddPanelDataSource={
                                    this.onAddPanelDataSource
                                  }
                                  onDeletePanelDataSource={
                                    this.onDeletePanelDataSource
                                  }
                                  appProps={this.props.appProps}
                                  onChangePanelFrequency={
                                    this.onChangePanelFrequency
                                  }
                                />
                              </div>
                            )
                          })}
                          </>
                    )}

                  {/*
                    activePanel === null &&
                    this.props.appProps.getAdjustMainContent() === false &&
                    this.state.mode === PanelMode.Edit && (
                      <div className="layout">
                        {this.state.dashboard.panel &&
                          this.state.dashboard.panel.map((item, index) => {
                            //Determine the Widget Visibility Type
                            let panelVisibilityClassName = "";
                            if (Service.isInRole(Role.SuperAdmin) === true) {
                              panelVisibilityClassName = "panel";
                            }
                            if (this.state.mode !== PanelMode.Normal) {
                              if (
                                this.state.activePanel &&
                                this.state.activePanel.id === item.id
                              ) {
                                panelVisibilityClassName =
                                  "fullscreen-toggle active";
                              } else {
                                panelVisibilityClassName = "hideForToggle";
                              }
                            }
                            var panelStyle = {};
                            if (
                              this.state.mode === PanelMode.Edit &&
                              item.position.h !== 0
                            ) {
                              panelStyle = { height: "400px" };
                            } else if (item.position.h !== 0) {
                              panelStyle = { height: item.position.h * 30 };
                            }

                            return (
                              <div
                                id={item.id}
                                key={item.id}
                                className={
                                  panelVisibilityClassName +
                                  " col-md-" +
                                  item.position.w
                                }
                                style={panelStyle}
                              >
                                <Panel
                                  {...this.props}
                                  activePanel={this.state.activePanel}
                                  panel={item}
                                  mode={this.state.mode}
                                  datasourceTypes={this.state.datasourceTypes}
                                  datasource={this.state.currentDatasource}
                                  data={this.state.datasourcesData}
                                  datasources={this.state.datasources}
                                  onPanelSave={this.onPanelSave}
                                  onPanelEdit={this.onPanelEdit}
                                  onPanelView={this.onPanelView}
                                  onPanelDelete={this.onPanelDelete}
                                  onPanelGoBack={this.onPanelGoBack}
                                  onAddDataSource={this.onAddDataSource}
                                  onAddPanelDataSource={
                                    this.onAddPanelDataSource
                                  }
                                  onDeletePanelDataSource={
                                    this.onDeletePanelDataSource
                                  }
                                  appProps={this.props.appProps}
                                  onChangePanelFrequency={
                                    this.onChangePanelFrequency
                                  }
                                />
                              </div>
                            );
                          })}
                      </div>
                        ) */}
                </Col>
              </Row>

              <Dialog
                title={' Add Datasource '}
                showModal={this.state.showDatasourceDialog}
                successButtonText="Save"
                closeButtonText="Close"
                onSuccessClick={this.onDatasourceDialogSave}
                onCloseClick={this.onDatasourceDialogClose}
              >
                <DatasourceContainer
                  datasourceTypes={this.state.datasourceTypes}
                  datasource={this.state.currentDatasource}
                />
              </Dialog>
            </Container>
          </div>
        </Print>
      </>
    )
  }
}

export default Dashboard;
