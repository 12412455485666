import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col
} from "react-bootstrap";

import Card from "components/card/Card.jsx";

import Button from "components/controls/CustomButton.jsx";
import Service from "Service";
import Constant from "../../constants/Constant";
import FieldRenderer from "components/common/FieldRenderer";
import StorageId from "constants/StorageId";
import Helper from '../../Helper';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      data: {
        strategy: "local",
        userName: null,
        passWord: null,
        questionId: null,
        answer: null
      },
      question: null,
      multifactorAuth: "N"
    };
  }
  componentDidMount() {
    setTimeout(
      function () {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
    // setTimeout(() => {
    //   this.props.appProps.showNotification(
    //     <div className="text-center">
    //       Welcome to <b>barn365</b>
    //     </div>,
    //     Constant.Notification.Type.Dynamic
    //   );
    // }, 2000);
  }

  handleInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleLoginClick = values => {
    
    if (Helper.isNullOrEmpty(this.state.data.userName) === true) {
      this.props.appProps.showNotification(
        "UserName can't be empty.",
        Constant.Notification.Type.Error
      );
      return;
    }
    if (Helper.isNullOrEmpty(this.state.data.passWord) === true) {
      this.props.appProps.showNotification(
        "Password can't be empty.",
        Constant.Notification.Type.Error
      );
      return;
    }

    let preAuthUrl = '{apiUrl}/pre-authentication?email='+this.state.data.userName+'&password='+this.state.data.passWord;
    Service.getRequest(
      preAuthUrl,
      res => {
          let resObj = res.body;
          if(resObj.MFAEnabled === "Y") {
            var data = {...this.state.data};
            data.questionId = resObj.questionId; 
            this.setState({
              multifactorAuth:resObj.MFAEnabled,
              question: resObj.question,
              data
            });
          } else {
            this.processAuthentication();
          }
      },
      this.props.appProps.handleServiceError
    );
  };

  processAuthentication = () => {
    this.props.appProps.showLoader();
    this.state.data.utcOffset = new Date().getUTCOffset();
    //call the servicel
    Service.postRequest("{apiUrl}/authentication",
      this.state.data,
      res => {
        let decoded = Service.decodeToken(res.body.accessToken);
        
        if (decoded.roles.length === 0) {
          throw new Error("Sorry! There is no roles defined for you. Please contact your administrator");
        }

        //var decoded = jwtDecode(res.body.accessToken);
        sessionStorage.setItem("token", res.body.accessToken);
        sessionStorage.setItem("userid", decoded.UserId);
        sessionStorage.setItem("usermenus", res.body.usermenus);
        let tenants = res.body.tenants;
        sessionStorage.setItem(
          StorageId.Session.Tenants,
          JSON.stringify(tenants)
        );
        sessionStorage.setItem(
          StorageId.Session.DashRoutes,
          JSON.stringify(res.body.menus)
        );
        this.props.appProps.showNotification(
          "Login is successful. Welcome " + Service.getTokenData("Name"),
          Constant.Notification.Type.Success
        );
        Service.GetDatasourcesAndTypes(
          res.body.accessToken,
          this.props.appProps.handleServiceError
        );
        this.logUserAudit(decoded.UserId);
      },
      this.props.appProps.handleServiceError
    );
  }

  logUserAudit = (userId) => {
    this.props.appProps.showLoader();

    var zone = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2] + ' (' + Intl.DateTimeFormat().resolvedOptions().timeZone + ')';
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    
    Service.postRequest("{apiUrl}/user-audit",
      {
        userId: userId, 
        utcOffset : new Date().getUTCOffset(),
        timeZone: zone,
        device: isMobile ? "Mobile" : "Computer"
      },
      res => {
        sessionStorage.setItem("UserAuditId", res.body.insertId);
      },
      this.props.appProps.handleServiceError
    );

  }

  handleValidateClick = values => {
    values.preventDefault();
    if (Helper.isNullOrEmpty(this.state.data.answer) === true) {
      this.props.appProps.showNotification(
        "Security Answer can't be empty.",
        Constant.Notification.Type.Error
      );
      return;
    }
    this.processAuthentication();
  };

  handleCancelClick = values => {
    console.log("cancel Clicked");
    this.setState({multifactorAuth: "N"});
  };

  render() {
    return (
      <Container>
        <Row>
          <Col md={{ span: 4, offset: 4 }} sm={{ span: 6, offset: 3 }}>
            
              <Card
                className="p-4"
                hidden={this.state.cardHidden}
                textCenter
                content={
                  <>
                    {this.state.multifactorAuth === "N" && <div>
                      <FieldRenderer
                        panel={this.state.data}
                        fieldDefinitions={{
                          fields: [
                            {
                              fieldSet: "",
                              name: "User Name",
                              path: "userName",
                              type: "string",
                              controlType: "text",
                              default: null,
                              options: [],
                              url: "",
                              required: false,
                              maxLength: null,
                              minLength: null
                            },
                            {
                              fieldSet: "",
                              name: "Password",
                              path: "passWord",
                              type: "string",
                              controlType: "password",
                              default: "",
                              options: [],
                              url: "",
                              required: false,
                              maxLength: null,
                              minLength: null
                            }
                          ]
                        }}
                      />
                      <div className="form-group p-3">
                        <Button
                          onClick={this.handleLoginClick}
                          variant="Button"
                          fill
                        >
                          Login
                      </Button>
                        <Link to="/Pages/ForgotPassword" className="pl-3 text-primary">
                          Forgot Password?
                      </Link>

                      </div></div>}
                    {this.state.multifactorAuth === "Y" && <div><label>Answer the below security question</label>
                      <FieldRenderer
                        panel={this.state.data}
                        fieldDefinitions={{
                          fields: [
                            {
                              fieldSet: "",
                              name: this.state.question,
                              path: "answer",
                              type: "string",
                              controlType: "text",
                              default: null,
                              options: [],
                              url: "",
                              required: true,
                              maxLength: null,
                              minLength: null
                            }
                          ]
                        }}
                      />
                      <div className="form-group p-3">
                        <Button
                          onClick={this.handleValidateClick}
                          variant="Button"
                          fill
                        >
                          Validate
                      </Button>
                      <Button
                          onClick={this.handleCancelClick}
                          variant="Button"
                          fill
                        >
                          Cancel
                      </Button>
                      </div>
                      </div>}
                  </>
                }
                ftTextCenter
              />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default LoginPage;
