import React from "react";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import Helper from "Helper";

class AreaWidget extends React.Component {
  render() {
    //Avoid Error when widget is NOT configured
    if (this.props.widget.xAxis === undefined)
    {
        return <div className="text-center">Widget configuration is incomplete. Please contact your Administrator</div>
    }
    var data = Helper.getDataFromJsonPathQuery(this.props.data, this.props.panel.datasources[0].path);
    return (
      <ResponsiveContainer width={this.props.widget.width} height={this.props.widget.height}>
        <AreaChart data={data}
          margin={{ top: this.props.widget.top, right: this.props.widget.right, left: this.props.widget.left, bottom: this.props.widget.bottom }}>
          <XAxis name={this.props.widget.xAxis.nameKey} dataKey={this.props.widget.xAxis.dataKey} />
          <YAxis />
          {this.props.widget.showCartesianGrid && <CartesianGrid strokeDasharray="3 3" />}
          {this.props.widget.showTooltip && <Tooltip isAnimationActive={false}/>}
          {this.props.widget.showLegend && <Legend />}
          {this.props.widget.yAxis.map((item, index) => {
            if (item.dataKey !== "")
            {
              return <Area  key={index} type="monotone" name={item.nameKey} dataKey={item.dataKey} stackId="1" stroke={item.color} fill={item.color}/>
            }
            return null;
          })}
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}

export default AreaWidget;