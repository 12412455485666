import React from "react";
import CheckboxTree from "react-checkbox-tree";
import Service from "../../Service";
import Constant from "constants/Constant";

export class TenantExportTableConfigureWidget extends React.Component {
    constructor(props) {
        super(props);

        this.tenants = JSON.parse(sessionStorage.getItem("tenants"));

        this.state = {
            tenantId: props.match.params.tenantId,
            tenantName: this.tenants.find(
                (e) => e.id.toString() === props.match.params.tenantId.toString()
            ).name,
            checked: [],
            expanded: [],
            tableList: []
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.tenantId !== this.props.match.params.tenantId) {
            this.setState({
                tenantId: nextProps.match.params.tenantId,
                tenantName: this.tenants.find(
                    (e) => e.id.toString() === nextProps.match.params.tenantId.toString()
                ).name,
                checked: [],
                expanded: [],
            }, () => {
                this.getTenantExportableTable();
            });
        }
    }

    componentDidMount = () => {
        this.getTableList();
    };

    getTenantExportableTable = () => {
        this.props.appProps.showLoader();
        Service.getRequest(
            `{apiUrl}/export-table-tenant-permission/${this.state.tenantId}`,
            this.getTenantExportableTableResponse,
            this.props.appProps.handleServiceError
        );
    };

    getTableList = () => {
        this.props.appProps.showLoader();
        Service.getRequest(
            `{apiUrl}/table`,
            this.getTableListResponse,
            this.props.appProps.handleServiceError
        );
    };

    getTableListResponse = (response) => {
        this.props.appProps.hideLoader();
        this.setState({
            tableList: response.body.data.tableList,
        });

        this.getTenantExportableTable();
    };

    getTenantExportableTableResponse = (response) => {
        this.props.appProps.hideLoader();
        this.setState({
            checked: response.body.data.tableNames,
        })
    }

    onChecked = (checked) => {
        this.setState({checked});
    };

    onExpand = (expanded) => {
        this.setState({expanded});
    };

    onSave = () => {
        this.props.appProps.showLoader();
        Service.postRequest(`{apiUrl}/export-table-tenant-permission`,
            {
                'tenantId': this.state.tenantId,
                'tableNames': this.state.checked
            },
            this.successCallback,
            this.props.appProps.handleServiceError
        )
    }

    successCallback = () => {
        this.props.appProps.hideLoader();
        this.props.appProps.showNotification(
            "Saved Successfully.",
            Constant.Notification.Type.Success
        );
    }

    render() {
        const nodes = this.state.tableList.reduce(
            (acc, cur) => [...acc, {value: cur, label: cur}],
            []
        );
        return (
            <div className="row export-table-data m-0">
                <div className="col-12">
                    <button
                        className="btn btn-primary"
                        onClick={this.onSave}
                    >
                        Save
                    </button>
                </div>
                <div className="col-12">
                    {Boolean(nodes.length) && (
                        <CheckboxTree
                            nodes={nodes}
                            checked={this.state.checked}
                            expanded={this.state.expanded}
                            onCheck={this.onChecked}
                            onExpand={this.onExpand}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default TenantExportTableConfigureWidget;
