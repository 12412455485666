import React, { Fragment } from 'react'
import Helper from 'Helper'
import Markdown from 'react-markdown'
import breaks from 'remark-breaks'
import Constant from 'constants/Constant'
import 'components/chatroom/index.js'
import 'components/chatroom/Chatroom.scss'
import ConnectedChatroom from 'components/chatroom/ConnectedChatroom'
import ResultChart from 'components/chatroom/ResultChart'
import ChatLanding from 'components/chatroom/ChatLanding'
import { noop, sleep, uuidv4 } from 'components/chatroom/utils'
import GridWidget from 'components/widgets/GridWidget'
import { Button, Pagination } from 'react-bootstrap'

const USERID_STORAGE_KEY = 'simple-chatroom-cid'

class ChatWidget extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      tenantId: 0,
      messages: [],
      active: 0,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.tenantId !== this.props.match.params.tenantId) {
      this.state.tenantId = nextProps.match.params.tenantId
    }
  }
  componentDidMount = () => {
    this.state.tenantId = this.props.match.params.tenantId
  }

  getResults = (message) => {
    let index = this.state.messages.length + 1
    let gridData = ''
    var parsedJsonData = Helper.ParseJson(message.text, null)
    let chartY = parsedJsonData.chart_y
    let chartX = parsedJsonData.chart_x
    let chartType = parsedJsonData.chartstyle
    if (parsedJsonData != null)
      gridData = Helper.ParseJson(parsedJsonData.detail, null)
    let existingMessages = [...this.state.messages]
    if (this.state.messages.length >= 5) {
      existingMessages.shift()
      index = 5
    }

    this.setState({
      messages: [
        ...existingMessages,
        {
          heading: parsedJsonData.msg,
          text: gridData,
          chartX,
          chartY,
          chartType,
        },
      ],
      active: index,
      currentMessage: '',
    })
  }

  clearMessages = () => {
    this.setState({ messages: [] })
  }

  setActivePage = (index) => {
    this.setState({ active: index })
  }

  render() {
    sessionStorage.removeItem('simple-chatroom-cid')
    let sessionUserId = window.sessionStorage.getItem(USERID_STORAGE_KEY)

    const isNewSession = sessionUserId == null

    if (isNewSession) {
      sessionUserId = uuidv4()
      window.sessionStorage.setItem(USERID_STORAGE_KEY, sessionUserId)
    }
    var token = sessionStorage.getItem('token')
    var options = {
      host: Constant.ApiBaseUrl,
      title: 'Barn365 Assistant',
      container: document.querySelector('.chat-container'),
      welcomeMessage: 'Hi, I am Barn 3 6 5 Assistant. How may I help you?',
      speechRecognition: 'en-US',
      voiceLang: 'en-US',
      fetchOptions: {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          tenantId: this.state.tenantId,
        },
      },
    }
    const paginationItems = []
    const active = this.state.active
    const hasMessages = this.state.messages.length > 0
    let currentMessage = {}
    if (hasMessages) {
      currentMessage = this.state.messages[active - 1]
      // Pagination
      for (let i = 0; i < this.state.messages.length; i++) {
        let index = i + 1
        paginationItems.push(
          <Pagination.Item
            key={index}
            active={index === active}
            onClick={() => this.setActivePage(index)}
          >
            {index}
          </Pagination.Item>
        )
      }
    }

    return (
      <Fragment>
        {hasMessages && (
          <div className="chat-pagination">
            <Pagination>{paginationItems}</Pagination>
            <Button variant="primary" onClick={this.clearMessages}>
              Clear All
            </Button>
          </div>
        )}
        <div className="flex-chat">
          <div className="flex-result">
            <h2>{currentMessage.heading}</h2>
            {currentMessage.chartX && <ResultChart data={currentMessage} />}
            {currentMessage && !currentMessage.chartX && (
              <GridWidget data={currentMessage.text} widget={{ rows: '$' }} />
            )}
            {!hasMessages && <ChatLanding />}
          </div>

          <div className="chat-room">
            <ConnectedChatroom
              userId={sessionUserId}
              host={options.host}
              title={options.title || 'Chat'}
              speechRecognition={options.speechRecognition}
              welcomeMessage={options.welcomeMessage}
              waitingTimeout={options.waitingTimeout}
              fetchOptions={options.fetchOptions}
              voiceLang={options.voiceLang}
              getResults={this.getResults}
            />
          </div>
        </div>
      </Fragment>
    )
  }

  loadChatRoom = (tenantId) => {
    console.log('TenantId: ' + tenantId)
    sessionStorage.removeItem('simple-chatroom-cid')
    var token = sessionStorage.getItem('token')
    var chatroom = new window.Chatroom({
      host: Constant.ApiBaseUrl,
      title: 'Chat with Barn365 Assistant',
      container: document.querySelector('.chat-container'),
      welcomeMessage: 'Hi, I am Barn365 Assistant. How may I help you?',
      speechRecognition: 'en-US',
      voiceLang: 'en-US',
      fetchOptions: {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          tenantId: tenantId,
        },
      },
    })
    chatroom.openChat()
  }
}
export default ChatWidget
