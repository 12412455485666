import React from 'react';
import Sugar from 'sugar';
import Service from 'Service';
import $ from "jquery";

// Import React Table
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {
  textFilter,
  Comparator,
} from 'react-bootstrap-table2-filter'
import FieldRenderer from 'components/common/FieldRenderer'
import Dialog from 'components/common/Dialog'
import Helper from 'Helper'
import Constant from 'constants/Constant'
import paginationFactory from 'react-bootstrap-table2-paginator'
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor'
import Button from 'components/controls/CustomButton.jsx'
import _ from 'lodash'

class CRUDWidgetNew extends React.Component {
  constructor(props, context) {
    super(props, context)
    Sugar.extend()
    this.state = {
      gridData: [],
      showDialog: false,
      action: null,
      data: null,
      sizePerPage: 25,
      totalSize: 0,
      page: 1,
      sortField: null,
      sortOrder: null,
      filters: null,
      addedRows: [],
      tableChange: false,
      dataTypes: {}
    }
    this.allTenantDtls = JSON.parse(sessionStorage.getItem('tenants'))
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.tenantId !== this.props.match.params.tenantId) {
      this.props.appProps.showLoader()
      this.getGridData(nextProps.match.params.tenantId)
    }
    this.tenantDetail = _.find(this.allTenantDtls, function (o) {
      return o.id == nextProps.match.params.tenantId
    })
  }

  componentDidMount = () => {
    this.props.appProps.showLoader()
    this.getGridData(this.props.match.params.tenantId)
  }

  reloadGridData = () => {
    this.getGridData(undefined)
  }

  getColumnDropdownData = (tenantId) => {
    if (
      Helper.isNullOrUndefined(this.props.widget.columnsDataSource) === true ||
      !tenantId
    ) {
      return
    }
    const datasources = this.props.widget.columnsDataSource.split(',')
    const existingColumns = this.props.widget.columnsToDisplay.split(',')
    for (let i = 0; i < datasources.length; i++) {
      const item = datasources[i].split(':')
      let url = item[1]
      let urlTokens = {
        tenantId,
      }
      url = Service.replaceUrlWithTokens(url, urlTokens)
      const columnName = existingColumns[item[0]]
      Service.getRequest(
        url,
        (e) => {
          let data = e.body.map((i) => ({
            value: i[item[2]],
            label: i[item[2]],
            data: i
          }))
          const d = this.state.dataSourceColumns
            ? this.state.dataSourceColumns
            : []
          d.push(columnName)
          data = data.unique();
          this.setState({ [columnName]: data, dataSourceColumns: d })
        },
        this.props.appProps.handleServiceError
      )
    }
  }

  getGridData = (tenantId) => {
    if (!this.props.widget.url) return
    this.setState({ addedRows: [] })
    this.props.appProps.showLoader()
    let url = this.props.widget.url
    tenantId = tenantId || this.props.match.params.tenantId
    let urlTokens = {
      tenantId,
      siteId: this.props.match.params.siteId,
      houseId: this.props.match.params.houseId,
    }
    url = Service.replaceUrlWithTokens(url, urlTokens)
    url = url.replace('{pageSize}', this.state.sizePerPage)
    url = url.replace('{pageNumber}', this.state.page)
    url = url.replace('{sortField}', this.state.sortField)
    url = url.replace('{sortOrder}', this.state.sortOrder)
    url = url.replace('{filters}', this.state.filters)
    Service.getRequest(
      url,
      (r) => this.getGridDataResponse(r, tenantId),
      this.props.appProps.handleServiceError
    )
    this.getColumnDropdownData(tenantId)
    this.getDataTypes()
  }

  getGridDataResponse = (response, tenantId) => {
    let { data, totalCount } = response.body
    if (!data) return
    if (data.length === 0 && !this.state.filters) {
      let ID = `temp${Math.floor(Date.now() * Math.random(1))}`
      data.push({
        ID,
        Tenant: this.tenantDetail.name,
        TS_DATE: `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`,
      })
    }
    this.setState({ gridData: data, totalSize: totalCount })
    this.props.appProps.hideLoader()
  }

  getDataTypes = () => {
    const columnTypes = this.props.widget.columnTypes.split(',')
    const columnNames = this.props.widget.columnNames
      .split(',')
      .map((t, i) => t)
    const types = {}
    if (columnTypes && columnTypes.length > 0) {
      for (let i = 0; i < columnTypes.length; i++) {
        const column = columnTypes[i].split(':')
        const name = columnNames[parseInt(column[0]) - 1].toLowerCase()
        types[name] = column.slice(1)
      }
    }
    this.setState({ dataTypes: types })
  }

  onExport = () => {
    let data = this.props
    let url = this.props.widget.exporturl
    let fileName = this.props.widget.exportFileName
    let fields = this.props.widget.reportfields
    let tenantId = this.props.match.params.tenantId
    let urlTokens = {
      tenantId: tenantId,
      siteId: this.props.match.params.siteId,
    }

    url = Service.replaceUrlWithTokens(url, urlTokens)
    url = url.replace('{sortField}', this.state.sortField)
    url = url.replace('{sortOrder}', this.state.sortOrder)
    url = url.replace('{filters}', this.state.filters)
    // if (url.includes('Status') && url.includes('flockheaderreport')) {
    //   url = url.replace('Status', 'a.Status')
    // }

    this.props.appProps.showLoader()
    Service.exportRequest(
      url,
      fields,
      fileName,
      this.props.appProps,
      this.props.appProps.handleServiceError
    )
  }

  addRow = () => {
    this.setState({
      showDialog: true,
      action: Constant.Action.Add,
      crudObject: {},
    })
  }

  editRow = (cell, row, rowIndex) => {
    this.setState({
      showDialog: true,
      action: Constant.Action.Edit,
      crudObject: Object.clone(row, true),
    })
  }

  onSave = () => {
    let ID = `temp${Math.floor(Date.now() * Math.random(1))}`
    let crudObj = { ...this.state.crudObject }
    if (!this.formValidation()) return
    this.props.appProps.showLoader()
    let url = this.props.widget.url.split('?')[0]
    Service.postRequest(
      url,
      crudObj,
      this.onSaveSuccess,
      this.props.appProps.handleServiceError
    )
  }

  onClose = () => {
    this.setState({ showDialog: false })
  }

  showSuccessMessage = () => {
    if (this.state.action !== null) {
      if (this.state.action === Constant.Action.Delete) {
        this.props.appProps.showNotification(
          'Deleted successfully.',
          Constant.Notification.Type.Success
        )
      } else if (
        this.state.action === Constant.Action.Add ||
        this.state.action === Constant.Action.Edit
      ) {
        this.props.appProps.showNotification(
          'Saved successfully.',
          Constant.Notification.Type.Success
        )
      }
    }
    this.setState({ action: null })
  }

  onSaveSuccess = () => {
    this.showSuccessMessage()
    this.reloadGridData()
  }

  onDeleteSuccess = () => {
    this.showSuccessMessage()
    this.reloadGridData()
    this.props.appProps.hideLoader()
  }

  confirmDelete = () => {
    this.props.appProps.hideAlert()
    this.props.appProps.showLoader()
    let keyValue = Helper.getWidgetFieldValue(
      this.state.crudObject,
      '$.' + this.props.widget.keyField,
      false
    )
    if (Helper.isNullOrUndefined(keyValue) === true || keyValue === '0') {
      this.onDeleteSuccess()
    }
    Service.deleteRequest(
      this.props.widget.url,
      keyValue,
      this.onDeleteSuccess,
      this.props.appProps.handleServiceError
    )
  }

  handleTableChange = (type, newState) => {
    let { sortField, sortOrder, filters, page, sizePerPage, data, cellEdit } =
      newState
    if (type === 'cellEdit') {
      const { rowId, dataField, newValue } = cellEdit
      const result = data.map((row) => {
        if (row.ID === rowId) {
          const newRow = { ...row }
          newRow[dataField] = newValue

          //Custom Logic for Inventory Page. Note: This is a cheesy fix
          if (dataField == "ProductName"){
            let columnsData = this.state[dataField];
            columnsData = columnsData.filter( (t) => t.data["productcategory"] == newRow["ProductCategory"]);
            columnsData = columnsData.filter( (t) => t.data["productsize"] == newRow["ProductSize"]);
            if(columnsData.length > 0){
              newRow["ProductId"] = columnsData[0].data["ID"];
            }
          }
          newRow['changeType'] = 'U'
          return newRow
        }
        return row
      })
      this.setState({ gridData: result })
    } else if (type === 'pagination' || type === 'filter' || type === 'sort') {
      //Get the filters
      let fieldFilters = ''
      Object.keys(filters).forEach((filter, index) => {
        if (index !== 0) {
          fieldFilters = fieldFilters + Constant.Comma
        }
        fieldFilters =
          fieldFilters + filter + Constant.Colon + filters[filter].filterVal
      })

      if (Helper.isNullOrEmpty(sortOrder) === true) {
        sortOrder = null
      }
      if (Helper.isNullOrEmpty(fieldFilters) === true) {
        fieldFilters = null
      }
      this.setState({
        sizePerPage: sizePerPage,
        page: page,
        sortField: sortField,
        sortOrder: sortOrder,
        filters: fieldFilters,
        tableChange: true,
      })
      setTimeout(() => {
        this.reloadGridData()
      }, 1)
    }
  }

  saveAllChanges = () => {
    // console.log("Saving Changes");
    let myData = this.state.gridData.filter({ changeType: 'U' })
    // console.log("No OfElements to Update : " + myData.length);
    // console.log(JSON.stringify(myData));
    if (myData.length === 0) {
      this.props.appProps.showNotification('No changes have been made!')
      return
    }
    this.props.appProps.showLoader()
    let url = this.props.widget.url.split('?')[0]

    Service.putRequest(
      url,
      null,
      myData,
      this.saveAllChangesSuccess,
      this.props.appProps.handleServiceError
    )
  }

  saveAllChangesSuccess = () => {
    this.props.appProps.showNotification(
      'Saved successfully.',
      Constant.Notification.Type.Success
    )
    this.reloadGridData()
  }

  deleteRow = (row) => {
    this.setState({ action: Constant.Action.Delete, crudObject: row })
    this.props.appProps.showAlert({
      title: 'Are you sure?',
      body: 'You will not be able to undo this action!',
      confirmBtnText: 'Yes, delete it!',
      onConfirm: this.confirmDelete,
    })
  }

  // concatenateColumns = (cell, row, rowIndex, extraData) => {
  concatenateColumns = (row, extraData) => {
    //console.log(JSON.stringify(extraData));
    let value = ''
    extraData.split('+').map((column) => {
      //console.log(column.startsWith("'"));
      if (column.startsWith("'") === true) {
        value = value + column.substring(1, column.length - 1)
      } else {
        value = value + Helper.getDataFromJsonPathQuery(row, '$.' + column)
      }
      return column
    })
    return value
  }

  dateFormatter = (cell, row) => {
    if (!cell) {
      return null
    }
    //return cell;
    cell = Date.create(cell.substring(0, 10)).format('{yyyy}-{MM}-{dd}')
    return Date.create(cell.substring(0, 10)).format('{yyyy}-{MM}-{dd}')
  }

  getHeaderStyle = (column) => {
    let headerStyle
    if (
      (this.state.dataSourceColumns &&
        this.state.dataSourceColumns.includes(column)) ||
      column === 'Comments'
    ) {
      headerStyle = { minWidth: '8rem' }
    } else {
      headerStyle = { minWidth: '6rem' }
    }
    return headerStyle
  }

  getEditorStyle = (column) => {
    let editorStyle
    if (
      (this.state.dataSourceColumns &&
        this.state.dataSourceColumns.includes(column)) ||
      column === 'Comments'
    ) {
      editorStyle = {
        fontSize: '.8rem',
        height: '1.4rem',
        padding: '0',
        margin: '0',
        width: '6.3rem',
      }
    } else {
      editorStyle = {
        fontSize: '.8rem',
        height: '1.4rem',
        padding: '0',
        margin: '0',
        width: '4.3rem',
      }
    }
    return editorStyle
  }

  getEditor = (column) => {
    let editor = {}
    if (
      this.state.dataSourceColumns &&
      this.state.dataSourceColumns.includes(column)
    ) {
      editor = {
        type: Type.SELECT,
        //options: this.state[column]
        getOptions: (setOptions, params) => {
            let columnsData = this.state[column];
            
            //Custom Logic for Inventory Page. Note: This is a cheesy fix
            if (params.column["dataField"] == "ProductName"){
              columnsData = columnsData.filter( (t) => t.data["productcategory"] == params.row["ProductCategory"]);
              columnsData = columnsData.filter( (t) => t.data["productsize"] == params.row["ProductSize"]);
            }

            //Added Unique filter for Product Type
            columnsData = columnsData.unique( c => c.value);
            return columnsData;
        }
      }
    }
    return editor
  }

  render() {
    let buildData = []
    let roles = Service.getRoles() + ''
    //Avoid Error when widget is NOT configured
    if (this.props.widget.url === undefined) {
      return (
        <div className="text-center">
          Widget configuration is incomplete. Please contact your Administrator
        </div>
      )
    }

    var isDataLoaded = false
    var columnDefinitions = []

    var data = []

    let canEdit = false
    if (
      Helper.isNullOrUndefined(this.props.widget.allowEditFor) === false &&
      this.props.widget.allowEditFor.split(Constant.Comma).includes(roles) ===
        true
    ) {
      canEdit = true
    }

    if (
      this.state.gridData &&
      this.state.gridData != null &&
      this.state.gridData.length > 0
    ) {
      data = this.state.gridData
    }
    //Check if Data exists?
    let columns = []
    if (data[0] != undefined) {
      columns = Object.keys(data[0])
    }
    let columnsToDisplay = []
    if (
      this.props.widget.columnsToDisplay !== undefined &&
      this.props.widget.columnsToDisplay !== ''
    ) {
      columnsToDisplay = this.props.widget.columnsToDisplay.split(',')
    } else {
      columnsToDisplay = columns
    }
    let columnNames = columnsToDisplay.clone()
    if (this.props.widget.columnNames && this.props.widget.columnNames !== '') {
      this.props.widget.columnNames.split(',').forEach((item, index) => {
        columnNames[index] = item
      })
    }

    //var columns = Object.keys(data[0]);
    columnsToDisplay.forEach((column, index) => {
      if (column.indexOf('+') === -1) {
        columnDefinitions.push({
          text: columnNames[index].toUpperCase(),
          dataField: column,
          sort: true,
          style: { whiteSpace: 'nowrap' },
          headerStyle: canEdit && this.getHeaderStyle(column),
          editCellStyle: { background: 'lightgrey' },
          editorStyle: canEdit && this.getEditorStyle(column),
          filter:
            columnNames[index].toLowerCase().trim() === 'status'
              ? textFilter({
                  placeholder: 'Type to filter',
                  comparator: Comparator.EQ,
                })
              : textFilter({ placeholder: 'Type to filter' }),
          editor: canEdit && this.getEditor(column),
          validator: (newValue, row, column) => {
            const name = column['text'].toLowerCase()
            const validator = this.state.dataTypes[name]
            if (!validator) return true
            const required = validator[0]
            const type = validator[1]
            const length = validator[2]
            if (!type) return true

            if (required === 'true') {
              if (!newValue || newValue.trim() == '') {
                this.props.appProps.showNotification(
                  `${name} should not be empty.`,
                  Constant.Notification.Type.Error
                )
                return {
                  valid: false,
                }
              }
            }
            if (type === 'number') {
              if (!newValue) {
                row[column.dataField] = 0
              }
              if (isNaN(newValue) || newValue > 999999999) {
                this.props.appProps.showNotification(
                  'Invalid number set to Number column.',
                  Constant.Notification.Type.Error
                )
                return {
                  valid: false,
                }
              }
            }
            if (type === 'text') {
              if (newValue.length > parseInt(length)) {
                this.props.appProps.showNotification(
                  `Max char limit is ${length}.`,
                  Constant.Notification.Type.Error
                )
                return {
                  valid: false,
                }
              }
            }
          },
        })
      } else {
        buildData[buildData.length] = {
          columnName: columnNames[index],
          columnFormatter: column,
        }
        columnDefinitions.push({
          text: columnNames[index].toUpperCase(),
          dataField: columnNames[index],
          // formatter : this.concatenateColumns,
          // formatExtraData : column.split("+"),
          sort: true,
          style: { whiteSpace: 'nowrap' },
          filter:
            columnNames[index].toLowerCase().trim() === 'status'
              ? textFilter({
                  placeholder: 'Type to filter',
                  comparator: Comparator.EQ,
                })
              : textFilter({ placeholder: 'Type to filter' }),
        })
      }
    })

    data.forEach((rowData, index) => {
      buildData.forEach((columnData, index) => {
        rowData[columnData.columnName] = this.concatenateColumns(
          rowData,
          columnData.columnFormatter
        )
      })
    })

    if (Helper.isNullOrEmpty(this.props.widget.columnTypes) === false) {
      let typeColumns = this.props.widget.columnTypes.split(',')

      typeColumns.forEach((column) => {
        let columnIndexTypeArray = column.split(':')
        if (columnIndexTypeArray[2] === 'date') {
          if (canEdit) {
            columnDefinitions[columnIndexTypeArray[0] - 1] = {
              ...columnDefinitions[columnIndexTypeArray[0] - 1],
              editor: { type: Type.DATE },
              headerStyle: this.getHeaderStyle(column),
              editorStyle: this.getEditorStyle(column),
            }
          }
          data.forEach((rowData, index) => {
            rowData[columnDefinitions[columnIndexTypeArray[0] - 1].dataField] =
              this.dateFormatter(
                rowData[
                  columnDefinitions[columnIndexTypeArray[0] - 1].dataField
                ],
                rowData
              )
          })
        }
      })
    }

    if (Helper.isNullOrEmpty(this.props.widget.columnNonEditable) === false) {
      let nonEditableColumns = this.props.widget.columnNonEditable.split(',')

      nonEditableColumns.forEach((columnIndex) => {
        columnDefinitions[columnIndex - 1].editable = false
      })
      if (!canEdit) {
        columnDefinitions.forEach((columnDef) => {
          columnDef.editable = false
        })
      }
    }

    let canDelete = false
    if (
      Helper.isNullOrUndefined(this.props.widget.allowDeleteFor) === false &&
      this.props.widget.allowDeleteFor.split(Constant.Comma).includes(roles) ===
        true
    ) {
      canDelete = true
    }

    if (canEdit) {
      columnDefinitions.forEach((def) => {
        if (
          this.state.dataSourceColumns &&
          this.state.dataSourceColumns.includes(def.column)
        ) {
          def.editor = { type: Type.SELECT, options: this.state[def.column] }
        }
      })
    }

    var paginationOptions = {
      page: this.state.page, // Specify the current page. It's necessary when remote is enabled
      sizePerPage: this.state.sizePerPage, // Specify the size per page. It's necessary when remote is enabled
      totalSize: this.state.totalSize, // Total data size. It's necessary when remote is enabled
      pageStartIndex: 1, // first page will be 0, default is 1
      sizePerPageList: true,
      onPageChange: (page, sizePerPage) => {}, // callback function when page was changing
      onSizePerPageChange: (sizePerPage, page) => {}, // callback function when page size was changing
      paginationTotalRenderer: (from, to, size) => {}, // custom the pagination total
    }

    var hideSaveCancel = true
    if (
      columnDefinitions.some(
        (col) => col.editable === true || col.editable === undefined
      ) &&
      canEdit === true
    ) {
      hideSaveCancel = false
    }
    var canAdd = false
    if (
      Helper.isNullOrUndefined(this.props.widget.allowAddFor) === false &&
      this.props.widget.allowAddFor.split(Constant.Comma).includes(roles) ===
        true
    ) {
      canAdd = true
    }

    if (canDelete === true || canEdit === true || canAdd === true) {
      columnDefinitions.append(
        {
          text: 'Actions',
          dataField: 'editColumn',
          editable: false,
          sort: false,
          formatExtraData: {
            canEdit: canEdit,
            canDelete: canDelete,
            canAdd: canAdd,
          },
          formatter: (cell, row, rowIndex, formatExtraData) => {
            // console.log(JSON.stringify(cell));
            // console.log(JSON.stringify(row));
            // console.log(JSON.stringify(rowIndex));
            // console.log(JSON.stringify(formatExtraData));
            return (
              <div className="d-inline" style={{ whiteSpace: 'nowrap' }}>
                {formatExtraData.canDelete === true && (
                  <a
                    href="javascript:void(0)"
                    onClick={() => this.deleteRow(row)}
                    className="text-danger pl-3"
                  >
                    <i className="fa fa-trash" />
                  </a>
                )}
                {formatExtraData.canAdd === true && (
                  <a
                    href="javascript:void(0)"
                    onClick={() => {
                      const { Tenant, Site, Customer, TS_DATE, PRODUCT } = row
                      let existingData = this.state.gridData
                      let ID = `temp${Math.floor(Date.now() * Math.random(1))}`
                      let newRowIndex = rowIndex + 1
                      existingData.splice(newRowIndex, 0, {
                        ID,
                        Tenant,
                        Site,
                        Customer,
                        TS_DATE,
                        PRODUCT,
                      })
                      let existingRows = this.state.addedRows
                      while (existingRows.includes(newRowIndex)) {
                        newRowIndex += 1
                      }
                      existingRows.push(newRowIndex)
                      this.setState({
                        gridData: existingData,
                        addedRows: existingRows,
                      })
                    }}
                    className="pr-1 ml-4"
                  >
                    <i className="fa fa-plus" />
                  </a>
                )}
              </div>
            )
          },
        },
        0
      )
    }

    var keyField = this.props.widget.keyField
    if (
      (keyField === undefined || keyField === '') &&
      columnDefinitions &&
      columnDefinitions.length > 0 &&
      columnDefinitions[0] !== undefined
    ) {
      keyField = columnDefinitions[0].dataField
    }
    isDataLoaded = true

    const rowStyle = (row, rowIndex) => {
      if (this.state.addedRows.includes(rowIndex))
        return { background: 'lightgrey' }
      return {}
    }

    return (
      <>
        <div className="row">
          <div className="col-sm-6">
            <div className="row">
              {Helper.isNullOrUndefined(this.props.widget.exporturl) ===
                false &&
                this.props.widget.exporturl !== '' && (
                  <div className="col-sm-3 col-md-2 col-lg-1">
                    <a
                      href="javascript:void(0)"
                      onClick={this.onExport}
                      className="stickyButtons export-button"
                    >
                      <i className="fa fa-file-excel-o pl-2 py-2" /> Export
                      <i
                        title="Allows only maximum of 5000 records to be exported."
                        className="fa fa-info-circle pl-2 py-2"
                      />
                    </a>
                  </div>
                )}
            </div>
          </div>
          <div className="col-sm-6 flock-widget-export-height-adjustor"></div>
        </div>
        {isDataLoaded && (
          <div style={{ paddingBottom: '4rem' }}>
            <div className="px-2">
              <BootstrapTable
                bootstrap4={true}
                keyField={keyField}
                data={data}
                columns={columnDefinitions}
                bordered={true}
                filter={filterFactory()}
                noDataIndication={isDataLoaded ? 'No Data' : ''}
                striped
                hover
                condensed
                cellEdit={cellEditFactory({
                  mode: 'click',
                  blurToSave: true,
                  beforeSaveCell(oldValue, newValue, row, column, done) {
                    setTimeout(() => {
                      const name = column['dataField']

                      // const type= this.state.dataTypes[name]
                      if (newValue) {
                        done() // contine to save the changes
                      } else {
                        done(false) // reject the changes
                      }
                    }, 0)
                    return { async: true }
                  },
                })}
                remote //={{ cellEdit: true }}
                headerSortingStyle
                pagination={paginationFactory(paginationOptions)}
                //pagination={paginationFactory()}
                //hideSizePerPage = {false}
                onTableChange={this.handleTableChange}
                rowStyle={rowStyle}
              />
              <div className="stickyButtons" hidden={false}>
                <Button
                  fill
                  onClick={this.saveAllChanges}
                  className="btn-fill flockButtons"
                  hidden={hideSaveCancel}
                >
                  Save
                </Button>
                <Button
                  fill
                  onClick={this.reloadGridData}
                  className="ml-3 btn-fill flockButtons"
                  hidden={hideSaveCancel}
                >
                  Cancel{' '}
                </Button>
                <a
                  href="javascript:location.reload()"
                  className="text-info pl-3"
                >
                  Reset Filter
                </a>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default CRUDWidgetNew;
