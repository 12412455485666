import React from "react";
import StorageId from "constants/StorageId";
import PowerbiEmbedded from 'react-powerbi';
import { Container, Col, Row } from "react-bootstrap";
import $ from "jquery";
import Service from "../../Service";

var report = null;
var currentPageIndex = 0;
class ShowReportWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: undefined,
      token: undefined,
      currentPageIndex : 0
    };
  }

  showReport = report => {
    this.setState({ url: report.url });
    this.setState({ reportId: report.PbiReportId });
    $("#reports").slideToggle();
    $("#powerBIFrame").show();
    this.props.appProps.setAutoHeight();
  }
  openReport = report => {
    if (this.state.token === undefined) {
      Service.postRequest("{apiUrl}/pbitoken", null,
        (res) => this.setPowerBIToken(res, report),
        this.props.appProps.handleServiceError)
    }
    else {
      this.showReport(report);
    }
  };

  setPowerBIToken = (res, report) => {
    sessionStorage.setItem(StorageId.Session.PowerBIToken, res.body.token);
    this.setState({ token: res.body.token });
    this.showReport(report);
  }

  backToReport = () => {
    $("#reports").slideToggle();
    $("#powerBIFrame").hide();
    this.setState({ url: undefined });
  };

  onEmbedded = (embed, thisComponent) => {
    report = embed;
    report.on("pageChanged", () => {
      report.getPages().then(pages => {
        if (thisComponent.state.pages === undefined) {
          thisComponent.setState({ pages: pages });
        }
      })
      // if (this.state.print === true){
      //   report.print();
      //   if (this.state.currentPageIndex <= this.state.totalPages-1){
      //     //report.setPage(this.state.pages[this.state.currentPageIndex+1].name);
      //   }
      //   else{
      //     this.setState({ print: true});
      //   }
      // }

    })
  }

  setFullScreen = () => {
    report.fullscreen();
  }
  printReport = () => {
    report.print();
  }

  printNextPageInReport = () => {
    report.getPages().then(pages => {
      if (currentPageIndex < pages.length-1){
        currentPageIndex = currentPageIndex + 1;
      }
      //console.log(" printing page# " + currentPageIndex)
      pages[currentPageIndex].setActive();
      report.print();
      if (window.matchMedia) {
        var mediaQueryList = window.matchMedia('print');
        mediaQueryList.addListener(function(mql) {
            if (mql.matches) {
                
            } else {
                this.printNextPageInReport();
            }
        });
    }
    });
  }

  embedAll = () => {
    // let embeds = [];
    // let pages = this.state.pages;
    // pages.map(page => {
    //   report.setPage(page.name);
    //   report.printReport();
    //   if (embeds.length == pages.length - 1) {
    //     embeds.push(<PowerbiEmbedded key={page.name}
    //       id={this.state.reportId}
    //       embedUrl={this.state.url + '&reportId=' + this.state.reportId}
    //       accessToken={this.state.token.access_token}
    //       filterPaneEnabled={false}
    //       navContentPaneEnabled={true}
    //       pageName={page.name}
    //       height='209.75mm'
    //       permissions={7}
    //       tokenType={0}
    //     //onEmbedded={(embed) => { this.onLoadedControl(embed, this) }}
    //     ></PowerbiEmbedded>);
    //   } else {
    //     embeds.push(<PowerbiEmbedded key={page.name}
    //       id={this.state.reportId}
    //       embedUrl={this.state.url + '&reportId=' + this.state.reportId}
    //       accessToken={this.state.token.access_token}
    //       filterPaneEnabled={false}
    //       navContentPaneEnabled={true}
    //       pageName={page.name}
    //       height='209.75mm'
    //       permissions={7}
    //       tokenType={0}
    //       onEmbedded={(embed) => { this.onEmbedded(embed, this) }}
    //     ></PowerbiEmbedded>);
    //   }

    // })
    // return embeds;
  }

  embed() {
    //var models = window['powerbi-client'].models;
    return (<PowerbiEmbedded
      id={this.state.reportId}
      embedUrl={this.state.url + '&reportId=' + this.state.reportId}
      accessToken={this.state.token.access_token}
      filterPaneEnabled={false}
      navContentPaneEnabled={true}
      width="100%"
      onEmbedded={(embed) => { this.onEmbedded(embed, this) }}
    ></PowerbiEmbedded>)
  }

  render() {
      this.openReport(this.props.match.params.reportId);
    return (
      <div className="h-powerBI">
          
        <Container fluid className="h-100">
          {this.state.token && (
            <Row className="h-100" id="powerBIFrame">
              <Col md={12} className="h-100">
                <div className="h-100">
                    <div className="row">
                      <div className="col-md-12">
                        {this.embed()}
                      </div>
                    </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    );
  }
}

export default ShowReportWidget;
