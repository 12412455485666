const Role = {
    SuperAdmin: "SuperAdmin",
    SuperUser: "SuperUser",
    TenantAdmin: "TenantAdmin",
    TenantUser: "TenantUser",
    SiteAdmin: "SiteAdmin",
    SiteUser: "SiteUser",
    ModuleUser: "ModuleUser",
    SiteModuleUser: "SiteModuleUser",
};
export default Role;
