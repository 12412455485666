import React from "react";
import Card from "components/card/Card";
import {Button} from "react-bootstrap";

class Tools extends React.Component {
  onAddPanel = (panelType, widgetType) => {
    this.props.onAddPanel(panelType, widgetType);
  };

  render() {
    return (
      <div className="hideForToggle" id="dashTools">
        <Card
          content={
            <div>
              <div className="row">
                <div className="col-md-3">
                  <Button variant="link"
                    onClick={() => this.onAddPanel("Default")}>
                    <i className="fa fa-plus" /> Add Panel
                  </Button>
                </div>
                <div className="col-md-3 text-center">
                  {/* <Button simple
                    onClick={this.props.onAddDataSource}
                  >
                    <i className="fa fa-plus" /> Add Datasource
                  </Button> */}
                </div>

                <div className="col-md-6 text-right">
                  {/*
                  <Button simple
                    onClick={this.props.onSaveDashboardToFile}
                  >
                    <i className="fa fa-save fa-lg" /> Save to File
                  </Button>
                  <FileReaderInput
                    as="binary"
                    id="my-file-input"
                    onChange={this.props.onLoadDashboardFromFile}
                  >
                    <button className="btn btn-simple text-info">
                      <i className="fa fa-save fa-lg" /> Load from File
                    </button>
                  </FileReaderInput>
                      */}

                  <Button variant="link"
                    onClick={this.props.onSaveDashboard}
                  >
                    <i className="fa fa-save fa-lg" /> Save
                  </Button>
                  <a
                    href="javascript:location.reload()"
                    className="text-info pl-3"
                  >
                    {" "}
                    <i className="fa fa-refresh fa-lg" /> Refresh
                  </a>
                </div>
              </div>

              {/* <div className="row text-center">
                <div className="col-md-12">
                  <br />
                  {widgets.map((item, index) => {
                    return (
                      <Button
                        key={index}
                        simple
                        onClick={() => this.onAddPanel("Default", item.type)}
                      >
                        <i className={"text-info " + item.icon} /> {item.name}
                      </Button>
                    );
                  })}
                </div>
              </div> */}
            </div>
          }
        />
        <br />
      </div>
    );
  }
}

export default Tools;
