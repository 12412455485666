const StorageId = {
  Session: {
    DashRoutes: "dashroutes",
    DsMap: "dsMap",
    DatasourceTypes: "datasourceTypes",
    Datasources : "datasources",
    Tenants : "tenants",
    Token : "token",
    PowerBIToken : "powerBIToken"
  },
  Local: {}
};
export default StorageId;
