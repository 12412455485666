import React from "react";
import Sugar from "sugar";
//import "react-select/dist/react-select.css";
import { Nav, NavItem, Tabs, Tab, ButtonToolbar, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
//import Button from "components/controls/CustomButton.jsx";

class FrequencyButtons extends React.Component {
  constructor(props, context) {
    super(props, context);
    Sugar.extend();
    this.state = {
      selectedFrequency: this.props.frequency
    };
  }

  changeFrequency = (e, frequency) => {
    this.setState({ selectedFrequency: frequency });
    this.props.onChangePanelFrequency(frequency);
  };

  render() {
    let frequencys = [
      //{ description: "Hourly", code: "H" },
      { description: "Daily", code: "D", tooltip: "Last 24 hours" },
      { description: "Weekly", code: "W", tooltip: "Last 7 Days" },
      { description: "Monthly", code: "M", tooltip: "Last 30 Days" },
      { description: "Yearly", code: "Y", tooltip: "Last 12 Months" },
      { description: "All", code: "A", tooltip: "All Time" }
    ];
    return (
      <div className="text-right pr-3">
        <ToggleButtonGroup type="checkbox" value={this.state.selectedFrequency}>
          {frequencys.map((item, index) => {
            return (
              <ToggleButton variant="primary" value={item.description} onChange={(e) => this.changeFrequency(e, item.description)}>{item.code}</ToggleButton>
            );
          })}
        </ToggleButtonGroup>

      </div>
    );
  }
}

export default FrequencyButtons;
