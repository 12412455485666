//Widgets
import GridWidget from "components/widgets/GridWidget";
import StatusWidget from "components/widgets/StatusWidget";
import SparklineChartWidget from "components/widgets/SparklineChartWidget";
import LineChartWidget from "components/widgets/LineChartWidget";
import BarChartWidget from "components/widgets/BarChartWidget";
import PieChartWidget from "components/widgets/PieChartWidget";
import DoughnutChartWidget from "components/widgets/DoughnutChartWidget";
import HalfDoughnutChartWidget from "components/widgets/HalfDoughnutChartWidget";
import HtmlWidget from "components/widgets/HtmlWidget";
import FormWidget from "components/widgets/FormWidget";
import EmptyWidget from "components/widgets/EmptyWidget";
import HouseWidget from "components/widgets/HouseWidget";
import ExecutiveWidget from "components/widgets/ExecutiveWidget";
import SiteWidget from "components/widgets/SiteWidget";
import TenantWidget from "components/widgets/TenantWidget";
import HeatMapWidget from "components/widgets/HeatMapWidget";
import ColumnWidget from "components/widgets/ColumnWidget";
import OnOffWidget from "components/widgets/OnOffWidget";
import AreaWidget from "../components/widgets/AreaWidget";
import PowerBIWidget from "../components/widgets/PowerBIWidget";
import ShowReportWidget from "../components/widgets/ShowReportWidget";
import CRUDWidget from "../components/widgets/CRUDWidget";
import FeedWidget from "components/widgets/FeedWidget";
import FlockWidget from "../components/widgets/FlockWidget";
import IFrameWidget from "../components/widgets/IFrameWidget";
import UserRoleAssignmentWidget from "../components/widgets/UserRoleAssignmentWidget";
import AIWidget from "../components/widgets/AIWidget";
import TrendWidget from "../components/widgets/TrendWidget";
import ChatWidget from "../components/widgets/ChatWidget";
import CRUDWidgetNew from 'components/widgets/CRUDWidgetNew';
import ImportFiles from '../components/widgets/ImportFilesWidget';
import ExportTableData from '../components/widgets/ExportTableDataWidget';
import TenantExportTableConfigure from "../components/widgets/TenantExportTableConfigureWidget";
//dropdownPath:
//dropdownPathAppend:
//dropdownPathField:

var widgetPropsDefinition = {
  Empty: {
    type: 'Empty',
    component: EmptyWidget,
    name: 'Empty',
    icon: 'fa fa-circle',
    tabs: [{ name: 'Widget', icon: 'fa fa-cog' }],
    fields: [],
  },
  CRUDWidgetNew: {
    type: 'CRUDWidgetNew',
    component: CRUDWidgetNew,
    name: 'CRUDWidgetNew',
    icon: 'fa fa-circle',
    tabs: [{ name: 'Widget', icon: 'fa fa-cog' }],
    fields: [
      {
        tab: 'Widget',
        fieldSet: 'Main',
        name: 'Name',
        path: 'name',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Main',
        name: 'Url',
        path: 'url',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Main',
        name: 'Export Url',
        path: 'exporturl',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Main',
        name: 'Export FileName',
        path: 'exportFileName',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Main',
        name: 'Report Fields',
        path: 'reportfields',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Grid',
        name: 'KeyField',
        path: 'keyField',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Grid',
        name: 'Columns To Display',
        path: 'columnsToDisplay',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Grid',
        name: 'Column Names',
        path: 'columnNames',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Grid',
        name: 'Column Types',
        path: 'columnTypes',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Grid',
        name: 'Column NonEditable',
        path: 'columnNonEditable',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Grid',
        name: 'Columns Data Source',
        path: 'columnsDataSource',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Form',
        name: 'Form Fields Definitions',
        path: 'formFieldsDefinitions',
        type: 'string',
        controlType: 'textarea',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
        className: 'largeTextArea',
      },
      {
        tab: 'Widget',
        fieldSet: 'Role Level Access',
        name: 'Allow Add for Roles',
        path: 'allowAddFor',
        type: 'string',
        controlType: 'text',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Role Level Access',
        name: 'Allow Edit for Roles',
        path: 'allowEditFor',
        type: 'string',
        controlType: 'text',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Role Level Access',
        name: 'Allow Delete for Roles',
        path: 'allowDeleteFor',
        type: 'string',
        controlType: 'text',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Dialog',
        name: 'Class Name',
        path: 'dialogClassName',
        type: 'string',
        controlType: 'text',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
    ],
  },

  Column: {
    type: 'Column',
    component: ColumnWidget,
    name: 'Column Widget',
    icon: 'pe-7s-id',
    tabs: [{ name: 'Widget', icon: 'fa fa-cog' }],
    fieldSets: [{ name: 'Column', arrayName: 'columns' }],
    fields: [
      {
        tab: 'Widget',
        fieldSet: 'Column',
        name: 'Icon',
        path: 'icon',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Column',
        name: 'Text',
        path: 'text',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Column',
        name: 'Prefix',
        path: 'prefix',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Column',
        name: 'Value',
        path: 'value',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Column',
        name: 'Suffix',
        path: 'suffix',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
    ],
  },

  OnOff: {
    type: 'OnOff',
    component: OnOffWidget,
    name: 'OnOff',
    icon: 'pe-7s-id',
    tabs: [{ name: 'Widget', icon: 'fa fa-cog' }],
    fields: [
      {
        tab: 'Widget',
        fieldSet: 'main',
        name: 'Icon',
        path: 'icon',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'main',
        name: 'data',
        path: 'data',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
    ],
  },

  Status: {
    type: 'Status',
    component: StatusWidget,
    name: 'Status',
    icon: 'pe-7s-id',
    tabs: [{ name: 'Widget', icon: 'fa fa-cog' }],
    fields: [
      {
        tab: 'Widget',
        fieldSet: 'Main',
        name: 'Icon',
        path: 'icon',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Main',
        name: 'Text',
        path: 'text',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Value',
        name: 'Prefix',
        path: 'prefix',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Value',
        name: 'Value',
        path: 'value',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Value',
        name: 'Suffix',
        path: 'suffix',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
    ],
  },

  Grid: {
    type: 'Grid',
    component: GridWidget,
    name: 'Grid',
    icon: 'fa fa-table',
    tabs: [{ name: 'Widget', icon: ' fa fa-cog' }],
    fields: [
      {
        tab: 'Widget',
        fieldSet: 'Misc',
        name: 'Rows',
        path: 'rows',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Misc',
        name: 'Columns To Display',
        path: 'columnsToDisplay',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Misc',
        name: 'Column Names',
        path: 'columnNames',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
    ],
  },

  LineChart: {
    type: 'LineChart',
    component: LineChartWidget,
    name: 'Line Chart',
    icon: 'fa fa-line-chart',
    tabs: [
      { name: 'Widget', icon: 'fa fa-cog' },
      { name: 'Axes', icon: 'pe-7s-graph1' },
    ],
    fieldSets: [{ name: 'Y-Axis', arrayName: 'yAxis' }],
    fields: [
      {
        tab: 'Widget',
        fieldSet: 'Size',
        name: 'Width',
        path: 'width',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Size',
        name: 'Height',
        path: 'height',
        type: 'number',
        controlType: 'number',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Labels',
        name: 'Show Legend',
        path: 'showLegend',
        type: 'bool',
        controlType: 'checkbox',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Labels',
        name: 'Show Tooltip',
        path: 'showTooltip',
        type: 'bool',
        controlType: 'checkbox',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Labels',
        name: 'Show Grid',
        path: 'showCartesianGrid',
        type: 'bool',
        controlType: 'checkbox',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Axes',
        fieldSet: 'X-Axis',
        name: 'Data Key',
        path: 'xAxis.dataKey',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        dropdownPathAppend: '.*',
        level: 1,
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Axes',
        fieldSet: 'X-Axis',
        name: 'Name Key',
        path: 'xAxis.nameKey',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        dropdownPathAppend: '.*',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Axes',
        fieldSet: 'Y-Axis',
        name: 'Data Key',
        path: 'dataKey',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        dropdownPathAppend: '.*',
        level: 1,
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Axes',
        fieldSet: 'Y-Axis',
        name: 'Name Key',
        path: 'nameKey',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        dropdownPathAppend: '.*',
        level: 1,
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Axes',
        fieldSet: 'Y-Axis',
        name: 'Color',
        path: 'color',
        type: 'string',
        controlType: 'select',
        dropdownPathField: 'datasources.0.path',
        dropdownPathAppend: '.*',
        default: '',
        options: [
          { value: '#23ccef', display: 'Blue' },
          { value: '#87cb16', display: 'Green' },
          { value: '#ffa534', display: 'Yellow' },
          { value: '#fb404b', display: 'Red' },
          { value: '#ed5e95', display: 'Pink' },
          { value: '#8884d8', display: 'Purple' },
        ],
        optionsDisplayField: 'display',
        optionsValueField: 'value',
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
    ],
  },

  AreaChart: {
    type: 'AreaChart',
    component: AreaWidget,
    name: 'Area Chart',
    icon: 'fa fa-line-chart',
    tabs: [
      { name: 'Widget', icon: 'fa fa-cog' },
      { name: 'Axes', icon: 'pe-7s-graph1' },
    ],
    fieldSets: [{ name: 'Y-Axis', arrayName: 'yAxis' }],
    fields: [
      {
        tab: 'Widget',
        fieldSet: 'Size',
        name: 'Width',
        path: 'width',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Size',
        name: 'Height',
        path: 'height',
        type: 'number',
        controlType: 'number',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Labels',
        name: 'Show Legend',
        path: 'showLegend',
        type: 'bool',
        controlType: 'checkbox',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Labels',
        name: 'Show Tooltip',
        path: 'showTooltip',
        type: 'bool',
        controlType: 'checkbox',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Labels',
        name: 'Show Grid',
        path: 'showCartesianGrid',
        type: 'bool',
        controlType: 'checkbox',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Axes',
        fieldSet: 'X-Axis',
        name: 'Data Key',
        path: 'xAxis.dataKey',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        dropdownPathAppend: '.*',
        level: 1,
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Axes',
        fieldSet: 'X-Axis',
        name: 'Name Key',
        path: 'xAxis.nameKey',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        dropdownPathAppend: '.*',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Axes',
        fieldSet: 'Y-Axis',
        name: 'Data Key',
        path: 'dataKey',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        dropdownPathAppend: '.*',
        level: 1,
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Axes',
        fieldSet: 'Y-Axis',
        name: 'Name Key',
        path: 'nameKey',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        dropdownPathAppend: '.*',
        level: 1,
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Axes',
        fieldSet: 'Y-Axis',
        name: 'Color',
        path: 'color',
        type: 'string',
        controlType: 'select',
        dropdownPathField: 'datasources.0.path',
        dropdownPathAppend: '.*',
        default: '',
        options: [
          { value: '#23ccef', display: 'Blue' },
          { value: '#87cb16', display: 'Green' },
          { value: '#ffa534', display: 'Yellow' },
          { value: '#fb404b', display: 'Red' },
          { value: '#ed5e95', display: 'Pink' },
          { value: '#8884d8', display: 'Purple' },
        ],
        optionsDisplayField: 'display',
        optionsValueField: 'value',
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
    ],
  },

  BarChart: {
    type: 'BarChart',
    component: BarChartWidget,
    name: 'Bar Chart',
    icon: 'fa fa-bar-chart',
    tabs: [
      { name: 'Widget', icon: 'fa fa-cog' },
      { name: 'Axes', icon: 'pe-7s-graph1' },
    ],
    fieldSets: [{ name: 'Y-Axis', arrayName: 'yAxis' }],
    fields: [
      {
        tab: 'Widget',
        fieldSet: 'Size',
        name: 'Width',
        path: 'width',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Size',
        name: 'Height',
        path: 'height',
        type: 'number',
        controlType: 'number',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Labels',
        name: 'Show Legend',
        path: 'showLegend',
        type: 'bool',
        controlType: 'checkbox',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Labels',
        name: 'Show Tooltip',
        path: 'showTooltip',
        type: 'bool',
        controlType: 'checkbox',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Labels',
        name: 'Show Grid',
        path: 'showCartesianGrid',
        type: 'bool',
        controlType: 'checkbox',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Axes',
        fieldSet: 'X-Axis',
        name: 'Data Key',
        path: 'xAxis.dataKey',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        dropdownPathAppend: '.*',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Axes',
        fieldSet: 'X-Axis',
        name: 'Name Key',
        path: 'xAxis.nameKey',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        dropdownPathAppend: '.*',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Axes',
        fieldSet: 'Y-Axis',
        name: 'Data Key',
        path: 'dataKey',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        dropdownPathAppend: '.*',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Axes',
        fieldSet: 'Y-Axis',
        name: 'Name Key',
        path: 'nameKey',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        dropdownPathAppend: '.*',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Axes',
        fieldSet: 'Y-Axis',
        name: 'Color',
        path: 'color',
        type: 'string',
        controlType: 'select',
        dropdownPathField: 'datasources.0.path',
        dropdownPathAppend: '.*',
        default: '',
        options: [
          { value: '#23ccef', display: 'Blue' },
          { value: '#87cb16', display: 'Green' },
          { value: '#ffa534', display: 'Yellow' },
          { value: '#fb404b', display: 'Red' },
          { value: '#ed5e95', display: 'Pink' },
          { value: '#8884d8', display: 'Purple' },
        ],
        optionsDisplayField: 'display',
        optionsValueField: 'value',
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
    ],
  },

  PieChart: {
    type: 'PieChart',
    component: PieChartWidget,
    name: 'Pie Chart',
    icon: 'fa fa-pie-chart',
    tabs: [{ name: 'Widget', icon: 'fa fa-cog' }],
    fields: [
      {
        tab: 'Widget',
        fieldSet: 'Axis',
        name: 'Data Key',
        path: 'rAxis.dataKey',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        dropdownPathAppend: '.*',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Axis',
        name: 'Name Key',
        path: 'rAxis.nameKey',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        dropdownPathAppend: '.*',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Size',
        name: 'Width',
        path: 'width',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Size',
        name: 'Height',
        path: 'height',
        type: 'number',
        controlType: 'number',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Labels',
        name: 'Show Legend',
        path: 'showLegend',
        type: 'bool',
        controlType: 'checkbox',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Labels',
        name: 'Show Tooltip',
        path: 'showTooltip',
        type: 'bool',
        controlType: 'checkbox',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Labels',
        name: 'Show Label',
        path: 'rAxis.showLabel',
        type: 'bool',
        controlType: 'checkbox',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
    ],
  },

  DoughnutChart: {
    type: 'DoughnutChart',
    component: DoughnutChartWidget,
    name: 'Doughnut Chart',
    icon: 'fa fa-pie-chart',
    tabs: [{ name: 'Widget', icon: 'fa fa-cog' }],
    fields: [
      {
        tab: 'Widget',
        fieldSet: 'Axis',
        name: 'Data Key',
        path: 'rAxis.dataKey',
        type: 'string',
        controlType: 'datasource',
        dropdownPathField: 'datasources.0.path',
        dropdownPathAppend: '.*',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Axis',
        name: 'Name Key',
        path: 'rAxis.nameKey',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        dropdownPathAppend: '.*',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      // { "tab": "Widget", "fieldSet": "Axis", "name": "Inner Radius", "path": "innerRadius.", "type": "number", "controlType": "datasourceData", "dropdownPathField":"datasources.0.path", "dropdownPathAppend": ".*", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
      // { "tab": "Widget", "fieldSet": "Axis", "name": "Outer Radius", "path": "outerRadius.", "type": "number", "controlType": "datasourceData", "dropdownPathField":"datasources.0.path", "dropdownPathAppend": ".*", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
      {
        tab: 'Widget',
        fieldSet: 'Size',
        name: 'Width',
        path: 'width',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Size',
        name: 'Height',
        path: 'height',
        type: 'number',
        controlType: 'number',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Properties',
        name: 'Show Legend',
        path: 'showLegend',
        type: 'bool',
        controlType: 'checkbox',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Properties',
        name: 'Show Tooltip',
        path: 'showTooltip',
        type: 'bool',
        controlType: 'checkbox',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Properties',
        name: 'Show Label',
        path: 'rAxis.showLabel',
        type: 'bool',
        controlType: 'checkbox',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
    ],
  },

  HalfDoughnutChart: {
    type: 'HalfDoughnutChart',
    component: HalfDoughnutChartWidget,
    name: 'Half Doughnut Chart',
    icon: 'fa fa-pie-chart',
    tabs: [{ name: 'Widget', icon: 'fa fa-cog' }],
    fields: [
      {
        tab: 'Widget',
        fieldSet: 'Main',
        name: 'Data Key',
        path: 'rAxis.dataKey',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        dropdownPathAppend: '.*',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Main',
        name: 'Name Key',
        path: 'rAxis.nameKey',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        dropdownPathAppend: '.*',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Size',
        name: 'Width',
        path: 'width',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        dropdownPathAppend: '.*',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Size',
        name: 'Height',
        path: 'height',
        type: 'number',
        controlType: 'number',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Labels',
        name: 'Show Legend',
        path: 'showLegend',
        type: 'bool',
        controlType: 'checkbox',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Labels',
        name: 'Show Tooltip',
        path: 'showTooltip',
        type: 'bool',
        controlType: 'checkbox',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Labels',
        name: 'Show Label',
        path: 'rAxis.showLabel',
        type: 'bool',
        controlType: 'checkbox',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
    ],
  },
  SparklineChart: {
    type: 'SparklineChart',
    component: SparklineChartWidget,
    name: 'Sparkline Chart',
    icon: 'fa fa-line-chart',
    tabs: [
      { name: 'Info', icon: 'fa fa-info' },
      { name: 'General', icon: ' fa fa-bank' },
      { name: 'Datasources', icon: 'fa fa-database' },
      { name: 'Widget', icon: 'fa fa-cog' },
    ],
    fields: [
      {
        tab: 'Widget',
        fieldSet: 'Main',
        name: 'Icon',
        path: 'icon',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Main',
        name: 'Text',
        path: 'text',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Value',
        name: 'Prefix',
        path: 'prefix',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Value',
        name: 'Value',
        path: 'value',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Value',
        name: 'Suffix',
        path: 'suffix',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
    ],
  },

  Html: {
    type: 'Html',
    component: HtmlWidget,
    name: 'Html',
    icon: 'fa fa-code',
    tabs: [{ name: 'Widget', icon: ' fa fa-cog' }],
    fields: [
      {
        tab: 'Widget',
        fieldSet: 'Html',
        name: 'Html',
        path: 'html',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
    ],
  },
  IFrameWidget: {
    type: 'IFrameWidget',
    component: IFrameWidget,
    name: 'iFrameWidget',
    icon: 'fa fa-code',
    tabs: [{ name: 'Widget', icon: ' fa fa-cog' }],
    fields: [
      {
        tab: 'Widget',
        fieldSet: 'iFrame',
        name: 'url',
        path: 'url',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
    ],
  },
  PowerBI: {
    type: 'PowerBI',
    component: PowerBIWidget,
    name: 'Power BI',
    icon: 'fa fa-code',
    tabs: [{ name: 'Report', icon: ' fa fa-line-chart' }],
    fields: [
      {
        tab: 'Report',
        fieldSet: 'Report',
        name: 'ID',
        path: 'id',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Report',
        fieldSet: 'Report',
        name: 'Url',
        path: 'url',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Report',
        fieldSet: 'Report',
        name: 'AccessToken',
        path: 'accessToken',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Report',
        fieldSet: 'Report',
        name: 'FilterPaneEnabled',
        path: 'filterPaneEnabled',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Report',
        fieldSet: 'Report',
        name: 'NavContentPaneEnabled',
        path: 'navContentPaneEnabled',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
    ],
  },
  ShowReport: {
    type: 'ShowReport',
    component: ShowReportWidget,
    name: 'Show Report',
    icon: 'fa fa-code',
    tabs: [{ name: 'Report', icon: ' fa fa-line-chart' }],
    fields: [],
  },

  Form: {
    type: 'Form',
    component: FormWidget,
    name: 'Form',
    icon: 'fa fa-calendar-o',
    tabs: [{ name: 'Widget', icon: ' fa fa-bank' }],
    fields: [
      {
        tab: 'Widget',
        fieldSet: 'Form',
        name: 'Form',
        path: 'form',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
    ],
  },
  Heat: {
    type: 'Heat',
    component: HeatMapWidget,
    name: 'Heat Map',
    icon: 'fa fa-thermometer-quarter',
    tabs: [{ name: 'Widget', icon: ' fa fa-bank' }],
    fields: [
      {
        tab: 'Widget',
        fieldSet: 'Form',
        name: 'Form',
        path: 'form',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
    ],
  },
  House: {
    type: 'House',
    component: HouseWidget,
    name: 'House',
    icon: 'pe-7s-home',
    tabs: [{ name: 'Widget', icon: 'fa fa-cog' }],
    fields: [
      {
        tab: 'Widget',
        fieldSet: 'Temperature',
        name: 'Outside',
        path: 'OutsideAirTemp',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Temperature',
        name: 'Target',
        path: 'TargetTemp',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Temperature',
        name: 'OptimizedTarget',
        path: 'OptimizedTargetTemp',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Temperature',
        name: 'Average',
        path: 'AverageTemp',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Temperature',
        name: 'Low',
        path: 'LowTemp',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Temperature',
        name: 'High',
        path: 'HighTemp',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Wind',
        name: 'WindSpeed',
        path: 'WindSpeed',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Wind',
        name: 'WindDirection',
        path: 'WindDirection',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Misc',
        name: 'StaticAirPressure',
        path: 'StaticAirPressure',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Misc',
        name: 'NH3Level',
        path: 'NH3Level',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Misc',
        name: 'CO2Level',
        path: 'CO2Level',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Misc',
        name: 'OutsideHumidity',
        path: 'OutsideHumidity',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Misc',
        name: 'Humidity',
        path: 'Humidity',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
    ],
  },
  Site: {
    type: 'Site',
    component: SiteWidget,
    name: 'Site',
    icon: 'fa fa-map-marker',
    tabs: [{ name: 'Widget', icon: 'fa fa-cog' }],
    fields: [],
  },
  Tenant: {
    type: 'Tenant',
    component: TenantWidget,
    name: 'Tenant',
    icon: 'fa fa-map-marker',
    tabs: [{ name: 'Widget', icon: 'fa fa-cog' }],
    fields: [],
  },
  Executive: {
    type: 'Executive',
    component: ExecutiveWidget,
    name: 'Executive',
    icon: 'fa fa-map-marker',
    tabs: [{ name: 'Widget', icon: 'fa fa-cog' }],
    fields: [],
  },
  Feed: {
    type: 'Feed',
    component: FeedWidget,
    name: 'Feed',
    icon: 'fa fa-map-marker',
    tabs: [{ name: 'Widget', icon: 'fa fa-cog' }],
    fields: [],
  },
  CRUD: {
    type: 'CRUD',
    component: CRUDWidget,
    name: 'CRUD',
    icon: 'pe-7s-id',
    tabs: [{ name: 'Widget', icon: 'fa fa-cog' }],
    fields: [
      {
        tab: 'Widget',
        fieldSet: 'Main',
        name: 'Name',
        path: 'name',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Main',
        name: 'Url',
        path: 'url',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Grid',
        name: 'KeyField',
        path: 'keyField',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Grid',
        name: 'Columns To Display',
        path: 'columnsToDisplay',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Grid',
        name: 'Column Names',
        path: 'columnNames',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Grid',
        name: 'Column Types',
        path: 'columnTypes',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Grid',
        name: 'Column NonEditable',
        path: 'columnNonEditable',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Form',
        name: 'Form Fields Definitions',
        path: 'formFieldsDefinitions',
        type: 'string',
        controlType: 'textarea',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
        className: 'largeTextArea',
      },
      {
        tab: 'Widget',
        fieldSet: 'Role Level Access',
        name: 'Allow Add for Roles',
        path: 'allowAddFor',
        type: 'string',
        controlType: 'text',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Role Level Access',
        name: 'Allow Edit for Roles',
        path: 'allowEditFor',
        type: 'string',
        controlType: 'text',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Role Level Access',
        name: 'Allow Delete for Roles',
        path: 'allowDeleteFor',
        type: 'string',
        controlType: 'text',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Dialog',
        name: 'Class Name',
        path: 'dialogClassName',
        type: 'string',
        controlType: 'text',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
    ],
  },
  AI: {
    type: 'AI',
    component: AIWidget,
    name: 'AI',
    icon: 'pe-7s-id',
    tabs: [
      { name: 'Widget', icon: 'fa fa-cog' },
      { name: 'Grid', icon: 'fa fa-table' },
    ],
    fields: [
      {
        tab: 'Widget',
        fieldSet: 'Filter',
        name: 'Filter Fields Definitions',
        path: 'filterFieldsDefinitions',
        type: 'string',
        controlType: 'textarea',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
        className: 'largeTextArea',
      },
      {
        tab: 'Grid',
        fieldSet: 'Misc',
        name: 'Rows',
        path: 'rows',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Grid',
        fieldSet: 'Misc',
        name: 'Columns To Display',
        path: 'columnsToDisplay',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Grid',
        fieldSet: 'Misc',
        name: 'Column Names',
        path: 'columnNames',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
    ],
  },
  Trend: {
    type: 'Trend',
    component: TrendWidget,
    name: 'Trend',
    icon: 'pe-7s-id',
    tabs: [
      { name: 'Widget', icon: 'fa fa-cog' },
      { name: 'Grid', icon: 'fa fa-table' },
    ],
    fields: [
      {
        tab: 'Widget',
        fieldSet: 'Filter',
        name: 'Filter Fields Definitions',
        path: 'filterFieldsDefinitions',
        type: 'string',
        controlType: 'textarea',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
        className: 'largeTextArea',
      },
      {
        tab: 'Grid',
        fieldSet: 'Misc',
        name: 'Rows',
        path: 'rows',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Grid',
        fieldSet: 'Misc',
        name: 'Columns To Display',
        path: 'columnsToDisplay',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Grid',
        fieldSet: 'Misc',
        name: 'Column Names',
        path: 'columnNames',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
    ],
  },
  Flock: {
    type: 'Flock',
    component: FlockWidget,
    name: 'Flock',
    icon: 'pe-7s-id',
    tabs: [{ name: 'Widget', icon: 'fa fa-cog' }],
    fields: [
      {
        tab: 'Widget',
        fieldSet: 'Main',
        name: 'Name',
        path: 'name',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Main',
        name: 'Url',
        path: 'url',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Export',
        name: 'Export Url',
        path: 'exporturl',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Export',
        name: 'Export FileName',
        path: 'exportFileName',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Export',
        name: 'Export Fields',
        path: 'reportfields',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Grid',
        name: 'KeyField',
        path: 'keyField',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Grid',
        name: 'Columns To Display',
        path: 'columnsToDisplay',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Grid',
        name: 'Column Names',
        path: 'columnNames',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Grid',
        name: 'Column Types',
        path: 'columnTypes',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Grid',
        name: 'Column NonEditable',
        path: 'columnNonEditable',
        type: 'string',
        controlType: 'datasourceData',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Grid',
        name: 'Client Side Paging',
        path: 'isClientSidePaging',
        type: 'bool',
        controlType: 'checkbox',
        dropdownPathField: 'datasources.0.path',
        default: true,
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Form',
        name: 'Form Fields Definitions',
        path: 'formFieldsDefinitions',
        type: 'string',
        controlType: 'textarea',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
        className: 'largeTextArea',
      },
      {
        tab: 'Widget',
        fieldSet: 'Filter',
        name: 'Filter Fields Definitions',
        path: 'filterFieldsDefinitions',
        type: 'string',
        controlType: 'textarea',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
        className: 'largeTextArea',
      },      
      {
        tab: 'Widget',
        fieldSet: 'Role Level Access',
        name: 'Allow Add for Roles',
        path: 'allowAddFor',
        type: 'string',
        controlType: 'text',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Role Level Access',
        name: 'Allow Edit for Roles',
        path: 'allowEditFor',
        type: 'string',
        controlType: 'text',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Role Level Access',
        name: 'Allow Delete for Roles',
        path: 'allowDeleteFor',
        type: 'string',
        controlType: 'text',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
      {
        tab: 'Widget',
        fieldSet: 'Dialog',
        name: 'Class Name',
        path: 'dialogClassName',
        type: 'string',
        controlType: 'text',
        dropdownPathField: 'datasources.0.path',
        default: '',
        options: [],
        url: '',
        required: false,
        maxLength: null,
        minLength: null,
      },
    ],
  },
  UserRoleAssignment: {
    type: 'UserRoleAssignment',
    component: UserRoleAssignmentWidget,
    name: 'UserRoleAssignment',
    icon: 'pe-7s-id',
    tabs: [{ name: 'Widget', icon: 'fa fa-cog' }],
    fields: [],
  },
  Chat: {
    type: 'Chat',
    component: ChatWidget,
    name: 'Chat',
    icon: 'pe-7s-id',
    tabs: [{ name: 'Widget', icon: 'fa fa-cog' }],
  },
  ImportFiles: {
    type: 'ImportFiles',
    component: ImportFiles,
    name: 'ImportFiles',
    icon: 'pe-7s-id',
    tabs: [{ name: 'Widget', icon: 'fa fa-cog' }],
    fields: [],
  },
  ExportTableData: {
    type: 'ExportTableData',
    component: ExportTableData,
    name: 'ExportTableData',
    icon: 'pe-7s-id',
    tabs: [{ name: 'Widget', icon: 'fa fa-cog' }],
    fields: [],
  },
  TenantExportTableConfigure: {
    type: 'TenantExportTableConfigure',
    component: TenantExportTableConfigure,
    name: 'TenantExportTableConfigure',
    icon: 'pe-7s-id',
    tabs: [{ name: 'Widget', icon: 'fa fa-cog' }],
    fields: [],
  },
}
export default widgetPropsDefinition;
