import React, { Component } from "react";
import {Button, Row, Col, Tabs, Tab,  ToggleButtonGroup, ToggleButton} from "react-bootstrap";
import FieldRenderer from "components/common/FieldRenderer";
import Service from "Service";
import Constant from "constants/Constant";

/* https://www.npmjs.com/package/react-checkbox-tree */
import CheckboxTree from 'react-checkbox-tree';

import _ from 'lodash';



export class UserRoleAssignmentWidget extends React.Component {

    // state = {
    //     checked: [],
    //     expanded: [],
    // };
    
    constructor(props) {
        super(props);

        this.state = {
            userRoleAssignObj: {}
        };
    }

    componentDidMount = () => {
        
    };

    getMenus = () => {
        this.props.appProps.showLoader();
        Service.getRequest(
            "{apiUrl}/user-menu/relation/" + this.state.userRoleAssignObj.userId,
            this.getMenuResponse,
            this.props.appProps.handleServiceError
        );
    };

    getMenuResponse = response => {
        let data = response.body.Menus;
        let selectedMenuIds = response.body.SelectedMenuIds;
        this.setIcon(data);
        this.setState({
            treeData: data,
            checked: selectedMenuIds,
            expanded: []
        });
        this.props.appProps.hideLoader();
    }

    setIcon = menus => {
        for(let menu of menus) {
            menu.icon=<i className={menu.icon}/>
            if (menu.children) {
                this.setIcon(menu.children);
            }
        }
    };

    save = () => {
        this.props.appProps.showLoader();
        Service.putRequest('{apiUrl}/user-menu/relation',
            this.state.userRoleAssignObj.userId,
            this.state.checked, 
            this.successCallback, 
            this.props.appProps.handleServiceError
        );
    };

    successCallback = () => {
        this.props.appProps.hideLoader();
        this.props.appProps.showNotification(
            "Saved Successfully.",
            Constant.Notification.Type.Success
        );
    }

    cancel = () => {
        this.state.userRoleAssignObj.userId = null;
        this.state.userRoleAssignObj.tenantId = null;
        this.setState({
            treeData: null
        });
    }

    render() {

        return (
            <>
                <div className="menu-role-assignment">
                    <div className="col-md-6">
                        <Row>
                            <Col md={12}>
                            <FieldRenderer
                            panel={this.state.userRoleAssignObj}
                            appProps={this.props.appProps}
                            fieldDefinitions={{
                                fields: [
                                    {
                                        fieldSet: "",
                                        name: "Tenants",
                                        path: "tenantId",
                                        type: "string",
                                        controlType: "select",
                                        default: "",
                                        optionsUrl: "{apiUrl}/tenant",
                                        optionsDisplayField: "name",
                                        optionsValueField: "id",
                                        required: false,
                                        maxLength: null,
                                        minLength: null,
                                        labelWidth: "col-md-2",
                                        controlWidth: "col-md-4",
                                        onChange: [
                                        {
                                            "action": "Reload",
                                            "fieldPath": "userId"
                                        }]
                                    },
                                    {
                                        fieldSet: "",
                                        name: "User",
                                        path: "userId",
                                        type: "string",
                                        controlType: "select",
                                        default: "",
                                        options: [],
                                        optionsUrlBuilder: [
                                            {
                                            property: "string",
                                            expression: "{apiUrl}/user?roleId=8,10&tenantid="
                                            },
                                            {
                                            property: "value",
                                            fieldPath: "tenantId"
                                            }
                                        ],
                                        optionsDisplayField: "firstName:lastName",
                                        optionsValueField: "id",
                                        required: false,
                                        maxLength: null,
                                        minLength: null,
                                        labelWidth: "col-md-2",
                                        controlWidth: "col-md-4"
                                    }
                                    
                                ]
                            }}
                            />
                            </Col>
                        </Row>

                    </div>
                    <div className="col-md-6">
                        <button className="col-md-2 btn btn-primary pull-right" onClick={this.getMenus}>Get Menus</button>
                    </div>
                    <div className="row col-md-6">
                            {
                                this.state.treeData && 
                                    <div className="checkbox-tree">
                                        <div>Menus</div>
                                        <CheckboxTree
                                            nodes={this.state.treeData}
                                            checked={this.state.checked}
                                            expanded={this.state.expanded}
                                            onCheck={checked => this.setState({ checked })}
                                            onExpand={expanded => this.setState({ expanded })}
                                        />
                                    </div>
                                
                            }
                        
                    </div>
                    {
                        this.state.treeData && 
                        <div className="stickyButtons">
                            <button className="ml-3 btn btn-primary" onClick={this.save}>Save</button>
                            <button className="ml-3 btn btn-primary" onClick={this.cancel}>Cancel</button>
                        </div>
                    }
                </div>
            </>        
        );
    };
}

export default UserRoleAssignmentWidget;