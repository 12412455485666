import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from 'perfect-scrollbar';
//import "perfect-scrollbar/dist/css/perfect-scrollbar.min.css";
// react component that creates notifications (like some alerts with messages)

import Sidebar from "components/sidebar/Sidebar.jsx";
import Header from "components/header/Header.jsx";
import Footer from "components/footer/Footer.jsx";
import Dashboard from "views/dashboard/Dashboard.jsx";
import ChangePassword from "views/pages/ChangePassword.jsx";


// dinamically create dashboard routes
import dashRoutes from "routes/dash.jsx";
var psMain;
var components = {
  Dashboard: Dashboard,
  ChangePassword: ChangePassword
};

class Dash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashRoutes: dashRoutes
    };
  }
  componentDidMount() {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      psMain = new PerfectScrollbar(this.refs.mainPanel, {
        wheelSpeed: 2,
        suppressScrollX: true
      });
    }
    //this.props.showNotification("info", "Cool", "tc", 5)
  }


  // function that creates perfect scroll bar for windows users (it creates a scrollbar that looks like the one from apple devices)
  isMac() {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }
  componentDidUpdate(e) {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      setTimeout(() => {
        if (psMain) {
          psMain.update(this.refs.mainPanel);
        };
      }, 350);
    }
    if (e.history.action === "PUSH") {
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  render() {
    //var dashRoutes = JSON.parse(sessionStorage.getItem(StorageId.Session.DashRoutes)).route;
    var dashRoutes = this.state.dashRoutes;
    if (dashRoutes) {
      dashRoutes.forEach(prop => {
        //prop.component = components[prop.component];
      });
    }
    return (
      <div className="wrapper">
        <Sidebar {...this.props} />
        <div id="mainPanel"
          className={
            "main-panel" +
            (this.props.location.pathname === "/maps/full-screen-maps"
              ? " main-panel-maps"
              : "")
          }
          ref="mainPanel"
        >
          <Header {...this.props} />
          <Switch>
            {dashRoutes &&
              dashRoutes.map((prop, key) => {
                if (prop.collapse) {
                  return prop.views.map((prop, key) => {
                    if (prop.redirect){
                      return (
                        <Redirect from={prop.path} to={prop.pathTo} key={key} />
                      );
                    }
                    else if (prop.name === "Notifications") {
                      return (
                        <Route
                          exact
                          path={prop.path}
                          key={key}
                          render={routeProps => (
                            <prop.component
                              {...routeProps}
                            />
                          )}
                        />
                      );
                    } else if (prop.component === "Dashboard") {
                      return (
                        <Route
                          exact
                          path={prop.path}
                          render={routeProps => (
                            <Dashboard
                              layoutJson =  {prop.layoutJson}
                              name = {prop.name}
                              {...routeProps}
                              selectedLocation = {prop.location}
                              selectedHouse = {prop.house}
                              appProps={this.props.appProps}
                        />
                          )}
                          key={key}
                        />
                      );
                    } else {
                      return (

                        <Route
                          exact
                          path={prop.path}
                          component={components[prop.component]}
                          key={key}
                          appProps={this.props.appProps}
                        />
                      );
                    }
                  });
                } else {
                  if (prop.redirect){
                    return (
                      <Redirect from={prop.path} to={prop.pathTo} key={key} />
                    );
                  }
                  else if (prop.component === "Dashboard") {
                      return (
                        <Route
                          exact
                          path={prop.path}
                          render={routeProps => (
                            <Dashboard
                              name = {prop.name}
                              layoutJson =  {prop.layoutJson}
                              {...routeProps}
                              selectedLocation = {prop.location}
                              selectedHouse = {prop.house}
                              appProps={this.props.appProps}
                            />
                          )}
                          key={key}
                        />
                      );
                    }
                    else if (prop.component === "ChangePassword") {
                      return (
                        <Route
                          exact
                          path={prop.path}
                          render={routeProps => (
                            <ChangePassword
                              name = {prop.name}
                              {...routeProps}
                              appProps={this.props.appProps}
                            />
                          )}
                          key={key}
                        />
                      );
                    }
                  else
                  {
                    return (
                      <Route
                        exact
                        path={prop.path}
                        component={components[prop.component]}
                        key={key}
                        appProps={this.props.appProps}
                      />
                    );
                  }
                }
              })}
          </Switch>
          <Footer fluid />
        </div>
      </div>
    );
  }
}

export default Dash;
