var MockData = {
    sample: {
        quarterlySalesData: [
            { "id": 0, "quarter": "Qtr 01", "sales": 422.25, "expenses": 88.09, "profit": 334.16 },
            { "id": 1, "quarter": "Qtr 02", "sales": 520.69, "expenses": 112.37, "profit": 408.32 },
            { "id": 2, "quarter": "Qtr 03", "sales": 979.27, "expenses": 139.44, "profit": 839.83 },
            { "id": 3, "quarter": "Qtr 04", "sales": 1033.69, "expenses": 143.55, "profit": 890.14 }
        ],
        yearlySalesData: [{ "id": 0, "month": "Jan", "sales": 422.25, "expenses": 88.09, "profit": 334.16 },
        { "id": 1, "month": "Feb", "sales": 520.69, "expenses": 112.37, "profit": 408.32 },
        { "id": 2, "month": "Mar", "sales": 979.27, "expenses": 139.44, "profit": 839.83 },
        { "id": 3, "month": "Apr", "sales": 1033.69, "expenses": 143.55, "profit": 890.14 },
        { "id": 4, "month": "May", "sales": 319.09, "expenses": 194.23, "profit": 124.86 },
        { "id": 5, "month": "Jun", "sales": 560.68, "expenses": 212.40, "profit": 348.28 },
        { "id": 6, "month": "Jul", "sales": 637.31, "expenses": 370.12, "profit": 267.18 },
        { "id": 7, "month": "Aug", "sales": 785.12, "expenses": 347.20, "profit": 437.92 },
        { "id": 8, "month": "Sep", "sales": 711.64, "expenses": 96.47, "profit": 615.16 },
        { "id": 9, "month": "Oct", "sales": 790.55, "expenses": 345.24, "profit": 445.30 },
        { "id": 10, "month": "Nov", "sales": 1002.28, "expenses": 200.53, "profit": 801.75 },
        { "id": 11, "month": "Dec", "sales": 1067.15, "expenses": 332.02, "profit": 735.12 }],
        yearlySalesDataArray: [[ 0, "Jan",  422.25,  88.09,  334.16 ],
        [ 1, "Feb",  520.69,  112.37,  408.32 ],
        [ 2, "Mar",  979.27,  139.44,  839.83 ],
        [ 3, "Apr",  1033.69,  143.55,  890.14 ],
        [ 4, "May",  319.09,  194.23,  124.86 ],
        [ 5, "Jun",  560.68,  212.40,  348.28 ],
        [ 6, "Jul",  637.31,  370.12,  267.18 ],
        [ 7, "Aug",  785.12,  347.20,  437.92 ],
        [ 8, "Sep",  711.64,  96.47,  615.16 ],
        [ 9, "Oct",  790.55,  345.24,  445.30 ],
        [ 10, "Nov",  1002.28,  200.53,  801.75 ],
        [ 11, "Dec",  1067.15,  332.02,  735.12 ]],
        temperatureData: [[ 0, "Temp01",  80,  88.09,  334.16 ],
        [ 1, "Temp02",  85,  112.37,  408.32 ],
        [ 2, "Temp03",  85,  139.44,  839.83 ],
        [ 3, "Temp04",  81,  143.55,  890.14 ],
        [ 4, "Temp05",  70,  194.23,  124.86 ],
        [ 5, "Temp06",  75,  212.40,  348.28 ],
        [ 6, "Temp07",  90,  370.12,  267.18 ],
        [ 7, "Temp08",  90,  347.20,  437.92 ],
        [ 8, "Temp09",  85,  96.47,  615.16 ],
        [ 9, "Temp10",  85,  345.24,  445.30 ],
        [ 10, "Temp11",  70,  200.53,  801.75 ],
        [ 11, "Temp12",  85,  332.02,  735.12 ]],
        randomNumbers: {
            "number1": Math.floor(Math.random() * 100), "number2": Math.floor(Math.random() * 100),
            "number3": Math.floor(Math.random() * 100), "number4": Math.floor(Math.random() * 100),
            "html": "<iframe width=\"720\" height=\"405\" src=\"https://www.youtube.com/embed/ss6X39_svVE\" frameborder=\"0\" allow=\"autoplay; encrypted-media\" allowfullscreen></iframe>"
        },
        
    },


    sampleDashboard: {
        "name": "dashboard",
        "panel": [

            {
                "id": "StatusWidget1",
                "type": "Status",
                "position": {
                    "w": 3,
                    "h": 4.5,
                    "x": 0,
                    "y": 0,
                    "i": "Panel2",
                    "moved": false,
                    "static": false
                },
                "datasources": [
                    {
                        "name": "SampleRandomNumbers",
                        "path": "$.SampleRandomNumbers"
                    }
                ],
                "widgets": [
                    {
                        "type": "Status",
                        "icon": "pe-7s-server text-warning",
                        "text": "Capacity",
                        "value": "$.SampleRandomNumbers.number1",
                        "suffix": "GB"
                    }
                ],

                "footer": {
                    "icon": "fa fa-refresh",
                    "text": "Updated now"
                }
            },
            {
                "id": "StatusWidget2",
                "type": "Status",
                "position": {
                    "w": 3,
                    "h": 4.5,
                    "x": 3,
                    "y": 0,
                    "i": "Panel2",
                    "moved": false,
                    "static": false
                },
                "datasources": [
                    {
                        "name": "SampleRandomNumbers",
                        "path": "$.SampleRandomNumbers"
                    }
                ],
                "widgets": [
                    {
                        "type": "Status",
                        "icon": "pe-7s-wallet text-success",
                        "text": "Revenue",
                        "value": "$.SampleRandomNumbers.number2",
                        "prefix": "$"
                    }],
                "footer": {
                    "icon": "fa fa-calendar-o",
                    "text": "Last day"
                }
            },
            {
                "id": "StatusWidget3",
                "type": "Status",
                "position": {
                    "w": 3,
                    "h": 4.5,
                    "x": 6,
                    "y": 0,
                    "i": "Panel2",
                    "moved": false,
                    "static": false
                },
                "datasources": [
                    {
                        "name": "SampleRandomNumbers",
                        "path": "$.SampleRandomNumbers"
                    }
                ],
                "widgets": [
                    {
                        "type": "Status",
                        "icon": "pe-7s-graph1 text-danger",
                        "text": "Errors",
                        "value": "$.SampleRandomNumbers.number3",
                    }],
                "footer": {
                    "icon": "fa fa-clock-o",
                    "text": "In the last hour"
                }
            },
            {
                "id": "StatusWidget4",
                "type": "Status",
                "position": {
                    "w": 3,
                    "h": 4.5,
                    "x": 9,
                    "y": 0,
                    "i": "Panel2",
                    "moved": false,
                    "static": false
                },
                "datasources": [
                    {
                        "name": "SampleRandomNumbers",
                        "path": "$.SampleRandomNumbers"
                    }
                ],
                "widgets": [
                    {
                        "type": "Status",
                        "icon": "fa fa-twitter text-info",
                        "text": "Followers",
                        "value": "$.SampleRandomNumbers.number4",
                        "suffix": "+"
                    }],
                "footer": {
                    "icon": "fa fa-refresh",
                    "text": "Updated now"
                }
            },
            {
                "id": "PieChart",
                "type": "PieChart",
                "title": "Pie Chart",
                "subtitle": "Last Campaign Performance",
                "position": {
                    "w": 4,
                    "h": 14,
                    "x": 0,
                    "y": 1,
                    "i": "Panel",
                    "moved": false,
                    "static": false
                },
                "datasources": [
                    {
                        "name": "SampleQtrSales",
                        "path": "$.SampleQtrSales"
                    }
                ],
                "widgets": [
                    {
                        "type": "PieChart",
                        "path": "$.SampleQtrSales",
                        "width": "100%",
                        "height": 350,
                        "showLegend": false,
                        "showTooltip": true,
                        "rAxis": { "dataKey": "sales", "nameKey": "quarter", "color": "#23ccef", "outerRadius": 125, "showLabel": true },
                        "cellColors": ["#23ccef", "#ffa534", "#fb404b", "#0088FE"]
                    }],

                "footer": {
                    "icon": "fa fa-clock-o",
                    "text": " Campaign sent 2 days ago"
                }
            },
            {
                "id": "DoughnutChart",
                "type": "DoughnutChart",
                "title": "Doughnut Chart",
                "subtitle": "Last Campaign Performance",
                "position": {
                    "w": 4,
                    "h": 14,
                    "x": 4,
                    "y": 1,
                    "i": "Panel",
                    "moved": false,
                    "static": false
                },
                "datasources": [
                    {
                        "name": "SampleQtrSales",
                        "path": "$.SampleQtrSales"
                    }
                ],

                "widgets": [
                    {
                        "type": "DoughnutChart",
                        "path": "$.SampleQtrSales",
                        "width": "100%",
                        "height": 350,
                        "showLegend": true,
                        "showTooltip": true,
                        "rAxis": { "dataKey": "profit", "nameKey": "quarter", "color": "#23ccef", "innerRadius": 80, "outerRadius": 125, "showLabel": true },
                        "cellColors": ["#23ccef", "#ffa534", "#fb404b", "#0088FE"]
                    }],

                "footer": {
                    "icon": "fa fa-clock-o",
                    "text": " Campaign sent 2 days ago"
                }
            },
            {
                "id": "HalfDoughnutChart",
                "type": "HalfDoughnutChart",
                "title": "Half Doughnut Chart",
                "subtitle": "Last Campaign Performance",
                "position": {
                    "w": 4,
                    "h": 11,
                    "x": 8,
                    "y": 1,
                    "i": "Panel",
                    "moved": false,
                    "static": false
                },
                "datasources": [
                    {
                        "name": "SampleQtrSales",
                        "path": "$.SampleQtrSales"
                    }
                ],
                "widgets": [
                    {
                        "type": "HalfDoughnutChart",
                        "path": "$.SampleQtrSales",
                        "width": "100%",
                        "height": 200,
                        "showLegend": true,
                        "showTooltip": true,
                        "rAxis": { "dataKey": "profit", "nameKey": "quarter", "color": "#23ccef", "innerRadius": 80, "outerRadius": 125, "showLabel": true },
                        "cellColors": ["#23ccef", "#ffa534", "#fb404b", "#0088FE"]
                    }],
                "footer": {
                    "icon": "fa fa-clock-o",
                    "text": " Campaign sent 2 days ago"
                }
            },
            {
                "id": "LineChart",
                "type": "LineChart",
                "title": "Line Chart",
                "subtitle": "24 Hours performance",
                "position": {
                    "w": 6,
                    "h": 14,
                    "x": 0,
                    "y": 2,
                    "i": "Panel",
                    "moved": false,
                    "static": false
                },
                "datasources": [
                    {
                        "name": "SampleYearSales",
                        "path": "$.SampleYearSales"
                    }
                ],
                "widgets": [
                    {
                        "type": "LineChart",
                        "path": "$.SampleYearSales",
                        "width": "100%",
                        "height": 350,
                        "margin": { "top": 25, "right": 25, "left": 0, "bottom": 50 },
                        "showLegend": true,
                        "showTooltip": true,
                        "showCartesianGrid": true,
                        "xAxis":
                            { "dataKey": "month", "icon": "fa fa-circle text-info" },
                        "yAxis": [
                            { "dataKey": "sales", "color": "#23ccef" },
                            { "dataKey": "profit", "color": "#ffa534" },
                            { "dataKey": "expenses", "color": "#fb404b" }
                        ]

                    }],

                "footer": {
                    "icon": "fa fa-history",
                    "text": "Updated 3 minutes ago"
                }
            },
            {
                "id": "BarChart",
                "type": "BarChart",
                "title": "Bar Chart",
                "subtitle": "All products including Taxes",
                "position": {
                    "w": 6,
                    "h": 14,
                    "x": 6,
                    "y": 2,
                    "i": "Panel",
                    "moved": false,
                    "static": false
                },
                "datasources": [
                    {
                        "name": "SampleYearSales",
                        "path": "$.SampleYearSales"
                    }
                ],
                "widgets": [
                    {
                        "type": "BarChart",
                        "path": "$.SampleYearSales",
                        "width": "100%",
                        "height": 350,
                        "margin": { "top": 25, "right": 25, "left": 0, "bottom": 50 },
                        "showLegend": true,
                        "showTooltip": true,
                        "showCartesianGrid": true,
                        "xAxis":
                            { "dataKey": "month", "icon": "fa fa-circle text-info" },
                        "yAxis": [
                            { "dataKey": "sales", "color": "#23ccef" },
                            { "dataKey": "profit", "color": "#ffa534" },
                            { "dataKey": "expenses", "color": "#fb404b" }
                        ]

                    }],


                "footer": {
                    "icon": "fa fa-check",
                    "text": " Data information certified"
                }
            },
            {
                "id": "GridWidget",
                "type": "Grid",
                "title": "Grid",
                "subtitle": "Here is a subtitle for this table",
                "position": {
                    "w": 8,
                    "h": 7,
                    "x": 0,
                    "y": 3,
                    "i": "Panel1",
                    "moved": false,
                    "static": false
                },
                "datasources": [
                    {
                        "name": "Reports",
                        "path": "$.Reports.data"
                    }
                ],
                "widgets": [
                    { "type": "Grid", "rows": "$.Reports.data" }
                ],

                "footer": {
                    "text": "",
                    "show": "true"
                }
            },
            {
                "id": "HtmlWidget",
                "type": "Html",
                "position": {
                    "w": 8,
                    "h": 15,
                    "x": 0,
                    "y": 4,
                    "i": "Panel2",
                    "moved": false,
                    "static": false
                },
                "datasources": [
                    {
                        "name": "SampleRandomNumbers",
                        "path": "$.SampleRandomNumbers"
                    }
                ],
                "widgets": [
                    {
                        "html": "$.SampleRandomNumbers.html",
                    }
                ],

                "footer": {
                    "icon": "fa fa-refresh",
                    "text": "Updated now"
                }
            },
            {
                "id": "HouseWidget",
                "type": "House",
                "position": {
                    "w": 3,
                    "h": 4.5,
                    "x": 9,
                    "y": 0,
                    "i": "House",
                    "moved": false,
                    "static": false
                },
                "datasources": [
                    {
                        "name": "SampleRandomNumbers",
                        "path": "$.SampleRandomNumbers"
                    }
                ],
                "widgets": [
                    {
                        "type": "House",
                        "icon": "pe-7s-home text-info",
                        "text": "Followers",
                        "value": "$.SampleRandomNumbers.number4",
                        "suffix": "+"
                    }],
                "footer": {
                    "icon": "fa fa-refresh",
                    "text": "Updated now"
                }
            },
        ],
        "_id": "lNfqWLc9OanG57Jq"
    }
};

export default MockData;