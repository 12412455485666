import React from "react"
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend, LabelList } from "recharts"
import Helper from 'Helper';

class PieChartWidget extends React.Component {
  render() {
    //Avoid Error when widget is NOT configured
    if (this.props.widget.rAxis === undefined)
    {
        return <div className="text-center">Widget configuration is incomplete. Please contact your Administrator</div>
    }

    var data = Helper.getDataFromJsonPathQuery(this.props.data, this.props.panel.datasources[0].path);
    return (
      <ResponsiveContainer width={this.props.widget.width} height={this.props.widget.height}>
        <PieChart>
          <Pie
            data={data}
            dataKey={this.props.widget.rAxis.dataKey}
            nameKey={this.props.widget.rAxis.nameKey}
            outerRadius={this.props.widget.rAxis.radius}
            fill={this.props.widget.rAxis.color}
            label={this.props.widget.rAxis.showLabel}
            cx="50%"
            cy="50%"
            >
            {
              this.props.cellColors && this.props.cellColors.map((item, index) => <Cell key={index} fill={item} />)
            }
            <LabelList dataKey={this.props.widget.rAxis.dataKey} position="top" angle="0"/>
          </Pie>
          {this.props.widget.showTooltip && <Tooltip isAnimationActive={false}/>}
          {this.props.widget.showLegend && <Legend verticalAlign="top" height={36} />}
        </PieChart>
      </ResponsiveContainer>
    );
  }
}

PieChartWidget.defaultProps = {
  cellColors: ["#23ccef", "#ffa534", "#fb404b", "#0088FE", "#fff", "#000"]
}



export default PieChartWidget;