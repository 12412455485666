import React from "react";

class FormEmpty extends React.Component {
  constructor(props) {
    super(props);
  }
  handleInputChange = event => {
    this.props.handleInputChange(event);
  };
  render() {
    return (
      <div className={this.props.controlWidth}>
        {!this.props.removeHeight &&  
        (
          <span>&nbsp;</span> 
        )}
      </div>
    );
  }
}

export default FormEmpty;
