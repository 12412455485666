import React, { Component } from "react";
import Card from "components/card/Card";
import PanelMode from "constants/PanelMode";
import Helper from "Helper";
import {
  Row,
  Col,
  Nav,
  NavItem,
  Tab,
  Tabs
} from "react-bootstrap";
import WidgetPropsDefinition from "variables/WidgetPropsDefinition";
import FrequencyButtons from "../common/FrequencyButtons";

export class SwitchPanel extends Component {
  render() {
    
    let panelStyle = {};
    let panelClass = "switch-panel";
    if (this.props.mode === PanelMode.Normal) {
      panelClass = panelClass + "-" + PanelMode.Normal;
    } else if (this.props.mode === PanelMode.Edit) {
      panelClass = panelClass + "-" + PanelMode.Edit;
    } else if (this.props.mode === PanelMode.View) {
      //TODO
    }

    return (
      <Card
        title={this.props.panel.title}
        category={this.props.panel.subtitle}
        data={this.props.data}
        content={
          <div className={"card-body " + panelClass} style={panelStyle}>
            {this.props.panel.trend && this.props.panel.trend.show === true &&
              <FrequencyButtons
                frequency={this.props.panel.trend.frequency}
                onChangePanelFrequency={this.props.onChangePanelFrequency}
              />
            }

            <Tabs defaultActiveKey="Widget0">
              {this.props.panel.widgets &&
                this.props.panel.widgets.map((item, index) => {
                  let widgetPropsDefinition = WidgetPropsDefinition[item.type];
                  return (
                    <Tab eventKey={"Widget" + index} title={<i className={widgetPropsDefinition.icon + " text-primary"}></i>}
                    >
                      {this.props.renderWidget(
                          this.props.panel,
                          this.props.panel.widgets[index],
                          index
                        )
                      }
                    </Tab>
                  );
                })}
            </Tabs>
          </div>
        }
        footerBar={
          this.props.panel.footer.icon !== "" ||
          this.props.panel.footer.text !== ""
        }
        stats={
          <div>
            {this.props.panel.footer.icon && (
              <i
                className={Helper.getWidgetFieldValue(
                  this.props.data,
                  this.props.panel.footer.icon,
                  false
                )}
              />
            )}{" "}
            {Helper.getWidgetFieldValue(
              this.props.data,
              this.props.panel.footer.text,
              false
            )}
          </div>
        }
      />
    );
  }
}

export default SwitchPanel;
