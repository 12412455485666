import LoginPage from "views/pages/LoginPage.jsx";
import ForgotPassword from "views/pages/ForgotPassword.jsx";
import RegisterPage from "views/pages/RegisterPage.jsx";
import LockScreenPage from "views/pages/LockScreenPage.jsx";
import LogoutPage from "views/pages/LogoutPage.jsx";

var pagesRoutes = [
  {
    showInNavigation: false,
    path: "/Pages/Login",
    name: "Login Page",
    mini: "LP",
    component: LoginPage
  },
  {
    showInNavigation: false,
    path: "/Pages/ForgotPassword",
    name: "Forgot Password",
    mini: "FP",
    component: ForgotPassword
  },
  {
    showInNavigation: false,
    path: "/pages/register-page",
    name: "Register",
    mini: "RP",
    component: RegisterPage
  },
  {
    showInNavigation: false,
    path: "/pages/lock-screen-page",
    name: "Lock Screen Page",
    mini: "LSP",
    component: LockScreenPage
  },
  {
    showInNavigation: false,
    path: "/Pages/Logout",
    name: "Log Out",
    mini: "LOP",
    component: LogoutPage
  }
];

export default pagesRoutes;
