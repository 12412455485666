import React, { Component } from 'react';
import Helper from 'Helper';

export class StatusWidget extends Component {
    // getValue = (value) =>
    // {
    //     if (value
    //             && value.startsWith("$."))
    //     {
    //         console.log(value);
    //         value = JsonPath.query(this.props.data, value).pop();
    //         console.log(JSON.stringify(value));
    //     }
    //     return value;
    // }
    render() {

        //Avoid Error when widget is NOT configured
        if (this.props.widget.icon === undefined)
        {
            return <div className="text-center">Widget configuration is incomplete. Please contact your Administrator</div>
        }

        return <div className="w-90">
                <div className="col-xs-5">
                    <div className="icon-big text-center icon-warning">
                        <i className={Helper.getWidgetFieldValue(this.props.data, this.props.widget.icon, false)}></i>
                    </div>
                </div>
                <div className="col-xs-7">
                    <div className="numbers">
                        <p style={{fontSize:"0.5em"}}>{Helper.getWidgetFieldValue(this.props.data, this.props.widget.text, false)}</p>
                        <span  style={{fontSize:"1em"}}>
                        {Helper.getWidgetFieldValue(this.props.data, this.props.widget.prefix, false)} {Helper.getWidgetFieldValue(this.props.data, this.props.widget.value, false)} {Helper.getWidgetFieldValue(this.props.data, this.props.widget.suffix, false)}
                        </span>
                    </div>
                </div>
            </div>
        
    }
}

export default StatusWidget;
