import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Container,
  Row,
  Col
} from "react-bootstrap";

class LogoutPage extends Component {
  render() {
    sessionStorage.clear();
    localStorage.clear();
    
    return (
      <Container>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <Redirect to="/#/Pages/Login"/>
            <form>
              <div className="user-profile">
                <div className="author">
                  <div className="mr-5 pr-3 pt-3">
                    <i className="fa fa-thumbs-up fa-5x text-success" />
                  </div>
                </div>
                <h4>
                  Thank You for using the Barn 365 Portal. You have been
                  successfully logged out now.
                </h4>
                <Link to="/Pages/Login"  className="btn btn-round btn-info btn-wd text-white">
                  Log In
                </Link>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default LogoutPage;
