import React from "react";
import Sugar from "sugar";
import Service from "Service";
import $ from "jquery";
// Import React Table
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  Comparator,
} from 'react-bootstrap-table2-filter'
import FieldRenderer from 'components/common/FieldRenderer'
import Dialog from 'components/common/Dialog'
import Helper from 'Helper'
import Constant from 'constants/Constant'
import paginationFactory from 'react-bootstrap-table2-paginator'
import cellEditFactory from 'react-bootstrap-table2-editor'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import Button from 'components/controls/CustomButton.jsx'
import { Object } from 'core-js'
import { relative } from 'path'
import { options } from "superagent";
import moment from "moment";

class FlockWidget extends React.Component {
  constructor(props, context) {
    super(props, context)
    Sugar.extend()
    this.state = {
      filterObject: {"weekEndingDate": moment().endOf('week').format("MM/DD/YYYY")},
      gridData: [],
      loadGrid: true,
      showDialog: false,
      action: null,
      data: null,
      sizePerPage: 15,
      page: 1,
      sortField: null,
      sortOrder: null,
      filters: null,
    }
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.match.params.tenantId !== this.props.match.params.tenantId) || 
    (nextProps.match.params.siteId !== this.props.match.params.siteId)) {
      this.getGridData(nextProps.match.params.tenantId, nextProps.match.params.siteId);
    }
  }

  componentDidMount = () => {
    this.getGridData(this.props.match.params.tenantId, this.props.match.params.siteId)
  }

  reloadGridData = () => {
    this.getGridData(undefined, undefined)
  }

  getGridData = (tenantId, siteId) => {
    if (Helper.isNullOrUndefined(this.props.widget.url) === true) {
      return
    }
    this.props.appProps.showLoader()
    let url = this.props.widget.url
    let urlTokens = {
      tenantId:
        tenantId === undefined ? this.props.match.params.tenantId : tenantId,
      siteId: siteId === undefined ? this.props.match.params.siteId : siteId,
      houseId: this.props.match.params.houseId,
    }

    url = Service.replaceUrlWithTokens(url, urlTokens)
    url = url.replace('{pageSize}', this.state.sizePerPage)
    url = url.replace('{pageNumber}', this.state.page)
    url = url.replace('{sortField}', this.state.sortField)
    url = url.replace('{sortOrder}', this.state.sortOrder)
    url = url.replace('{filters}', this.state.filters)
    for (const item in this.state.filterObject) {
      url = url.replace('{' +item+ '}', this.state.filterObject[item]??moment().endOf('week').format("MM/DD/YYYY"));
    }
    url = url.replace('{weekEndingDate}', );
    Service.getRequest(
      url,
      this.getGridDataResponse,
      this.props.appProps.handleServiceError
    )
  }

  getGridDataResponse = (response) => {
    this.setState({
      totalSize: response.body.totalCount,
      gridData: response.body.data,
      showDialog: false,
      isDataLoaded: true,
    })
    setTimeout(() => {
      this.props.appProps.hideLoader()
    }, 1000)
  }

  onExport = () => {
    let data = this.props
    let url = this.props.widget.exporturl
    let fileName = this.props.widget.exportFileName
    let fields = this.props.widget.reportfields
    let tenantId = this.props.match.params.tenantId
    let urlTokens = {
      tenantId: tenantId,
      siteId: this.props.match.params.siteId,
      houseId: this.props.match.params.houseId,
    }

    url = Service.replaceUrlWithTokens(url, urlTokens)
    url = url.replace('{sortField}', this.state.sortField)
    url = url.replace('{sortOrder}', this.state.sortOrder)
    url = url.replace('{filters}', this.state.filters)
    if (url.includes('Status') && url.includes('flockheaderreport')) {
      url = url.replace('Status', 'a.Status')
    }

    this.props.appProps.showLoader()
    Service.exportRequest(
      url,
      fields,
      fileName,
      this.props.appProps,
      this.props.appProps.handleServiceError
    )
  }

  addRow = () => {
    this.setState({
      showDialog: true,
      action: Constant.Action.Add,
      crudObject: {},
    })
  }

  editRow = (cell, row, rowIndex) => {
    this.setState({
      showDialog: true,
      action: Constant.Action.Edit,
      crudObject: Object.clone(row, true),
    })
  }

  formValidation = () => {
    let obj = this.state.crudObject
    let ffd = JSON.parse(this.props.widget.formFieldsDefinitions)

    var isValid = true
    for (var i = 0; i < ffd.fields.length; i++) {
      var field = ffd.fields[i]
      if (
        field.required &&
        (obj[field.path] == null || obj[field.path] == '')
      ) {
        var msg = field.name + " can't be empty."
        this.props.appProps.showNotification(
          msg,
          Constant.Notification.Type.Error
        )
        isValid = false
      }
      if (
        field.required &&
        field.controlType == 'time' &&
        obj[field.path] == '00:00:00'
      ) {
        var msg = field.name + " can't be empty."
        this.props.appProps.showNotification(
          msg,
          Constant.Notification.Type.Error
        )
        isValid = false
      }

      if (
        field.required &&
        field.controlType == 'number' &&
        obj[field.path] == '0'
      ) {
        var msg = field.name + ' should be greater than zero.'
        this.props.appProps.showNotification(
          msg,
          Constant.Notification.Type.Error
        )
        isValid = false
      }
      if (
        !Helper.isNullOrEmpty(obj[field.path]) &&
        field.controlType == 'email'
      ) {
        const re =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        let emails = obj[field.path].split(',')

        for (var j = 0; j < emails.length; j++) {
          if (!re.test(emails[j])) {
            var msg = field.name + ' Invalid.'
            this.props.appProps.showNotification(
              msg,
              Constant.Notification.Type.Error
            )
            isValid = false
            break
          }
        }
      }
    }
    return isValid
  }

  onSave = () => {
    if (!this.formValidation()) return
    this.props.appProps.showLoader()
    let action = this.state.action
    let keyValue = Helper.getWidgetFieldValue(
      this.state.crudObject,
      '$.' + this.props.widget.keyField,
      false
    )

    if (Helper.isNullOrUndefined(keyValue) === true || keyValue === '0') {
      action = Constant.Action.Add
    }

    let data = JSON.stringify(this.state.crudObject, function (key, value) {
      return value === '' ? null : value
    })

    if (action === Constant.Action.Add) {
      Service.postRequest(
        this.props.widget.url,
        this.state.crudObject,
        this.onSaveSuccess,
        this.props.appProps.handleServiceError
      )
    } else {
      Service.putRequest(
        this.props.widget.url,
        keyValue,
        data,
        this.onSaveSuccess,
        this.props.appProps.handleServiceError
      )
    }
  }

  onClose = () => {
    this.setState({ showDialog: false })
  }

  showSuccessMessage = () => {
    if (this.state.action !== null) {
      if (this.state.action === Constant.Action.Delete) {
        this.props.appProps.showNotification(
          'Deleted successfully.',
          Constant.Notification.Type.Success
        )
      } else if (
        this.state.action === Constant.Action.Add ||
        this.state.action === Constant.Action.Edit
      ) {
        this.props.appProps.showNotification(
          'Saved successfully.',
          Constant.Notification.Type.Success
        )
      }
    }
  }

  onSaveSuccess = () => {
    this.showSuccessMessage()
    this.reloadGridData()
  }

  onDeleteSuccess = () => {
    this.showSuccessMessage()
    this.reloadGridData()
    this.props.appProps.hideAlert()
    this.props.appProps.hideLoader()
  }

  confirmDelete = () => {
    this.props.appProps.showLoader()
    let keyValue = Helper.getWidgetFieldValue(
      this.state.crudObject,
      '$.' + this.props.widget.keyField,
      false
    )
    if (Helper.isNullOrUndefined(keyValue) === true || keyValue === '0') {
      this.onDeleteSuccess()
    }
    Service.deleteRequest(
      this.props.widget.url,
      keyValue,
      this.onDeleteSuccess,
      this.props.appProps.handleServiceError
    )
  }

  deleteRow = (row) => {
    this.setState({ action: Constant.Action.Delete, crudObject: row })
    this.props.appProps.showAlert({
      title: 'Are you sure?',
      body: 'You will not be able to undo this action!',
      confirmBtnText: 'Yes, delete it!',
      onConfirm: this.confirmDelete,
    })
  }

  // concatenateColumns = (cell, row, rowIndex, extraData) => {
  concatenateColumns = (row, extraData) => {
    //console.log(JSON.stringify(extraData));
    let value = ''
    extraData.split('+').map((column) => {
      //console.log(column.startsWith("'"));
      if (column.startsWith("'") === true) {
        value = value + column.substring(1, column.length - 1)
      } else {
        value = value + Helper.getDataFromJsonPathQuery(row, '$.' + column)
      }
      return column
    })
    return value
  }

  handleTableChange = (
    type,
    { sortField, sortOrder, filters, page, sizePerPage, data, cellEdit }
  ) => {
    if (type === 'cellEdit') {
      const { rowId, dataField, newValue } = cellEdit
      const result = data.map((row) => {
        if (row.id === rowId) {
          const newRow = { ...row }
          newRow[dataField] = newValue
          newRow['changeType'] = 'U'
          $('.flockButtons').removeClass('hide')
          return newRow
        }
        return row
      })
      this.setState({ gridData: result })
    } else if (type === 'pagination' || type === 'filter' || type === 'sort') {
      //Get the filters
      let fieldFilters = ''
      Object.keys(filters).forEach((filter, index) => {
        if (index !== 0) {
          fieldFilters = fieldFilters + Constant.Comma
        }
        fieldFilters =
          fieldFilters + filter + Constant.Colon + filters[filter].filterVal
      })

      if (Helper.isNullOrEmpty(sortOrder) === true) {
        sortOrder = null
      }
      if (Helper.isNullOrEmpty(fieldFilters) === true) {
        fieldFilters = null
      }
      this.setState({
        sizePerPage: sizePerPage,
        page: page,
        sortField: sortField,
        sortOrder: sortOrder,
        filters: fieldFilters,
      })
      setTimeout(() => {
        this.reloadGridData()
      }, 1)
    }
  }

  saveAllChanges = () => {
    // console.log("Saving Changes");
    let myData = this.state.gridData.filter({ changeType: 'U' })
    // console.log("No OfElements to Update : " + myData.length);
    // console.log(JSON.stringify(myData));
    if (myData.length === 0) {
      this.props.appProps.showNotification('No changes have been made!')
      return
    }
    this.props.appProps.showLoader()

    Service.putRequest(
      this.props.widget.url,
      null,
      myData,
      this.saveAllChangesSuccess,
      this.props.appProps.handleServiceError
    )
  }

  handleButtonClick = (event, field) => {
    this.reloadGridData()
  }

  optionsParentPopulate = (field) => {
    var options = [];
    var endOfWeek = moment().endOf('week');
    for (let index = 0; index < 8; index++) {
      let option = endOfWeek.format("MM/DD/YYYY");
      options.push({"name": option, "value": option})      
      endOfWeek.subtract(7, 'days');
    }
    return options;
  }

  saveAllChangesSuccess = () => {
    this.props.appProps.showNotification(
      'Saved successfully.',
      Constant.Notification.Type.Success
    )
    this.reloadGridData()
  }

  dateFormatter = (cell, row) => {
    if (!cell) {
      return ''
    }
    //return cell;
    cell = Date.create(cell.substring(0, 10)).format('{yyyy}-{MM}-{dd}')
    return Date.create(cell.substring(0, 10)).format('{yyyy}-{MM}-{dd}')
  }

  render() {
    let buildData = []
    let roles = Service.getRoles() + ''
    //Avoid Error when widget is NOT configured
    if (this.props.widget.url === undefined) {
      return (
        <div className="text-center">
          Widget configuration is incomplete. Please contact your Administrator
        </div>
      )
    }

    //var isDataLoaded = false;
    var columnDefinitions = []

    var data = []
    if (
      this.state.gridData &&
      this.state.gridData != null &&
      this.state.gridData.length > 0
    ) {
      data = this.state.gridData
    }
    //Check if Data exists?
    // if (data === undefined || data[0] === undefined) {
    // return null;
    // }

    //Get the COlumns Definitions
    let columns = []
    if (data[0] != undefined) {
      columns = Object.keys(data[0])
    }
    let columnsToDisplay = []
    
    if (data[0] != undefined 
          && Helper.isNullOrEmpty(this.props.widget.columnsToDisplay) == true)
    {
      columnsToDisplay = columns.slice(0, columns.length-1);
    } else {
      columnsToDisplay = this.props.widget.columnsToDisplay.split(',')
    }
    
    let columnNames = columnsToDisplay.clone()
    if (this.props.widget.columnNames && this.props.widget.columnNames !== '') {
      this.props.widget.columnNames.split(',').forEach((item, index) => {
        columnNames[index] = item
      })
    }

    //var columns = Object.keys(data[0]);
    columnsToDisplay.forEach((column, index) => {
          //console.log(columnNames[index])
      if (column.indexOf('+') === -1) {
        columnDefinitions.push({
          text: columnNames[index].toUpperCase(),
          dataField: column,
          sort: true,
          style: { whiteSpace: 'nowrap' },
          filter:
            columnNames[index].toLowerCase().trim() === 'status'
              ? textFilter({
                  placeholder: 'Type to filter',
                  comparator: Comparator.EQ,
                })
              : textFilter({
                  placeholder: 'Type to filter',
                  style: { minWidth: '50px' },
                }),
        })
      } else {
        buildData[buildData.length] = {
          columnName: columnNames[index],
          columnFormatter: column,
        }
        columnDefinitions.push({
          text: columnNames[index].toUpperCase(),
          dataField: columnNames[index],
          // formatter : this.concatenateColumns,
          // formatExtraData : column.split("+"),
          sort: true,
          style: { whiteSpace: 'nowrap' },
          filter:
            columnNames[index].toLowerCase().trim() === 'status'
              ? textFilter({
                  placeholder: 'Type to filter',
                  comparator: Comparator.EQ,
                })
              : textFilter({
                  placeholder: 'Type to filter',
                  style: { minWidth: '50px' },
                }),
        })
      }
    })

    data.forEach((rowData, index) => {
      buildData.forEach((columnData, index) => {
        rowData[columnData.columnName] = this.concatenateColumns(
          rowData,
          columnData.columnFormatter
        )
      })
    })

    if (Helper.isNullOrEmpty(this.props.widget.columnTypes) === false) {
      let typeColumns = this.props.widget.columnTypes.split(',')

      typeColumns.forEach((column) => {
        let columnIndexTypeArray = column.split(':')
        if (columnIndexTypeArray[1] === 'date') {
          //columnDefinitions[columnIndexTypeArray[0]-1].type = columnIndexTypeArray[1];
          data.forEach((rowData, index) => {
            // console.log(rowData)
            rowData[columnDefinitions[columnIndexTypeArray[0] - 1].dataField] =
              this.dateFormatter(
                rowData[
                  columnDefinitions[columnIndexTypeArray[0] - 1].dataField
                ],
                rowData
              )
          })
        } else if (columnIndexTypeArray[1] === 'number') {
          //columnDefinitions[columnIndexTypeArray[0] - 1].type = "number";
          data.forEach((rowData, index) => {
            if (
              isNaN(
                rowData[
                  columnDefinitions[columnIndexTypeArray[0] - 1].dataField
                ]
              )
            ) {
              //rowData[columnDefinitions[columnIndexTypeArray[0] - 1].dataField] = rowData.barnPrevDataForLocal[columnDefinitions[columnIndexTypeArray[0] - 1].dataField];
              this.props.appProps.showNotification(
                'Invalid number set to Number column. Resetting the value to 0.',
                Constant.Notification.Type.Error
              )
              rowData[
                columnDefinitions[columnIndexTypeArray[0] - 1].dataField
              ] = 0
            }
          })
        }
      })
    }

    if (Helper.isNullOrEmpty(this.props.widget.columnNonEditable) === false) {
      let nonEditableColumns = this.props.widget.columnNonEditable.split(',')

      nonEditableColumns.forEach((columnIndex) => {
        columnDefinitions[columnIndex - 1].editable = false
      })
    }

    var paginationOptions = {
      page: this.state.page, // Specify the current page. It's necessary when remote is enabled
      sizePerPage: this.state.sizePerPage, // Specify the size per page. It's necessary when remote is enabled
      totalSize: this.state.totalSize, // Total data size. It's necessary when remote is enabled
      pageStartIndex: 1, // first page will be 0, default is 1
      // paginationSize: 3,  // the pagination bar size, default is 5
      // showTotal: true, // display pagination information
      sizePerPageList: [
        {
          text: '10',
          value: 10,
        },
        {
          text: '25',
          value: 25,
        },
        {
          text: '50',
          value: 50,
        },
        {
          text: '100',
          value: 100,
        },
      ], // A numeric array is also available: [5, 10]. the purpose of above example is custom the text
      // withFirstAndLast: false, // hide the going to first and last page button
      // alwaysShowAllBtns: true, // always show the next and previous page button
      // firstPageText: 'First', // the text of first page button
      // prePageText: 'Prev', // the text of previous page button
      // nextPageText: 'Next', // the text of next page button
      // lastPageText: 'Last', // the text of last page button
      // nextPageTitle: 'Go to next', // the title of next page button
      // prePageTitle: 'Go to previous', // the title of previous page button
      // firstPageTitle: 'Go to first', // the title of first page button
      // lastPageTitle: 'Go to last', // the title of last page button
      hideSizePerPage: true, // hide the size per page dropdown
      // hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
      onPageChange: (page, sizePerPage) => {}, // callback function when page was changing
      onSizePerPageChange: (sizePerPage, page) => {}, // callback function when page size was changing
      paginationTotalRenderer: (from, to, size) => {}, // custom the pagination total
    }
    //isDataLoaded = true;

    let canEdit = false
    if (
      Helper.isNullOrUndefined(this.props.widget.allowEditFor) === false &&
      this.props.widget.allowEditFor.split(Constant.Comma).includes(roles) ===
        true
    ) {
      canEdit = true
    }
    //Allow previous one week edit for Pullet weekly & Layer weekly page on monday
    try{
      if (['Pullet Weekly', 'Layer Weekly'].indexOf(this.props.name) != -1 && !moment(this.state.filterObject.weekEndingDate).isSame(new Date(), 'week')) {
        if (moment().day() == 1) {
          const from = moment(this.state.filterObject.weekEndingDate);
          const now = moment(now);
          const dayDiff = now.diff(from, 'days');
          if (!(dayDiff === 2) ) {
            canEdit = false;
          }
        } else {
          canEdit = false;
        }
      } 
    } catch(e) {}
      
    let canDelete = false
    if (
      Helper.isNullOrUndefined(this.props.widget.allowDeleteFor) === false &&
      this.props.widget.allowDeleteFor.split(Constant.Comma).includes(roles) ===
        true
    ) {
      canDelete = true
    }

    var hideSaveCancel = true
    if (
      columnDefinitions.some(
        (col) => col.editable === true || col.editable === undefined
      ) &&
      canEdit === true
    ) {
      hideSaveCancel = false
    }

    if (
      this.props.widget.keyField !== undefined &&
      this.props.widget.keyField !== '' &&
      (canDelete === true || canEdit === true)
    ) {
      columnDefinitions.append(
        {
          text: 'Actions',
          dataField: 'editColumn',
          sort: false,
          formatExtraData: { canEdit: canEdit, canDelete: canDelete },
          formatter: (cell, row, rowIndex, formatExtraData) => {
            // console.log(JSON.stringify(cell));
            // console.log(JSON.stringify(row));
            // console.log(JSON.stringify(rowIndex));
            // console.log(JSON.stringify(formatExtraData));
            return (
              <div className="d-inline" style={{ whiteSpace: 'nowrap' }}>
                {formatExtraData.canEdit === true && (
                  <a
                    href="javascript:void(0)"
                    onClick={() => this.editRow(cell, row, rowIndex)}
                    className="pr-3"
                  >
                    <i className="fa fa-edit" />
                  </a>
                )}
                {formatExtraData.canDelete === true && (
                  <a
                    href="javascript:void(0)"
                    onClick={() => this.deleteRow(row)}
                    className="text-danger pl-4"
                  >
                    <i className="fa fa-trash" />
                  </a>
                )}
              </div>
            )
          },
        },
        0
      )
    }

    var keyField = this.props.widget.keyField
    if (keyField === undefined || keyField === '') {
      let id = 1
      data.map((val, ix) => {
        val.id = id
        id++
        return val
      })
      keyField = 'id'
    }
    if (
      (keyField === undefined || keyField === '') &&
      columnDefinitions &&
      columnDefinitions.length > 0 &&
      columnDefinitions[0] !== undefined
    ) {
      keyField = columnDefinitions[0].dataField
    }

    let totalSize = this.state.totalSize
    let cellEdit = {}
    if (canEdit === true) {
      cellEdit = { mode: 'click', blurToSave: true }
    }
    return (
      <>
        <div className="row">
          <div className="col-sm-6">
            <div className="row">
              {Helper.isNullOrUndefined(this.props.widget.allowAddFor) ===
                false &&
                this.props.widget.allowAddFor
                  .split(Constant.Comma)
                  .includes(roles) === true && (
                  <div className="col-sm-3 col-md-2 col-lg-1">
                    <a
                      href="javascript:void(0)"
                      onClick={this.addRow}
                      className="stickyButtons add-button"
                    >
                      <i className="fa fa-plus-circle pl-2 py-2" /> Add
                    </a>
                  </div>
                )}

              {Helper.isNullOrUndefined(this.props.widget.exporturl) ===
                false &&
                this.props.widget.exporturl !== '' && (
                  <div className="col-sm-3 col-md-2 col-lg-1">
                    <a
                      href="javascript:void(0)"
                      onClick={this.onExport}
                      className="stickyButtons export-button"
                    >
                      <i className="fa fa-file-excel-o pl-2 py-2" /> Export
                      <i
                        title="Allows only maximum of 5000 records to be exported."
                        className="fa fa-info-circle pl-2 py-2"
                      />
                    </a>
                  </div>
                )}
            </div>
          </div>
          <div className="col-sm-6 flock-widget-export-height-adjustor"></div>
        </div>
        {this.state.action === Constant.Action.Add ||
          this.state.action === Constant.Action.Edit}
        <Dialog
          title={this.state.action + ' ' + this.props.widget.name}
          showModal={this.state.showDialog}
          dialogClassName={this.props.widget.dialogClassName}
          successButtonText="Save"
          closeButtonText="Close"
          onSuccessClick={this.onSave}
          onCloseClick={this.onClose}
        >
          <FieldRenderer
            panel={this.state.crudObject}
            appProps={this.props.appProps}
            data={this.props.data}
            fieldDefinitions={
              this.props.widget.formFieldsDefinitions &&
              Helper.ParseJson(this.props.widget.formFieldsDefinitions)
            }
          />
        </Dialog>
        
        {!Helper.isNullOrEmpty(this.props.widget.filterFieldsDefinitions) && 
        <div>
          <FieldRenderer
            panel={this.state.filterObject}
            appProps={this.props.appProps}
            data={this.props.data}
            fieldDefinitions={
              this.props.widget.filterFieldsDefinitions &&
              Helper.ParseJson(this.props.widget.filterFieldsDefinitions)
            }
            handleButtonClick = {this.handleButtonClick}
            optionsParentPopulate = {this.optionsParentPopulate}
          />        
        </div>
        }
        <div className="px-2">
          <BootstrapTable
            bootstrap4={true}
            keyField={keyField}
            data={data}
            columns={columnDefinitions}
            bordered={true}
            filter={filterFactory()}
            noDataIndication={this.state.isDataLoaded ? 'No Data' : ''}
            striped
            hover
            condensed
            cellEdit={cellEditFactory(cellEdit)}
            remote ={!this.props.widget.isClientSidePaging} //=  {{ cellEdit: true }}
            headerSortingStyle
            pagination={paginationFactory(paginationOptions)}
            //pagination={paginationFactory()}
            //hideSizePerPage = {false}
            onTableChange={this.handleTableChange}
          />
          <div className="stickyButtons" hidden={false}>
            <Button
              fill
              onClick={this.saveAllChanges}
              className="btn-fill flockButtons"
              hidden={hideSaveCancel}
            >
              Save
            </Button>
            <Button
              fill
              onClick={this.reloadGridData}
              className="ml-3 btn-fill flockButtons"
              hidden={hideSaveCancel}
            >
              Cancel{' '}
            </Button>
            <a href="javascript:location.reload()" className="text-info pl-3">
              Reset Filter
            </a>
          </div>
        </div>

        <br />
        <br />
        <br />
      </>
    )
  }
}

export default FlockWidget;
