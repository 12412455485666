import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch } from "react-router-dom";

//Styles Imports
import "bootstrap/dist/css/bootstrap.css";
import "./assets/sass/paper-dashboard.scss";
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/sass/images.scss";
import "./assets/sass/app.scss";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "./assets/css/table.css"

//Components
import registerServiceWorker from "./registerServiceWorker";
import indexRoutes from "./routes/index.jsx";
import 'core-js';

ReactDOM.render(
  <HashRouter>
    <Switch>
      {indexRoutes.map((prop, key) => {
        return <Route path={prop.path} component={prop.component} key={key} />;
      })}
    </Switch>
  </HashRouter>
  ,
  document.getElementById("root")
);
//registerServiceWorker();
