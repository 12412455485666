import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col
} from "react-bootstrap";

import Card from "components/card/Card.jsx";

import Button from "components/controls/CustomButton.jsx";
import Service from "Service";
import Constant from "../../constants/Constant";
import FieldRenderer from "components/common/FieldRenderer";
import Helper from '../../Helper';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      data: {
        eMail: null
      }
    };
  }
  componentDidMount() {
    setTimeout(
      function() {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
    // setTimeout(() => {
    //   this.props.appProps.showNotification(
    //     <div className="text-center">
    //       Welcome to <b>barn365</b>
    //     </div>,
    //     Constant.Notification.Type.Dynamic
    //   );
    // }, 2000);
  }

  handleInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleClick = values => {
    values.preventDefault();
    if (Helper.isNullOrEmpty(this.state.data.eMail) === true){
        this.props.appProps.showNotification(
            "User Name can't be empty.",
            Constant.Notification.Type.Error
          );
         return;   
    }

    this.props.appProps.showLoader();
    //call the servicel
    Service.putRequest(
      "{apiUrl}/password?service=forgot", null,
      this.state.data,
      res => {
        this.props.appProps.showNotification(
            "A temporary password was sent to your registered email address. Please check your email & then login.",
            Constant.Notification.Type.Success
          );
          this.props.appProps.hideLoader();
          window.location.href = "/#/Pages/Login";
      },
      this.props.appProps.handleServiceError
    );
  };

  render() {
    return (
        <Container>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}></Col>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form onSubmit={this.handleClick}>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                content={
                  <div className="p-4">
                    <FieldRenderer
                      panel={this.state.data}
                      fieldDefinitions={{
                        fields: [
                          {
                            fieldSet: "",
                            name: "User Name",
                            path: "eMail",
                            type: "string",
                            controlType: "text",
                            default: null,
                            options: [],
                            url: "",
                            required: false,
                            maxLength: null,
                            minLength: null
                          }
                        ]
                      }}
                    />

                    <div>
                      <Button
                        type="submit"
                        onClick={this.handleClick}
                        variant="primary"
                        fill
                      >
                        Email Password
                      </Button>
                      <Link to="/Pages/Login"  className="pl-4 text-primary">
                        Log In
                      </Link>

                    </div>
                  </div>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ForgotPassword;
