import React, { Component } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import { ErrorBoundary } from "containers/app/ErrorBoundary.jsx";
// dinamically create app routes
import appRoutes from "routes/app.jsx";
import Pages from "containers/pages/Pages.jsx";
import NotificationSystem from "react-notification-system";
// style for notifications
import { style } from "variables/Variables.jsx";
import Constant from "../../constants/Constant";
import SweetAlert from "react-bootstrap-sweetalert";
import Helper from "Helper";
import Service from "Service";
import Role from "constants/Role";
import $ from "jquery";
import PrintProvider, { NoPrint } from "react-easy-print";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: null,
      notificationSystem: null,
      adjustMainContent: false,
      dateTime: 0
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  unstable_handleError() {
    this.hideLoader();
  }

  componentDidMount() {
    this.setState({ notificationSystem: this.refs.notificationSystem });
  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.action === "PUSH" &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }

  log(message, logType) {
    if (Service.isInRole(Role.SuperAdmin) === false || Service.isInRole(Role.SuperUser) === false) {
      return;
    }
    switch (logType) {
      case Constant.LogType.Error:
        console.error(message);
        break;
      case Constant.LogType.Warning:
        console.warn(message);
        break;
      case Constant.LogType.Information:
        console.info(message);
        break;
    }
  }

  handleServiceError = (err, showHttpErrData = false) => {
    let isDataError = false;
    this.hideLoader();
    let message;
    this.log("Oops. See below for error details......", Constant.LogType.Error);
    if (
      err &&
      err.description &&
      err.description ===
      "Unable to get property 'apply' of undefined or null reference"
    ) {
      //Ignore this error for IE. Unable to find which causes it
      return;
    }
    this.log(JSON.stringify(err), Constant.LogType.Error);
    if (Helper.isNullOrEmpty(err) === false) {
      //Check for Service Call Errors
      if (err.response && err.response.body) {

        message = err.response.body.message;
        if (showHttpErrData) {
          message += "\n" + JSON.stringify(err.response.body.data);
        }

        if (err.response.body.message === "jwt expired") {
          message = "Your Session got expired. Please login to continue.";
          this.showNotification(
            message,
            Constant.Notification.Type.Error,
            Constant.Notification.Position.TopCenter,
            Constant.Notification.AutoDismiss.Default
          );
          window.location.href = "/#/Pages/Logout";
          return;
        }

        if (err.response.body.code === 404 || err.response.body.code === 500) {
          isDataError = true;
        }

      } else {
        if (err && err.message) {
          if (err.response && err.response.data && err.response.data.message) {
            message = err.response.data.message;
          } else {
            message = err.message;
          } 
        } else {
          message = err;
        }
      }
    } else {
      message = "Unknown error occured.";
    }
    this.log(JSON.stringify(message), Constant.LogType.Error);
    this.showNotification(
      (isDataError) ? "Data error. Please contact Barn365 administrator." : message,
      Constant.Notification.Type.Error,
      Constant.Notification.Position.TopCenter,
      Constant.Notification.AutoDismiss.Default
    );
  };

  showNotification = (
    message,
    type = Constant.Notification.Type.Default,
    position = Constant.Notification.Position.Default,
    autoDismissDelay = Constant.Notification.AutoDismiss.Default
  ) => {
    //Choose type randomly
    if (type === Constant.Notification.Type.Dynamic) {
      var color = Math.floor(Math.random() * 4 + 1);
      switch (color) {
        case 1:
          type = Constant.Notification.Type.Success;
          break;
        case 2:
          type = Constant.Notification.Type.Warning;
          break;
        case 3:
          type = Constant.Notification.Type.Error;
          break;
        case 4:
          type = Constant.Notification.Type.Information;
          break;
        default:
          throw new Error(`${color} is not valid`);
      }
    }

    var d = new Date();
    if ((this.state.dateTime + (autoDismissDelay * 1000)) < d.getTime()) {
      this.state.dateTime = d.getTime();
      //Set the Notification
      this.state.notificationSystem.addNotification({
        //title: <span data-notify="icon" className="pe-7s-gift" />,
        title: "",
        message: <div>{message}</div>,
        level: type,
        position: position,
        autoDismiss: autoDismissDelay
      });
    }
  };

  showLoader = loaderText => {
    if (loaderText === undefined || loaderText === null) {
      // loaderText = "Please wait....";
    }
    this.setState({ loader: true, loaderText: loaderText });
  };

  showAlert = alert => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          title={alert.title}
          onConfirm={alert.onConfirm}
          onCancel={alert.onCancel ? alert.onCancel : this.hideAlert}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText={alert.confirmBtnText}
          cancelBtnText="Cancel"
          showCancel
        >
          {alert.body}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  hideLoader = () => {
    this.setState({ loader: false, loaderText: null });
    setTimeout(this.setAutoHeight, 300);
  };

  setAdjustMainContent = flag => {
    this.setState({ adjustMainContent: flag });
  };

  getAdjustMainContent = () => {
    return this.state.adjustMainContent;
  };

  setAutoHeight = () => {
    //Find all panels with height Zero
    $(".react-grid-item").each(function () {
      if ($(this).attr("data-grid-height") === "auto") {
        //Set the height
        $(this).height($(this).prop("scrollHeight"));
      }
    });
  };


  setGlobalState = (key, value) => {
    let newState = {};
    newState[key] = value;
    this.setState(newState);
  };

  getGlobalState = key => {
    return Helper.getDataFromJsonPathQuery(this.state, "$." + key);
  };

  getAppProps = () => {
    return {
      showNotification: this.showNotification,
      handleServiceError: this.handleServiceError,
      showLoader: this.showLoader,
      hideLoader: this.hideLoader,
      showAlert: this.showAlert,
      hideAlert: this.hideAlert,
      setAdjustMainContent: this.setAdjustMainContent,
      getAdjustMainContent: this.getAdjustMainContent,
      setGlobalState: this.setGlobalState,
      getGlobalState: this.getGlobalState,
      setAutoHeight: this.setAutoHeight
    };
  };

  PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        sessionStorage.getItem("token") === null ? (
          <Redirect to="/Pages/Login" component={Pages} />
        ) : (
            <Component {...props} appProps={this.getAppProps()} />
          )
      }
    />
  );

  render() {
    try {
      let appProps = this.getAppProps();
      return (
        <PrintProvider>
          <NoPrint>
            <ErrorBoundary appProps={appProps}>

              {this.state.alert}
              {this.state.loader && (
                <div id="mainLoader" className="full-page1 text-center">
                  <div className="largeLoader" />
                  <div className="text-info mainLoaderText">
                    {this.state.loaderText}
                  </div>
                </div>
              )}

              <NotificationSystem ref="notificationSystem" style={style} />
              <Switch>
                {appRoutes.map((prop, key) => {
                  if (prop.name === "Home") {
                    //console.log("In Home")
                    return (
                      <this.PrivateRoute
                        path={prop.path}
                        component={prop.component}
                        key={key}
                        appProps={appProps}
                      />
                    );
                  } else {
                    //console.log("In Route")
                    return (
                      <Route
                        path={prop.path}
                        key={key}
                        appProps={appProps}
                        render={routeProps => (
                          <prop.component
                            {...routeProps}
                            appProps={appProps}
                          />
                        )}
                      />
                    );
                  }
                })}
              </Switch>

            </ErrorBoundary>
          </NoPrint>
        </PrintProvider>
      );
    } catch (err) {
      this.handleServiceError(err);
    }
  }
}

export default App;
