import React from "react"
import Helper from 'Helper';

import { Container, Col, Row } from "react-bootstrap";

class IFrameWidget extends React.Component {
  createMarkup = () => {
    //var html = JsonPath.query(this.props.data, this.props.widget.html).pop();
    return { __html: "" };
  }
  render() {
    //var url = Helper.getDataFromJsonPathQueryNoPop(this.props.data, this.props.widget.url);
    console.log(this.props.widget.url)
    return (          
        <Container fluid className="h-100">
            <Row className="h-100" id="powerBIFrame">
              <Col md={12} className="h-100">
                <div className="h-100">
                    <div className="row">
                      <div className="col-md-12">
                        <iframe src={this.props.widget.url} style={{border:"0px", width:"100%", height:"70vh"}}></iframe>
                      </div>
                    </div>
                </div>
              </Col>
            </Row>
        </Container>
    );
  }
}

export default IFrameWidget;
