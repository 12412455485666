import React, { Component } from "react";
import Helper from "Helper";
import Sugar from "sugar";
import {
  OverlayTrigger,
  Tooltip,
  ButtonGroup,
  Pagination,
  Grid,
  Row,
  Col
} from "react-bootstrap";
import Service from "Service";
import Constant from "constants/Constant";
import $ from "jquery";

export class ExecutiveWidget extends Component {
  constructor(props) {
    super(props);
    Sugar.extend();
  }

  componentDidMount = () => {};
  componentWillUpdate(nextProps, nextState) {
    //Reset the toggles
    $(".tenant").hide();
    $(".site").hide();
  }

  toggleHouse = site => {
    $("." + site).slideToggle(500);
  };
  toggleSites = () => {
    $(".tenant").slideToggle(500);
  };
  render() {
    let data = Helper.getWidgetFieldValue(
      this.props.data,
      this.props.panel.datasources[0].path,
      true
    );
    let tenantName = "";
    let tenant = this.props.appProps.getGlobalState(
      Constant.GlobalState.Tenant
    );
    if (tenant != undefined) {
      tenantName = tenant.name;
    }

      var sites = undefined;
      var siteData = undefined;
      var tenantData = undefined;
      var actualHouseData = undefined;
      if (data != null && data != undefined && data.Tenant != undefined) {
        tenantData = data.Tenant[0];
        siteData = data.Site;
        actualHouseData = data.House;
        //console.log(JSON.stringify(tenantData));
        sites = Object.keys(data);
      }
      return (
        <div className="flex-grid text-center">
          {tenantData && (
            <table className="table table-striped">
              <thead>
                <tr>
                  {Object.keys(tenantData).map((keyName, index) => {
                    return <th key={index}>{keyName}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {Object.values(tenantData).map((value, index) => {
                    if (index === 0) {
                      return (
                        <td
                          key={index}
                          className="text-nowrap text-left pointerCursor"
                          onClick={() => {
                            this.toggleSites();
                          }}
                        >
                          <i className="text-info icon-custom icon-home-tenant" />
                          {"  "}
                          {value}
                        </td>
                      );
                    } else {
                      return (
                        <td key={index} className="text-left">
                          {value}
                        </td>
                      );
                    }
                  })}
                </tr>
                <tr className={"tenant hideForToggle"}>
                  <td colSpan={Object.keys(tenantData).length} className="px-5">
                    {siteData && (
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            {Object.keys(siteData[0]).map((keyName, index) => {
                              return <th key={index}>{keyName}</th>;
                            })}
                          </tr>
                        </thead>

                        {siteData.map((site, index) => {
                          let houseData = actualHouseData.filter({
                            Site: site.Site
                          });
                          return (
                            <tbody key={index}>
                              <tr>
                                {Object.values(site).map(
                                  (value, siteValueindex) => {
                                    if (siteValueindex === 0) {
                                      return (
                                        <td
                                          key={siteValueindex}
                                          className="text-nowrap text-left pointerCursor"
                                          onClick={() => {
                                            this.toggleHouse("site" + index);
                                          }}
                                        >
                                          <i className="fa fa-map-marker text-danger" />{" "}
                                          {value}
                                        </td>
                                      );
                                    } else {
                                      return (
                                        <td
                                          key={siteValueindex}
                                          className="text-left"
                                        >
                                          {value}
                                        </td>
                                      );
                                    }
                                  }
                                )}
                              </tr>
                              <tr
                                className={
                                  "site site" + index + " hideForToggle"
                                }
                              >
                                <td
                                  colSpan={Object.keys(siteData[0]).length}
                                  className="px-5"
                                >
                                  <table className="table table-striped">
                                    <thead>
                                      <tr>
                                        {Object.keys(houseData[0]).map(
                                          (keyName, index) => {
                                            if (index > 0) {
                                              return (
                                                <th key={index}>{keyName}</th>
                                              );
                                            }
                                          }
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {houseData.map((house, index) => {
                                        return (
                                          <tr>
                                            {Object.values(house).map(
                                              (value, index) => {
                                                if (index === 0) {
                                                } else if (index === 1) {
                                                  return (
                                                    <td
                                                      key={index}
                                                      className="text-nowrap text-left pointerCursor"
                                                    >
                                                      <i className="fa fa-home" />{" "}
                                                      {value}
                                                    </td>
                                                  );
                                                } else {
                                                  return (
                                                    <td
                                                      key={index}
                                                      className="text-left"
                                                    >
                                                      {value}
                                                    </td>
                                                  );
                                                }
                                              }
                                            )}
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          );
                        })}
                      </table>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      );
    
  }
}

export default ExecutiveWidget;
