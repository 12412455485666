import React from "react"
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import Helper from 'Helper';

class SparklineChartWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = { tempData: [] };
        setInterval(() => {
            let value = Helper.getDataFromJsonPathQuery(this.props.data, this.props.panel.datasources[0].path);
            if (isNaN(value) === false) {
                this.setState({
                    tempData: this.state.tempData.concat(parseInt(value)).last(20)
                });
            }
        }, 1000);

    }

    render() {
        //Avoid Error when widget is NOT configured
        if (this.props.widget.text === undefined)
        {
            return <div className="text-center">Widget configuration is incomplete. Please contact your Administrator</div>
        }

        return (<div>
            <div className="row">
                <div className="col-xs-8">
                    <Sparklines data={this.state.tempData} limit={20}>
                        <SparklinesLine color="#1c8cdc" />
                        <SparklinesSpots />
                    </Sparklines>
                </div>
                <div className="col-xs-4">
                    <div className="numbers">
                        <p>{Helper.getWidgetFieldValue(this.props.data, this.props.widget.text, false)}</p>
                        {Helper.getWidgetFieldValue(this.props.data, this.props.widget.prefix, false)} {this.state.tempData[this.state.tempData.length - 1]} {Helper.getWidgetFieldValue(this.props.data, this.props.widget.suffix, false)}
                    </div>
                </div>
            </div>
        </div>

        );
    }
}

export default SparklineChartWidget