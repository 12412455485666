import React from "react";
import {Button, Row, Col, Tabs, Tab,  ToggleButtonGroup, ToggleButton} from "react-bootstrap";

import FieldRenderer from "components/common/FieldRenderer";
import Helper from "Helper";
import Service from "Service";
import Constant from "../../constants/Constant";
import {ResponsiveContainer, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line } from "recharts";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import AIWidgetGrids from "components/widgets/AIWidgetGrids";

class AIWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            aiObject: {
                status: "All",
                group: "breed",
                tenantName: JSON.parse(sessionStorage.getItem("tenants")).find( e => e.id == props.match.params.tenantId).name
            }
        };
    };

    componentDidMount = () => {
    };
    
    shouldComponentUpdate(nextProps, nextState){
        var tenantName = JSON.parse(sessionStorage.getItem("tenants")).find( e => e.id == nextProps.match.params.tenantId).name;
        if (nextState.aiObject.tenantName != tenantName) {
            nextState.aiObject.tenantName = tenantName;
            return true;
        }
        //Arrested the re-rendering in the parent.
        return false;
     }

    flockCallback = (field) => {
        document.getElementsByClassName("frequency-btn")[0].style.visibility = (this.state.aiObject.flock == 'All') ? "hidden" : "";
    }

    render() {
        
        return(
            <>
            <div className="ai">
                <Row>
                    <Col md={11}>
                    <FieldRenderer
                      panel={this.state.aiObject}
                      appProps={this.props.appProps}
                      fieldDefinitions={{
                        fields: [
                            {
                                fieldSet: "",
                                name: "Status",
                                path: "status",
                                type: "string",
                                controlType: "select",
                                default: "All",
                                options: [
                                    {
                                        "status": "All",
                                        "name": "All"
                                    },
                                    {
                                        "status": "Active",
                                        "name": "Active"
                                    },
                                    {
                                        "status": "Inactive",
                                        "name": "InActive"
                                    }
                                ],    
                                optionsDisplayField: "name",
                                optionsValueField: "status",
                                required: false,
                                maxLength: null,
                                minLength: null,
                                labelWidth: "col-md-1",
                                controlWidth: "col-md-2",
                                required: true,
                                onChange: [
                                    {
                                        "action": "Reload",
                                        "fieldPath": "breed"
                                    }
                                    // {
                                    //   "action": "Reload",
                                    //   "fieldPath": "site"
                                    // },
                                    // {
                                    //     "action": "Reload",
                                    //     "fieldPath": "house"
                                    // },
                                    // {
                                    //     "action": "Reload",
                                    //     "fieldPath": "flock"
                                    // }
                                ]
                            },
                            {
                                fieldSet: "",
                                name: "Breed",
                                path: "breed",
                                type: "string",
                                controlType: "select",
                                //default: "All",
                                options: this.state.breedData,
                                optionsUrlBuilder : [
                                    {
                                        "property": "string",
                                        "expression": "{apiUrl}/ai/breeds?tName="+this.state.aiObject.tenantName+"&active="
                                    },
                                    {
                                        "property": "value",
                                        "fieldPath": "status"
                                    }],
                                optionsDisplayField: "breed",
                                optionsValueField: "breed",
                                required: true,
                                maxLength: null,
                                minLength: null,
                                labelWidth: "col-md-1",
                                controlWidth: "col-md-2",
                                required: true,
                                onChange: [
                                    {
                                      "action": "Reload",
                                      "fieldPath": "site"
                                    },
                                    {
                                        "action": "Reload",
                                        "fieldPath": "house"
                                    },
                                    {
                                        "action": "Reload",
                                        "fieldPath": "flock"
                                      }]
                            },
                            {
                                fieldSet: "",
                                name: "Site",
                                path: "site",
                                type: "string",
                                controlType: "select",
                                default: "All",
                                options: this.state.siteData,
                                optionsUrlBuilder : [
                                {
                                    "property": "string",
                                    "expression": "{apiUrl}/ai/sites?tName="+this.state.aiObject.tenantName+"&active="
                                },
                                {
                                    "property": "value",
                                    "fieldPath": "status"
                                },
                                {
                                    "property": "string",
                                    "expression": "&breeds="
                                },
                                {
                                    "property": "value",
                                    "fieldPath": "breed"
                                }],
                                optionsDisplayField: "site",
                                optionsValueField: "site",
                                required: false,
                                maxLength: null,
                                minLength: null,
                                labelWidth: "col-md-1",
                                controlWidth: "col-md-2",
                                required: true,
                                onChange: [
                                    {
                                      "action": "Reload",
                                      "fieldPath": "house"
                                    },
                                    {
                                        "action": "Reload",
                                        "fieldPath": "flock"
                                    }]
                            },
                            {
                                fieldSet: "",
                                name: "House",
                                path: "house",
                                type: "string",
                                controlType: "select",
                                default: "All",
                                options: this.state.houseData,
                                optionsUrlBuilder : [
                                    {
                                        "property": "string",
                                        "expression": "{apiUrl}/ai/houses?tName="+this.state.aiObject.tenantName+"&active="
                                    },
                                    {
                                        "property": "value",
                                        "fieldPath": "status"
                                    },
                                    {
                                        "property": "string",
                                        "expression": "&breeds="
                                    },
                                    {
                                        "property": "value",
                                        "fieldPath": "breed"
                                    },
                                    {
                                        "property": "string",
                                        "expression": "&sites="
                                    },
                                    {
                                        "property": "value",
                                        "fieldPath": "site"
                                    }],
                                optionsDisplayField: "house",
                                optionsValueField: "house",
                                required: false,
                                maxLength: null,
                                minLength: null,
                                labelWidth: "col-md-1",
                                controlWidth: "col-md-2",
                                required: true,
                                onChange: [
                                    {
                                      "action": "Reload",
                                      "fieldPath": "flock"
                                    }]
                            },
                            {
                                fieldSet: "",
                                name: "Flock",
                                path: "flock",
                                type: "string",
                                controlType: "select",
                                default: "All",
                                options: this.state.flockData,
                                optionsUrlBuilder : [
                                    {
                                        "property": "string",
                                        "expression": "{apiUrl}/ai/flocks?tName="+this.state.aiObject.tenantName+"&active="
                                    },
                                    {
                                        "property": "value",
                                        "fieldPath": "status"
                                    },
                                    {
                                        "property": "string",
                                        "expression": "&breeds="
                                    },
                                    {
                                        "property": "value",
                                        "fieldPath": "breed"
                                    },
                                    {
                                        "property": "string",
                                        "expression": "&sites="
                                    },
                                    {
                                        "property": "value",
                                        "fieldPath": "site"
                                    },
                                    {
                                        "property": "string",
                                        "expression": "&houses="
                                    },
                                    {
                                        "property": "value",
                                        "fieldPath": "house"
                                    }],
                                optionsDisplayField: "flocknumber",
                                optionsValueField: "flocknumber",
                                required: false,
                                maxLength: null,
                                minLength: null,
                                labelWidth: "col-md-1",
                                controlWidth: "col-md-2",
                                required: true,
                                callbackMethod: this.flockCallback,
                                onChange: [
                                {
                                    "action": "Callback"
                                }]
                                
                            }
                        ]
                      }}
                    />
                    </Col>
                    <Col md={1}>
                    <Button variant="Button"
                        className = "mt-md-5 pull-right"
                        onClick={() => this.getAI()}>
                         Go
                    </Button>
                    </Col>
                </Row>
                <AIWidgetGrids appProps={this.props.appProps} data={this.state} setAIData={click => this.getAI = click}></AIWidgetGrids>
            </div>
            </>
        )
    };
}
export default AIWidget