import React from "react";
import { DropdownButton, Dropdown, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import Sugar from "sugar";

import Helper from "Helper";

//Panels
import PanelMode from "constants/PanelMode";
import PanelEdit from "components/panels/PanelEdit";
import DefaultPanel from "components/panels/DefaultPanel";
import SwitchPanel from "components/panels/SwitchPanel";
import FilterPanel from "components/panels/FilterPanel";
import EmptyWidget from "components/widgets/EmptyWidget";
import WidgetPropsDefinition from "variables/WidgetPropsDefinition";
import PanelPropsDefinition from "variables/PanelPropsDefinition";
import Role from "constants/Role";
import Service from "Service";

class Panel extends React.Component {
  constructor(props) {
    super(props);
    Sugar.extend();
    //this.props.panel.newDatasource = { name: "", path: "" };
    this.state = {
      panel: this.props.panel,
      options: []
    };
  }

  onPanelView = () => {
    this.props.onPanelView(this.props.panel);
  };

  onPanelEdit = () => {
    this.props.onPanelEdit(this.props.panel);
  };

  onPanelSave = () => {
    this.props.onPanelSave(this.props.panel);
  };

  onPanelDelete = () => {
    this.props.onPanelDelete(this.props.panel);
  };

  onPanelGoBack = () => {
    this.props.onPanelGoBack(this.props.panel);
  };

  onAddPanelDataSource = () => {
    this.props.onAddPanelDataSource(
      this.props.panel,
      this.props.panel.newDatasource
    );
  };

  onChangePanelFrequency = frequency => {
    this.props.onChangePanelFrequency(this.props.panel, frequency);
  };

  onTitleMouseOver = event => {
    event.target.click();
  };

  renderPanel = () => {
    let panel = this.props.panel;
    switch (panel.type) {
      case "Switch": {
        return (
          <SwitchPanel
            key={panel.id}
            {...this.props}
            onPanelEdit={this.onPanelEdit}
            panel={panel}
            activePanel={this.props.activePanel}
            data={this.props.data}
            datasources={this.props.datasources}
            mode={this.props.mode}
            onPanelSave={this.onPanelSave}
            onLoadDatasourceData={this.props.onLoadDatasourceData}
            renderWidget={this.renderWidget}
            onChangePanelFrequency={this.onChangePanelFrequency}
          />
        );
      }
      case "Filter": {
        return (
          <FilterPanel
            key={panel.id}
            {...this.props}
            onPanelEdit={this.onPanelEdit}
            panel={panel}
            activePanel={this.props.activePanel}
            data={this.props.data}
            datasources={this.props.datasources}
            mode={this.props.mode}
            onPanelSave={this.onPanelSave}
            onLoadDatasourceData={this.props.onLoadDatasourceData}
            renderWidget={this.renderWidget}
            onChangePanelFrequency={this.onChangePanelFrequency}
          />
        );
      }
      default: {
        return (
          <DefaultPanel
            key={panel.id}
            {...this.props}
            onPanelEdit={this.onPanelEdit}
            panel={panel}
            activePanel={this.props.activePanel}
            data={this.props.data}
            datasources={this.props.datasources}
            mode={this.props.mode}
            onPanelSave={this.onPanelSave}
            onLoadDatasourceData={this.props.onLoadDatasourceData}
            renderWidget={this.renderWidget}
            onChangePanelFrequency={this.onChangePanelFrequency}
          />
        );
      }      
      //TODO: Fix this
      // default:
      //    console.error(panel.type + " is not defined.");
    }
  };

  renderWidget = (panel, widget, index) => {
    let key = panel.id + "_" + index;
    if (
      panel.needsConfiguration !== undefined &&
      panel.needsConfiguration === true
    ) {
      return (
        <EmptyWidget
          key={key}
          widget={panel.widgets[0]}
          panel={panel}
          onPanelEdit={this.onPanelEdit}
        />
      );
    }

    let CustomWidgetComponent = WidgetPropsDefinition[widget.type].component;
    return (
      <CustomWidgetComponent
        {...this.props}
        key={key}
        data={this.props.data}
        panel={panel}
        widget={widget}
        showLoader={this.props.showLoader}
        hideLoader={this.props.hideLoader}
      />
    );
  };

  render() {
    let className = "panel" + this.props.mode + "Mode";
    let draggableClass = "";
    let permissions = { canEdit: false, canDelete: false, canView: false };
    if (Service.isInRole(Role.SuperAdmin) === true) {
      permissions.canView = true;
      permissions.canDelete = true;
      permissions.canEdit = true;
      draggableClass = "react-grid-item-draggable-handle";
    }

    let lastUpdated = Helper.getWidgetFieldValue(
      this.props.data,
      this.props.panel.lastUpdated,
      false
    );
    return (
      <div className={draggableClass}>
        <div className="flex-grid">
          <div className="col">
            <span className="title">
              {Helper.getWidgetFieldValue(
                this.props.data,
                this.props.panel.title,
                false
              )}
            </span>
          </div>
          <div className="col text-right">
            {Helper.isNullOrEmpty(lastUpdated) === false && 
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tlu">
                  Last Updated on <br />
                  {new Date(lastUpdated).format()}
                </Tooltip>
              }
            >
              <i className="fa fa-clock-o pr-3" />
            </OverlayTrigger>
            }
            {permissions.canView && (
              <DropdownButton
                pullRight
                variant="link"
                className="react-grid-item-draggable-handle-cancel d-inline"
                title={<i className="fa fa-ellipsis-h fa-lg" />}
                id={"panelMenu" + this.props.panel.id}
              >
                {permissions.canView && (
                  <Dropdown.Item onClick={this.onPanelView}>
                    <i className="fa fa-eye" /> View
                  </Dropdown.Item>
                )}
                {permissions.canEdit && (
                  <Dropdown.Item onClick={this.onPanelEdit}>
                    <i className="fa fa-edit" /> Edit
                  </Dropdown.Item>
                )}
                {permissions.canEdit && (
                  <Dropdown.Item onClick={this.onPanelDelete}>
                    <i className="fa fa-trash" /> Delete
                  </Dropdown.Item>
                )}
              </DropdownButton>
            )}

            {/* <Button  simple className="thinPadding" onClick={this.onPanelView}><i className="fa fa-eye text-info"></i> View</Button>
                <Button  simple className="thinPadding text-info" onClick={this.onPanelEdit}><i className="fa fa-edit text-info"></i> Edit</Button>
                <Button  simple className="thinPadding text-danger" onClick={this.onPanelDelete}><i className="fa fa-trash"></i> Delete</Button> */}

            {this.props.mode !== PanelMode.Normal && (
              <Button variant="link"
                className="thinPadding text-info"
                onClick={this.onPanelGoBack}
              >
                <i className="fa fa-reply" /> Back{" "}
              </Button>
            )}
          </div>
        </div>
        <div className={"bg-white " + className}>
          {this.renderPanel()}

          {this.props.mode === PanelMode.Edit && this.props.panel && (
            <div>
              <PanelEdit
                fieldDefinitions={PanelPropsDefinition["Default"]}
                types={Helper.getDataFromJsonPathQueryNoPop(
                  PanelPropsDefinition,
                  "$.*.type"
                )}
                {...this.props}
              />

              {this.props.panel.widgets &&
                this.props.panel.widgets.map((item, index) => {
                  return (
                    <PanelEdit
                      key={index}
                      types={Helper.getDataFromJsonPathQueryNoPop(
                        WidgetPropsDefinition,
                        "$.*.type"
                      )}
                      widgetIndex={index}
                      widget={item}
                      fieldDefinitions={WidgetPropsDefinition[item.type]}
                      {...this.props}
                    />
                  );
                })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Panel;
