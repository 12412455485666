import React, { Component } from "react";
import Button from "components/controls/CustomButton.jsx";

export class EmptyWidget extends Component {
  // getValue = (value) =>
  // {
  //     if (value
  //             && value.startsWith("$."))
  //     {
  //         console.log(value);
  //         value = JsonPath.query(this.props.data, value).pop();
  //         console.log(JSON.stringify(value));
  //     }
  //     return value;
  // }
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-12 text-center">
            <Button
              simple
              className="text-info"
              onClick={this.props.onPanelEdit}
            >
              Click Here{" "}
            </Button>{" "}
            to configure {this.props.widget.type} widget
          </div>
        </div>
      </div>
    );
  }
}

export default EmptyWidget;
