import React, { Component } from "react";
import Helper from "Helper";
import Sugar from "sugar";
import { DropdownButton, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";

import Button from "components/controls/CustomButton.jsx";
import moment from "moment";

export class HouseWidget extends Component {
  constructor(props) {
    super(props);
    Sugar.extend();
    this.state = {
      houseData: null
    };
  }

  componentDidMount = () => { };

  getHouseName = (house) => {
    var houseName = "";
    if (Helper.isNullOrEmpty(house.TenantHouseID) === false) {
      houseName = house.TenantHouseID;
    }
    return houseName;
  }

  gotoHouse = house => {
    this.props.history.push(
      `/Controls/${this.props.match.params.tenantId}/${
      this.props.match.params.siteId
      }/${house.houseId}`
    );
  };

  render() {
    let houseData = Helper.getWidgetFieldValue(
      this.props.data,
      this.props.panel.datasources[0].path,
      true
    );

    //Avoid Error when widget is NOT configured
    if (
      houseData !== undefined &&
      houseData !== null &&
      Array.isArray(houseData) === true
    ) {
      houseData = houseData.sortBy(h => String(h.House));
    }
    return (
      <div className="d-flex flex-wrap px-2 py-1 text-center">
        {houseData &&
          houseData.map((house, key) => {
            let alarm = [];
            alarm = house["Alarms"];
            let statusColor = "red";
            let statusClassName = "text-danger";
            if (alarm === undefined) {
              statusColor = "green";
              statusClassName = "text-success";
            }
            return (
              <div
                key={key}
                className="mx-2 my-3 house bg-barn handCursor house-content center-block"
                
              >
                <>
                  <div className="row"> 
                  <div className="col">
                      &nbsp;
                      </div>
                    </div>
                    <div className="row"> 
                  <div className="col">
                      &nbsp;
                      </div>
                    </div>
            
                  <div className="row">
                    <div className="col">
                      <div className="d-inline">
                      <Button round fill className={"btn btn-round bg-house btn btn-default btn-house"} onClick={() => this.gotoHouse(house)}>
                        <span className="fs-1p2">{house.House} </span>
                      </Button>
                      </div>
                      <div className="bell d-inline pull-right">
                        {alarm === undefined && (<DropdownButton
                          drop={"down"}
                          variant="link"
                          style = {{width:"20px", height:"31px", marginTop:"9px"}}
                          title={<i className={"fa fa-bell fs-1p5 " + statusClassName } />}
                          id={"panelMenu" + this.props.panel.id}>
                            <Dropdown.Item>
                              No Alarms
                            </Dropdown.Item>
                        </DropdownButton>)}
                        {alarm !== undefined && (<DropdownButton
                          variant="link"
                          style = {{width:"20px", height:"31px", marginTop:"9px"}}
                          title={<i className={"fa fa-bell fs-1p5 " + statusClassName } />}
                          id={"panelMenu" + this.props.panel.id}>
                           
                              <Dropdown.Item >
                                <tr>
                                  <th className={"tableBorder"}>{'Date & Time'}</th>
                                  <th className={"tableBorder"}>{'Group'}</th>
                                  <th className={"tableBorder"}>{'Sensor'}</th>
                                  <th className={"tableBorder"}>{'Value'}</th>
                                  <th className={"tableBorder"}>{'Msg'}</th>
                                </tr>
                              {alarm.map((ala, key) => (<tr>
                                  <td className={"tableBorder"}>{ala.TimeStamp}</td>
                                  <td className={"tableBorder"}>{ala.grp}</td>
                                  <td className={"tableBorder"}>{ala.sensor}</td>
                                  <td className={"tableBorder"}>{ala.val}</td>
                                  <td className={"tableBorder"}>{ala.msg}</td>
                                </tr>))}
                              </Dropdown.Item>
                        </DropdownButton>)}
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-xs-12 text-center houseAlias">
                      {this.getHouseName(house)}
                    </div>
                  </div>

                  <div className="row justify-content-center" onClick={() => this.gotoHouse(house)}>
                    <div className="px-5 pt-2">
                      <div className="row house-line px-4 text-center">
                        <span>PSI</span>
                        {house.StaticAirPressure === null
                          ? "0"
                          : house.StaticAirPressure}{" "}
                      </div>
                      <div className="row house-line px-4 text-center">
                        <span>Low</span>
                        {house.LowTemp === null ? "0" : house.LowTemp}{" "}
                      </div>
                      <div className="row house-line px-4 text-center">
                        <span>Avg</span>
                        {house.AverageTemp === null
                          ? "0"
                          : house.AverageTemp}{" "}
                        {/* <span style={{ fontSize: "0.5em" }}>&deg;F</span> */}
                      </div>
                      <div className="row house-line px-4 text-center">
                        <span>High</span>
                        {house.HighTemp === null ? "0" : house.HighTemp}{" "}
                        {/* <span style={{ fontSize: "0.5em" }}>&deg;F</span> */}
                      </div>
                    </div>
                  </div>
                </>
              </div>
            );
          })}
      </div>
    );
  }
}

export default HouseWidget;
