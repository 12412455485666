import React from "react"
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend } from "recharts"
import Helper from 'Helper';

class HalfDoughnutChartWidget extends React.Component {
    render() {
        //Avoid Error when widget is NOT configured
        if (this.props.widget.rAxis === undefined)
        {
            return <div className="text-center">Widget configuration is incomplete. Please contact your Administrator</div>
        }

        var data = Helper.getDataFromJsonPathQuery(this.props.data, this.props.panel.datasources[0].path);
        return (
            <ResponsiveContainer width={this.props.widget.width} height={this.props.widget.height}>
                <PieChart onMouseEnter={this.onPieEnter}>
                    <Pie
                        data={data}
                        dataKey={this.props.widget.rAxis.dataKey}
                        nameKey={this.props.widget.rAxis.nameKey}
                        fill={this.props.widget.rAxis.color}
                        cx="50%"
                        cy="100%"
                        innerRadius={this.props.widget.rAxis.innerRadius}
                        outerRadius={this.props.widget.rAxis.outerRadius}
                        startAngle={0}
                        endAngle={180}
                        paddingAngle={0}
                        label={this.props.widget.rAxis.showLabel}
                    >
                        {
                            this.props.widget.cellColors && this.props.widget.cellColors.map((item, index) => <Cell  key={index} fill={item} />)
                        }
                    </Pie>
                    {this.props.widget.showTooltip && <Tooltip isAnimationActive={false}/>}
                    {this.props.widget.showLegend && <Legend verticalAlign="top" height={36} />}
                </PieChart>
            </ResponsiveContainer>
        );
    }
}


export default HalfDoughnutChartWidget;