import React from "react";
//import "react-select/dist/react-select.css";
import ReactTooltip from "react-tooltip";

class FormSelect extends React.Component {
  handleInputChange = event => {
    this.props.handleInputChange(event);
  };
  render() {
    let fieldSeparator = " ";

    if (this.props.displayFieldSeparator !== undefined) {
      fieldSeparator = this.props.displayFieldSeparator;
    }

    let options = this.props.options;
    if (options === undefined || options === null) {
      options = [];
    }
    return (
      <>
        <div className={"form-group " + this.props.labelWidth + " " + this.props.groupClassName}>
          <label className={"form-label " + (this.props.required===true?"required":"") + " " + this.props.labelClass} htmlFor={this.props.actualControlId}>
            {this.props.name} {" "}
            { this.props.desc && 
              <i data-for="main" data-tip={this.props.desc} data-iscapture="true" className="fa fa-info-circle"/>
            }
          </label>
          <ReactTooltip id="main" place="top" type="dark" effect="float" html={true}/>
        </div>
        <div className={"form-group " + this.props.controlWidth + " " + this.props.groupClassName }>
          <select
            id={this.props.actualControlId}
            name={this.props.id}
            className={"form-control " + this.props.className}
            onChange={this.handleInputChange}
            value={(this.props.value == null ? '' : this.props.value)}
          >
            {this.props.noDefault===true && <option value="" />}
            {options.map((item, index) => {
              
              let value = this.props.valueField === undefined
                ? item
                : eval("item." + this.props.valueField);

              let display = "";
              if (this.props.displayField === undefined || this.props.displayField === null) {
                display = item;
              }
              else {
                let displayFieldArray = this.props.displayField.split(":")
                
                for (let i = 0; i < displayFieldArray.length; i++) {
                  if (i !== 0) {
                  
                    display = display + fieldSeparator;
                  }
                  
                  display = display + eval("item." + displayFieldArray[i]);
                }
              }
              if(value === null || display === null) {
                return;
              }

              return (
                <option
                  key={index}
                  value={value}
                >
                  {display}
                </option>
              );
            })}
          </select>
          <div className="message"></div>
        </div>
      </>
    );
  }
}
FormSelect.defaultProps = {
  noDefault: true
  // displayField : 'display',
  // valueField : 'value'
};

export default FormSelect;
