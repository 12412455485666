import React from "react";
import Helper from "Helper";
//import Checkbox from "components/controls/CustomCheckbox.jsx";
//import Radio from "components/controls/CustomRadio.jsx";
import ReactTooltip from "react-tooltip";

class FormInput extends React.Component {
  handleInputChange = event => {
    this.props.handleInputChange(event);
  };
  handleBlur = event => {
    if (this.props.onBlur) this.props.onBlur(event);
  };
  handleFocus = event => {
    if (this.props.onFocus) this.props.onFocus(event);
  };
  render() {
    if (this.props.type === "hidden") {
      return (
        <input
          onChange={this.handleInputChange}
          type={this.props.type}
          id={this.props.actualControlId}
          name={this.props.id}
          value={this.props.value}
        />
      );
    }
    if (this.props.type === "checkbox") {
      var groupClassName = "";
      return (
        <div className={"form-group " + groupClassName}>
          {/* <Checkbox isChecked={this.props.value} number={this.props.id} label={this.props.name}/> */}
          <div className="checkbox">
            <input
              id={this.props.actualControlId}
              name={this.props.id}
              value={this.props.value}
              type="checkbox"
              className={"form-control " + this.props.className}
              checked={this.props.value}
              onChange={this.handleInputChange}
            />
            <label htmlFor={this.props.id}>{this.props.name}</label>
          </div>
        </div>
      );
    }
    var placeholder = this.props.placeholder;
    if (this.props.placeholder === undefined) {
      placeholder = this.props.name;
    }
    if (this.props.groupClassName !== undefined) {
      groupClassName = this.props.groupClassName
    }
    return (
      <>
        <div className={"form-group " + this.props.labelWidth + " " + this.props.groupClassName}>
          <label className={"form-label " + (this.props.required===true?"required":"") + " " + this.props.labelClass} htmlFor={this.props.actualControlId}>
            {this.props.name} {" "}
            { this.props.desc && 
              <i data-for="main" data-tip={this.props.desc} data-iscapture="true" className="fa fa-info-circle"/>
            }
          </label>
          <ReactTooltip id="main" place="top" type="dark" effect="float" html={true}/>
        </div>
        <div className={"form-group " + this.props.controlWidth + " " + this.props.groupClassName}>
          {(Helper.isNullOrEmpty(this.props.prefix) === false ||
            Helper.isNullOrEmpty(this.props.suffix) === false) && (
              <span className="input-group">
                {this.props.prefix && (
                  <span className="input-group-prepend">{this.props.prefix}</span>
                )}
                <input
                  data-controltype={this.props.type}
                  onChange={this.handleInputChange}
                  onBlur={this.handleBlur}
                  onFocus={this.handleFocus}
                  type={this.props.type}
                  className={"form-control " + this.props.className}
                  id={this.props.actualControlId}
                  name={this.props.id}
                  placeholder={placeholder}
                  value={this.props.value}
                  readOnly={this.props.readOnly}
                  maxLength={this.props.maxLength}
                  max={this.props.max}
                  min={this.props.min}
                  defaultValue={this.props.type == "number" ? 0 : '' }
                  
                />
                {this.props.suffix && (
                  <span className="input-group-prepend">{this.props.suffix}</span>
                )}
              </span>
            )}

          {(this.props.prefix === undefined || this.props.prefix === "") &&
            (this.props.suffix === undefined ||
              this.props.suffix === null ||
              this.props.suffix === "") && (
              <input
                data-controltype={this.props.type}
                onChange={this.handleInputChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                type={this.props.type}
                className={"form-control " + this.props.className}
                id={this.props.id.replace(".", "_")}
                name={this.props.id}
                placeholder={placeholder}
                value={this.props.value === null || this.props.value === undefined ? "" : this.props.value}
                readOnly={this.props.readOnly}
                maxLength={this.props.maxLength}
                max={this.props.type == 'date' ? "2100-12-31" : this.props.max}
                min={this.props.min}
                defaultValue={this.props.type == "number" ? 0 : '' }
              />
            )}
        </div>
      </>
    );
  }
}

export default FormInput;
