//Widgets
import DefaultPanel from "../components/panels/DefaultPanel";
import SwitchPanel from "../components/panels/SwitchPanel";
import FilterPanel from "../components/panels/FilterPanel";

var panelPropsDefinition = {
    "Default": {
        "type" : "Default",
        "component" : DefaultPanel,
        "name": "Default Panel",
        "icon": "pe-7s-id",
        "tabs": [{ "name": "Info", "icon": "fa fa-info" }, { "name": "Datasources", "icon": "fa fa-database" }, { "name": "General", "icon": " fa fa-bank" }],
        "fieldSets": [{ "name": "Datasource", "arrayName": "datasources" }],
        "fields": [
            { "tab": "Info", "fieldSet": "", "name": "Id", "path": "id", "type": "string", "controlType": "text", "default": null, "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "Info", "fieldSet": "", "name": "Name", "path": "name", "type": "string", "controlType": "text", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "Info", "fieldSet": "", "name": "Description", "path": "description", "type": "string", "controlType": "text", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Header", "name": "Title", "path": "title", "type": "string", "controlType": "datasourceData", "dropdownPathField":"datasources.0.path" , "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Header", "name": "Subtitle", "path": "subtitle", "type": "string", "controlType": "datasourceData", "dropdownPathField":"datasources.0.path" , "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Header", "name": "Last Updated", "path": "lastUpdated", "type": "string", "controlType": "datasourceData", "dropdownPathField":"datasources.0.path" , "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Position", "name": "Width", "path": "position.w", "type": "number", "controlType": "number", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Position", "name": "Minimum Width", "path": "position.minW", "type": "number", "controlType": "number", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Position", "name": "Maximum Width", "path": "position.maxW", "type": "number", "controlType": "number", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Position", "name": "Height", "path": "position.h", "type": "number", "controlType": "number", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Position", "name": "Minimum Height", "path": "position.minH", "type": "number", "controlType": "number", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Position", "name": "Maximum Height", "path": "position.maxH", "type": "number", "controlType": "number", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Position", "name": "Class Name", "path": "className", "type": "string", "controlType": "text", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Trend", "name": "Show Trend", "path": "trend.show", "type": "bool", "controlType": "checkbox", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Footer", "name": "Icon", "path": "footer.icon", "type": "string", "controlType": "datasourceData", "dropdownPathField":"datasources.0.path" , "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Footer", "name": "Text", "path": "footer.text", "type": "string", "controlType": "datasourceData", "dropdownPathField":"datasources.0.path" , "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "Datasources", "fieldSet": "Datasource", "name": "Name", "path": "name", "type": "string", "controlType": "datasource", "dropdownPath":"$", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "Datasources", "fieldSet": "Datasource", "name": "Path", "path": "path", "type": "string", "controlType": "datasourceData", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null }
        ]
    },
    "Switch": {
        "type" : "Switch",
        "component" : SwitchPanel,
        "name": "Switch Panel",
        "icon": "pe-7s-id",
        "tabs": [{ "name": "Info", "icon": "fa fa-info" }, { "name": "Datasources", "icon": "fa fa-database" }, { "name": "General", "icon": " fa fa-bank" }],
        "fieldSets": [{ "name": "Datasource", "arrayName": "datasources" }],
        "fields": [
            { "tab": "Info", "fieldSet": "", "name": "Id", "path": "id", "type": "string", "controlType": "text", "default": null, "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "Info", "fieldSet": "", "name": "Name", "path": "name", "type": "string", "controlType": "text", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "Info", "fieldSet": "", "name": "Description", "path": "description", "type": "string", "controlType": "text", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Header", "name": "Title", "path": "title", "type": "string", "controlType": "datasourceData", "dropdownPathField":"datasources.0.path" , "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Header", "name": "Subtitle", "path": "subtitle", "type": "string", "controlType": "datasourceData", "dropdownPathField":"datasources.0.path" , "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Header", "name": "Last Updated", "path": "lastUpdated", "type": "string", "controlType": "datasourceData", "dropdownPathField":"datasources.0.path" , "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Position", "name": "MinimumWidth", "path": "position.minW", "type": "number", "controlType": "number", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Position", "name": "Maximum Width", "path": "position.maxW", "type": "number", "controlType": "number", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Footer", "name": "Icon", "path": "footer.icon", "type": "string", "controlType": "datasourceData", "dropdownPathField":"datasources.0.path" , "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Footer", "name": "Text", "path": "footer.text", "type": "string", "controlType": "datasourceData", "dropdownPathField":"datasources.0.path" , "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "Datasources", "fieldSet": "Datasource", "name": "Name", "path": "name", "type": "string", "controlType": "datasource", "dropdownPath":"$", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "Datasources", "fieldSet": "Datasource", "name": "Path", "path": "path", "type": "string", "controlType": "datasourceData", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null }
        ]
    },
    "Filter": {
        "type" : "Filter",
        "component" : FilterPanel,
        "name": "Filter Panel",
        "icon": "pe-7s-id",
        "tabs": [{ "name": "Info", "icon": "fa fa-info" }, { "name": "Datasources", "icon": "fa fa-database" }, { "name": "General", "icon": " fa fa-bank" }],
        "fieldSets": [{ "name": "Datasource", "arrayName": "datasources" }],
        "fields": [
            { "tab": "Info", "fieldSet": "", "name": "Id", "path": "id", "type": "string", "controlType": "text", "default": null, "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "Info", "fieldSet": "", "name": "Name", "path": "name", "type": "string", "controlType": "text", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "Info", "fieldSet": "", "name": "Description", "path": "description", "type": "string", "controlType": "text", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Header", "name": "Title", "path": "title", "type": "string", "controlType": "datasourceData", "dropdownPathField":"datasources.0.path" , "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Header", "name": "Subtitle", "path": "subtitle", "type": "string", "controlType": "datasourceData", "dropdownPathField":"datasources.0.path" , "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Header", "name": "Last Updated", "path": "lastUpdated", "type": "string", "controlType": "datasourceData", "dropdownPathField":"datasources.0.path" , "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Position", "name": "MinimumWidth", "path": "position.minW", "type": "number", "controlType": "number", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Position", "name": "Maximum Width", "path": "position.maxW", "type": "number", "controlType": "number", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Footer", "name": "Icon", "path": "footer.icon", "type": "string", "controlType": "datasourceData", "dropdownPathField":"datasources.0.path" , "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "General", "fieldSet": "Footer", "name": "Text", "path": "footer.text", "type": "string", "controlType": "datasourceData", "dropdownPathField":"datasources.0.path" , "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "Datasources", "fieldSet": "Datasource", "name": "Name", "path": "name", "type": "string", "controlType": "datasource", "dropdownPath":"$", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null },
            { "tab": "Datasources", "fieldSet": "Datasource", "name": "Path", "path": "path", "type": "string", "controlType": "datasourceData", "default": "", "options": [], "url": "", "required": false, "maxLength": null, "minLength": null }
        ]
    }
};

export default panelPropsDefinition;