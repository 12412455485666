import React from "react";
import Sugar from "sugar";
import FormSelect from "components/controls/FormSelect";
import FormInput from "components/controls/FormInput";
import FormTextArea from "components/controls/FormTextArea";
//import "react-select/dist/react-select.css";
import JsonPath from "jsonpath";
import {
  Row,
  Col,
  Nav,
  NavItem,
  Tabs,
  Tab,
  Dropdown,
  DropdownButton,
  Container
} from "react-bootstrap";
import Button from "components/controls/CustomButton.jsx";
import Helper from "Helper";
import WidgetPropsDefinition from "variables/WidgetPropsDefinition";

class PanelEdit extends React.Component {
  constructor(props, context) {
    super(props, context);
    Sugar.extend();
    this.state = {
      panel: this.props.panel,
      dropDownOptions: null,
      dropdownInputField: null,
      dropDownOptionsCache: null
    };
  }

  onPanelSave = () => {
    this.props.onPanelSave(this.state.panel);
  };

  handleInputChange = (event, field) => {
    var panel = this.state.panel;
    let target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    let name = target.name;
  //Make sure that the object exists. If not, initialize it
  if (field !== undefined && field.path.indexOf(".") !== -1)
  {
     let objectName = name.replace(field.path, "") + field.path.substring(0, field.path.indexOf("."));
     if (Helper.getDataFromJsonPathQuery(panel, objectName.replace("panel.", "")) === undefined)
     {
        eval(objectName + "= {}")
     }
  }


    if (target.type === "number") {
      eval(name + "=" + value);
    } else if (target.type === "checkbox") {
      eval(name + "=" + value);
      target.checked = value;
    } else {
      eval(name + "=`" + value + "`");
    }

    if (field !== undefined && field.controlType === "datasource") {
      //Cutom Logic to Load Datasources in the this.state.datasourcesData
      this.populateDatasource(panel, field, name, value);
      this.loadDropdown(target, field);
    }
    this.setState({
      panel: panel
    });
  };

  //TODO: Rethink this ` to keep it simple
  loadDropdown = (target, field) => {
    let value = target.value;
    let panel = this.state.panel;
    let rootPath = "$";
    let initialPath = rootPath;
    let dataPath = initialPath;
    let data;
    let dropDownOptionsCache = this.state.dropDownOptionsCache;
    let dataArray = [];
    let options = [];
    let dropdownPath = null;

    //Set the InitialPath if we need to get the Data in reference to a particular WidgetField
    if (field !== null && field.dropdownPathField !== undefined) {
      initialPath = Helper.getWidgetFieldValue(
        panel,
        "$." + field.dropdownPathField,
        false
      );

      //Further DrillDown the DataPath using the dropdownPathAppend
      if (field.dropdownPathAppend !== undefined) {
        initialPath = initialPath + field.dropdownPathAppend;
        dropdownPath = initialPath;
      }
      dataPath = initialPath;
    }

    //Change the Data's source based on the Field Path
    if (field !== undefined && field.controlType === "datasource") {
      //For DataSource, Set the data as "this.props.datasources"
      let datasources = {};
      if (this.props.datasources && this.props.datasources) {
        this.props.datasources.forEach(item => {
          datasources[item.name] = item.name;
        });
      }
      data = datasources;
      dropdownPath = field.dropdownPath;
    } else {
      //For all other set the data as data
      data = this.props.data;
    }

    //console.log(dataPath + ":" + initialPath)

    //Set the DataPath if the field has some value. Means that user selected something or typed something to search for
    if (value !== undefined && value !== "") {
      dataPath = value;
    } else {
      value = "";
    }

    //If the dropDownPath is defined then override Path if it it doesn't start with initialPath
    if (
      field !== null &&
      field.dropdownPathField !== undefined &&
      dataPath.startsWith(initialPath) === false
    ) {
      dataPath = initialPath;
    }
    if (dropdownPath != null) {
      dataPath = dropdownPath;
    }

    //dropdownPath: Where to search Starts
    //dropdownPathAppend:
    //dropdownPathField: Define the WidgetField that defines the DataLocation or Where the data is located

    //console.log(JSON.stringify(field));
    // console.log( "dataPath:"
    //    + dataPath +
    //    "; initialPath:" +
    //    initialPath +
    //     "; dropdownPath:" +
    //     dropdownPath +
    //     "; dropdownPathAppend:"  +
    //     field.dropdownPathAppend +
    //     "; value:"  +
    //     value
    // );

    //Ignore if the DataPath doesn't start with rootPat ($) as the user can type anything which doesn't need to come from the DataField
    if (
      dataPath.startsWith(rootPath) === false &&
      field.path !== "datasources[0].name"
    ) {
      return;
    }

    //If DropDown Cache exists then filter out of it
    if (
      dropDownOptionsCache !== null &&
      dataPath.startsWith(dropDownOptionsCache.path) === true
    ) {
      //If its with DOT Json Path will fail, So strip the DOT
      if (dataPath.endsWith(".") === true) {
        dataPath = dataPath.substring(0, dataPath.length - 1);
      } else {
        //Filter the Data
        let valueInLowerCase = value.toLowerCase();
        options = dropDownOptionsCache.options.filter(item =>
          item.value.toLowerCase().startsWith(valueInLowerCase)
        );

        //Check if the user typed something that starts with dropDownOptionsCache.path
        if (
          dataPath.startsWith(dropDownOptionsCache.path) === true &&
          options.length > 0
        ) {
          this.setState({ dropDownOptions: options });
          return;
        }
        //Check if the user typed something other than the dropDownOptionsCache.path
        else if (dropDownOptionsCache.path && options.length === 0) {
          options.push({ label: "No Results", value: "" });
          this.setState({ dropDownOptions: options });
          return;
        }
      }
    }

    //Stripdown the end DOT
    if (dataPath.endsWith(".") === true) {
      dataPath = dataPath.substring(0, dataPath.length - 1);
    }

    //Filter Data
    dataArray = Helper.getDataFromJsonPathQuery(data, dataPath);

    //Push the Initial Path as there mayn't be any match
    if (field === undefined || dropdownPath === null) {
      options.push({ label: dataPath, value: dataPath });
    }

    //Check for Object. If so, get the keys & add them as options
    if (typeof dataArray === "object") {
      const keys = Object.keys(dataArray);
      keys.forEach(item => {
        let optionValue = dataPath + "." + item;

        //Determine the Type
        let tempArray = Helper.getDataFromJsonPathQuery(
          data,
          dataPath + "." + item
        );
        let optionLabel = optionValue + " ( " + typeof tempArray + " ) ";

        //Replace the  dropdownPath
        if (
          field !== undefined &&
          (field.dropdownPath !== undefined ||
            field.dropdownPathAppend !== undefined)
        ) {
          optionLabel = optionLabel.replace(dropdownPath + ".", "");
          optionValue = optionValue.replace(dropdownPath + ".", "");
        }
        options.push({ label: optionLabel, value: optionValue });
      });

      //Set the options Cache
      this.setState({
        dropDownOptionsCache: { options: options, path: dataPath }
      });
    }
    this.setState({ dropDownOptions: options });
  };

  onKeyDown = e => {
    if (e.keyCode === 27) {
      this.onCloseDropdown();
    }
  };

  onCloseDropdown = () => {
    this.setState({ dropDownOptions: null, dropDownOptionsCache: null });
    //Added timeout as Selection needs the state
    // setTimeout(() => {
    //   this.setState({ dropDownOptions: null, dropDownOptionsCache: null });
    // }, 1000);
  };

  onDropdownFoucs = (event, panel, field) => {
    //Clear Cache
    //this.setState({ dropDownOptions: null, dropDownOptionsCache: null });

    let inputField = event.target;
    this.setState({ dropdownInputField: inputField });
    this.loadDropdown(event.target, field);
  };

  populateDatasource = (panel, field, fieldName, value) => {
    //Cutom Logic to Load Datasources in the this.state.datasourcesData
    if (field.controlType === "datasource") {
      eval(fieldName.replace("name", "path") + '="$." + "' + value + '"');
      this.props.onPanelSave(panel);
    }
  };

  selectItem(field, value) {
    let name = this.state.dropdownInputField.name;
    let panel = this.state.panel;

  //Make sure that the object exists. If not, initialize it
  if (field !== undefined && field.path.indexOf(".") !== -1)
  {
     let objectName = name.replace(field.path, "") + field.path.substring(0, field.path.indexOf("."));
     if (Helper.getDataFromJsonPathQuery(panel, objectName.replace("panel.", "")) === undefined)
     {
        eval(objectName + "= {}")
     }
  }

    eval(name + "=`" + value + "`");
    this.populateDatasource(panel, field, name, value);
    this.setState({
      dropDownOptions: null,
      dropDownOptionsCache: null,
      panel: panel
    });
  }

  renderField(field, widgetPrefix, arrayPrefix) {
    let panel = this.state.panel;
    let computedFieldPath = "panel.";
    let computedWidgetFieldPath = widgetPrefix;
    if (arrayPrefix !== undefined)
      computedWidgetFieldPath = computedWidgetFieldPath + arrayPrefix;
    computedWidgetFieldPath = computedWidgetFieldPath + field.path;
    computedFieldPath = computedFieldPath + computedWidgetFieldPath;
    switch (field.controlType) {
      case "text":
      case "number":
      case "phone":
      case "email":
      case "hidden": {
        return (
          <FormInput
            key={computedFieldPath}
            id={computedFieldPath}
            type={field.controlType}
            name={field.name}
            value={Helper.getDataFromJsonPathQuery(
              panel,
              "$." + computedWidgetFieldPath
            )}
            handleInputChange={event => this.handleInputChange(event, field)}
            labelWidth={"col-md-12"}
            controlWidth={"col-md-12"}
          />
        );
      }
      case "textarea": {
        return (
          <FormTextArea
            key={computedFieldPath}
            id={computedFieldPath}
            type={field.controlType}
            name={field.name}
            className={field.className}
            value={Helper.getDataFromJsonPathQuery(
              panel,
              "$." + computedWidgetFieldPath
            )}
            handleInputChange={event => this.handleInputChange(event, field)}
            labelWidth={"col-md-12"}
            controlWidth={"col-md-12"}
          />
        );
      }
      case "select": {
        return (
          <FormSelect
            key={computedFieldPath}
            id={computedFieldPath}
            type={field.controlType}
            name={field.name}
            value={Helper.getDataFromJsonPathQuery(
              panel,
              "$." + computedWidgetFieldPath
            )}
            handleInputChange={event => this.handleInputChange(event, field)}
            options={field.options}
            displayField={field.optionsDisplayField}
            valueField={field.optionsValueField}
            displayFieldSeparator = {field.optionsDisplayFieldSeparator}
            labelWidth={"col-md-12"}
            controlWidth={"col-md-12"}
          />
        );
      }
      case "checkbox": {
        return (
          <FormInput
            key={computedFieldPath}
            id={computedFieldPath}
            type={field.controlType}
            name={field.name}
            value={Helper.getDataFromJsonPathQuery(
              panel,
              "$." + computedWidgetFieldPath
            )}
            handleInputChange={event => this.handleInputChange(event, field)}
            options={field.options}
            labelWidth={"col-md-12"}
            controlWidth={"col-md-12"}
          />
        );
      }
      // case "radio":
      //     {
      //         return <FormInput key={"panel." + field.path} id={"panel." + field.path} type={field.type} name={field.name} value={JsonPath.query(panel, "$." + field.path)[0]} handleInputChange={this.handleInputChange} options={field.options} />
      //     }
      case "datasourceData":
      case "datasource": {
        return (
          <div key={computedFieldPath}>
            <FormInput
              controlType="datasource"
              id={computedFieldPath}
              type={"text"}
              name={field.name}
              value={Helper.getDataFromJsonPathQuery(
                panel,
                "$." + computedWidgetFieldPath
              )}
              handleInputChange={event => this.handleInputChange(event, field)}
              //onBlur={this.onCloseDropdown}
              //onKeyDown = {event => this.onKeyDown(event)}
              onFocus={event => {
                this.onDropdownFoucs(event, panel, field);
              }}
              suffix={
                this.state.dropDownOptions &&
                this.state.dropDownOptions.length > 0 &&
                this.state.dropdownInputField &&
                this.state.dropdownInputField.name === computedFieldPath && (
                  <Dropdown
                    className="open panelEditDropdown"
                    id="dropdownOptions"
                  >
                    <Dropdown.Toggle className="input-group-addon hidden" />
                    <Dropdown.Menu>
                      <div
                        onClick={this.onCloseDropdown}
                        className="pull-right pointerCursor"
                      >
                        {" "}
                        <i className="pt-2 pr-3 fa fa-lg fa-close text-danger" />{" "}
                      </div>
                      {this.state.dropDownOptions.map((item, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            value={item.value}
                            onSelect={() => this.selectItem(field, item.value)}
                          >
                            {item.label}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                )
              }
              labelWidth={"col-md-12"}
              controlWidth={"col-md-12"}
            />
          </div>
        );

        // return <Async
        //     cache={false}
        //     value={JsonPath.query(panel, "$." + field.path)[0]}
        //     onChange={this.handleDatasourcePathChange}
        //     key={field.name}
        //     name={field.name}
        //     loadOptions={this.loadDatasorcePathOptions}
        //     onValueClick = {this.handleInputChange}
        //     //closeOnSelect={false}
        //     //onBlurResetsInput={false}
        //     //onSelectResetsInput={false}
        // />
      }
      default: {
        throw field.controlType + " is not configured";
      }
    }
  }

  onAddArrayItem = (widgetPrefix, arrayPrefix, index) => {
    let panel = this.state.panel;
    let myArray = Helper.getWidgetFieldValue(
      panel,
      "$." + widgetPrefix + arrayPrefix,
      true
    );
    myArray.insert(Object.clone(myArray[index]), index);
    this.setState({ panel: panel });
  };
  onMoveArrayItemRight = (widgetPrefix, arrayPrefix, currentIndex) => {
    let panel = this.state.panel;
    let destIndex = currentIndex + 1;
    let myArray = Helper.getWidgetFieldValue(
      panel,
      "$." + widgetPrefix + arrayPrefix,
      true
    );
    if (destIndex >= myArray.length) {
      //Do nothing as its the end
      return;
    }
    let itemToMove = myArray[currentIndex];
    myArray[currentIndex] = myArray[destIndex];
    myArray[destIndex] = itemToMove;
    this.setState({ panel: panel });
  };
  onMoveArrayItemLeft = (widgetPrefix, arrayPrefix, currentIndex) => {
    let panel = this.state.panel;
    let destIndex = currentIndex - 1;
    let myArray = Helper.getWidgetFieldValue(
      panel,
      "$." + widgetPrefix + arrayPrefix,
      true
    );
    if (destIndex < 0) {
      //Do nothing as its the end
      return;
    }
    let itemToMove = myArray[currentIndex];
    myArray[currentIndex] = myArray[destIndex];
    myArray[destIndex] = itemToMove;
    this.setState({ panel: panel });
  };
  onDeleteArrayItem = (widgetPrefix, arrayPrefix, index) => {
    let panel = this.state.panel;
    let myArray = Helper.getWidgetFieldValue(
      panel,
      "$." + widgetPrefix + arrayPrefix,
      true
    );
    myArray.removeAt(index);
    this.setState({ panel: panel });
  };

  onPanelOrWidgetTypeChanged = type => {
    let panel = this.state.panel;
    if (this.props.widget === undefined) {
      panel.type = type;
    } else {
      panel.widgets[this.props.widgetIndex] = {type : type};
      WidgetPropsDefinition[type] && WidgetPropsDefinition[type].fieldSets && WidgetPropsDefinition[type].fieldSets.forEach(fieldSet => {
        eval("panel.widgets[" + this.props.widgetIndex + "]." + fieldSet.arrayName + " = [{}]");
        //eval("panel.widgets[" + this.props.widgetIndex + "]." + fieldSet.arrayName + ".push({})")
      });
  

    }
    this.props.onPanelSave(panel);
  };

  onAddWidget = () => {
    let panel = this.state.panel;
    let newWidget = {type: "Empty"};
    panel.widgets.append(newWidget);
    this.props.onPanelSave(panel);
    this.setState({panel:panel});
    setTimeout(() => {
      document.getElementById("lastWidget").scrollIntoView();
    }, 200);
  };

  onMoveWidgetDown = currentIndex => {
    let panel = this.state.panel;
    let destIndex = currentIndex + 1;
    let myArray = panel.widgets;
    if (destIndex >= myArray.length) {
      //Do nothing as its the end
      return;
    }
    let itemToMove = myArray[currentIndex];
    myArray[currentIndex] = myArray[destIndex];
    myArray[destIndex] = itemToMove;
    this.setState({panel:panel});
    this.props.onPanelSave(panel);
  };

  onMoveWidgetUp = currentIndex => {
    let panel = this.state.panel;
    let destIndex = currentIndex - 1;
    let myArray = panel.widgets;
    if (destIndex < 0) {
      //Do nothing as its the end
      return;
    }
    let itemToMove = myArray[currentIndex];
    myArray[currentIndex] = myArray[destIndex];
    myArray[destIndex] = itemToMove;
    this.setState({panel:panel});
    this.props.onPanelSave(panel);
  };

  onDeleteWidget = currentIndex => {
    console.log(currentIndex);
    let panel = this.state.panel;
    let myArray = panel.widgets;
    myArray.removeAt(currentIndex);
    this.setState({panel:panel});
    this.props.onPanelSave(panel);
  };

  render() {
    var widgetPropsDefinition = this.props.fieldDefinitions;
    let selectedTabKey = "Widget";
    if (
      widgetPropsDefinition &&
      widgetPropsDefinition.tabs &&
      this.props.activePanel &&
      this.props.activePanel.id === this.props.panel.id
    ) {
    } else {
      return null;
    }
    if (
      widgetPropsDefinition &&
      widgetPropsDefinition.tabs &&
      widgetPropsDefinition.tabs.length > 0
    ) {
      selectedTabKey = widgetPropsDefinition.tabs[0].name;
    }

    let widgetPrefix = "";
    if (this.props.widget !== undefined)
      widgetPrefix = "widgets[" + this.props.widgetIndex + "].";

    return (
      <div>
        {this.state.panel.widgets && this.props.widgetIndex === this.state.panel.widgets.length-1 && <div id="lastWidget"></div>}
        <Container
          id="tabs-with-dropdown"
          defaultActiveKey={selectedTabKey}
        >
          <Row className="">
            <Col sm={12}>
              <div className="p-2 bg-info text-uppercase my-1">
                <DropdownButton
                  id="location"
                  onSelect={this.onPanelOrWidgetTypeChanged}
                  className="d-inline"
                  variant="link"
                  title={
                    <span>
                      {this.props.widget
                        ? this.state.panel.widgets[this.props.widgetIndex].type
                        : this.state.panel.type}
                    </span>
                  }
                >
                  <Dropdown.Item divider />
                  {this.props.types.map((type, key) => (
                    <Dropdown.Item key={key} eventKey={type}>
                      {type}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                
                {this.props.widget === undefined && (
                  <>
                  <div class="d-inline">Panel</div>
                  <div className="d-inline">
                    {" "}
                    
                    <span
                      onClick={this.onAddWidget}
                      className="pull-right pointerCursor pr-3"
                    >
                      <i className="fa fa-plus" /> Add Widget
                    </span>{" "}
                  </div>
                  </>
                )}

                {this.props.widget !== undefined && <div className="d-inline">
                    {" "}
                    Widget
                    <span
                      className="pull-right pointerCursor pr-3"
                    >
                      <i className="fa fa-arrow-down px-2" onClick={() => this.onMoveWidgetDown(this.props.widgetIndex)}/>
                      <i className="fa fa-arrow-up px-2" onClick={() => this.onMoveWidgetUp(this.props.widgetIndex)}/>
                      <i className="fa fa-save px-2" onClick={this.onPanelSave}/>
                      <i className="fa fa-trash px-2" onClick={() => this.onDeleteWidget(this.props.widgetIndex)}/>
                    </span>{" "}
                  </div>}
              </div>
              <Tabs defaultActiveKey={widgetPropsDefinition.tabs[0].name}>
                {widgetPropsDefinition.tabs.map( (tab, tabKey) => {
                  let fieldSets = JsonPath.query(
                    widgetPropsDefinition.fields,
                    "$[?(@.tab=='" + tab.name + "')].fieldSet"
                  ).unique();
                 
                  return (
                    <Tab eventKey={tab.name} title={<>{tab.icon && <i className={tab.icon} />} {tab.name}</>}>
                      <div className="flex-grid">
                      {fieldSets &&
                        fieldSets.map((fieldSet, fieldSetKey) => {
                          let arrayPrefix = "";
                          let fieldSetType = "";
                          let arrayLength = 1;
                          let showFields = true;
                          if (widgetPropsDefinition.fieldSets !== undefined) {
                            let fieldsetDefinition = widgetPropsDefinition.fieldSets.find(
                              { name: fieldSet }
                            );
                            if (fieldsetDefinition !== undefined) {
                              arrayPrefix = fieldsetDefinition.arrayName;
                              fieldSetType = fieldsetDefinition.type;
                              let fieldsetArray = Helper.getDataFromJsonPathQuery(
                                this.state.panel,
                                "$." + widgetPrefix + arrayPrefix
                              );
                              if (fieldsetArray === undefined) {
                                showFields = false;
                              } else {
                                arrayLength = fieldsetArray.length;
                              }
                            }
                          }

                          let fields = JsonPath.query(
                            widgetPropsDefinition.fields,
                            "$[?(@.fieldSet=='" + fieldSet + "')]"
                          );
                          return new Array(arrayLength)
                            .fill()
                            .map((item, index) => {
                              let arrayPrefixWithIndex = "";
                              if (arrayPrefix !== "")
                                arrayPrefixWithIndex =
                                  arrayPrefix + "[" + index + "].";
                              return (
                                
                                <div className="col-md-3">
                                  {fieldSet === "" && (
                                    <div className="rounded">
                                      {fields &&
                                        fields.map(field => {
                                          return this.renderField(
                                            field,
                                            widgetPrefix,
                                            arrayPrefixWithIndex
                                          );
                                        })}
                                    </div>
                                  )}
                                  {fieldSet !== "" && (
                                    <fieldset className="rounded">
                                      <legend>
                                        {fieldSet}{" "}
                                        {arrayPrefix !== "" && index + 1}{" "}
                                        {arrayPrefix !== "" && (
                                          <span className="pull-right">
                                            <i
                                              className="fa fa-plus text-info pointerCursor px-1"
                                              onClick={() => {
                                                this.onAddArrayItem(
                                                  widgetPrefix,
                                                  arrayPrefix,
                                                  index
                                                );
                                              }}
                                            />
                                            <i
                                              className="fa fa-arrow-right text-info pointerCursor px-1"
                                              onClick={() => {
                                                this.onMoveArrayItemRight(
                                                  widgetPrefix,
                                                  arrayPrefix,
                                                  index
                                                );
                                              }}
                                            />
                                            <i
                                              className="fa fa-arrow-left text-info pointerCursor px-1"
                                              onClick={() => {
                                                this.onMoveArrayItemLeft(
                                                  widgetPrefix,
                                                  arrayPrefix,
                                                  index
                                                );
                                              }}
                                            />
                                            <i
                                              className="fa fa-trash text-info pointerCursor px-1"
                                              onClick={() => {
                                                this.onDeleteArrayItem(
                                                  widgetPrefix,
                                                  arrayPrefix,
                                                  index
                                                );
                                              }}
                                            />
                                          </span>
                                        )}
                                      </legend>
                                      {fields &&
                                        fields.map(field => {
                                          return this.renderField(
                                            field,
                                            widgetPrefix,
                                            arrayPrefixWithIndex
                                          );
                                        })}
                                    </fieldset>
                                  )}
                                </div>
                                
                              );
                            });
                        })}
                        </div>
                    </Tab>
                  );})}
                    </Tabs>
              
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default PanelEdit;
