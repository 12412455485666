import React from "react";
import Sugar from "sugar";
import Service from "Service";

// Import React Table
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  Comparator,
} from 'react-bootstrap-table2-filter'
import FieldRenderer from 'components/common/FieldRenderer'
import Dialog from 'components/common/Dialog'
import Helper from 'Helper'
import Constant from 'constants/Constant'
class CRUDWidget extends React.Component {
  constructor(props, context) {
    super(props, context)
    Sugar.extend()
    this.state = {
      gridData: [],
      loadGrid: true,
      showDialog: false,
      action: null,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.tenantId !== this.props.match.params.tenantId) {
      this.getGridData(nextProps.match.params.tenantId)
    }
  }

  componentDidMount = () => {
    this.getGridData(this.props.match.params.tenantId)
  }

  reloadGridData = () => {
    this.getGridData(undefined)
  }

  getGridData = (tenantId) => {
    if (Helper.isNullOrUndefined(this.props.widget.url) === true) {
      return
    }
    this.props.appProps.showLoader()
    let url = this.props.widget.url
    let urlTokens = {
      tenantId:
        tenantId === undefined ? this.props.match.params.tenantId : tenantId,
      siteId: this.props.match.params.siteId,
      houseId: this.props.match.params.houseId,
    }
    url = Service.replaceUrlWithTokens(url, urlTokens)
    Service.getRequest(
      url,
      this.getGridDataResponse,
      this.props.appProps.handleServiceError
    )
  }

  getGridDataResponse = (response) => {
    this.setState({ gridData: response.body, showDialog: false })
    setTimeout(() => {
      this.props.appProps.hideLoader()
    }, 1000)
  }

  addRow = () => {
    this.setState({
      showDialog: true,
      action: Constant.Action.Add,
      crudObject: {},
    })
  }

  editRow = (cell, row, rowIndex) => {
    this.setState({
      showDialog: true,
      action: Constant.Action.Edit,
      crudObject: Object.clone(row, true),
    })
  }

  formValidation = () => {
    let obj = this.state.crudObject
    let ffd = JSON.parse(this.props.widget.formFieldsDefinitions)

    var isValid = true
    for (var i = 0; i < ffd.fields.length; i++) {
      var field = ffd.fields[i]
      if (
        field.required &&
        (obj[field.path] == null || obj[field.path] == '')
      ) {
        var msg = field.name + " can't be empty."
        this.props.appProps.showNotification(
          msg,
          Constant.Notification.Type.Error
        )
        isValid = false
      }

      if (
        field.required &&
        field.controlType == 'time' &&
        obj[field.path] == '00:00:00'
      ) {
        var msg = field.name + " can't be empty."
        this.props.appProps.showNotification(
          msg,
          Constant.Notification.Type.Error
        )
        isValid = false
      }
      if (
        field.required &&
        field.controlType == 'number' &&
        obj[field.path] == '0'
      ) {
        var msg = field.name + ' should be greater than zero.'
        this.props.appProps.showNotification(
          msg,
          Constant.Notification.Type.Error
        )
        isValid = false
      }

      if (
        !Helper.isNullOrEmpty(obj[field.path]) &&
        field.controlType == 'email'
      ) {
        const re =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        let emails = obj[field.path].split(',')

        for (var j = 0; j < emails.length; j++) {
          if (!re.test(emails[j])) {
            var msg = field.name + ' Invalid.'
            this.props.appProps.showNotification(
              msg,
              Constant.Notification.Type.Error
            )
            isValid = false
            break
          }
        }
      }
    }
    return isValid
  }

  onSave = () => {
    let action = this.state.action
    let keyValue = Helper.getWidgetFieldValue(
      this.state.crudObject,
      '$.' + this.props.widget.keyField,
      false
    )

    if (!this.formValidation()) return

    this.props.appProps.showLoader()
    if (Helper.isNullOrUndefined(keyValue) === true || keyValue === '0') {
      action = Constant.Action.Add
    }
    if (action === Constant.Action.Add) {
      Service.postRequest(
        this.props.widget.url,
        this.state.crudObject,
        this.onSaveSuccess,
        this.props.appProps.handleServiceError
      )
    } else {
      Service.putRequest(
        this.props.widget.url,
        keyValue,
        this.state.crudObject,
        this.onSaveSuccess,
        this.props.appProps.handleServiceError
      )
    }
  }

  onClose = () => {
    this.setState({ showDialog: false })
  }

  showSuccessMessage = () => {
    if (this.state.action !== null) {
      if (this.state.action === Constant.Action.Delete) {
        this.props.appProps.showNotification(
          'Deleted successfully.',
          Constant.Notification.Type.Success
        )
      } else if (
        this.state.action === Constant.Action.Add ||
        this.state.action === Constant.Action.Edit
      ) {
        this.props.appProps.showNotification(
          'Saved successfully.',
          Constant.Notification.Type.Success
        )
      }
    }
    this.setState({ action: null })
  }

  onSaveSuccess = () => {
    this.showSuccessMessage()
    this.reloadGridData()
  }

  onDeleteSuccess = () => {
    this.showSuccessMessage()
    this.reloadGridData()
    this.props.appProps.hideAlert()
    this.props.appProps.hideLoader()
  }

  confirmDelete = () => {
    this.props.appProps.showLoader()
    let keyValue = Helper.getWidgetFieldValue(
      this.state.crudObject,
      '$.' + this.props.widget.keyField,
      false
    )
    if (Helper.isNullOrUndefined(keyValue) === true || keyValue === '0') {
      this.onDeleteSuccess()
    }
    Service.deleteRequest(
      this.props.widget.url,
      keyValue,
      this.onDeleteSuccess,
      this.props.appProps.handleServiceError
    )
  }

  deleteRow = (row) => {
    this.setState({ action: Constant.Action.Delete, crudObject: row })
    this.props.appProps.showAlert({
      title: 'Are you sure?',
      body: 'You will not be able to undo this action!',
      confirmBtnText: 'Yes, delete it!',
      onConfirm: this.confirmDelete,
    })
  }

  // concatenateColumns = (cell, row, rowIndex, extraData) => {
  concatenateColumns = (row, extraData) => {
    //console.log(JSON.stringify(extraData));
    let value = ''
    extraData.split('+').map((column) => {
      //console.log(column.startsWith("'"));
      if (column.startsWith("'") === true) {
        value = value + column.substring(1, column.length - 1)
      } else {
        value = value + Helper.getDataFromJsonPathQuery(row, '$.' + column)
      }
      return column
    })
    return value
  }

  dateFormatter = (cell, row) => {
    if (!cell) {
      return null
    }
    //return cell;
    cell = Date.create(cell.substring(0, 10)).format('{yyyy}-{MM}-{dd}')
    return Date.create(cell.substring(0, 10)).format('{yyyy}-{MM}-{dd}')
  }

  render() {
    let buildData = []
    let roles = Service.getRoles() + ''
    //Avoid Error when widget is NOT configured
    if (this.props.widget.url === undefined) {
      return (
        <div className="text-center">
          Widget configuration is incomplete. Please contact your Administrator
        </div>
      )
    }

    var isDataLoaded = false
    var columnDefinitions = []

    var data = []

    if (
      this.state.gridData &&
      this.state.gridData != null &&
      this.state.gridData.length > 0
    ) {
      data = this.state.gridData
      //Check if Data exists?
      if (data !== undefined && data[0] !== undefined) {
        //Get the COlumns Definitions
        let columns = Object.keys(data[0])
        let columnsToDisplay = []
        if (
          this.props.widget.columnsToDisplay !== undefined &&
          this.props.widget.columnsToDisplay !== ''
        ) {
          columnsToDisplay = this.props.widget.columnsToDisplay.split(',')
        } else {
          columnsToDisplay = columns
        }
        let columnNames = columnsToDisplay.clone()
        if (
          this.props.widget.columnNames &&
          this.props.widget.columnNames !== ''
        ) {
          this.props.widget.columnNames.split(',').forEach((item, index) => {
            columnNames[index] = item
          })
        }

        //var columns = Object.keys(data[0]);
        columnsToDisplay.forEach((column, index) => {
          const name = columnNames[index].toLowerCase().trim()
          if (column.indexOf('+') === -1) {
            columnDefinitions.push({
              text: columnNames[index],
              dataField: column,
              sort: true,
              style: { whiteSpace: 'nowrap' },
              filter: textFilter(
                this.props.name === 'Bin Capacity'
                  ? name.includes('status')
                    ? {
                        placeholder: 'Type to filter',
                        comparator: Comparator.EQ,
                      }
                    : { placeholder: 'Type to filter' }
                  : name === 'status'
                  ? {
                      placeholder: 'Type to filter',
                      comparator: Comparator.EQ,
                    }
                  : { placeholder: 'Type to filter' }
              ),
            })
          } else {
            buildData[buildData.length] = {
              columnName: columnNames[index],
              columnFormatter: column,
            }
            columnDefinitions.push({
              text: columnNames[index].toUpperCase(),
              dataField: columnNames[index],
              // formatter : this.concatenateColumns,
              // formatExtraData : column.split("+"),
              sort: true,
              style: { whiteSpace: 'nowrap' },
              filter: textFilter(
                this.props.name === 'Bin Capacity'
                  ? name.includes('status')
                    ? {
                        placeholder: 'Type to filter',
                        comparator: Comparator.EQ,
                      }
                    : { placeholder: 'Type to filter' }
                  : name === 'status'
                  ? {
                      placeholder: 'Type to filter',
                      comparator: Comparator.EQ,
                    }
                  : { placeholder: 'Type to filter' }
              ),
            })
          }
        })

        data.forEach((rowData, index) => {
          buildData.forEach((columnData, index) => {
            rowData[columnData.columnName] = this.concatenateColumns(
              rowData,
              columnData.columnFormatter
            )
          })
        })

        if (Helper.isNullOrEmpty(this.props.widget.columnTypes) === false) {
          let typeColumns = this.props.widget.columnTypes.split(',')

          typeColumns.forEach((column) => {
            let columnIndexTypeArray = column.split(':')
            if (columnIndexTypeArray[1] === 'date') {
              //columnDefinitions[columnIndexTypeArray[0]-1].type = columnIndexTypeArray[1];
              data.forEach((rowData, index) => {
                // console.log(rowData)
                rowData[
                  columnDefinitions[columnIndexTypeArray[0] - 1].dataField
                ] = this.dateFormatter(
                  rowData[
                    columnDefinitions[columnIndexTypeArray[0] - 1].dataField
                  ],
                  rowData
                )
              })
            }
          })
        }

        if (
          Helper.isNullOrEmpty(this.props.widget.columnNonEditable) === false
        ) {
          let nonEditableColumns =
            this.props.widget.columnNonEditable.split(',')

          nonEditableColumns.forEach((columnIndex) => {
            columnDefinitions[columnIndex - 1].editable = false
          })
        }

        // var paginationOptions = {
        //   paginationSize: 1,
        //   pageStartIndex: 1,
        //   // alwaysShowAllBtns: true, // Always show next and previous button
        //   // withFirstAndLast: false, // Hide the going to First and Last page button
        //   // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        //   hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        //   firstPageText: "First",
        //   prePageText: "Back",
        //   nextPageText: "Next",
        //   lastPageText: "Last",
        //   nextPageTitle: "First page",
        //   prePageTitle: "Pre page",
        //   firstPageTitle: "Next page",
        //   lastPageTitle: "Last page",
        //   sizePerPageList: [
        //     {
        //       text: "10",
        //       value: 10
        //     },
        //     {
        //       text: "All",
        //       value: data.length
        //     }
        //   ] // A numeric array is also available. the purpose of above example is custom the text
        // };
        isDataLoaded = true
      }

      let canEdit = false
      if (
        Helper.isNullOrUndefined(this.props.widget.allowEditFor) === false &&
        this.props.widget.allowEditFor.split(Constant.Comma).includes(roles) ===
          true
      ) {
        canEdit = true
      }
      let canDelete = false
      if (
        Helper.isNullOrUndefined(this.props.widget.allowDeleteFor) === false &&
        this.props.widget.allowDeleteFor
          .split(Constant.Comma)
          .includes(roles) === true
      ) {
        canDelete = true
      }

      if (canDelete === true || canEdit === true) {
        columnDefinitions.append(
          {
            text: 'Actions',
            dataField: 'editColumn',
            sort: false,
            formatExtraData: { canEdit: canEdit, canDelete: canDelete },
            formatter: (cell, row, rowIndex, formatExtraData) => {
              // console.log(JSON.stringify(cell));
              // console.log(JSON.stringify(row));
              // console.log(JSON.stringify(rowIndex));
              // console.log(JSON.stringify(formatExtraData));
              return (
                <div className="d-inline" style={{ whiteSpace: 'nowrap' }}>
                  {formatExtraData.canEdit === true && (
                    <a
                      href="javascript:void(0)"
                      onClick={() => this.editRow(cell, row, rowIndex)}
                      className="pr-1"
                    >
                      <i className="fa fa-edit" />
                    </a>
                  )}
                  {formatExtraData.canDelete === true && (
                    <a
                      href="javascript:void(0)"
                      onClick={() => this.deleteRow(row)}
                      className="text-danger pl-3"
                    >
                      <i className="fa fa-trash" />
                    </a>
                  )}
                </div>
              )
            },
          },
          0
        )
      }

      var keyField = this.props.widget.keyField
      if (
        (keyField === undefined || keyField === '') &&
        columnDefinitions &&
        columnDefinitions.length > 0 &&
        columnDefinitions[0] !== undefined
      ) {
        keyField = columnDefinitions[0].dataField
      }
    }
    return (
      <>
        {Helper.isNullOrUndefined(this.props.widget.allowAddFor) === false &&
          this.props.widget.allowAddFor
            .split(Constant.Comma)
            .includes(roles) === true && (
            <div>
              <a href="javascript:void(0)" onClick={this.addRow}>
                <i className="fa fa-plus-circle pl-2 py-2" /> Add
              </a>
            </div>
          )}
        {(this.state.action === Constant.Action.Add ||
          this.state.action === Constant.Action.Edit) && (
          <Dialog
            title={this.state.action + ' ' + this.props.widget.name}
            showModal={this.state.showDialog}
            dialogClassName={this.props.widget.dialogClassName}
            successButtonText="Save"
            closeButtonText="Close"
            onSuccessClick={this.onSave}
            onCloseClick={this.onClose}
          >
            <FieldRenderer
              panel={this.state.crudObject}
              appProps={this.props.appProps}
              data={this.props.data}
              fieldDefinitions={
                this.props.widget.formFieldsDefinitions &&
                Helper.ParseJson(this.props.widget.formFieldsDefinitions)
              }
            />
          </Dialog>
        )}
        {isDataLoaded && (
          <div className="px-2">
            <BootstrapTable
              bootstrap4={true}
              keyField={keyField}
              data={data}
              columns={columnDefinitions}
              bordered={true}
              filter={filterFactory()}
              //headerSortingStyle
              //pagination={paginationFactory(paginationOptions)}
              //hideSizePerPage = {false}
              noDataIndication="No Data"
              striped
              hover
              condensed
            />
          </div>
        )}
      </>
    )
  }
}

export default CRUDWidget;
