import React from 'react'
import { AiOutlineArrowRight } from "react-icons/ai"
import {
  Container,
  Row,
  Col,
  Media,
  FormControl,
  ListGroup,
  FormGroup,
} from 'react-bootstrap'

const ChatLanding = () => {
    return (
      <Container>
        <Row>
          <div>
            <h2>
              Welcome to the AI Assisted ChatBot. You can converse and ask
              questions on your Barn KPIs.
            </h2>
          </div>
          <div>
            <div className="landing">
              <div className="fw-bold">Egg Count</div>
              <div>
                <AiOutlineArrowRight /> What is the Egg count
              </div>
              <div>
                <AiOutlineArrowRight /> Top3 Flocknumbers by Lay Percent
              </div>
              <div className="fw-bold">Mortality Rate</div>
              <div>
                <AiOutlineArrowRight /> What are the worst 3 flocknumbers by
                Mortality rate
              </div>
              <div className="fw-bold">Feed Use</div>
              <div>
                <AiOutlineArrowRight /> Feed user site wise
              </div>
              <div className="fw-bold">Bin Summary </div>
              <div>
                <AiOutlineArrowRight /> What is the bin summary as on date
              </div>
            </div>
          </div>
        </Row>
      </Container>
    )
}

export default ChatLanding
