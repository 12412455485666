import React, { Component } from 'react';
import {
  Container, Row, Col, Tabs, Tab
} from 'react-bootstrap';
import DashHeader from "views/dashboard/DashHeader";
import Card from 'components/card/Card.jsx';
import Button from "components/controls/CustomButton.jsx";
import Service from "Service";
import Constant from "../../constants/Constant";
import FieldRenderer from "components/common/FieldRenderer";
import Helper from '../../Helper';
import Checkbox from 'components/controls/CustomCheckbox.jsx';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      userName: "",
      firstName: "",
      lastName: "",
      oldPassword: null,
      newPassword: null,
      reenteredNewPassword: null,
      oldPasswordMFA: null,
      MFAEnabled: "N",
      SecuQues1: null,
      SecuQues2: null,
      SecuAns1: null,
      SecuAns2: null,
      prevSecQues1: null,
      prevSecQues2: null
    };
  }
  componentDidMount() {
    setTimeout(
      function () {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
    this.getUserDetails();
  }

  getUserDetails = () => {

    let userId = sessionStorage.getItem("userid");
    let url = "{apiUrl}/user/" + userId + "?all=Y";
    Service.getRequest(
      url,
      res => {
        
        var response = res.body;
        this.setState({
          userName: response.UserName,
          firstName: response.FirstName,
          lastName: response.LastName
        });
        if (response.MFAEnabled === "Y") {
          this.setState({
            MFAEnabled: response.MFAEnabled,
            SecuQues1: response.SecuQues1,
            SecuQues2: response.SecuQues2,
            prevSecQues1: response.SecuQues1,
            prevSecQues2: response.SecuQues2,
            SecuAns1: "********",
            SecuAns2: "********"
          });
        }
      },
      this.props.appProps.handleServiceError
    );
  }

  passwordCaseValidator = (str) => {
    return /[a-z]/.test(str) && /[A-Z]/.test(str);
  }

  hasNumber = str => {
    return /\d/.test(str);
  }
  hasSPCharacters = str => {
    return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str);
  }

  hasWhiteSpace = (str) => {
    return /\s/.test(str);
  }
  handleSavePassword = values => {
    values.preventDefault();
    if (Helper.isNullOrEmpty(this.state.oldPassword) === true) {
      this.props.appProps.showNotification(
        "Current Password can't be empty.",
        Constant.Notification.Type.Error
      );
      return;
    }
    if (Helper.isNullOrEmpty(this.state.newPassword) === true) {
      this.props.appProps.showNotification(
        "NewPassword can't be empty.",
        Constant.Notification.Type.Error
      );
      return;
    }
    if (Helper.isNullOrEmpty(this.state.reenteredNewPassword) === true) {
      this.props.appProps.showNotification(
        "Re-enter Password can't be empty.",
        Constant.Notification.Type.Error
      );
      return;
    }
    if (this.state.newPassword !== this.state.reenteredNewPassword) {
      this.props.appProps.showNotification(
        "NewPassword & Re-enter Password are not the same.",
        Constant.Notification.Type.Error
      );
      return;
    }
    if (this.state.newPassword === this.state.oldPassword) {
      this.props.appProps.showNotification(
        "NewPassword & Current Password can't be the same.",
        Constant.Notification.Type.Error
      );
      return;
    }
    let newPwdLength = this.state.newPassword.length;
    if (newPwdLength < 8 || newPwdLength > 12) {
      this.props.appProps.showNotification(
        "NewPassword should be minimum 8 and maximum 12 characters in length.",
        Constant.Notification.Type.Error
      );
      return;
    }
    if (this.passwordCaseValidator(this.state.newPassword) === false) {
      this.props.appProps.showNotification(
        "NewPassword should have atleast one upper and lower case character.",
        Constant.Notification.Type.Error
      );
      return;
    }
    if (this.hasNumber(this.state.newPassword) === false) {
      this.props.appProps.showNotification(
        "NewPassword should have atleast one number.",
        Constant.Notification.Type.Error
      );
      return;
    }
    if (this.hasSPCharacters(this.state.newPassword) === false) {
      this.props.appProps.showNotification(
        "NewPassword should have atleast one special character.",
        Constant.Notification.Type.Error
      );
      return;
    }
    if (this.hasWhiteSpace(this.state.newPassword) === true) {
      this.props.appProps.showNotification(
        "NewPassword should not have space.",
        Constant.Notification.Type.Error
      );
      return;
    }


    var data = {};
    data.oldPassword = this.state.oldPassword;
    data.newPassword = this.state.newPassword;
    data.reenteredNewPassword = this.state.reenteredNewPassword;

    this.props.appProps.showLoader();
    //call the servicel
    Service.putRequest(
      "{apiUrl}/password?service=change", null,
      data,
      () => {
        this.props.appProps.showNotification(
          "Password is changed successfully.",
          Constant.Notification.Type.Success
        );
        this.props.appProps.hideLoader();
        this.onCancel();
      }, (error) => {
        this.props.appProps.handleServiceError(error, true)
      }
    );
  };

  handleSaveMFA = values => {
    values.preventDefault();
    console.log(this.state);
    if (Helper.isNullOrEmpty(this.state.oldPassword) === true) {
      this.props.appProps.showNotification(
        "Current Password can't be empty.",
        Constant.Notification.Type.Error
      );
      return;
    }
    if (this.state.MFAEnabled === "Y" && Helper.isNullOrEmpty(this.state.SecuQues1) === true) {
      this.props.appProps.showNotification(
        "Security questions can't be empty.",
        Constant.Notification.Type.Error
      );
      return;
    }
    if (this.state.MFAEnabled === "Y" && Helper.isNullOrEmpty(this.state.SecuAns1) === true) {
      this.props.appProps.showNotification(
        "Security answers can't be empty.",
        Constant.Notification.Type.Error
      );
      return;
    }
    if (this.state.MFAEnabled === "Y" && Helper.isNullOrEmpty(this.state.SecuQues2) === true) {
      this.props.appProps.showNotification(
        "Security questions can't be empty.",
        Constant.Notification.Type.Error
      );
      return;
    }
    if (this.state.MFAEnabled === "Y" && Helper.isNullOrEmpty(this.state.SecuAns2) === true) {
      this.props.appProps.showNotification(
        "Security answers can't be empty.",
        Constant.Notification.Type.Error
      );
      return;
    }
    if (this.state.MFAEnabled === "Y" && (this.state.SecuQues1 != this.state.prevSecQues1) && (this.state.SecuAns1 === "********")) {
      this.props.appProps.showNotification(
        "Enter a valid answer for security question 1.",
        Constant.Notification.Type.Error
      );
      return;
    }
    if (this.state.MFAEnabled === "Y" && (this.state.SecuQues2 != this.state.prevSecQues2) && (this.state.SecuAns2 === "********")) {
      this.props.appProps.showNotification(
        "Enter a valid answer for security question 2.",
        Constant.Notification.Type.Error
      );
      return;
    }
    if (this.state.MFAEnabled === "Y" && this.state.SecuQues1 === this.state.SecuQues2) {
      this.props.appProps.showNotification(
        "Both questions can't be the same.",
        Constant.Notification.Type.Error
      );
      return;
    }
    

    var data = {};
    data.oldPassword = this.state.oldPassword;
    data.MFAEnabled = this.state.MFAEnabled;
    data.SecuQues1 = this.state.SecuQues1;
    data.SecuAns1 = (this.state.SecuAns1 === "********") ? null : this.state.SecuAns1;
    data.SecuQues2 = this.state.SecuQues2;
    data.SecuAns2 = (this.state.SecuAns2 === "********") ? null : this.state.SecuAns2;

    this.props.appProps.showLoader();
    //call the servicel
    Service.putRequest(
      "{apiUrl}/password?service=change", null,
      data,
      () => {
        this.props.appProps.showNotification(
          "Multi-factor authentication updated successfully.",
          Constant.Notification.Type.Success
        );
        this.props.appProps.hideLoader();
        this.onCancel();
      }, (error) => {
        this.props.appProps.handleServiceError(error, true)
      }
    );
  }
  handleCheckbox = (event) => {

    this.setState({
      MFAEnabled: (this.state.MFAEnabled === "Y") ? "N" : "Y"
    });
  };
  onEditMFA = () => {
    
    this.setState({
      editMFA: true,
      editPassword: false,
      oldPassword: null
    });
    if (this.state.MFAEnabled === "Y") {
      this.setState({
        SecuAns1: "********",
        SecuAns2: "********",
        SecuQues1: this.state.prevSecQues1,
        SecuQues2: this.state.prevSecQues2
      });
    }
  };
  onEditpassword = () => {
    
    this.setState({
      editPassword: true,
      editMFA: false,
      oldPassword: null,
      newPassword: null,
      reenteredNewPassword: null
    });
  };
  onCancel = () => {
    this.setState({
      editPassword: false,
      editMFA: false
    })
  }
  resetAnswer = (field) => {
    console.log("Security Question Changed");
    console.log(this.state.SecuQues1);
    if(field.path === 'SecuQues1' && this.state.SecuQues1 != this.state.prevSecQues1){
      this.setState({
        SecuAns1: "",
        prevSecQues1: this.state.SecuQues1
      });
    }
    if(field.path === 'SecuQues2' && this.state.SecuQues2 != this.state.prevSecQues2){
      this.setState({
        SecuAns2: "",
        prevSecQues2: this.state.SecuQues2
      });
    }
  }
  
  render() {
    return (
      <div>
        <DashHeader
          name={"User Profile"}
        />
        <div className="main-content">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card
                  title="Edit Profile"
                  content={
                    <form onSubmit={this.handleClick}>
                      <Card
                        hidden={this.state.cardHidden}
                        textCenter
                        content={
                          <div className="p-4">
                            <b>User Details</b>
                            <Row>
                              <Col md={1}>
                                <label className="label-align-right"><b>User Id</b></label>
                              </Col>
                              <Col md={3}>
                                <label>{this.state.userName}</label>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={1}>
                                <label className="label-align-right"><b>First Name</b></label>
                              </Col>
                              <Col md={3}>
                                <label>{this.state.firstName}</label>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={1}>
                                <label className="label-align-right"><b>Last Name</b></label>
                              </Col>
                              <Col md={3}>
                                <label>{this.state.lastName}</label>
                              </Col>
                            </Row>
                            <div>
                              <b>Password</b><br></br>
                              <Button variant="link" onClick={this.onEditpassword}><i class="fa fa-edit"></i>Edit</Button>
                            </div>
                            {this.state.editPassword === true && <div>
                              <FieldRenderer
                                panel={this.state}
                                fieldDefinitions={{
                                  fields: [
                                    {
                                      fieldSet: "",
                                      name: "Current Password",
                                      path: "oldPassword",
                                      type: "string",
                                      controlType: "password",
                                      default: "",
                                      options: [],
                                      url: "",
                                      required: true,
                                      maxLength: null,
                                      minLength: null,
                                      labelClass: "label-align-right",
                                      labelWidth: "col-md-1",
                                      controlWidth: "col-md-4",
                                    },
                                    {
                                      "fieldSet": "",
                                      "controlType": "empty",
                                      "controlWidth": "col-md-12"
                                    },
                                    {
                                      fieldSet: "",
                                      name: "New Password",
                                      path: "newPassword",
                                      type: "string",
                                      controlType: "password",
                                      default: "",
                                      options: [],
                                      url: "",
                                      required: true,
                                      maxLength: null,
                                      minLength: null,
                                      labelClass: "label-align-right",
                                      desc: "<B>Password Policy</B><UL><LI>Minimum 8 characters</LI><LI>Maximum 12 characters</LI><LI>1 Uppercase</LI><LI>1 Lowercase</LI><LI>1 Number</LI><LI>1 Special Character</LI><LI>No Spaces</LI></UL>",
                                      labelWidth: "col-md-1",
                                      controlWidth: "col-md-4",
                                    },
                                    {
                                      "fieldSet": "",
                                      "controlType": "empty",
                                      "controlWidth": "col-md-12"
                                    },
                                    {
                                      fieldSet: "",
                                      name: "Re-enter Password",
                                      path: "reenteredNewPassword",
                                      type: "string",
                                      controlType: "password",
                                      default: "",
                                      options: [],
                                      url: "",
                                      required: true,
                                      maxLength: null,
                                      minLength: null,
                                      labelClass: "label-align-right",
                                      labelWidth: "col-md-1",
                                      controlWidth: "col-md-4",
                                    },
                                    {
                                      "fieldSet": "",
                                      "controlType": "empty",
                                      "controlWidth": "col-md-12"
                                    }
                                  ]
                                }}
                              />
                              <div>
                                <Button
                                  type="submit"
                                  onClick={this.handleSavePassword}
                                  variant="primary"
                                  fill
                                  wd
                                > Save </Button>
                                <Button variant="Button"
                                  onClick={() => this.onCancel()}>
                                  Cancel
                              </Button>
                              </div>
                            </div>}
                            <div>
                              <b>Multi-Factor Authentication</b><br></br>
                              <Button style={{ marginLeft: "0px" }} variant="link" onClick={this.onEditMFA}><i class="fa fa-edit"></i>Edit</Button>
                            </div>
                            {this.state.editMFA === true && <div>
                              <div>
                                <Checkbox number={1}
                                  checked={this.state.MFAEnabled === "Y" ? true : false}
                                  label="Enable Two-Factor Authentication"
                                  onClick={(event) => this.handleCheckbox(event)} />
                                {this.state.MFAEnabled === "N" && <div><Row>
                                  <Col md={12}>
                                    <FieldRenderer
                                      panel={this.state}
                                      fieldDefinitions={{
                                        fields: [
                                          {
                                            fieldSet: "",
                                            name: "Current Password",
                                            path: "oldPassword",
                                            type: "string",
                                            controlType: "password",
                                            default: "",
                                            options: [],
                                            url: "",
                                            required: true,
                                            maxLength: null,
                                            minLength: null,
                                            labelClass: "label-align-right",
                                            labelWidth: "col-md-1",
                                            controlWidth: "col-md-4",
                                          },
                                          {
                                            "fieldSet": "",
                                            "controlType": "empty",
                                            "controlWidth": "col-md-12"
                                          }]
                                      }} />
                                  </Col>
                                </Row>
                                </div>}
                              </div>
                              {this.state.MFAEnabled === "Y" && <div>
                                <Row>
                                  <Col md={12}>
                                    <FieldRenderer
                                      panel={this.state}
                                      appProps={this.props.appProps}
                                      fieldDefinitions={{
                                        fields: [
                                          {
                                            fieldSet: "",
                                            name: "Current Password",
                                            path: "oldPassword",
                                            type: "string",
                                            controlType: "password",
                                            default: "",
                                            options: [],
                                            url: "",
                                            required: true,
                                            maxLength: null,
                                            minLength: null,
                                            labelClass: "label-align-right",
                                            labelWidth: "col-md-1",
                                            controlWidth: "col-md-4",
                                          },
                                          {
                                            "fieldSet": "",
                                            "controlType": "empty",
                                            "controlWidth": "col-md-12"
                                          },
                                          {
                                            fieldSet: "",
                                            name: "Security Question 1",
                                            path: "SecuQues1",
                                            type: "string",
                                            controlType: "select",
                                            default: "",
                                            options: [],
                                            optionsUrlBuilder: [
                                              {
                                                property: "string",
                                                expression: "{apiUrl}/user-security-questions"
                                              }
                                            ],
                                            optionsDisplayField: "Question",
                                            optionsValueField: "ID",
                                            required: true,
                                            maxLength: null,
                                            minLength: null,
                                            labelClass: "label-align-right",
                                            labelWidth: "col-md-1",
                                            controlWidth: "col-md-4"
                                          },
                                          {
                                            "fieldSet": "",
                                            "controlType": "empty",
                                            "controlWidth": "col-md-12"
                                          },
                                          {
                                            fieldSet: "",
                                            name: "Answer",
                                            path: "SecuAns1",
                                            type: "string",
                                            controlType: "text",
                                            default: null,
                                            options: [],
                                            url: "",
                                            required: true,
                                            maxLength: null,
                                            minLength: null,
                                            labelClass: "label-align-right",
                                            labelWidth: "col-md-1",
                                            controlWidth: "col-md-4",
                                          },
                                          {
                                            "fieldSet": "",
                                            "controlType": "empty",
                                            "controlWidth": "col-md-12"
                                          },
                                          {
                                            fieldSet: "",
                                            name: "Security Question 2",
                                            path: "SecuQues2",
                                            type: "string",
                                            controlType: "select",
                                            default: "",
                                            options: [],
                                            optionsUrlBuilder: [
                                              {
                                                property: "string",
                                                expression: "{apiUrl}/user-security-questions"
                                              }
                                            ],
                                            optionsDisplayField: "Question",
                                            optionsValueField: "ID",
                                            required: true,
                                            maxLength: null,
                                            minLength: null,
                                            labelClass: "label-align-right",
                                            labelWidth: "col-md-1",
                                            controlWidth: "col-md-4"
                                          },
                                          {
                                            "fieldSet": "",
                                            "controlType": "empty",
                                            "controlWidth": "col-md-12"
                                          },
                                          {
                                            fieldSet: "",
                                            name: "Answer",
                                            path: "SecuAns2",
                                            type: "string",
                                            controlType: "text",
                                            default: null,
                                            options: [],
                                            url: "",
                                            required: true,
                                            maxLength: null,
                                            minLength: null,
                                            labelClass: "label-align-right",
                                            labelWidth: "col-md-1",
                                            controlWidth: "col-md-4",
                                          },
                                          {
                                            "fieldSet": "",
                                            "controlType": "empty",
                                            "controlWidth": "col-md-12"
                                          }
                                        ]
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </div>}
                              <Button
                                type="submit"
                                onClick={this.handleSaveMFA}
                                variant="primary"
                                fill
                                wd
                              >Save </Button>
                              <Button variant="Button"
                                onClick={() => this.onCancel()}>
                                Cancel
                              </Button>
                            </div>}
                          </div>
                        }
                        ftTextCenter
                      />
                    </form>
                  }
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>

    );
  }
}

export default ChangePassword;
