import React from "react";
import Form from "react-jsonschema-form";
import Service from 'Service';
class FormWidget extends React.Component {
  onSubmit = ({formData}) => {
      const dataValue = JSON.parse(sessionStorage.getItem('dsMap'));
      const ds = dataValue[this.props.dsName];
      Service.postRequest(ds.url, formData);
      //pass the datasource from the panel widget and do a post 
  }
  log = (type) => console.log.bind(console, type);
  render() {
    //Avoid Error when widget is NOT configured
    if (this.props.widget.form === undefined)
    {
        return <div className="text-center">Widget configuration is incomplete. Please contact your Administrator</div>
    }

    return (
        <div>
             <Form schema={this.props.widget.form}
                onSubmit={this.onSubmit}
                onError={this.log("errors")} />
        </div>    
    );
  }
}

export default FormWidget;