import React from "react";
import Helper from "Helper";
import StorageId from "constants/StorageId";
import Role from "constants/Role";
import PowerbiEmbedded from 'react-powerbi';
import { Container, Col, Row } from "react-bootstrap";
import $ from "jquery";
import Service from "../../Service";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import Constant from "constants/Constant";

var report = null;
var currentPageIndex = 0;
class PowerBIWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: undefined,
      token: undefined,
      currentPageIndex : 0
    };
  }
  
  componentWillReceiveProps(nextProps){
    if(nextProps.match.params.tenantId !== this.props.match.params.tenantId) {
      this.backToReport();
    }
  }

  showReport = report => {
    this.setState({ url: report.url });
    this.setState({ siteFilter: report.siteFilter });
    this.setState({ reportId: report.PbiReportId });
    $("#reports").slideToggle();
    $("#powerBIFrame").show();
    this.props.appProps.setAutoHeight();
  }
  openReport = report => {
    if (this.state.token === undefined) {
      //console.log("Getting New Token")
      Service.postRequest("{apiUrl}/pbitoken", null,
        (res) => this.setPowerBIToken(res, report),
        this.props.appProps.handleServiceError)
    }
    else {
      
      let currentTime = new Date().getTime();
      let expiredOn = this.state.token.expires_on *1000;
      //console.log("Expires On" + new Date(expiredOn))
      let refreshToken = this.state.token.refresh_token;
      // time until token refresh in milliseconds
      let safetyInterval = 120000; // 2 Minutes
      let timeout = expiredOn - currentTime - safetyInterval;
      // if token already expired, generate new token and set the access token
      if (timeout <= 0) {
        //console.log("Refreshing the Token" + timeout)
        Service.postRequest("{apiUrl}/pbitoken", {refreshToken:refreshToken},
        (res) => this.setPowerBIToken(res, report),
        this.props.appProps.handleServiceError)
      }
      else{
        this.showReport(report);
      }
    }
  };

  setPowerBIToken = (res, report) => {
    sessionStorage.setItem(StorageId.Session.PowerBIToken, res.body.token);
    this.setState({ token: res.body.token });
    this.showReport(report);
  }

  backToReport = () => {
    $("#reports").show();
    $("#powerBIFrame").hide();
    this.setState({ url: undefined });
  };

  onEmbedded = (embed, thisComponent) => {
    report = embed;
    report.on("pageChanged", () => {
      report.getPages().then(pages => {
        if (thisComponent.state.pages === undefined) {
          thisComponent.setState({ pages: pages });
        }
      })
      // if (this.state.print === true){
      //   report.print();
      //   if (this.state.currentPageIndex <= this.state.totalPages-1){
      //     //report.setPage(this.state.pages[this.state.currentPageIndex+1].name);
      //   }
      //   else{
      //     this.setState({ print: true});
      //   }
      // }

    })
  }

  setFullScreen = () => {
    report.fullscreen();
  }
  printReport = () => {
    report.print();
  }

  printNextPageInReport = () => {
    if(report === null || report === undefined) return;
    
    report.getPages().then(pages => {
      if (currentPageIndex < pages.length-1){
        currentPageIndex = currentPageIndex + 1;
      }
      pages[currentPageIndex].setActive();
      report.print();
      if (window.matchMedia) {
        var mediaQueryList = window.matchMedia('print');
        mediaQueryList.addListener(function(mql) {
            if (mql.matches) {
                
            } else {
                this.printNextPageInReport();
            }
        });
    }
    });
  }

  embedAll = () => {
    // let embeds = [];
    // let pages = this.state.pages;
    // pages.map(page => {
    //   report.setPage(page.name);
    //   report.printReport();
    //   if (embeds.length == pages.length - 1) {
    //     embeds.push(<PowerbiEmbedded key={page.name}
    //       id={this.state.reportId}
    //       embedUrl={this.state.url + '&reportId=' + this.state.reportId}
    //       accessToken={this.state.token.access_token}
    //       filterPaneEnabled={false}
    //       navContentPaneEnabled={true}
    //       pageName={page.name}
    //       height='209.75mm'
    //       permissions={7}
    //       tokenType={0}
    //     //onEmbedded={(embed) => { this.onLoadedControl(embed, this) }}
    //     ></PowerbiEmbedded>);
    //   } else {
    //     embeds.push(<PowerbiEmbedded key={page.name}
    //       id={this.state.reportId}
    //       embedUrl={this.state.url + '&reportId=' + this.state.reportId}
    //       accessToken={this.state.token.access_token}
    //       filterPaneEnabled={false}
    //       navContentPaneEnabled={true}
    //       pageName={page.name}
    //       height='209.75mm'
    //       permissions={7}
    //       tokenType={0}
    //       onEmbedded={(embed) => { this.onEmbedded(embed, this) }}
    //     ></PowerbiEmbedded>);
    //   }

    // })
    // return embeds;
  }

  embed() {
    // console.log(this.state.reportId)
    // console.log(this.state.token.access_token)
    let embedUrl = Constant.PowerBIBaseUrl + this.state.reportId;
    if((Service.isInRole(Role.SiteAdmin) || Service.isInRole(Role.SiteUser)) &&
     (this.state.siteFilter != null && this.state.siteFilter != undefined &&
       this.state.siteFilter.length > 0) && this.props.match.params.tenantId != undefined &&
       this.props.match.params.siteId != undefined) {
        console.log("******************************");
        var tenantId = this.props.match.params.tenantId;
        var SiteId = this.props.match.params.siteId;
        var tenants = JSON.parse(sessionStorage.getItem(StorageId.Session.Tenants));
      
        var tenant = tenants.filter(function (tenant){
          return tenant.id == tenantId;
        });
        if(tenant != null) {
          var site = tenant[0].sites.filter(function (site){
            return site.id == SiteId;
          });
        }
        
        var filter = this.state.siteFilter;
        if(site != null) {
          var find = '<site>';
          var re = new RegExp(find, 'g');
          filter = filter.replace(re, site[0].name);
        }       
        filter = encodeURI(filter);
        filter = filter.replace("filter%3D", "filter=");
        embedUrl += '&' + filter;
    }
    console.log("EmbedUrl :" + embedUrl);
    return (<PowerbiEmbedded
      id={this.state.reportId}
      embedUrl={embedUrl}
      accessToken={this.state.token.access_token}
      filterPaneEnabled={false}
      navContentPaneEnabled={true}
      height="700px"
      width="100%"
      onEmbedded={(embed) => { this.onEmbedded(embed, this) }}
    ></PowerbiEmbedded>)
  }

  render() {
    // <Report
    //   id={this.props.widget.id}
    //   embedUrl={this.props.widget.url}
    //   accessToken={this.props.widget.accessToken}
    //   filterPaneEnabled={true}
    //   navContentPaneEnabled={true}
    //   onEmbedded={this.onEmbedded}
    // />
    var data = Helper.getWidgetFieldValue(
      this.props.data,
      this.props.panel.datasources[0].path,
      true
    );

    //Avoid Error when widget is NOT configured
    if (data === undefined || data === null || Array.isArray(data) === false) {
      return null; //<div className="text-center">No data. Please contact your Administrator</div>
    }

    return (
      <div className="h-powerBI">
        <Container fluid className="h-100">
          <Row id="reports" className="h-100">
            <Col md={12} className="h-100">
              <div className="flex-grid">
                {data.map((report, key) => {
                  return (
                    <div className="flex-report-col text-left" key={key}>
                      <a
                        href="javascript:void(0)"
                        onClick={() => this.openReport(report)}
                        className="text-primary"
                      >

                        <i className="fa fa-bar-chart fa-square-bg"></i> <span className="pl-3">{report.name}</span>
                      </a>
                      <div>{report.description}</div>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
          {this.state.token && (
            <Row className="h-100" id="powerBIFrame">
              <Col md={12} className="h-100">
                <div className="h-100">
                  <div className="row power-bi-tool-bar">
                    <div className="col-md-3 float-left">
                      <a href="javascript:void(0)" onClick={this.backToReport}>
                        <i className="fa fa-arrow-left"></i> Go Back To Reports
                    </a>
                    </div>
                    <div className="col-md-9 text-right">
                    <a href="javascript:void(0)" onClick={this.printNextPageInReport}><i className="fa fa-print"></i> Print Next Page</a>
                      &nbsp;&nbsp;&nbsp;&nbsp;

                      <a href="javascript:void(0)" onClick={this.printReport}><i className="fa fa-print"></i> Print</a>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <a href="javascript:void(0)" onClick={this.setFullScreen} className="hidden-xs"><i className="fa fa-external-link"></i> Full Screen</a>&nbsp;&nbsp;
                    </div>
                  </div>
                  <Print single >
                    <div className="row">
                      <div className="col-md-12">
                        {this.embed()}
                      </div>
                    </div>
                  </Print>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    );
  }
}

export default PowerBIWidget;
