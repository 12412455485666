// @flow
import React, { useEffect } from "react";
import Markdown from "react-markdown";
import breaks from "remark-breaks";
import { formatDistance } from "date-fns";
import classnames from "classnames";
import type { ChatMessage } from "./Chatroom";
import { noop } from "./utils";
import GridWidget from 'components/widgets/GridWidget';
import Helper from "Helper";

type MessageTimeProps = {
  time: number,
  isBot: boolean
};
export const MessageTime = ({ time, isBot }: MessageTimeProps) => {
  if (time === 0) return null;

  const messageTime = Math.min(Date.now(), time);
  const messageTimeObj = new Date(messageTime);
  return (
    <li
      className={classnames("time", isBot ? "left" : "right")}
      title={messageTimeObj.toISOString()}
    >
      {formatDistance(messageTimeObj, Date.now())}
    </li>
  );
};

type MessageProps = {
  chat: ChatMessage,
  onButtonClick?: (title: string, payload: string) => void,
  voiceLang?: ?string
};

const supportSpeechSynthesis = () => "SpeechSynthesisUtterance" in window;

const speak = (message: string, voiceLang: string) => {
  const synth = window.speechSynthesis;
  let voices = [];
  voices = synth.getVoices();
  const toSpeak = new SpeechSynthesisUtterance(message);
  toSpeak.voice = voices.find(voice => voice.lang === voiceLang);
  synth.speak(toSpeak);
};

const Message = ({ chat, onButtonClick, voiceLang = null }: MessageProps) => {
  const message = chat.message;
  const isBot = chat.username === "bot";

  useEffect(() => {
    if (
      isBot &&
      voiceLang != null &&
      message.type === "text" &&
      supportSpeechSynthesis()
    ) {
      speak(message.text, voiceLang);
    }
  }, []);

  switch (message.type) {
    case "button":
      return (
        <ul className="chat-buttons">
          {message.buttons.map(({ payload, title, selected }) => (
            <li
              className={classnames("chat-button", {
                "chat-button-selected": selected,
                "chat-button-disabled": !onButtonClick
              })}
              key={payload}
              onClick={
                onButtonClick != null
                  ? () => onButtonClick(title, payload)
                  : noop
              }
            >
              <Markdown
                source={title}
                skipHtml={false}
                allowedTypses={["root", "break"]}
                renderers={{
                  paragraph: ({ children }) => <span>{children}</span>
                }}
                plugins={[breaks]}
              />
            </li>
          ))}
        </ul>
      );

    case "image":
      return (
        <li className={`chat ${isBot ? "left" : "right"} chat-img`}>
          <img src={message.image} alt="" />
        </li>
      );
    case "text":
      var parsedJsonData = Helper.ParseJson(message.text, null);
      //console.log("Message:" + parsedJsonData)
      if(parsedJsonData != null){
        var gridData = Helper.ParseJson(parsedJsonData.detail, null);
        if (gridData != null)
        {
            return ( <li className={classnames("chat", isBot ? "left" : "right")}>
                        <strong className="pl-2"> {parsedJsonData.msg}</strong>
                          <GridWidget
                            data={gridData}
                            widget = { {"rows": "$"} }/>
                      </li>)
        }
        else{
          return ( <li className={classnames("chat", isBot ? "left" : "right")}>
                      <strong className="pl-2"> {parsedJsonData.msg}</strong>
                      <span className="pl-2">No Data</span>  
                    </li>)
        }
      }
      else {
        let updated = message.text.replace('Barn 3 6 5', 'Barn365')
        return (
          <li className={classnames('chat', isBot ? 'left' : 'right')}>
            <Markdown
              className="text"
              source={updated}
              skipHtml={false}
              allowedTypses={[
                'root',
                'break',
                'paragraph',
                'emphasis',
                'strong',
                'link',
                'list',
                'listItem',
                'image',
              ]}
              renderers={{
                paragraph: ({ children }) => <span>{children}</span>,
                link: ({ href, children }) => (
                  <a href={href} target="_blank">
                    {children}
                  </a>
                ),
              }}
              plugins={[breaks]}
            />
          </li>
        )
      }
    default:
      return null;
  }
};

export default Message;
