import React, { Component } from "react";
import StorageId from "constants/StorageId";
import { Link } from "react-router-dom";
import {
  OverlayTrigger,
  Tooltip,
  ButtonGroup,
  Pagination,
  Grid,
  Row,
  Col
} from "react-bootstrap";

export class TenantWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { tenants: null };
  }

  componentDidMount = () => {
    let tenants = JSON.parse(sessionStorage.getItem(StorageId.Session.Tenants));
    //Avoid Error when widget is NOT configured
    if (
      tenants !== undefined &&
      tenants !== null &&
      Array.isArray(tenants) === true
    ) {
      tenants = tenants.sortBy(t => String(t.name));
    }
    this.setState({ tenants: tenants });
  };

  render() {
    return (
      this.state.tenants && (
        <div>
          <div className="flex-grid">
            {this.state.tenants.map((tenant, key) => {
              let tenantUrl = "/Controls/" + tenant.id;
              return (
                <div className="flex-col-4 site" key={key}>
                  <Link to={tenantUrl}>
                    <div>
                      <i className="icon-custom icon-home-tenant fa-square-bg"/> <span className="text-primary pl-3">{tenant.name}</span>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      )
    );
  }
}

export default TenantWidget;
