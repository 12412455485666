import React from "react"
import Helper from 'Helper';
class HtmlWidget extends React.Component {
  createMarkup = () => {
    //var html = JsonPath.query(this.props.data, this.props.widget.html).pop();
    return { __html: "" };
  }
  render() {
    var html = Helper.getDataFromJsonPathQueryNoPop(this.props.data, this.props.widget.html);
    return (
      
        <div dangerouslySetInnerHTML={{ __html: html }} />
      
    );
  }
}

export default HtmlWidget;
