import Pages from "containers/pages/Pages.jsx";
import Dash from "containers/dash/Dash.jsx";

var appRoutes = [
  { path: "/Pages/Login", name: "Pages", component: Pages },
  { path: "/Pages/ForgotPassword", name: "Pages", component: Pages },
  { path: "/pages/register-page", name: "Pages", component: Pages },
  { path: "/pages/lock-screen-page", name: "Pages", component: Pages },
  { path: "/Pages/Logout", name: "Pages", component: Pages },
  { path: "/", name: "Home", component: Dash }
];

export default appRoutes;
