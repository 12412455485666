import React from 'react'
import { Tabs, Tab, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
} from 'recharts'
import GridWidget from 'components/widgets/GridWidget'

const ResultChart = ({ data }) => {
  const { text, chartX, chartY } = data
  const displayData = text.map((item) => {
    delete item['ID']
    return item
  })
  return (
    <div class="col-sm-12 spacer">
      <div class="trend-section">
        <div class="trend-section-header">{chartY}</div>
        <div class="trend-section-body">
          <Tabs>
            <Tab
              eventKey={'LineChart'}
              title={<i className={'fa fa-line-chart text-primary'}></i>}
            >
              <ResponsiveContainer height={500} width={750}>
                <BarChart
                  data={displayData}
                  margin={{ top: 10, right: 20, left: 20, bottom: 10 }}
                >
                  <XAxis name="Date" dataKey={chartX} />
                  <YAxis domain={['auto', 'auto']} />
                  <CartesianGrid strokeDasharray="3 3" />
                  {/* <Tooltip content={<CustomTooltip />} /> */}
                  <Legend />
                  <Bar
                    key="1"
                    name={chartY}
                    dataKey={chartY}
                    fill="Purple"
                    maxBarSize={15}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Tab>
            <Tab
              eventKey={'Grid'}
              title={<i className={'fa fa-table text-primary'}></i>}
              width={700}
              height={450}
            >
              <ResponsiveContainer height={450} width={700}>
                {text && (
                  <GridWidget data={displayData} widget={{ rows: '$' }} />
                )}
              </ResponsiveContainer>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default ResultChart
