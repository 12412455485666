import React from "react";
import {Button, Row, Col, Tabs, Tab,  ToggleButtonGroup, ToggleButton} from "react-bootstrap";
import {ResponsiveContainer, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line } from "recharts";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Service from "Service";
import Constant from "../../constants/Constant";

const CustomTooltip = ({ active, payload, label }) => {
    
    if(payload && payload.length > 0) {
        //console.log(payload);
        const items = []
        items.push(<div>{(payload.length == 3) ? "Week" : "Day" } : {payload[0].payload.interval}</div>)                
        for (const [index, value] of payload.entries()) {
            if(payload.length == 3) {
                switch(value.name) {
                    case 'Predicted': {
                        if(value.dataKey == 'pred_mortalitycount') {
                            items.push(<div style={{"color":value.color}}> {value.name} : {value.value + "%"} ({value.payload.pred_mortalitycount_qty})</div>)
                        } else if(value.dataKey == 'pred_eggcount') {
                            items.push(<div style={{"color":value.color}}> {value.name} : {value.value + "%"} ({value.payload.pred_eggcount_qty})</div>)
                        } else if(value.dataKey == 'pred_feeduse') {
                            items.push(<div style={{"color":value.color}}> {value.name} : {value.value + "%"} ({value.payload.pred_feeduse_qty})</div>)
                        }
                        break;
                    }
                    case 'Actual': {
                        if(value.dataKey == 'mortalitycount') {
                            items.push(<div style={{"color":value.color}}> {value.name} : {value.value + "%"} ({value.payload.mortalitycount_qty})</div>)
                        } else if(value.dataKey == 'eggcount') {
                            items.push(<div style={{"color":value.color}}> {value.name} : {value.value + "%"} ({value.payload.eggcount_qty})</div>)
                        } else if(value.dataKey == 'feeduse') {
                            items.push(<div style={{"color":value.color}}> {value.name} : {value.value + "%"} ({value.payload.feeduse_qty})</div>)
                        }
                        break;
                    }
                    default: {
                        items.push(<div style={{"color":value.color}}> {value.name} : {value.value + "%"}</div>)
                        break;
                    }
                }
            } else {
                items.push(<div style={{"color":value.color}}> {value.name} : {value.value}</div>)
            }
        }
        
        return (
            <div className="recharts-default-tooltip" >
                {/* <div className="recharts-tooltip-label">Test label</div> */}
                <div className="recharts-tooltip-item-list">
                    {items}
                </div>
            </div>
        );
    } else {
        return '';
    }
};

class AIWidgetGrids extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            aiObject: {}
        };
    };

    componentDidMount = () => {
        this.setState({
            isDataLoaded: false,
            selectedFrequency: "weekly",
            xKey: "interval", 
            xName: "interval"
        });
        this.props.setAIData(this.getAIData);
    }

    prepareColumnDefinition = () => {
        var interval = 'interval';
        var mortalityCountCD = [{text: interval, dataField: interval, sort: true, filter: textFilter({placeholder: "Type to filter"})},
                                {text:"Predicted", dataField: "pred_mortalitycount", Sort: true, filter: textFilter({placeholder: "Type to filter"})},
                                {text:"Actual", dataField: "mortalitycount", Sort: true, filter: textFilter({placeholder: "Type to filter"})}];
        var eggCountCD = [{text: interval, dataField: interval, sort: true, filter: textFilter({placeholder: "Type to filter"})},
                          {text:"Predicted", dataField: "pred_eggcount", Sort: true, filter: textFilter({placeholder: "Type to filter"})},
                          {text:"Actual", dataField: "eggcount", Sort: true, filter: textFilter({placeholder: "Type to filter"})}];
        var feedUseCD = [{text: interval, dataField: interval, sort: true, filter: textFilter({placeholder: "Type to filter"})},
                         {text:"Predicted", dataField: "pred_feeduse", Sort: true, filter: textFilter({placeholder: "Type to filter"})},
                         {text:"Actual", dataField: "feeduse", Sort: true, filter: textFilter({placeholder: "Type to filter"})}];                         
        var populationCD = [{text: interval, dataField: interval, sort: true, filter: textFilter({placeholder: "Type to filter"})},
                            {text:"Actual", dataField: "currentpopulation", Sort: true, filter: textFilter({placeholder: "Type to filter"})}];
        if(this.state.selectedFrequency == "weekly") {
            mortalityCountCD.splice(2, 0,{text:"Predicted Qty", dataField: "pred_mortalitycount_qty", Sort: true, filter: textFilter({placeholder: "Type to filter"})});
            mortalityCountCD.splice(4, 0,{text:"Actual Qty", dataField: "mortalitycount_qty", Sort: true, filter: textFilter({placeholder: "Type to filter"})});
            mortalityCountCD.splice(5, 0,{text:"Standard", dataField: "std_mortalitycount", Sort: true, filter: textFilter({placeholder: "Type to filter"})});    
            eggCountCD.splice(2, 0, {text:"Predicted Qty", dataField: "pred_eggcount_qty", Sort: true, filter: textFilter({placeholder: "Type to filter"})})
            eggCountCD.splice(4, 0, {text:"Actual Qty", dataField: "eggcount_qty", Sort: true, filter: textFilter({placeholder: "Type to filter"})});
            eggCountCD.splice(5, 0, {text:"Standard", dataField: "std_eggcount", Sort: true, filter: textFilter({placeholder: "Type to filter"})});
            feedUseCD.splice(2, 0, {text:"Predicted Qty", dataField: "pred_feeduse_qty", Sort: true, filter: textFilter({placeholder: "Type to filter"})});
            feedUseCD.splice(4, 0,  {text:"Actual Qty", dataField: "feeduse_qty", Sort: true, filter: textFilter({placeholder: "Type to filter"})});
            feedUseCD.splice(5, 0,  {text:"Standard", dataField: "std_feeduse", Sort: true, filter: textFilter({placeholder: "Type to filter"})});
        }

        this.state.mortalityCountCD = mortalityCountCD;
        this.state.eggCountCD= eggCountCD;
        this.state.feedUseCD=feedUseCD;
        this.state.populationCD= populationCD;
    }

    displayHeaderInfo = () => {
        console.log(this.props);
        let data = this.props.data;
        let breedInfo = (data.aiObject.breed) ? "Breed " + data.aiObject.breed : "";
        let flockInfo = (data.aiObject.flock) ? "Flock Number " + data.aiObject.flock : "";
        let headerInfo = data.aiObject.flock == 'All' ? "(" + breedInfo + ")" : "(" + flockInfo + ")";
        return (
            <span>{headerInfo}</span>
        );
    }

    getPredictionDataResponse = response => { 
        
        setTimeout(() => {
            this.props.appProps.hideLoader();
            this.setState({ 
                prediction: response.body,
                isDataLoaded: true,
                 showDialog: false})
        }, 500);
    }

    changeFrequency = (e, frequency) => {
        this.setState({selectedFrequency: frequency});
        
        setTimeout(() => {
            this.getAIData();
          }, 100);
    };

    getAIData = () => {
        console.log(this.props);
        let data = this.props.data;
        
        var errormsg = "";
        if(!data.aiObject.breed) { errormsg = errormsg + "Select Breed, ";}
        if(!data.aiObject.site) { errormsg = errormsg + "Select Site, ";}
        if(!data.aiObject.house) { errormsg = errormsg + "Select House, ";}
        if(!data.aiObject.flock) { errormsg = errormsg + "Select Flock, ";}
        if(!data.aiObject.group) { errormsg = errormsg + "Select Group, ";}
        if(!data.aiObject.status) { errormsg = errormsg + "Select Status ";}

        if(errormsg.length > 0) {
            this.props.appProps.showNotification(
                errormsg,
                Constant.Notification.Type.Error
            );
            return;
        }
        
        this.props.appProps.showLoader();
        this.prepareColumnDefinition();
        if(data.aiObject.flock != undefined) {
            let predictionUrl = '{apiUrl}/ai/prediction?tName='+ data.aiObject.tenantName +'&group=breed&breeds={breed}&sites={site}&houses={house}&flocknumbers={flocknumber}&interval={interval}&active={active}';
            predictionUrl = predictionUrl.replace("{group}", data.aiObject.group);
            if(data.aiObject.breed != "All") {
                predictionUrl = predictionUrl.replace("{breed}", data.aiObject.breed);
            } else {
                predictionUrl = predictionUrl.replace("&breeds={breed}", "");
            }
            if(data.aiObject.breed != "All") {
                predictionUrl = predictionUrl.replace("{site}", data.aiObject.site);
            } else {
                predictionUrl = predictionUrl.replace("&sites={site}", "");
            }
            if(data.aiObject.house != "All") {
                predictionUrl = predictionUrl.replace("{house}", data.aiObject.house);
            } else {
                predictionUrl = predictionUrl.replace("&houses={house}", "");
            }
            if(data.aiObject.flock != "All") {
                predictionUrl = predictionUrl.replace("{flocknumber}", data.aiObject.flock);
            } else {
                predictionUrl = predictionUrl.replace("&flocknumbers={flocknumber}", "");
            }

            if(data.aiObject.status != "All") {
                predictionUrl = predictionUrl.replace("{active}", data.aiObject.status);
            } else {
                predictionUrl = predictionUrl.replace("&active={active}", "");
            }

            predictionUrl = predictionUrl.replace("{interval}", this.state.selectedFrequency);

            Service.getRequest(
                predictionUrl,
                this.getPredictionDataResponse,
                this.props.appProps.handleServiceError
              );
        }
    };

  render() {
    
    let frequencys = [
        { description: "weekly", code: "W", tooltip: "Last 7 Days" },
        { description: "daily", code: "D", tooltip: "Last 24 hours" }
      ];
    
    return (
      <>
        <div className="text-right pr-3 frequency-btn">
            <ToggleButtonGroup type="checkbox" value={this.state.selectedFrequency}>
                {frequencys.map((item, index) => {
                return (
                        <ToggleButton key={"aiFrequencyButton_" + index}  variant="primary" value={item.description} onChange={(e) => this.changeFrequency(e, item.description)}>{item.code}</ToggleButton>
                    );
                })}
            </ToggleButtonGroup>
        </div>
        <div className="ai-class">
            <div className="panel-headers">Mortality Count : {this.displayHeaderInfo()}</div>
            <Tabs>
                <Tab eventKey={"LineChart"} title={<i className={"fa fa-line-chart text-primary"}></i>}>
                <ResponsiveContainer height={200}> 
                    <LineChart data={this.state.prediction}
                    margin={{ top: 10, right: 20, left: 20, bottom: 10 }}>
                        <XAxis name={this.state.xName} dataKey={this.state.xKey}/>
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip content={<CustomTooltip />}/>
                        <Legend />
                        <Line type="monotone" name="Predicted" dataKey="pred_mortalitycount" stroke="Red" dot={false} activeDot={{ r: 6 }} />
                        <Line type="monotone" name="Actual" dataKey="mortalitycount" stroke="Green" dot={false} activeDot={{ r: 6 }} />
                        {this.state.selectedFrequency == 'weekly' && (
                            <Line type="monotone" name="Standard" dataKey="std_mortalitycount" stroke="blue" strokeDasharray="5 5" dot={false} activeDot={{ r: 6 }} />
                        )}
                    </LineChart>
                </ResponsiveContainer>
                </Tab>
                <Tab eventKey={"Grid"} title={<i className={"fa fa-table text-primary"}></i>} width={1500} height={600}>
                    {this.state.isDataLoaded && (
                        <div className="ai-table">
                        <BootstrapTable
                            bootstrap4 = {true}
                            keyField={this.state.xKey}
                            data={this.state.prediction}
                            columns={this.state.mortalityCountCD}
                            bordered={false}
                            filter={filterFactory()}
                            //headerSortingStyle
                            //pagination={paginationFactory(paginationOptions)}
                            //hideSizePerPage = {false}
                            noDataIndication="No Data"
                            hover
                            condensed
                        />
                        </div>
                    )}
                    
                </Tab>
            </Tabs>
            <div className="panel-headers">Egg Count : {this.displayHeaderInfo()}</div>
            <Tabs>
                <Tab eventKey={"LineChart"} title={<i className={"fa fa-line-chart text-primary"}></i>}>
                <ResponsiveContainer height={200}> 
                    <LineChart data={this.state.prediction}
                    margin={{ top: 10, right: 20, left: 20, bottom: 10 }}>
                        <XAxis name={this.state.xName} dataKey={this.state.xKey}/>
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip content={<CustomTooltip />}/>
                        <Legend />
                        <Line type="monotone" name="Predicted" dataKey="pred_eggcount" stroke="Red" dot={false} activeDot={{ r: 6 }} />
                        <Line type="monotone" name="Actual" dataKey="eggcount" stroke="Green" dot={false} activeDot={{ r: 6 }} />
                        {this.state.selectedFrequency == 'weekly' && (
                            <Line type="monotone" name="Standard" dataKey="std_eggcount" stroke="blue" strokeDasharray="5 5" dot={false} activeDot={{ r: 6 }} />
                        )}
                    </LineChart>
                </ResponsiveContainer>
                </Tab>
                <Tab eventKey={"Grid"} title={<i className={"fa fa-table text-primary"}></i>} width={1500} height={600}>
                    {this.state.isDataLoaded && (
                        <div className="ai-table">
                        <BootstrapTable
                            bootstrap4 = {true}
                            keyField={this.state.xKey}
                            data={this.state.prediction}
                            columns={this.state.eggCountCD}
                            bordered={false}
                            filter={filterFactory()}
                            //headerSortingStyle
                            //pagination={paginationFactory(paginationOptions)}
                            //hideSizePerPage = {false}
                            noDataIndication="No Data"
                            hover
                            condensed
                        />
                        </div>
                    )}
                    
                </Tab>
            </Tabs>
            <div className="panel-headers">Feed Use : {this.displayHeaderInfo()}</div>
            <Tabs>
                <Tab eventKey={"LineChart"} title={<i className={"fa fa-line-chart text-primary"}></i>}>
                <ResponsiveContainer height={200}> 
                    <LineChart data={this.state.prediction}
                    margin={{ top: 10, right: 20, left: 20, bottom: 10 }}>
                        <XAxis name={this.state.xName} dataKey={this.state.xKey}/>
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip content={<CustomTooltip />}/>
                        <Legend />
                        <Line type="monotone" name="Predicted" dataKey="pred_feeduse" stroke="Red" dot={false} activeDot={{ r: 6 }} />
                        <Line type="monotone" name="Actual" dataKey="feeduse" stroke="Green" dot={false} activeDot={{ r: 6 }} />
                        {this.state.selectedFrequency == 'weekly' && (
                            <Line type="monotone" name="Standard" dataKey="std_feeduse" stroke="blue" strokeDasharray="5 5" dot={false} activeDot={{ r: 6 }} />
                        )}
                    </LineChart>
                </ResponsiveContainer>
                </Tab>
                <Tab eventKey={"Grid"} title={<i className={"fa fa-table text-primary"}></i>} width={1500} height={600}>
                    {this.state.isDataLoaded && (
                        <div className="ai-table">
                        <BootstrapTable
                            bootstrap4 = {true}
                            keyField={this.state.xKey}
                            data={this.state.prediction}
                            columns={this.state.feedUseCD}
                            bordered={false}
                            filter={filterFactory()}
                            //headerSortingStyle
                            //pagination={paginationFactory(paginationOptions)}
                            //hideSizePerPage = {false}
                            noDataIndication="No Data"
                            hover
                            condensed
                        />
                        </div>
                    )}
                    
                </Tab>
            </Tabs>
            <div className="panel-headers">Population : {this.displayHeaderInfo()}</div>
            <Tabs>
                <Tab eventKey={"LineChart"} title={<i className={"fa fa-line-chart text-primary"}></i>}>
                <ResponsiveContainer height={200}> 
                    <LineChart data={this.state.prediction}
                    margin={{ top: 10, right: 20, left: 20, bottom: 10 }}>
                        <XAxis name={this.state.xName} dataKey={this.state.xKey}/>
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip isAnimationActive={false}/>
                        <Legend />
                        <Line type="monotone" name="Actual" dataKey="currentpopulation" stroke="Green" dot={false} activeDot={{ r: 6 }} />
                    </LineChart>
                </ResponsiveContainer>
                </Tab>
                <Tab eventKey={"Grid"} title={<i className={"fa fa-table text-primary"}></i>} width={1500} height={600}>
                    {this.state.isDataLoaded && (
                        <div className="ai-table">
                        <BootstrapTable
                            bootstrap4 = {true}
                            keyField={this.state.xKey}
                            data={this.state.prediction}
                            columns={this.state.populationCD}
                            bordered={false}
                            filter={filterFactory()}
                            //headerSortingStyle
                            //pagination={paginationFactory(paginationOptions)}
                            //hideSizePerPage = {false}
                            noDataIndication="No Data"
                            hover
                            condensed
                        />
                        </div>
                    )}
                    
                </Tab>
            </Tabs>
        </div>
      </>
    );
  }
}

export default AIWidgetGrids;
