import React, { Component } from "react";

import ApiSource from "components/datasource/ApiSource";
import FormSelect from "components/controls/FormSelect";
import FormInput from "components/controls/FormInput";
import Constant from "constants/Constant";
//TODO
//api to get different types of datasources - discuss to see if we need to implement plugins
//api to get list of components - discuss to see if we need to implement plugins

class DatasourceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datasource: this.props.datasource
    };
  }

  // handleChange = (event) => {
  //     event.preventDefault();
  //     let datasource = this.state.datasource;
  //     datasource.type = event.target.value;
  //     this.setState({datasource:datasource});
  // }

  handleInputChange = event => {
    let datasource = this.state.datasource;
    let target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    let name = target.name;
    eval(name + "='" + value + "'");
    this.setState({
      datasource: datasource
    });
  };

  renderComponent() {
    if (this.state.datasource.dsTypeId === Constant.DatasourceType.Api) {
      return (
        <ApiSource
          datasource={this.state.datasource}
          handleInputChange={this.handleInputChange}
        />
      );
    }
  }

  handleSubmit(props) {
    //call the api to store the data source for that particular client
  }
  render() {
    return (
      <div>
        <form>
          <FormInput
            id="datasource.dataSourceId"
            type="hidden"
            name="ID"
            value={this.props.datasource.id}
            handleInputChange={this.handleInputChange}
          />
          <FormInput
            id="datasource.name"
            type="text"
            name="Name"
            value={this.props.datasource.name}
            handleInputChange={this.handleInputChange}
          />
          <FormInput
            id="datasource.tenantId"
            type="hidden"
            name="Tenant Id"
            value={"1"}
            handleInputChange={this.handleInputChange}
          />
          <FormSelect
            id="datasource.dsTypeId"
            type="select"
            name="Type"
            options={this.props.datasourceTypes}
            displayField = "dsTypeName"
            valueField = "dsTypeId"
            value={this.props.datasource.dsTypeId}
            handleInputChange={this.handleInputChange}
          />
          {this.renderComponent()}
        </form>
      </div>
    );
  }
}

export default DatasourceContainer;
