import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import bgImage from "assets/img/full-screen-image-3.jpg";

import Footer from "components/footer/Footer.jsx";
import PagesHeader from "components/header/PagesHeader.jsx";

//Dynamically create pages routes
import pagesRoutes from "routes/pages.jsx";


class Pages extends Component {
  getPageClass() {
    var pageClass = "";
    switch (this.props.location.pathname) {
      case "/Pages/Login":
        pageClass = " login-page";
        break;
      case "/Pages/ForgotPassword":
        pageClass = " login-page";
        break;
      case "/pages/register-page":
        pageClass = " register-page";
        break;
      case "/pages/lock-screen-page":
      case "/Pages/Logout":
        pageClass = " lock-page";
        break;
      default:
        pageClass = "";
        break;
    }
    return pageClass;
  }
  componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  render() {
    return (
      <>
        <div className="wrapper wrapper-full-page">
          <div
            className={this.getPageClass()}
            data-color="blue"
            //data-image={bgImage}
          >
            
              <div className="text-center">
                {/* <span className="logoBarn"></span> */}
                <span className="logo-barn-page"></span>
                <br/><br/>
              </div>

              <Switch>
                {pagesRoutes.map((prop, key) => {
                  return (
                    <Route
                      path={prop.path}
                      key={key}
                      render={routeProps => (
                        <prop.component
                          {...routeProps}
                          appProps={this.props.appProps}
                        />)}
                    />
                  );
                })}
              </Switch>
            </div>
            <Footer transparent />
            <div
              className="full-page-background" style={{backgroundColor:"#404040"}}
              //style={{ backgroundImage: "url(" + bgImage + ")" }}
            />
          </div>
        
      </>
    );
  }
}

export default Pages;
