import React from "react";
import Sugar from "sugar";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
// Import React Table
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  Comparator,
} from 'react-bootstrap-table2-filter'
import jpath from 'jsonpath'

class GridWidget extends React.Component {
  constructor(props, context) {
    super(props, context)
    Sugar.extend()
    /*var hideColumnHeader = false;
    if(data && data[0]){
      if(!Array.isArray(data[0])){
        hideColumnHeader = true;
      }
    }
    this.state = { hideColumnHeader: hideColumnHeader };*/
  }

  render() {
    //Avoid Error when widget is NOT configured
    if (this.props.widget.rows === undefined) {
      return (
        <div className="text-center">
          Widget configuration is incomplete. Please contact your Administrator
        </div>
      )
    }

    var isDataLoaded = false
    var columnDefinitions = []
    var data = []
    var paginationOptions = {}

    if (Object.isEmpty(this.props.data) === false) {
      data = jpath.query(this.props.data, this.props.widget.rows)
      //Check if Data exists?

      if (data === undefined || data[0] === undefined) {
        return null
      }
      if (Array.isArray(data[0])) {
        //Get First from the Data to get columns
        data = data[0]
        if (data === undefined || data[0] === undefined) {
          return null
        }

        //Get the COlumns Definitions
        var columnNames = []
        var columnsToDisplay = []
        var columns = Object.keys(data[0])
        if (
          this.props.widget.columnNames &&
          this.props.widget.columnNames !== ''
        ) {
          columnNames = this.props.widget.columnNames.split(',')
        } else {
          columnNames = columns
        }

        if (columnNames.length < columns.length) {
          for (
            let index = columnNames.length;
            index < columns.length;
            index++
          ) {
            columnNames[index] = columns[index]
          }
        }

        if (
          this.props.widget.columnsToDisplay &&
          this.props.widget.columnsToDisplay !== ''
        ) {
          columnsToDisplay = this.props.widget.columnsToDisplay.split(',')
        } else {
          columnsToDisplay = columns
        }
        //var columns = Object.keys(data[0]);
        columnsToDisplay.forEach((column, index) => {
          columnDefinitions.push({
            text: columnNames[index].toUpperCase(),
            dataField: column,
            sort: true,
            filter:
              columnNames[index].toLowerCase().trim() === 'status'
                ? textFilter({
                    placeholder: 'Type to filter',
                    comparator: Comparator.EQ,
                  })
                : textFilter({ placeholder: 'Type to filter' }),
          })
        })
      } else {
        if (data[0] === undefined) {
          return null
        }
        columnDefinitions.push({
          text: 'test',
          dataField: 'name',
          headerClasses: 'hide',
        })
        columnDefinitions.push({
          text: 'test1',
          dataField: 'value',
          headerClasses: 'hide',
        })

        const columns = Object.keys(data[0])
        var newData = []

        columns.forEach((item) => {
          newData.push({
            name: item,
            value: data[0][item],
          })
        })
        data = newData
      }
      paginationOptions = {
        paginationSize: 1,
        pageStartIndex: 1,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        sizePerPageList: [
          {
            text: '10',
            value: 10,
          },
          {
            text: 'All',
            value: data.length,
          },
        ], // A numeric array is also available. the purpose of above example is custom the text
      }
      isDataLoaded = true
    }

    var keyField = this.props.widget.keyField
    if (keyField === undefined || keyField === '') {
      let id = 1
      data.map((val, ix) => {
        val.id = id
        id++
        return val
      })
      keyField = 'id'
    }
    // if (
    //   (keyField === undefined || keyField === "") &&
    //   (columnDefinitions &&
    //     columnDefinitions.length > 0 &&
    //     columnDefinitions[0] !== undefined)
    // ) {
    //   keyField = columnDefinitions[0].dataField;
    // }

    // if (columnDefinitions[0] !== undefined) {
    //   keyField = columnDefinitions[0].dataField;
    // }
    return (
      isDataLoaded && (
        <BootstrapTable
          bootstrap4={true}
          keyField={keyField}
          data={data}
          columns={columnDefinitions}
          bordered={false}
          filter={filterFactory()}
          //headerSortingStyle
          //pagination={paginationFactory(paginationOptions)}
          //hideSizePerPage = {false}
          noDataIndication="No Data"
          hover
          condensed
        />
      )
    )
  }
}

export default GridWidget;
