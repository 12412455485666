import React from "react";
import { DropdownButton, Dropdown, Button} from "react-bootstrap";
//import "react-select/dist/react-select.css";
import Service from "Service";
import Constant from "constants/Constant";
import Role from "constants/Role";
import { Link } from 'react-router-dom'
import Helper from "../../Helper";

class DashHeader extends React.Component {
  onAddPanel = (panelType, widgetType) => {
    this.props.onAddPanel(panelType, widgetType);
  };

  reload = function() {
    window.location.href="/";
  }

  render() {
    let isAlarmExists = this.props.alarms && this.props.alarms.length > 0;
    let statusColor = "red";
    let statusClassName = "text-danger";
    if (isAlarmExists == false) {
      statusColor = "green";
      statusClassName = "text-success";
    }
    let locations = this.props.locations;
    let canAddAllLocations = false;
    let canAddAllHouses = true;
    if( Service.isInRole(Role.SuperAdmin) === true 
        || Service.isInRole(Role.SuperUser) === true
        || Service.isInRole(Role.TenantAdmin) === true
        || Service.isInRole(Role.TenantUser) === true
        || Service.isInRole(Role.ModuleUser) === true){
      canAddAllLocations = true;
    }

    if(this.props.name === "Trend"){
      canAddAllLocations = false;
      canAddAllHouses = false;
    }

    return (
        <div className="row dashHeader">
          <div className="col-8 col-md-8 dashHeaderCol header-menu-left-details">
            <div className="d-inline">
              {/* <Link to="/"> */}
                <div onClick={this.reload} className="btn mb-0 mb-md-3 text-normal header-button header-home-button"><i className="fa fa-home"/></div>
              {/* </Link> */}
              <Button className="mb-0 mb-md-3 text-normal header-button">{this.props.name}</Button>
            </div>
            {(Service.isInRole(Role.SuperAdmin) === true || Service.isInRole(Role.SuperUser) === true) && this.props.tenants && this.props.tenant &&  this.props.tenant.name && 
            <DropdownButton
              id="tenant"
              drop={"down"}
              className="px-2 px-md-0 text-white d-inline"
              title={
                <span>
                  <i className="icon-custom icon-home-tenant" />{" "}
                  {this.props.tenant.name}
                </span>
              }
            >
              <Dropdown.Item disabled>Select Tenant</Dropdown.Item>
              <Dropdown.Divider />
              {this.props.tenants.sortBy("name").map((tenant, key) => (
                <Dropdown.Item key={key} onSelect={() => this.props.onTenantChanged(tenant)}>
                  {tenant.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            }

            {this.props.location && 
            <DropdownButton
              id="location"
              drop={"down"}
              className="px-2 px-md-0 text-white d-inline"
              title={
                <span>
                  <i className="fa fa-map-marker" />{" "}
                  {Helper.getSiteName(this.props.location)}
                </span>
              }
            >
              <Dropdown.Item disabled>Select Site</Dropdown.Item>
              <Dropdown.Divider />
              {canAddAllLocations === true && <Dropdown.Item onSelect={() => this.props.onLocationChanged(Constant.AllHouse)}>All Sites</Dropdown.Item>}
              {locations.sortBy("name").map((location, key) => (
                <Dropdown.Item key={key} onSelect={() => this.props.onLocationChanged(location)}>
                  {Helper.getSiteName(location)}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            }
            {this.props.house && 
            <DropdownButton
              id="house"
              drop={"down"}
              className="px-2 px-md-0 text-white d-inline "
              title={
                <span>
                  <i className="fa fa-home" />{" "}
                  {Helper.getHouseName(this.props.house)}
                </span>
              }
            >
              <Dropdown.Item disabled>Select House</Dropdown.Item>
              <Dropdown.Divider />
              {canAddAllHouses === true && <Dropdown.Item onSelect={() => this.props.onHouseChanged(Constant.AllHouse)}>All Houses</Dropdown.Item>}
              {this.props.houses.sortBy("name").map((house, key) => (
                <Dropdown.Item key={key} onSelect={() => this.props.onHouseChanged(house)}>
                  {Helper.getHouseName(house)}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            }
          </div>
          <div className="col-4 col-md-4 dashHeaderCol">
            {!!(this.props.outside && this.props.outside.OutsideAirTemp && this.props.house &&
              this.props.house.id !== Constant.AllHouse.id) && (
                <ul className="nav justify-content-end">
                  <li className="p-3 pr-3 d-none d-sm-block my-auto">
                  {/* <OverlayTrigger placement="bottom" id="lastUpdated" overlay={<Tooltip id="tlu">Last Updated on <br/>{new Date(this.props.outside.TimeStamp).format()}</Tooltip>}>
                    <i className="fa fa-clock-o pr-5"></i>
                  </OverlayTrigger> */}
                    Outside :{" "}
                    <span>
                      {this.props.outside.OutsideAirTemp}
                    </span>{" "}
                    &deg;F 
                    {/*
                    &nbsp;&nbsp;<span className="fs-1p2">/</span>&nbsp;&nbsp;{" "}
                    <span className="fs-1p2">
                      {this.props.outside.OutsideHumidity}
                    </span>%
                    */}
                  </li>{" "}
                  <li className="pt-3 pr-3 d-sm-none my-auto">
                    <span>
                      {this.props.outside.OutsideAirTemp}
                    </span>{" "}
                    &deg;F 
                  </li>{" "}
                  <li className="my-auto alarmDropdown">
                  <DropdownButton
                    title={
                      <div className="d-inline">
                        <i className={"fa fa-bell fs-1p5 " + statusClassName } />
                      </div>
                    }
                    id="basic-nav-dropdown-2"
                    className=" d-inline"
                  >
                    {isAlarmExists && (
                          <Dropdown.Item >
                                <tr>
                                  <th className={"tableBorder"}>{'Date & Time'}</th>
                                  <th className={"tableBorder"}>{'Group'}</th>
                                  <th className={"tableBorder"}>{'Sensor'}</th>
                                  <th className={"tableBorder"}>{'Value'}</th>
                                  <th className={"tableBorder"}>{'Msg'}</th>
                                </tr>
                              {this.props.alarms.map((ala, key) => (<tr>
                                  <td className={"tableBorder"}>{ala.TimeStamp}</td>
                                  <td className={"tableBorder"}>{ala.grp}</td>
                                  <td className={"tableBorder"}>{ala.sensor}</td>
                                  <td className={"tableBorder"}>{ala.val}</td>
                                  <td className={"tableBorder"}>{ala.msg}</td>
                                </tr>))}
                              </Dropdown.Item>
                        )}
                    {isAlarmExists === false && (
                      <Dropdown.Item eventKey={3.1}>No Alarm</Dropdown.Item>
                    )}
                  </DropdownButton>
                  </li>
                </ul>
              )}
          </div>
        </div>
    );
  }
}

DashHeader.defaultProps = {
  locations: [],
  houses: []
};

export default DashHeader;
