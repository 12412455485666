import Service from "Service";
import StorageId from "constants/StorageId";
import pagesRoutes from "./pages.jsx";
import Role from "constants/Role";
import Helper from "../Helper";
import Constant from "../constants/Constant.js";

/*var dashRoutes = [
    { path: "/dashboard", name: "Dashboard", icon: "pe-7s-graph", component: Dashboard },
    { collapse: true, path: "/components", name: "Components", state: "openComponents", icon: "pe-7s-plugin", views:[
        { path: "/components/buttons", name: "Buttons", mini: "B", component: Buttons },
        { path: "/components/grid-system", name: "Grid System", mini: "GS", component: GridSystem },
        { path: "/components/panels", name: "Panels", mini: "P", component: Panels },
        { path: "/components/sweet-alert", name: "Sweet Alert", mini: "SA", component: SweetAlert },
        { path: "/components/notifications", name: "Notifications", mini: "N", component: Notifications },
        { path: "/components/icons", name: "Icons", mini: "I", component: Icons },
        { path: "/components/typography", name: "Typography", mini: "T", component: Typography }]
    },
    { collapse: true, path: "/forms", name: "Forms", state: "openForms", icon: "pe-7s-note2", views:
        [{ path: "/forms/regular-forms", name: "Regular Forms", mini: "RF", component: RegularForms },
        { path: "/forms/extended-forms", name: "Extended Forms", mini: "EF", component: ExtendedForms },
        { path: "/forms/validation-forms", name: "Validation Forms", mini: "VF", component: ValidationForms },
        { path: "/forms/wizard", name: "Wizard", mini: "W", component: Wizard }]
    },
    { collapse: true, path: "/tables", name: "Tables", state: "openTables", icon: "pe-7s-news-paper", views:
        [{ path: "/tables/regular-tables", name: "Regular Tables", mini: "RT", component: RegularTables },
        { path: "/tables/extended-tables", name: "Extended Tables", mini: "ET", component: ExtendedTables },
        { path: "/tables/data-tables", name: "Data Tables", mini: "DT", component: DataTables }]
    },
    { collapse: true, path: "/maps", name: "Maps", state: "openMaps", icon: "pe-7s-map-marker", views:
        [{ path: "/maps/google-maps", name: "Google Maps", mini: "GM", component: GoogleMaps },
        { path: "/maps/full-screen-maps", name: "Full Screen Map", mini: "FSM", component: FullScreenMap },
        { path: "/maps/vector-maps", name: "Vector Map", mini: "VM", component: VectorMap }]
    },
    { path: "/charts", name: "Charts", icon: "pe-7s-graph1", component: Charts },
    { path: "/calendar", name: "Calendar", icon: "pe-7s-date", component: Calendar },
    { collapse: true, path: "/pages", name: "Pages", state: "openPages", icon:"pe-7s-gift", views:
        pages
    },
    { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" }
]; */

var dashRoutes = pagesRoutes;

let tenants = JSON.parse(sessionStorage.getItem(StorageId.Session.Tenants));
let usermenus = sessionStorage.getItem("usermenus");
let defaultTenantId = 0;
let defaultSiteId = Constant.AllHouse.id;
let defaultHouseId = Constant.AllHouse.id;

let firstSiteId = defaultSiteId;
let firstHouseId = defaultHouseId;

if (Helper.isNullOrUndefined(tenants) === false && tenants.length > 0) {
  let defaultTenant = tenants[0];
  defaultTenantId = defaultTenant.id;
  if (
    Helper.isNullOrUndefined(defaultTenant.sites) === false &&
    defaultTenant.sites.length > 0
  ) {
    firstSiteId = defaultTenant.sites[0].id;
    if (
      Helper.isNullOrUndefined(defaultTenant.sites[0].houses) === false &&
      defaultTenant.sites[0].houses.length > 0
    ) {
      firstHouseId = defaultTenant.sites[0].houses[0].id;
    }
  }
}

//Routes for Dashboards
dashRoutes.push({
  MenuId: "1",
  showInNavigation: false,
  path: "/Controls/:tenantId",
  layoutJson: 3,
  name: "Controls",
  icon: "fa fa-cogs",
  component: "Dashboard",
});

dashRoutes.push({
  MenuId: "1",
  showInNavigation: false,
  path: "/Controls/:tenantId/:siteId",
  layoutJson: 4,
  name: "Controls",
  icon: "fa fa-cogs",
  component: "Dashboard",
});
dashRoutes.push({
  MenuId: "1",
  showInNavigation: false,
  path: "/Controls/:tenantId/:siteId/:houseId",
  layoutJson: 5,
  name: "Controls",
  icon: "fa fa-cogs",
  component: "Dashboard",
});

//Routes for Dashboard Redirection
if (
  Service.isInRole(Role.SuperAdmin) === true ||
  Service.isInRole(Role.SuperUser) === true
) {
  dashRoutes.push({
    MenuId: "1",
    path: "/Controls",
    layoutJson: 28,
    name: "Controls",
    icon: "fa fa-cogs",
    component: "Dashboard",
  });
} else if (
  Service.isInRole(Role.TenantAdmin) === true ||
  Service.isInRole(Role.TenantUser) === true ||
  Service.isInRole(Role.ModuleUser) === true
) {
  dashRoutes.push({
    MenuId: "1",
    redirect: true,
    path: "/Controls",
    pathTo: `/Controls/${defaultTenantId}`,
    name: "Controls",
    icon: "fa fa-cogs",
  });
} else {
  dashRoutes.push({
    MenuId: "1",
    redirect: true,
    path: "/Controls",
    pathTo: `/Controls/${defaultTenantId}/${firstSiteId}`,
    name: "Controls",
    icon: "fa fa-cogs",
  });
}

//Route for Feeds
let feedViews = [];
feedViews.push({
  MenuId: "55",
  showInNavigation: false,
  path: `/FeedBin/:tenantId/:siteId`,
  layoutJson: 31,
  name: "FeedBin",
  component: "Dashboard",
});

feedViews.push({
  MenuId: "55",
  showInNavigation: false,
  redirect: true,
  path: `/FeedBin/:tenantId`,
  pathTo: `/FeedBin/:tenantId/${defaultSiteId}`,
  layoutJson: 31,
  name: "FeedBin",
  component: "Dashboard",
});

if (
  Service.isInRole(Role.SiteAdmin) === false &&
  Service.isInRole(Role.SiteUser) === false &&
  Service.isInRole(Role.SiteModuleUser) === false
) {
  feedViews.push({
    MenuId: "55",
    redirect: true,
    path: "/FeedBin",
    pathTo: `/FeedBin/${defaultTenantId}/${defaultSiteId}`,
    layoutJson: 31,
    name: "FeedBin",
    icon: "fa fa-inbox",
  });

  //Route for Feed  delivery
  feedViews.push({
    MenuId: "56",
    showInNavigation: false,
    path: "/Feed-Delivery/:tenantId",
    name: "Feed Delivery",
    layoutJson: 97,
    component: "Dashboard",
  });

  //Route for Feed  delivery redirection
  feedViews.push({
    MenuId: "56",
    redirect: true,
    path: "/Feed-Delivery",
    pathTo: `/Feed-Delivery/${defaultTenantId}`,
    name: "Feed Delivery",
    icon: "fa fa-truck",
  });

} else {
  feedViews.push({
    MenuId: "55",
    redirect: true,
    path: "/FeedBin",
    pathTo: `/FeedBin/${defaultTenantId}/${firstSiteId}`,
    layoutJson: 31,
    name: "FeedBin",
    icon: "fa fa-inbox",
  });
  //Route for Feed  delivery
  feedViews.push({
    MenuId: "56",
    showInNavigation: false,
    path: "/Feed-Delivery/:tenantId",
    name: "Feed Delivery",
    layoutJson: 97,
    component: "Dashboard",
  });

  //Route for Feed  delivery redirection
  feedViews.push({
    MenuId: "56",
    redirect: true,
    path: "/Feed-Delivery",
    pathTo: `/Feed-Delivery/${defaultTenantId}`,
    name: "Feed Delivery",
    icon: "fa fa-truck",
  });
}



//Route for PulletSummary redirection
feedViews.push({
  MenuId: "72",
  showInNavigation: false,
  path: "/FeedDeliveryReport/:tenantId/:siteId",
  name: "Feed Delivery Report",
  component: "Dashboard",
  layoutJson: 120
});

 //Route for LayerSummary redirection
 feedViews.push({
  MenuId: "72",
  showInNavigation: false,
  redirect: true,
  path: "/FeedDeliveryReport/:tenantId",
  pathTo: `/FeedDeliveryReport/:tenantId/${defaultSiteId}`,
  name: "Feed Delivery Report",
  icon: "fa fa-list",
  layoutJson: 120
});     

feedViews.push({
  MenuId: "72",
  redirect: true,
  path: "/FeedDeliveryReport",
  pathTo: `/FeedDeliveryReport/${defaultTenantId}/${defaultSiteId}`,
  name: "Feed Delivery Report",
  icon: "fa fa-list",
  layoutJson: 120
}); 

dashRoutes.push({
  MenuId: "2",
  path: "/Feed",
  name: "Feed",
  icon: "icon-sidebar icon-feed",
  collapse: true,
  views: feedViews,
});

//Route for New Inventory
dashRoutes.push({
  MenuId: "62", //TODO
  showInNavigation: false,
  path: `/Inventory/:tenantId`,
  layoutJson: 107,
  name: "Inventory",
  icon: "fa fa-book",
  component: "Dashboard",
});
//Route for New Inventory
dashRoutes.push({
  MenuId: "62", //TODO
  redirect: true,
  path: "/Inventory",
  pathTo: `/Inventory/${defaultTenantId}`,
  name: "Inventory",
  icon: "fa fa-book",
});

if (Service.isInRole(Role.SiteUser) === false) {
  let flockViews = [];

  if (Service.isInRole(Role.SiteAdmin) === false && Service.isInRole(Role.SiteModuleUser) === false) {
    //Route for FlockHeader
    flockViews.push({
      MenuId: "4",
      showInNavigation: false,
      path: "/Flock/Header/:tenantId",
      name: "Flock Header",
      layoutJson: 47,
      component: "Dashboard",
    });

    //Route for FlockHeader redirection
    flockViews.push({
      MenuId: "4",
      redirect: true,
      path: "/Flock/Header",
      pathTo: `/Flock/Header/${defaultTenantId}`,
      name: "Flock Header",
      icon: "fa fa-list",
    });
  }

  //Route for FlockDetail
  flockViews.push({
    MenuId: "5",
    showInNavigation: false,
    path: "/Flock/Detail/:tenantId/:siteId",
    name: "Flock Detail",
    layoutJson: 30,
    component: "Dashboard",
  });

  //Route for FlockDetail redirection
  flockViews.push({
    MenuId: "5",
    showInNavigation: false,
    redirect: true,
    path: "/Flock/Detail/:tenantId",
    pathTo: `/Flock/Detail/:tenantId/${defaultSiteId}`,
    name: "Flock Detail",
    icon: "fa fa-list",
  });

  //Route for LayerSummary redirection
  flockViews.push({
    MenuId: "69",
    showInNavigation: false,
    path: "/Flock/LayerSummary/:tenantId/:siteId",
    name: "Layer Summary",
    component: "Dashboard",
    layoutJson: 116
  });

  //Route for PulletSummary
  flockViews.push({
    MenuId: "70",
    showInNavigation: false,
    path: "/Flock/PulletSummary/:tenantId/:siteId",
    name: "Pullet Summary",
    component: "Dashboard",
    layoutJson: 118
  });

  //Route for PulletDetail
  flockViews.push({
    MenuId: "65",
    showInNavigation: false,
    path: "/Flock/PulletDetail/:tenantId/:siteId",
    name: "Pullet Detail",
    layoutJson: 113,
    component: "Dashboard",
  });

  //Route for PulletDetail redirection
  flockViews.push({
    MenuId: "65",
    showInNavigation: false,
    redirect: true,
    path: "/Flock/PulletDetail/:tenantId",
    pathTo: `/Flock/PulletDetail/:tenantId/${defaultSiteId}`,
    name: "Pullet Detail",
    icon: "fa fa-list",
  });

    //Route for LayerSummary
    flockViews.push({
      MenuId: "69",
      showInNavigation: false,
      path: "/Flock/LayerSummary/:tenantId/:siteId",
      name: "Layer Summary",
      layoutJson: 116,
      component: "Dashboard",
    });
  
    //Route for LayerSummary redirection
    flockViews.push({
      MenuId: "69",
      showInNavigation: false,
      redirect: true,
      path: "/Flock/LayerSummary/:tenantId",
      pathTo: `/Flock/LayerSummary/:tenantId/${defaultSiteId}`,
      name: "Layer Summary",
      icon: "fa fa-list",
      layoutJson: 116
    });

    //Route for PulletSummary
    flockViews.push({
      MenuId: "65",
      showInNavigation: false,
      path: "/Flock/PulletSummary/:tenantId/:siteId",
      name: "Pullet Summary",
      layoutJson: 118,
      component: "Dashboard",
    });
  
    //Route for PulletSummary redirection
    flockViews.push({
      MenuId: "65",
      showInNavigation: false,
      redirect: true,
      path: "/Flock/PulletSummary/:tenantId",
      pathTo: `/Flock/PulletSummary/:tenantId/${defaultSiteId}`,
      name: "Pullet Summary",
      icon: "fa fa-list",
      layoutJson: 118
    });
  
  if (
    Service.isInRole(Role.SuperAdmin) === true ||
    Service.isInRole(Role.SuperUser) === true ||
    Service.isInRole(Role.ModuleUser) === true ||
    Service.isInRole(Role.SiteModuleUser) === true 
  ) {
    //Route for LayerSummaryWeekly redirection
    flockViews.push({
      MenuId: "74",
      showInNavigation: false,
      path: "/Flock/LayerSummaryWeekly/:tenantId/:siteId",
      name: "Layer Weekly",
      component: "Dashboard",
      layoutJson: 122
    });
    //Route for PulletSummaryWeekly redirection
    flockViews.push({
      MenuId: "73",
      showInNavigation: false,
      path: "/Flock/PulletSummaryWeekly/:tenantId/:siteId",
      name: "Pullet Weekly",
      component: "Dashboard",
      layoutJson: 121
    });
    flockViews.push({
      MenuId: "74",
      showInNavigation: false,
      redirect: true,
      path: "/Flock/LayerSummaryWeekly/:tenantId",
      pathTo: `/Flock/LayerSummaryWeekly/:tenantId/${defaultSiteId}`,
      name: "Layer Weekly",
      icon: "fa fa-list",
      layoutJson: 122
    });
    //Route for PulletSummary Weekly redirection
    flockViews.push({
      MenuId: "73",
      showInNavigation: false,
      redirect: true,
      path: "/Flock/PulletSummaryWeekly/:tenantId",
      pathTo: `/Flock/PulletSummaryWeekly/:tenantId/${defaultSiteId}`,
      name: "Pullet Weekly",
      icon: "fa fa-list",
      layoutJson: 121
    });
  }

  if (Service.isInRole(Role.SiteAdmin) === false && Service.isInRole(Role.SiteModuleUser) === false) {
    //Route for FlockDetail redirection
    flockViews.push({
      MenuId: "5",
      redirect: true,
      path: "/Flock/Detail",
      pathTo: `/Flock/Detail/${defaultTenantId}/${defaultSiteId}`,
      name: "Flock Detail",
      icon: "fa fa-list",
    });
    flockViews.push({
      MenuId: "69",
      redirect: true,
      path: "/Flock/LayerSummary",
      pathTo: `/Flock/LayerSummary/${defaultTenantId}/${defaultSiteId}`,
      name: "Layer Summary",
      icon: "fa fa-list",
      layoutJson: 116
    });      
    flockViews.push({
      MenuId: "70",
      redirect: true,
      path: "/Flock/PulletSummary",
      pathTo: `/Flock/PulletSummary/${defaultTenantId}/${defaultSiteId}`,
      name: "Pullet Summary",
      icon: "fa fa-list",
      layoutJson: 118
    });
    if (
      Service.isInRole(Role.SuperAdmin) === true ||
      Service.isInRole(Role.SuperUser) === true ||
      Service.isInRole(Role.ModuleUser) === true
    ) {
      //Route for LayerSummary redirection
      flockViews.push({
        MenuId: "74",
        redirect: true,
        path: "/Flock/LayerSummaryWeekly",
        pathTo: `/Flock/LayerSummaryWeekly/${defaultTenantId}/${defaultSiteId}`,
        name: "Layer Weekly",
        icon: "fa fa-list",
        layoutJson: 122
      }); 
      flockViews.push({
        MenuId: "73",
        redirect: true,
        path: "/Flock/PulletSummaryWeekly",
        pathTo: `/Flock/PulletSummaryWeekly/${defaultTenantId}/${defaultSiteId}`,
        name: "Pullet Weekly",
        icon: "fa fa-list",
        layoutJson: 121
      });   
    }
    flockViews.push({
      MenuId: "65",
      redirect: true,
      path: "/Flock/PulletDetail",
      pathTo: `/Flock/PulletDetail/${defaultTenantId}/${defaultSiteId}`,
      name: "Pullet Detail",
      icon: "fa fa-list",
    });
  } else {
    flockViews.push({
      MenuId: "5",
      redirect: true,
      path: "/Flock/Detail",
      pathTo: `/Flock/Detail/${defaultTenantId}/${firstSiteId}`,
      name: "Flock Detail",
      icon: "fa fa-list",
    });
    flockViews.push({
      MenuId: "69",
      redirect: true,
      path: "/Flock/LayerSummary",
      pathTo: `/Flock/LayerSummary/${defaultTenantId}/${firstSiteId}`,
      name: "Layer Summary",
      icon: "fa fa-list",
      layoutJson: 116
    });      
    flockViews.push({
      MenuId: "70",
      redirect: true,
      path: "/Flock/PulletSummary",
      pathTo: `/Flock/PulletSummary/${defaultTenantId}/${firstSiteId}`,
      name: "Pullet Summary",
      icon: "fa fa-list",
      layoutJson: 118
    });
    if (Service.isInRole(Role.SiteModuleUser) === true) {
      flockViews.push({
        MenuId: "74",
        redirect: true,
        path: "/Flock/LayerSummaryWeekly",
        pathTo: `/Flock/LayerSummaryWeekly/${defaultTenantId}/${firstSiteId}`,
        name: "Layer Weekly",
        icon: "fa fa-list",
        layoutJson: 122
      });  
      flockViews.push({
        MenuId: "73",
        redirect: true,
        path: "/Flock/PulletSummaryWeekly",
        pathTo: `/Flock/PulletSummaryWeekly/${defaultTenantId}/${firstSiteId}`,
        name: "Pullet Weekly",
        icon: "fa fa-list",
        layoutJson: 121
      }); 
    }
    flockViews.push({
      MenuId: "65",
      redirect: true,
      path: "/Flock/PulletDetail",
      pathTo: `/Flock/PulletDetail/${defaultTenantId}/${firstSiteId}`,
      name: "Pullet Detail",
      icon: "fa fa-list",
    });
  }

  if (Service.isInRole(Role.SiteAdmin) === false && Service.isInRole(Role.SiteModuleUser) === false) {
    //Route
    flockViews.push({
      MenuId: "54",
      showInNavigation: false,
      path: "/Breed/Standards/:tenantId",
      name: "Breed Standards",
      layoutJson: 96,
      component: "Dashboard",
    });

    //Route for Breed Standards redirection
    flockViews.push({
      MenuId: "54",
      redirect: true,
      path: "/Breed/Standards",
      pathTo: `/Breed/Standards/${defaultTenantId}`,
      name: "Breed Standards",
      icon: "fa fa-list",
    });

    //Route
    flockViews.push({
      MenuId: "60",
      showInNavigation: false,
      path: "/Flock/HouseBirdScale/:tenantId",
      name: "Bird Scale",
      layoutJson: 104,
      component: "Dashboard",
    });

    //Route for House Bird Scale redirection
    flockViews.push({
      MenuId: "60",
      redirect: true,
      path: "/Flock/HouseBirdScale",
      pathTo: `/Flock/HouseBirdScale/${defaultTenantId}`,
      name: "Bird Scale",
      icon: "fa fa-list",
    });
  }

  dashRoutes.push({
    MenuId: "3",
    path: "/Flock",
    name: "Flock",
    icon: "icon-sidebar icon-chicken",
    collapse: true,
    views: flockViews,
  });
}

if (
  Service.isInRole(Role.SuperAdmin) === true ||
  Service.isInRole(Role.SuperUser) === true ||
  Service.isInRole(Role.TenantAdmin) === true ||
  Service.isInRole(Role.ModuleUser) === true
) {
  let graderViews = [];
  //Route for Performance Data
  graderViews.push({
    MenuId: "51",
    path: "/Grader/PerformanceData/:tenantId",
    showInNavigation: false,
    layoutJson: 81,
    name: "Performance Data",
    icon: "fa fa-bar-chart",
    component: "Dashboard",
  });

  //Route for Performance Data redirection
  graderViews.push({
    MenuId: "51",
    redirect: true,
    path: "/Grader/PerformanceData",
    pathTo: `/Grader/PerformanceData/${defaultTenantId}`,
    name: "Performance Data",
    icon: "fa fa-bar-chart",
  });

  //Route for Daily Count
  graderViews.push({
    MenuId: "52",
    path: "/Grader/GraderDailyCount/:tenantId",
    showInNavigation: false,
    layoutJson: 91,
    name: "Grader Daily Count",
    icon: "fa fa-bar-chart",
    component: "Dashboard",
  });

  //Route for Daily Count redirection
  graderViews.push({
    MenuId: "52",
    redirect: true,
    path: "/Grader/GraderDailyCount",
    pathTo: `/Grader/GraderDailyCount/${defaultTenantId}`,
    name: "Grader Daily Count",
    icon: "fa fa-bar-chart",
  });

  dashRoutes.push({
    MenuId: "50",
    path: "/Grader",
    name: "Grader",
    icon: "icon-sidebar icon-egg",
    component: "Dashboard",
    collapse: true,
    views: graderViews,
  });
}
//Route for Trends
// dashRoutes.push({
//   MenuId: "6",
//   showInNavigation: false,
//   path: `/Trend/:tenantId/:siteId/:houseId`,
//   layoutJson: 14,
//   name: "Trend",
//   icon: "fa fa-line-chart",
//   component: "Dashboard"
// });
// //Route for Trends redirection
// dashRoutes.push({
//   MenuId: "6",
//   redirect: true,
//   path: "/Trend",
//   pathTo: `/Trend/${defaultTenantId}/${firstSiteId}/${firstHouseId}`,
//   name: "Trend",
//   icon: "fa fa-line-chart"
// });

//Route for New Trends
dashRoutes.push({
  MenuId: "6", //TODO
  showInNavigation: false,
  path: `/Trend/:tenantId/:siteId/:houseId`,
  layoutJson: 102,
  name: "Trend",
  icon: "fa fa-line-chart",
  component: "Dashboard",
});
//Route for New Trends redirection
dashRoutes.push({
  MenuId: "6", //TODO
  redirect: true,
  path: "/Trend",
  pathTo: `/Trend/${defaultTenantId}/${firstSiteId}/${firstHouseId}`,
  name: "Trend",
  icon: "fa fa-line-chart",
});

//Route for Reports
dashRoutes.push({
  MenuId: "7",
  showInNavigation: false,
  path: "/ShowReport/:groupId/:reportId/:PbiReportId",
  name: "Report",
  layoutJson: 38,
  component: "Dashboard",
});
dashRoutes.push({
  MenuId: "7",
  showInNavigation: false,
  path: "/ShowReport",
  name: "Report",
  layoutJson: 38,
  component: "Dashboard",
});

//Route for Reports
dashRoutes.push({
  MenuId: "7",
  showInNavigation: false,
  path: "/Report/:tenantId/:siteId",
  name: "Report",
  layoutJson: 7,
  component: "Dashboard",
});

//Route for Reports redirection
dashRoutes.push({
  MenuId: "7",
  showInNavigation: false,
  redirect: true,
  path: "/Report/:tenantId",
  pathTo: `/Report/:tenantId/${defaultSiteId}`,
  layoutJson: 7,
  name: "Report",
  component: "Dashboard",
  icon: "fa fa-bar-chart",
});
dashRoutes.push({
  MenuId: "7",
  redirect: true,
  path: "/Report",
  pathTo: `/Report/${defaultTenantId}/${firstSiteId}`,
  layoutJson: 7,
  name: "Report",
  icon: "fa fa-bar-chart",
});
//Route for Executive
if (
  Service.isInRole(Role.SuperAdmin) === true ||
  Service.isInRole(Role.SuperUser) === true
  //|| Service.isInRole(Role.TenantAdmin) === true
  //|| Service.isInRole(Role.ModuleUser) === true
) {
  //Route for AI
  dashRoutes.push({
    MenuId: "8",
    showInNavigation: false,
    path: "/AI/:tenantId",
    name: "AI",
    layoutJson: 60,
    component: "Dashboard",
  });

  //Route for AI redirection
  dashRoutes.push({
    MenuId: "8",
    redirect: true,
    path: "/AI",
    pathTo: `/AI/${defaultTenantId}`,
    name: "AI",
    icon: "fa fa-pie-chart",
  });

  //Route for Executive
  dashRoutes.push({
    MenuId: "9",
    showInNavigation: false,
    path: `/Executive/:tenantId`,
    layoutJson: 29,
    name: "Executive",
    icon: "fa fa-briefcase",
    component: "Dashboard",
  });

  dashRoutes.push({
    showInNavigation: false,
    path: `/NewExecutive/:tenantId`,
    layoutJson: 37,
    name: "NewExecutive",
    icon: "fa fa-briefcase",
    component: "Dashboard",
  });

  // dashRoutes.push({
  //   redirect: true,
  //   path: "/NewExecutive",
  //   pathTo: `/NewExecutive/${defaultTenantId}`,
  //   name: "New Executive",
  //   icon: "fa fa-briefcase"
  // });

  dashRoutes.push({
    MenuId: "9",
    redirect: true,
    path: "/Executive",
    pathTo: `/Executive/${defaultTenantId}`,
    name: "Executive",
    icon: "fa fa-briefcase",
  });
}

if (
  Service.isInRole(Role.SuperAdmin) === true ||
  Service.isInRole(Role.SuperUser) === true ||
  Service.isInRole(Role.SiteAdmin) === true ||
  Service.isInRole(Role.TenantAdmin) === true ||
  Service.isInRole(Role.ModuleUser) === true ||
  Service.isInRole(Role.SiteModuleUser) === true
) {
  if (
    Service.isInRole(Role.SuperAdmin) === true ||
    Service.isInRole(Role.SuperUser) === true
  ) {
    //Route for Chat
    dashRoutes.push({
      MenuId: "61",
      showInNavigation: false,
      path: "/Chatbot/:tenantId",
      name: "Chatbot",
      layoutJson: 106,
      component: "Dashboard",
    });

    //Route for Chat redirection
    dashRoutes.push({
      MenuId: "61",
      redirect: true,
      path: "/Chatbot",
      pathTo: `/Chatbot/${defaultTenantId}`,
      name: "Chatbot",
      icon: "fa fa-comments",
    });
  }

  // Route for Import Files module
  dashRoutes.push({
    MenuId: "66",
    showInNavigation: false,
    path: "/file-upload/:tenantId/:siteId",
    layoutJson: 115,
    name: "File Upload",
    icon: "fa fa-upload",
    component: "Dashboard"
  });

  dashRoutes.push({
    MenuId: "66",
    showInNavigation: false,
    redirect: true,
    path: "/file-upload/:tenantId",
    pathTo: `/file-upload/:tenantId/${defaultSiteId}`,
    name: "File Upload",
    component: "Dashboard",
    icon: "fa fa-bar-upload"
  });

  dashRoutes.push({
    MenuId: "66",
    redirect: true,
    path: "/file-upload",
    pathTo: `/file-upload/${defaultTenantId}/${firstSiteId}`,
    name: "File Upload",
    icon: "fa fa-upload",
  });

  //Route for Export
  dashRoutes.push({
    MenuId: "64",
    showInNavigation: false,
    path: "/export-table/:tenantId",
    layoutJson: 114,
    name: "Export Table",
    icon: "fa fa-file",
    component: "Dashboard",
  });
  dashRoutes.push({
    MenuId: "64",
    redirect: true,
    path: "/export-table",
    pathTo: `/export-table/${defaultTenantId}`,
    name: "Export Table",
    icon: "fa fa-file",
  });

  let adminViews = [];
  let configurationViews = [];
  let flockConfigurationViews = [];

  if (
    Service.isInRole(Role.SuperAdmin) === true ||
    Service.isInRole(Role.SuperUser) === true
  ) {
    configurationViews.push({
      MenuId: "34",
      path: "/Config/Tenants",
      layoutJson: 8,
      name: "Tenants",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });

    flockConfigurationViews.push({
      MenuId: "16",
      path: "/FlockConfig/Country",
      layoutJson: 42,
      name: "Country",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });
    flockConfigurationViews.push({
      MenuId: "17",
      path: "/FlockConfig/Currency",
      layoutJson: 43,
      name: "Currency",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });

    flockConfigurationViews.push({
      MenuId: "22",
      path: "/FlockConfig/Status",
      layoutJson: 58,
      name: "Status",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });
  }

  if (
    Service.isInRole(Role.SuperAdmin) === true ||
    Service.isInRole(Role.SuperUser) === true ||
    Service.isInRole(Role.TenantAdmin) === true ||
    Service.isInRole(Role.ModuleUser) === true
  ) {
    configurationViews.push({
      MenuId: "35",
      path: "/Config/Sites",
      layoutJson: 9,
      name: "Sites",
      icon: "fa fa-map-marker",
      component: "Dashboard",
    });

    configurationViews.push({
      MenuId: "36",
      path: "/Config/Houses",
      layoutJson: 10,
      name: "Houses",
      icon: "fa fa-home",
      component: "Dashboard",
    });

    flockConfigurationViews.push({
      MenuId: "14",
      path: "/FlockConfig/Breed",
      layoutJson: 40,
      name: "Breed",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });

    flockConfigurationViews.push({
      MenuId: "58",
      path: "/FlockConfig/BreedXref",
      layoutJson: 95,
      name: "Breed Xref",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });

    flockConfigurationViews.push({
      MenuId: "15",
      path: "/FlockConfig/Company",
      layoutJson: 41,
      name: "Company",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });

    flockConfigurationViews.push({
      MenuId: "18",
      path: "/FlockConfig/Category",
      layoutJson: 46,
      name: "Category",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });

    flockConfigurationViews.push({
      MenuId: "63",
      path: "/FlockConfig/Product",
      layoutJson: 109,
      name: "Product",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });

    flockConfigurationViews.push({
      MenuId: "64",
      path: "/FlockConfig/Customer",
      layoutJson: 110,
      name: "Customer",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });

    flockConfigurationViews.push({
      MenuId: "19",
      path: "/FlockConfig/Flock Type",
      layoutJson: 45,
      name: "Flock Type",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });
    flockConfigurationViews.push({
      MenuId: "20",
      path: "/FlockConfig/Sex",
      layoutJson: 59,
      name: "Sex",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });
    flockConfigurationViews.push({
      MenuId: "71",
      path: "/FlockConfig/EggSize",
      layoutJson: 119,
      name: "Egg Size",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });
    flockConfigurationViews.push({
      MenuId: "21",
      path: "/FlockConfig/State",
      layoutJson: 57,
      name: "State",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });

    flockConfigurationViews.push({
      MenuId: "23",
      path: "/FlockConfig/UOM",
      layoutJson: 56,
      name: "UOM",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });

    flockConfigurationViews.push({
      MenuId: "57",
      path: "/FlockConfig/Item",
      layoutJson: 98,
      name: "Item",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });

    flockConfigurationViews.push({
      MenuId: "24",
      path: "/FlockConfig/FeedMill",
      layoutJson: 44,
      name: "Feed Mill",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });
    flockConfigurationViews.push({
      MenuId: "25",
      path: "/FlockConfig/Formula",
      layoutJson: 48,
      name: "Formula",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });
    flockConfigurationViews.push({
      MenuId: "26",
      path: "/FlockConfig/GLAccount",
      layoutJson: 49,
      name: "GL Account",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });
    flockConfigurationViews.push({
      MenuId: "27",
      path: "/FlockConfig/GLCode",
      layoutJson: 50,
      name: "GL Code",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });
    flockConfigurationViews.push({
      MenuId: "28",
      path: "/FlockConfig/GLType",
      layoutJson: 51,
      name: "GL Type",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });
    flockConfigurationViews.push({
      MenuId: "29",
      path: "/FlockConfig/Ingredient",
      layoutJson: 52,
      name: "Ingredient",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });
    flockConfigurationViews.push({
      MenuId: "30",
      path: "/FlockConfig/Plant",
      layoutJson: 53,
      name: "Plant",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });
    flockConfigurationViews.push({
      MenuId: "31",
      path: "/FlockConfig/PlantType",
      layoutJson: 54,
      name: "Plant Type",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });
    flockConfigurationViews.push({
      MenuId: "32",
      path: "/FlockConfig/PulletLayerXref",
      layoutJson: 55,
      name: "Pullet Layer Xref",
      icon: "pe-7s-culture",
      component: "Dashboard",
    });
  }

  if (
    Service.isInRole(Role.SuperAdmin) === true ||
    Service.isInRole(Role.SuperUser) === true ||
    Service.isInRole(Role.TenantAdmin) === true ||
    Service.isInRole(Role.ModuleUser) === true
  ) {
    configurationViews.push({
      MenuId: "37",
      showInNavigation: false,
      path: `/Config/BinCapacity/:tenantId`,
      layoutJson: 32,
      name: "Bin Capacity",
      icon: "fa fa-filter",
      component: "Dashboard",
    });
    configurationViews.push({
      MenuId: "37",
      redirect: true,
      path: "/Config/BinCapacity",
      pathTo: `/Config/BinCapacity/${defaultTenantId}`,
      name: "Bin Capacity",
      icon: "fa fa-filter",
    });

    configurationViews.push({
      MenuId: "53",
      path: "/Config/controlNotification",
      layoutJson: 89,
      name: "Threshold Alert",
      icon: "fa fa-bell",
      component: "Dashboard",
    });

    configurationViews.push({
      MenuId: "999",
      path: "/Config/feedBinNotification",
      layoutJson: 108,
      name: "FeedBin Notifications",
      icon: "fa fa-bell",
      component: "Dashboard",
    });

    configurationViews.push({
      MenuId: "999",
      path: "/Config/emailReport",
      layoutJson: 112,
      name: "Email Report",
      icon: "fa fa-bell",
      component: "Dashboard",
    });
  }
  if (
    Service.isInRole(Role.SuperAdmin) === true ||
    Service.isInRole(Role.SuperUser) === true ||
    Service.isInRole(Role.TenantAdmin) === true ||
    Service.isInRole(Role.ModuleUser) === true
  ) {
    adminViews.push({
      MenuId: "11",
      path: "/Admin/Users",
      layoutJson: 11,
      name: "Users",
      icon: "fa fa-user m-auto",
      component: "Dashboard",
    });

    //Route for user log  delivery
    adminViews.push({
      MenuId: "59",
      showInNavigation: false,
      path: "/Admin/UserLog/:tenantId",
      name: "User Log",
      layoutJson: 103,
      component: "Dashboard",
    });

    //Route for user log redirection
    adminViews.push({
      MenuId: "59",
      redirect: true,
      path: "/Admin/UserLog",
      pathTo: `/Admin/UserLog/${defaultTenantId}`,
      name: "User Log",
      icon: "fa fa-user m-auto",
    });

    dashRoutes.push({
      MenuId: "10",
      path: "/Admin",
      name: "Admin",
      icon: "pe-7s-box1",
      component: "Dashboard",
      collapse: true,
      views: adminViews,
    });
  }

  if (
    Service.isInRole(Role.SiteAdmin) === false &&
    Service.isInRole(Role.SiteUser) === false &&
    Service.isInRole(Role.SiteModuleUser) === false
  ) {
    dashRoutes.push({
      MenuId: "13",
      path: "/FlockConfig",
      name: "Flock Configuration",
      icon: "fa fa-list",
      component: "Dashboard",
      collapse: true,
      views: flockConfigurationViews,
    });
  }

  if (
    Service.isInRole(Role.SuperAdmin) === true ||
    Service.isInRole(Role.SuperUser) === true
  ) {
    configurationViews.push({
      MenuId: "44",
      path: "/Config/Reports/Reports",
      layoutJson: 15,
      name: "Reports",
      icon: "fa fa-bar-chart",
      component: "Dashboard",
    });
    configurationViews.push({
      MenuId: "39",
      path: "/Config/Reports/Permissions",
      layoutJson: 35,
      name: "Permissions",
      icon: "fa fa-bar-chart",
      component: "Dashboard",
    });
    configurationViews.push({
      MenuId: "999", //TODO
      path: "/Config/FTPFileFieldMap",
      layoutJson: 99,
      name: "FTP File Field Map",
      icon: "pe-7s-server",
      component: "Dashboard",
    });
    configurationViews.push({
      MenuId: "999", //TODO
      path: "/Config/FTPFileHousedMap",
      layoutJson: 101,
      name: "FTP File House Map",
      icon: "pe-7s-server",
      component: "Dashboard",
    });
    configurationViews.push({
      MenuId: "999", //TODO
      path: "/Config/MobaFieldMap",
      layoutJson: 105,
      name: "MOBA Field Map",
      icon: "pe-7s-server",
      component: "Dashboard",
    });
    configurationViews.push({
      MenuId: "40",
      path: "/Config/FTPUploader",
      layoutJson: 61,
      name: "FTP Uploader",
      icon: "pe-7s-server",
      component: "Dashboard",
    });
    configurationViews.push({
      MenuId: "41",
      path: "/Config/DBUploader",
      layoutJson: 62,
      name: "DB Uploader",
      icon: "pe-7s-server",
      component: "Dashboard",
    });
    configurationViews.push({
      MenuId: "42",
      path: "/Config/DBUploaderQuery",
      layoutJson: 64,
      name: "DB Uploader Query",
      icon: "pe-7s-server",
      component: "Dashboard",
    });
    configurationViews.push({
      MenuId: "43",
      path: "/Config/DBUploaderServiceNames",
      layoutJson: 63,
      name: "DB Uploader Service",
      icon: "pe-7s-server",
      component: "Dashboard",
    });

    if (Service.isInRole(Role.SuperAdmin) === true) {
      configurationViews.push({
        MenuId: "44",
        path: "/Config/DashBoards",
        layoutJson: 39,
        name: "DashBoards",
        icon: "pe-7s-server",
        component: "Dashboard",
      });
      configurationViews.push({
        MenuId: "45",
        path: "/Config/Datasources",
        layoutJson: 13,
        name: "Datasources",
        icon: "pe-7s-server",
        component: "Dashboard",
      });
      configurationViews.push({
        MenuId: "48",
        path: "/Config/menus",
        layoutJson: 71,
        name: "Menus",
        icon: "fa fa-list",
        component: "Dashboard",
      });
    }

    configurationViews.push({
      MenuId: "46",
      path: "/Config/Reports/Services",
      layoutJson: 33,
      name: "Services",
      icon: "fa fa-bar-chart",
      component: "Dashboard",
    });
    configurationViews.push({
      MenuId: "47",
      path: "/Config/Reports/ResourceTypes",
      layoutJson: 36,
      name: "Resource Types",
      icon: "fa fa-bar-chart",
      component: "Dashboard",
    });

    configurationViews.push({
      MenuId: "49",
      path: "/Config/menuRoleConfig",
      layoutJson: 72,
      name: "Menu Role",
      icon: "fa fa-pencil",
      component: "Dashboard",
    });

    configurationViews.push({
      MenuId: "67",
      showInNavigation: false,
      path: "/Config/Exports/:tenantId",
      layoutJson: 117,
      name: "Export Data Tables",
      icon: "fa fa-file",
      component: "Dashboard",
    });
    configurationViews.push({
      MenuId: "67",
      redirect: true,
      path: "/Config/Exports",
      pathTo: `/Config/Exports/${defaultTenantId}`,
      name: "Export Data Tables",
      icon: "fa fa-file",
    });
  }

  if (
    Service.isInRole(Role.SiteAdmin) === false &&
    Service.isInRole(Role.SiteUser) === false &&
    Service.isInRole(Role.SiteModuleUser) === false
  ) {
    dashRoutes.push({
      MenuId: "33",
      path: "/Config",
      name: "Configuration",
      icon: "fa fa-wrench",
      component: "Dashboard",
      collapse: true,
      views: configurationViews,
    });
  }
}

//Routes for ChangePassword
dashRoutes.push({
  showInNavigation: false,
  path: "/Pages/ChangePassword",
  name: "Change Password",
  component: "ChangePassword",
});

let defaultPath = "/Controls";
if (Service.isInRole(Role.ModuleUser) === true || Service.isInRole(Role.SiteModuleUser) === true) {
  let menus = usermenus ? usermenus.split(",") : [];
  filterDashRoutes(menus);

  for (let dashRoute of dashRoutes) {
    if (dashRoute.redirect) {
      defaultPath = dashRoute.path.split("/:")[0];
      break;
    }
  }
}

dashRoutes.push({
  showInNavigation: false,
  redirect: true,
  path: "/",
  pathTo: defaultPath,
});

function filterDashRoutes(menus) {
  let routes = [];
  for (let dashRoute of dashRoutes) {
    let subRoutes = [];
    let haveSubRoutes = false;
    if (dashRoute.views && dashRoute.views.length > 0) {
      haveSubRoutes = true;
      for (let subRoute of dashRoute.views) {
        checkMenuExist(subRoutes, subRoute, menus);
      }
      dashRoute.views = subRoutes;
    }
    if (subRoutes.length > 0) {
      routes.push(dashRoute);
    } else if (haveSubRoutes) {
      dashRoute.showInNavigation = false;
    } else {
      checkMenuExist(routes, dashRoute, menus);
    }
  }
  dashRoutes = routes;
}

function checkMenuExist(routes, route, menus) {
  if (!route.MenuId || menus.includes(route.MenuId)) {
    routes.push(route);
  }
}

export default dashRoutes;
