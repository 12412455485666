import React, { Component } from 'react';

import Helper from 'Helper';

class Card extends Component{
    render(){
        return (
            <div {...this.props.cardProps} className={"card react-grid-item-draggable-handle-cancel"
                + (this.props.hidden ? " card-hidden":"")
                + (this.props.calendar ? " card-calendar":"")
                + (this.props.plain ? " card-plain":"")
                + (this.props.wizard ? " card-wizard":"")
                + (this.props.stats ? " card-stats":"")
                + (this.props.className? " "+ this.props.className:"")}>
                    {this.props.content}
                
                {
                    (this.props.footerBar === true && this.props.stats !== undefined) || (this.props.legend !== undefined) ? (
                        <div className={"footer"
                            + ( this.props.ftTextCenter ? " text-center":"" )}>
                            {this.props.legend !== undefined ? (
                                <div className="legend">
                                    {this.props.legend}
                                </div>
                            ):null}

                            {this.props.footerBar === true ? (<hr />):null}
                            {this.props.stats !== undefined ? (
                                <div className="stats">
                                    {this.props.stats}
                                </div>
                            ):null}
                        </div>
                    ):null
                }
            </div>
        );
    }
}

export default Card;