import React from "react"
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts"
import Helper from 'Helper';

//This is required to resolve the existing reachart issue
//x-axis is missing during resize
const CustomizedAxisTick = props => {
  const { x, y, payload } = props

  return (
    <g transform={`translate(${x},${y})`}>
      <text dy={16} textAnchor='middle' fill='#666'>{payload.value}</text>
    </g>
  )
};

const CustomTooltip = ({ active, payload, label }) => {
    
  if(payload && payload.length > 0) {
      const items = [];
      
      items.push(<div>{label}</div>)
      for (const [index, value] of payload.entries()) {

        if(value.payload.ACT_DATE !== undefined && index === 0) {
          items.push(<div>{"Date"} : {value.payload.ACT_DATE}</div>) 
        }
        items.push(<div style={{"color":value.color}}> {value.name} : {value.value}</div>)
      }
      
      return (
          <div className="recharts-default-tooltip">
              <div className="recharts-tooltip-item-list">
                  {items}
              </div>
          </div>
      );
  } else {
      return '';
  }
};

class LineChartWidget extends React.Component {
  render() {
    //Avoid Error when widget is NOT configured
    if (this.props.widget.xAxis === undefined)
    {
        return <div className="text-center">Widget configuration is incomplete. Please contact your Administrator</div>
    }

    var data = Helper.getDataFromJsonPathQuery(this.props.data, this.props.panel.datasources[0].path);
    return (
      <ResponsiveContainer width={this.props.widget.width} height={this.props.widget.height}>
        <LineChart data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <XAxis name={this.props.widget.xAxis.nameKey} dataKey={this.props.widget.xAxis.dataKey} tick={<CustomizedAxisTick />} />
          <YAxis domain={['auto', 'auto']}/>
          {this.props.widget.showCartesianGrid && <CartesianGrid strokeDasharray="3 3" />}
          {this.props.widget.showTooltip && <Tooltip content={<CustomTooltip />}/>}
          {this.props.widget.showLegend && <Legend />}
          {this.props.widget.yAxis.map((item, index) => {
            if (item.dataKey !== "")
            {
              return <Line key={index} type="monotone" name={item.nameKey} dataKey={item.dataKey} stroke={item.color} activeDot={{ r: 6 }} />
            }
            return null;
          })}
        </LineChart>
      </ResponsiveContainer>
    );
  }
}

export default LineChartWidget