import request from "superagent";
//import app from "./feathers";
import jwt_decode from "jwt-decode";
import StorageId from "constants/StorageId";
import Helper from "Helper";
import Sugar from "sugar";
import ShortId from "shortid";
import MockData from "variables/MockData";
import Constant from "constants/Constant"
import { isNullOrUndefined } from "util";
import Axios from "axios";
import FileDownload from "js-file-download";

//Service Calls
class Service {

  static log(obj, key)
  {
    if (isNullOrUndefined(key))
    {
      key = "Log";
    }
    //console.log( key + ":" + obj);
  }
  static getTokenHeader(token) {
    if (token === undefined)
    {
       token = sessionStorage.getItem(StorageId.Session.Token);
    }
    let headers = {};
    if(token && token != null)
    {
      headers = {"Authorization":"Bearer " + token}
    }
    return headers;
  }

  static getBaseUrl(){
     return Constant.ApiBaseUrl;
  }

  static sanitizeUrl(url, id, tokens){
    url = url.replace("{apiUrl}", Constant.ApiBaseUrl);
    let urlArray = url.split("?");
    if(urlArray.length >= 1){
      url = urlArray[0];
    }

    if (Helper.isNullOrUndefined(id) === false){
      url = url + "/" + id;
    }
    if(urlArray.length > 1){
      url = url + "?" + urlArray[1];
    }
    //console.log(url);
    return url;
  }

  static getRequest(url, successCallback, errorCallback) {
    url = url.replace("{apiUrl}", Constant.ApiBaseUrl);
    if (url.indexOf("Feed")
            && url.endsWith("&site=1") === true){
          url = url.replace("&site=1", "");
    }
    let headers = this.getTokenHeader();
    this.log(url, "GetURL")
    //this.log("Headers", headers)
    request("get", url)
      .set(headers)
      .then(successCallback)
      .catch(errorCallback);
  }

  static postRequest(url, data, successCallback, errorCallback) {
    url = this.sanitizeUrl(url, null);
    let headers = this.getTokenHeader();
    this.log(url, "POSTURL")
    request("post", url)
      .set(headers)
      .send(data)
      .then(successCallback)
      .catch(errorCallback);
  }

  static uploadRequest(url, files, options, successCallback, errorCallback) {
    url = this.sanitizeUrl(url, null);
    let headers = this.getTokenHeader();
    this.log(url, "POSTURL")
    var req = request("post", url)
        .set(headers)
        .unset('Content-Type')

    var formData = new FormData();

    Array.from(files).forEach((file)=> {
      formData.append('files', file);
    });

    Array.from(options).forEach((option) => {
      formData.append(option.key, option.value)
    })

    req.send(formData)
        .then(successCallback)
        .catch(errorCallback);
  }

  static putRequest(url, id, data, successCallback, errorCallback) {
    url = this.sanitizeUrl(url, id);
    let headers = this.getTokenHeader();
    request("put", url)
      .set('Content-Type', 'application/json')
      .set(headers)
      .send(data)
      .then(successCallback)
      .catch(errorCallback);
  }

  static patchRequest(url, id, data, successCallback, errorCallback) {
    url = this.sanitizeUrl(url, id);
    let headers = this.getTokenHeader();
    request("patch", url)
      .set(headers)
      .send(data)
      .then(successCallback)
      .catch(errorCallback);
  }


  static deleteRequest(url, id, successCallback, errorCallback) {
    url = this.sanitizeUrl(url, id);
    let headers = this.getTokenHeader();
    request("delete", url)
      .set(headers)
      .then(successCallback)
      .catch(errorCallback);
  }

  // To download the files
  static exportRequest(url, body, filename, appProps, errorCallback) {
    url = url.replace("{apiUrl}", Constant.ApiBaseUrl);

    let token = sessionStorage.getItem(StorageId.Session.Token);
    let config = {
      headers: {
        "Authorization":"Bearer " + token
      }
    }
    let data = {
      "fields":  body
    }
    Axios.post(url, data, config)
     .then((response) => {
          FileDownload(response.data, filename);
          appProps.hideLoader();
     }).catch(errorCallback);
  }

  static downloadCsvRequest(url, body, filename, appProps, errorCallback) {
    url = url.replace("{apiUrl}", Constant.ApiBaseUrl);

    let token = sessionStorage.getItem(StorageId.Session.Token);
    let config = {
      headers: {
        "Authorization":"Bearer " + token
      }
    }
    Axios.post(url, body, config)
        .then((response) => {
          FileDownload(response.data, filename);
          appProps.hideLoader();
        }).catch(errorCallback);
  }

  ///To check the role from the Token
  static isInRole(role) {
    let roles = this.getRoles();
    let returnValue = roles !== null && Sugar.Array.some(roles, role) === true;
    return returnValue;
   }

   static getRoles() {
    let roles = this.getTokenData("roles");
    return roles;
   }

   static decodeToken(token)
   {
     if (token && token != null)
     {
      return jwt_decode(token);
     }
     return null;
   }

  ///To get the value for a particular key from the Token
  static getTokenData(id) {
    let token = sessionStorage.getItem(StorageId.Session.Token);
    if (token === null) {
      return null;
    }
    let decoded = jwt_decode(token);
    let returnData = null;
    switch (id) {
      case "Name":
        returnData =
          Helper.getDataFromJsonPathQuery(decoded, "$.FirstName") +
          " " +
          Helper.getDataFromJsonPathQuery(decoded, "$.LastName");
        break;
      default:
        returnData = Helper.getDataFromJsonPathQuery(decoded, "$." + id);
    }

    return returnData;
  }

  static GetDatasourcesAndTypes(token, errorCallback) {
      let headers = this.getTokenHeader(token);
      const dtypes = request.get(`${Constant.ApiBaseUrl}/datasourcetype`).set(headers);
      const ds = request.get(`${Constant.ApiBaseUrl}/datasource`).set(headers);
      const dp = Promise.all([dtypes, ds]);
      dp.then(resp => {
        sessionStorage.setItem(
          StorageId.Session.DatasourceTypes,
          JSON.stringify(resp[0].body)
        );
        this.log(resp[1].body)
        var datasources = resp[1].body;
        sessionStorage.setItem(
          StorageId.Session.Datasources,
          JSON.stringify(datasources)
        );
        var dsMaps = {};
        datasources.forEach(function(datasource) {
          dsMaps[datasource.name] = datasource;
        });

        sessionStorage.setItem(
          StorageId.Session.DsMap,
          JSON.stringify(dsMaps)
        );

        var decryptedToken = this.decodeToken(token);
        //Refresh the Page
        if (decryptedToken != null && decryptedToken.tempPassword === "Y"){
          window.location.href = "/#/Pages/ChangePassword";
          window.location.reload();
        }
        else{
          window.location.href = "/";
        }
      }).catch(errorCallback);
  }

  // static saveRoutes(routes) {
  //   var _id = JSON.parse(sessionStorage.getItem(StorageId.Session.DashRoutes))
  //     ._id;
  //   if (_id) {
  //     var dashroutes = JSON.parse(
  //       sessionStorage.getItem(StorageId.Session.DashRoutes)
  //     );
  //     dashroutes.route = routes;
  //     request
  //       .put(baseurl + "/routes/" + _id)
  //       .query({ _id: _id })
  //       .send(dashroutes)
  //       .set("Accept", "application/json")
  //       .then(function(res) {
  //         sessionStorage.setItem(
  //           StorageId.Session.DashRoutes,
  //           JSON.stringify(res.body)
  //         );
  //       });
  //   } else {
  //     request
  //       .post(baseurl + "/routes")
  //       .send({ route: routes })
  //       .set("Accept", "application/json")
  //       .then(function(res) {
  //         //alert(JSON.stringify(res));
  //       });
  //   }
  // }

  static massageTokensForDummyTenant(tokens){
    if (tokens.tenantId === "-1"){
      tokens.tenantId = 4;
    }
    if (tokens.siteId === "-1"){
      tokens.siteId = 2;
    }else if (tokens.siteId === "-2"){
      tokens.siteId = 9;
    }
    return tokens;
  }

  static getDatasourceData(name, isRefresh, tokens, successCallback, errorCallback) {
    //TODO: Load MockData from the Service
    var mockData = null;

    switch (name) {
      case "SampleDemoDatasource":
        mockData = { body: MockData.sample };
        break;
      case "SampleQtrSales":
        mockData = { body: MockData.sample.quarterlySalesData };
        break;
      case "SampleYearSales":
        mockData = { body: MockData.sample.yearlySalesData };
        break;
      case "SampleRandomNumbers":
        mockData = { body: MockData.sample.randomNumbers };
        break;
      case "":
        mockData = { body: null };
        break;
      default:
        break;
    }

    if (mockData !== null) {
      successCallback(name, mockData);
      return;
    }

    const dsMap = JSON.parse(sessionStorage.getItem(StorageId.Session.DsMap));
    const datasource = dsMap[name];
    if (datasource === undefined) {
      console.error("datasource (" + name + ") is not defined");
      return;
    }
    //this.log(tokens, "tokens")
    if (datasource && datasource.dsTypeId === Constant.DatasourceType.Api) {
      let url = datasource.url;

      //Replace tokens
      tokens = Service.massageTokensForDummyTenant(tokens);
      //this.log(tokens, "tokens");
      url = Service.replaceUrlWithTokens(url, tokens);

      //Replace Invalid SiteID. Needed for Feed Widget
      if(tokens.siteId === "1"){
        url = url.replace("&siteid=1", "");
      }
      if(tokens.siteId === undefined){
        url = url.replace("&siteid=undefined", "");
      }

      //From Token
      url = url.replace("{tenantId}", this.getTokenData("TenantID"));

      this.getRequest(url, (res) => {
        successCallback(name, isRefresh, res);
        }, errorCallback);
    }
  }

  static replaceUrlWithTokens(url, tokens) {
    url = url.replace("{tenant}", tokens.tenantId);
    url = url.replace("{site}", tokens.siteId);
    url = url.replace("{house}", tokens.houseId);
    url = url.replace("{frequency}", tokens.frequency);
    return url;
  }

 static getEmptyPanel(panelType) {
    var panel = {
      id: "Pnl" + ShortId.generate(),
      title: "",
      needsConfiguration: true,
      type: panelType,
      position: {
        w: 5,
        h: 3,
        x: 0,
        y: 0,
        moved: false,
        static: false
      },
      datasources: [
        {
          name: "",
          path: ""
        }
      ],
      widgets: [],

      footer: {
        icon: "",
        text: ""
      }
    };
    return panel;
  }

  static saveDatasources(datasources, id, successCallback, errorCallback) {
    console.log(JSON.stringify(datasources))
    if (id) {
      this.putRequest(Constant.ApiBaseUrl + "/datasource", id, datasources, successCallback, errorCallback);
    } else {
      this.postRequest(Constant.ApiBaseUrl + "/datasource", datasources, successCallback, errorCallback);
    }
  }

  static GetDashboard(dashboardName, callback, errorCallback) {
      this.getRequest(Constant.ApiBaseUrl + "/dashboard/" + dashboardName, callback, errorCallback);
  }

  /********** Dashboard Services ***********/
  static saveDashboard(dashboard, successCallback, errorCallback) {
    dashboard.layoutJSON = JSON.stringify(dashboard.panel);
    if (dashboard.hasOwnProperty("dashboardId")) {
      this.putRequest(Constant.ApiBaseUrl + "/dashboard", dashboard.dashboardId, dashboard, successCallback, errorCallback);
    } else {
      this.postRequest(Constant.ApiBaseUrl + "/dashboard", dashboard, successCallback, errorCallback);
    }
  }
}
export default Service;
