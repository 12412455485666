import React from "react";
import Sugar from "sugar";
import FormSelect from "components/controls/FormSelect";
import FormInput from "components/controls/FormInput";
import FormTime from "components/controls/FormTime";
import FormEmpty from "components/controls/FormEmpty";
import FormTextArea from "components/controls/FormTextArea";
//import "react-select/dist/react-select.css";
import JsonPath from "jsonpath";
import { Button, Col } from "react-bootstrap";
import Helper from "Helper";
import Service from "Service";
import $ from "jquery";
import TimePicker from 'react-time-picker';
import _ from 'lodash';



class FieldRenderer extends React.Component {
  constructor(props, context) {
    super(props, context);
    Sugar.extend();
    this.state = {
      panel: this.props.panel,
      dropDownOptions: null,
      dropdownInputField: null,
      dropDownOptionsCache: null,
      urlData: [],
      initialLoad: [],
      isFRLoaded: false
    };
  }

  //To initialize Sub Object / Array as needed
  initObject = (objectAsString, panel) => {
    //Find the separators like .,[x] & [x].
    let arr = objectAsString.split(/\.|\[\d\]\.|\[\d\]/g);

    //If there is nothing. No need to initialize
    if (arr.length === 0) {
      return;
    }

    let i = 0;
    let partialString = "";
    //Loop the array
    while (i < arr.length) {
      //console.log( objectAsString + " Building " + arr[i]);
      //Get the current Object
      if (partialString === "") {
        partialString = arr[i];
      } else {
        partialString += "." + arr[i];
      }

      //Find whether its an array or object based on the next element
      let nextChar = objectAsString.substring(
        partialString.length,
        partialString.length + 1
      );
      //console.log("nextChar" + nextChar);

      let defaultValue = "null";
      //If its an object
      if (nextChar === ".") {
        defaultValue = "{}";
        //console.log(partialString);
        //console.log(JSON.stringify(eval(partialString)));
        if (typeof eval(partialString) === "undefined"
          || eval(partialString) === null) {
          //console.log(partialString + "= " + defaultValue);
          eval(partialString + "= " + defaultValue);
        }
      }
      //If its an array
      else if (nextChar === "[") {
        let arrayLength = objectAsString.substring(
          partialString.length + 1,
          partialString.length + 2
        );
        arrayLength = parseInt(arrayLength) + 1;
        defaultValue = "[]";
        if (typeof eval(partialString) === "undefined") {
          eval(partialString + "= " + defaultValue);
        }
        //Loop & Initialize the array elements
        for (let j = 0; j < arrayLength; j++) {
          let tempPartialString = partialString + "[" + j + "]";
          //console.log(tempPartialString);
          //Find whether its an array or object & then initialize as needed
          if (typeof eval(tempPartialString) === "undefined") {
            if (i != arr.length - 1) {
              nextChar = objectAsString.substring(
                tempPartialString.length,
                tempPartialString.length + 1
              );
              if (nextChar === ".") {
                eval(tempPartialString + "= {}");
              } else {
                eval(tempPartialString + "= []");
              }
            } else {
              eval(tempPartialString + "= null");
            }
          }
        }
      } else {
        //console.log(partialString)
        eval(partialString + "= null");
      }
      //console.log(partialString + ":" + JSON.stringify(eval(partialString)))
      i++;
    }
  };

  handleInputChange = (event, field) => {
    var panel = this.state.panel;
    let target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    let name = target.name;

    this.initObject(name, panel);
    if (field !== undefined && field.altPath !== undefined) {
      this.initObject(name.replace(field.path, field.altPath), panel);
    }

    if (target.type === "number") {
      if (isNaN(value) || value == null || value == "") {
        value = 0;
      }

      if (!field.min && value < 0) {
        value = 0;
      } else if (value < field.min) {
        value = field.min;
      }

      if (field.max && value > field.max) {
        value = field.max;
      }

      if (!field.max && value > 999999999) {
        value = 999999999;
      }

      if (value === "") {
        value = null;

        if (target.type === "number")
          value = 0;
      }

      value = "'" + value + "'";
      eval(name + "=" + value);
      if (field !== undefined && field.altPath !== undefined) {
        eval(name.replace(field.path, field.altPath) + "=" + value);
      }
    } else if (target.type === "checkbox") {
      eval(name + "=" + value);
      if (field !== undefined && field.altPath !== undefined) {
        eval(name.replace(field.path, field.altPath) + "=" + value);
      }

      target.checked = value;
    } else {
      //Replace backslash with double backslash
      value = value.replace(/\\/g, "\\\\");

      //Replace ` with empty
      value = value.replace(/\`/g, "");
      if (Helper.IsIE11OrLess() === true) {
        eval(name + "='" + value + "'");
      } else {
        eval(name + "=`" + value + "`");
      }
      if (field !== undefined && field.altPath !== undefined) {
        eval(name.replace(field.path, field.altPath) + "=`" + value + "`");
      }
    }
    if (field !== undefined
      && field.controlType === "date") {
      if (value === "") {
        value = null;
        eval(name + "=" + value);
      }

    }
    if (field !== undefined && field.controlType === "datasource") {
      //Cutom Logic to Load Datasources in the this.state.datasourcesData
      this.populateDatasource(panel, field, name, value);
      this.loadDropdown(target, field);
    }
    this.processFieldChange(field, panel);

    this.setState({
      panel: panel
    });
  };

  processFieldChange(field, panel) {
    if (field.onChange !== undefined) {
      setTimeout(() => {
        field.onChange.forEach(changeObj => {
          switch (changeObj.action) {
            case "Reload":
              //Find the field definitions
              let reloadField = Helper.getDataFromJsonPathQuery(this.props.fieldDefinitions, "$.fields.[?(@.path=='" + changeObj.fieldPath + "')]");
              
              //console.log(reloadField);
              //Fix for reset reloading fields
              //panel[reloadField] = null;
              //reloadField.defaultIndex = null;
              //reloadField.defaultValue = null;
              //reloadField.value = null;
              
              //Call the reload
              this.reloadFieldData(reloadField, panel);
              
              break;
            case "Reset":
              if (this.state.isFRLoaded) {
                let resetField = Helper.getDataFromJsonPathQuery(this.props.fieldDefinitions, "$.fields.[?(@.path=='" + changeObj.fieldPath + "')]");
                resetField.options = [];
                resetField.value = "";
              } else {
                this.state.isFRLoaded = true;
              }
              break;
            case "Callback":
              field.callbackMethod(field);
              break;
            case "Visible":
              break;
            default:
              break;
          }
        });
      }, 1);
      //console.log("Options are " + JSON.stringify(Helper.getDataFromJsonPathQuery(field, "$.options[?(@.name=='API')].url")))
      //console.log("field is " + JSON.stringify(field))
      //console.log("Panel is " + JSON.stringify(panel))
      //console.log("FieldDefinitions is " + JSON.stringify(this.props.fieldDefinitions))
    }
  }

  renderField(field, widgetPrefix, arrayPrefix) {
    let panel = this.state.panel;
    let computedFieldPath = "panel.";
    let groupClassName = "";
    let computedWidgetFieldPath = widgetPrefix;
    if (arrayPrefix !== undefined)
      computedWidgetFieldPath = computedWidgetFieldPath + arrayPrefix;
    computedWidgetFieldPath = computedWidgetFieldPath + field.path;
    computedFieldPath = computedFieldPath + computedWidgetFieldPath;

    if (field.altPath !== undefined) {
      //console.log(computedWidgetFieldPath);
      //console.log(computedWidgetFieldPath.replace(field.path, field.altPath));
      eval(
        computedFieldPath +
        "=" +
        Helper.getDataFromJsonPathQuery(
          panel,
          "$." + computedWidgetFieldPath.replace(field.path, field.altPath)
        )
      );
    }
    let value = Helper.getDataFromJsonPathQuery(
      panel,
      "$." + computedWidgetFieldPath
    );

    if (field.labelWidth === undefined) {
      field.labelWidth = "col-md-12";
    }
    if (field.controlWidth === undefined) {
      field.controlWidth = "col-md-12";
    }
    if (field.groupClassName !== undefined) {
      groupClassName = field.groupClassName;
    }

    if (field.hideForRoles !== undefined
      && Sugar.Array.intersect(field.hideForRoles, Service.getRoles()).length > 0) {
      groupClassName = groupClassName + " hide";
      //      console.log(groupClassName);
    }

    if (Helper.isNullOrEmpty(value) === true) {
      if (Helper.isNullOrEmpty(field.default) === false) {
        value = field.default;
      }
    }
    field.actualControlId = computedFieldPath.replace(".", "_").replace("[", "__").replace("]", "___")

    switch (field.controlType) {
      case "empty": {
        return (
          <FormEmpty
            removeHeight={field.removeHeight}
            controlWidth={field.controlWidth}
          />
        )
      }
      case "button": {
        return (
          <div key={field.actualControlId} className={"form-group " + this.props.controlWidth + " " + this.props.groupClassName }>
            <Button
              variant="secondary"
              id={field.actualControlId}
              name={field.name}
              className={field.controlWidth}
              onClick={event => this.props.handleButtonClick(event, field)}
            >{field.value}</Button>
          </div>

        );
      }
      case "text":
      case "number":
      case "phone":
      case "readonly":
      case "email":
      case "zip":
      case "password":
      case "date":
      case "hidden": {
        return (
          <FormInput
            key={computedFieldPath}
            id={computedFieldPath}
            actualControlId={field.actualControlId}
            type={field.controlType}
            name={field.name}
            diabled={"disabled"}
            readOnly={field.controlType === "readonly" ? "readonly" : ""}
            value={value}
            labelWidth={field.labelWidth}
            controlWidth={field.controlWidth}
            groupClassName={groupClassName}
            required={field.required}
            maxLength={field.maxLength}
            max={field.max}
            min={field.min}
            minLength={field.minLength}
            desc={field.desc}
            handleInputChange={event => this.handleInputChange(event, field)}
            labelClass={field.labelClass}
          />
        );
      }
      case "textarea": {
        return (
          <FormTextArea
            key={computedFieldPath}
            id={computedFieldPath}
            actualControlId={field.actualControlId}
            type={field.controlType}
            name={field.name}
            value={value}
            handleInputChange={event => this.handleInputChange(event, field)}
            labelWidth={field.labelWidth}
            className={field.className}
            groupClassName={field.groupClassName}
            required={field.required}
            maxLength={field.maxLength}
            minLength={field.minLength}
            controlWidth={field.controlWidth}
            labelClass={field.labelClass}
          />
        );
      }
      case "select": {
        let options = field.options;
        
        if (field.loaded === undefined
          && (field.optionsUrl || field.optionsUrlBuilder || field.optionsCache || field.dataSourceRef)) {
          this.reloadFieldData(field, panel);
        }

        if(field.optionsParentPopulate === true) {
          options = this.props.optionsParentPopulate(field);
        }

        if (Helper.isNullOrEmpty(value) === true) {
          if (field.loaded !== undefined
            && Helper.isNullOrEmpty(field.defaultIndex) === false
            && field.defaultIndex <= options.length) {
            value = eval("options[" + (field.defaultIndex - 1) + "]." + field.optionsValueField);
            eval("panel." + field.path + "=" + Helper.getStringAsVariable(value));
            this.processFieldChange(field, panel);
          }
        }
        else if (field.loaded === true
          && Helper.isNullOrEmpty(value) === false
          && this.state.initialLoad[field.name] === undefined
        ) {
          let initialLoad = this.state.initialLoad;
          initialLoad[field.name] = true;
          this.setState({ initialLoad: initialLoad });
          eval("panel." + field.path + "=" + Helper.getStringAsVariable(value));
          this.processFieldChange(field, panel);
        }
        if (Helper.isNullOrEmpty(field.defaultIndex) === false 
            && Helper.isNullOrEmpty(value) === false) {
              eval("panel."+field.path +  "=`" + value + "`");
            }
        return (
          <FormSelect
            key={computedFieldPath}
            id={computedFieldPath}
            actualControlId={field.actualControlId}
            type={field.controlType}
            name={field.name}
            value={value}
            handleInputChange={event => this.handleInputChange(event, field)}
            options={options}
            noDefault={field.noDefault}
            displayField={field.optionsDisplayField}
            valueField={field.optionsValueField}
            displayFieldSeparator={field.optionsDisplayFieldSeparator}
            labelWidth={field.labelWidth}
            controlWidth={field.controlWidth}
            required={field.required}
            maxLength={field.maxLength}
            minLength={field.minLength}
            groupClassName={groupClassName}
            optionsUrlBuilder={field.optionsUrlBuilder}
            desc={field.desc}
            labelClass={field.labelClass}
          />
        );
      }
      case "checkbox": {
        return (
          <FormInput
            key={computedFieldPath}
            id={computedFieldPath}
            actualControlId={field.actualControlId}
            type={field.controlType}
            name={field.name}
            value={value}
            handleInputChange={event => this.handleInputChange(event, field)}
            options={field.options}
            labelWidth={field.labelWidth}
            controlWidth={field.controlWidth}
            groupClassName={groupClassName}
          />
        );
      }
      case "time": {
        return (
          <FormTime
            key={computedFieldPath}
            id={computedFieldPath}
            actualControlId={field.actualControlId}
            type={field.controlType}
            name={field.name}
            diabled={"disabled"}
            readOnly={field.controlType === "readonly" ? "readonly" : ""}
            value={value}
            labelWidth={field.labelWidth}
            controlWidth={field.controlWidth}
            groupClassName={groupClassName}
            required={field.required}
            maxLength={field.maxLength}
            minLength={field.minLength}
            handleInputChange={event => this.handleInputChange(event, field)}
          />
        )
      }
      case "empty": {
        return <div>&nbsp;</div>;
      }
      // case "radio":
      //     {
      //         return <FormInput key={"panel." + field.path} id={"panel." + field.path} type={field.type} name={field.name} value={JsonPath.query(panel, "$." + field.path)[0]} handleInputChange={this.handleInputChange} options={field.options} />
      //     }
      default: {
        throw new Error(field.controlType + " is not configured");
      }
    }
  }

  onAddArrayItem = (widgetPrefix, arrayPrefix, index) => {
    let panel = this.state.panel;
    let myArray = Helper.getWidgetFieldValue(
      panel,
      "$." + widgetPrefix + arrayPrefix,
      true
    );
    myArray.insert(Object.clone(myArray[index]), index);
    this.setState({ panel: panel });
  };
  onMoveArrayItemRight = (widgetPrefix, arrayPrefix, currentIndex) => {
    let panel = this.state.panel;
    let destIndex = currentIndex + 1;
    let myArray = Helper.getWidgetFieldValue(
      panel,
      "$." + widgetPrefix + arrayPrefix,
      true
    );
    if (destIndex >= myArray.length) {
      //Do nothing as its the end
      return;
    }
    let itemToMove = myArray[currentIndex];
    myArray[currentIndex] = myArray[destIndex];
    myArray[destIndex] = itemToMove;
    this.setState({ panel: panel });
  };
  onMoveArrayItemLeft = (widgetPrefix, arrayPrefix, currentIndex) => {
    let panel = this.state.panel;
    let destIndex = currentIndex - 1;
    let myArray = Helper.getWidgetFieldValue(
      panel,
      "$." + widgetPrefix + arrayPrefix,
      true
    );
    if (destIndex < 0) {
      //Do nothing as its the end
      return;
    }
    let itemToMove = myArray[currentIndex];
    myArray[currentIndex] = myArray[destIndex];
    myArray[destIndex] = itemToMove;
    this.setState({ panel: panel });
  };
  onDeleteArrayItem = (widgetPrefix, arrayPrefix, index) => {
    let panel = this.state.panel;
    let myArray = Helper.getWidgetFieldValue(
      panel,
      "$." + widgetPrefix + arrayPrefix,
      true
    );
    myArray.removeAt(index);
    this.setState({ panel: panel });
  };

  setMessageForField = (field, message) => {
    //console.log("Setting message for " + field.name);
    $("#" + field.actualControlId).next().html(message);
    $("#" + field.actualControlId).next().css("bg-info");
    //$("#" + field.actualControlId).next().toggleClass("hide");
  }

  clearMessageForField = (field) => {
    //console.log("Clearing message for " + field.name);
    $("#" + field.actualControlId).next().html("");
    //$("#" + field.actualControlId).next().toggleClass("hide");
  }

  callback = (res, field, panel) => {
    let urlData = this.state.urlData;
    field.options = res.body;

    this.resetInitiateField(field, panel);
  };

  resetInitiateField(field, panel) {
    let urlData = this.state.urlData;

    let found;

    if (Helper.isNullOrEmpty(field.options)) {
      found = undefined;
    } else {
      found = field.options.some(el => el[field.optionsValueField] == panel[field.path]);
    }

    if (!found && panel[field.path] != null) {
      panel[field.path] = null;
      field.default = null;
      field.defaultIndex = null;
    }
    urlData[field.name] = field.options;
    field.loaded = true;
    this.setState({ urlData: urlData });
    this.processFieldChange(field, panel);
    this.clearMessageForField(field);
  }

  reloadFieldData = (field, panel) => {
    //field.loaded = "Loading...";
    if(!field) return
    field.loaded = "";
    
    let url = field.optionsUrl;
    if (Helper.isNullOrEmpty(url) === true
      && Helper.isNullOrEmpty(field.optionsUrlBuilder) !== true) {
      url = "";
      field.optionsUrlBuilder.forEach(builder => {
        //console.log("I am in " + JSON.stringify(builder));
        let selectedOptionField = Helper.getDataFromJsonPathQuery(this.props.fieldDefinitions, "$.fields.[?(@.path=='" + builder.fieldPath + "')]");
        //console.log("I am in2 " + JSON.stringify(selectedOptionField));
        let selectedOption = null;
        if (selectedOptionField !== undefined) {
          let value = Helper.getDataFromJsonPathQuery(panel, "$." + selectedOptionField.path);
          if (Helper.isNullOrEmpty(value) === false) {
            selectedOption = Helper.getDataFromJsonPathQuery(selectedOptionField, "$.options[?(@." + selectedOptionField.optionsValueField + " == " + Helper.getStringAsVariable(value) + ")]");
          }
        }
        //console.log("I am in3 " + "$.options[?(@." + selectedOptionField.optionsValueField + " == " + Helper.getDataFromJsonPathQuery(this.state.panel, "$." + selectedOptionField.path) + ")]");
        //console.log("I am in3 " + JSON.stringify(selectedOption));
        //console.log("Builder is " + JSON.stringify(builder));
        let urlToAppend = null;
        switch (builder.property) {
          case "selectedOption":
            if (selectedOption === undefined) {
              url = null;
            }
            else {
              urlToAppend = Helper.getDataFromJsonPathQuery(selectedOption, builder.expression);
              if (Helper.isNullOrEmpty(urlToAppend) === true) {
                url = null;
                return;
              }
              else {
                url = url + urlToAppend;
              }
            }
            //  console.log(url);
            break;
          case "string":
            urlToAppend = builder.expression;
            if (Helper.isNullOrEmpty(urlToAppend) === true) {
              url = null;
              return;
            }
            else {
              url = url + urlToAppend;
            }
            break
          case "value":
            urlToAppend = Helper.getDataFromJsonPathQuery(this.state.panel, builder.fieldPath);
            //console.log(urlToAppend)
            if (Helper.isNullOrEmpty(urlToAppend) === true) {
              url = null;
              return;
            }
            else {
              url = url + urlToAppend;
            }
            break;
          case "optionsDisplayField":
            field.optionsDisplayField = Helper.getDataFromJsonPathQuery(selectedOption, builder.expression);;
            break;
          case "optionsValueField":
            field.optionsValueField = Helper.getDataFromJsonPathQuery(selectedOption, builder.expression);
            break;
          default:
            break;
        }
        //console.log("Building URL " + url);
      });
    }
    //console.log(url);

    if (Helper.isNullOrEmpty(url) === false
      && field.lastUrl != url && url.startsWith("{apiUrl}/") == true) {
      //this.setMessageForField(field, "Loading " + field.name + " ...");
      this.setMessageForField(field, "");
      field.lastUrl = url;
      Service.getRequest(
        url,
        res => this.callback(res, field, panel),
        this.props.appProps.handleServiceError
      );
    }

    if (Helper.isNullOrEmpty(field.optionsCache) === false) {
      
      field.options = this.getFromCache(field.optionsCache, panel);
      setTimeout(() => {
        this.resetInitiateField(field, panel);
      }, 10);
    }

    if (Helper.isNullOrEmpty(field.dataSourceRef) == false) {
      //field.options = this.props.data[field.dataSourceRef];
      field.options = this.filteredDataSourceByTenant(field);
      this.setState({
        panel: panel
      });
    }
  }

  filteredDataSourceByTenant(field) {
    let data = this.props.data[field.dataSourceRef];

    if (Helper.isNullOrEmpty(data)) {
      return [];
    }
    
    if (Helper.isNullOrEmpty(field.dataSourceTenantFilter)) {
      return data;
    }

    //Example:
    //"dataSourceTenantFilter": "tenant:Tenant"
    //Here, 
    // tenant -> the field name from the data source to fitler
    // Tenant -> Path of the Tenant field in the UI
    let filterParams = field.dataSourceTenantFilter.split(":");
    let fieldNameInDS = filterParams[0]; //Ex: tenant
    let tenantFieldPathName = filterParams[1]; //Ex: Tenant

    let selectedTenantName = this.props.panel[tenantFieldPathName]; //Ex: Rembrandt
    if (Helper.isNullOrEmpty(selectedTenantName)) {
      return [];
    }

    if (selectedTenantName == "ALL") {
      return data;
    }

    data = data.filter(a => a[fieldNameInDS] == selectedTenantName);
    
    return data;
  }

  getFromCache(cacheKey, panel) {

    // "optionsCache": {
    //   "root": "tenants",
    //   "paths": ["name:Tenant:sites"], //<fieldName>:<valueFieldName>:<lookupArrayField>
    //   }

    // "optionsCache": {
    //   "root": "tenants",
    //   "paths": ["name:Tenant:sites", "name:Site:houses"],
    //   },
    
    let isAllPathFound = true;

    let context = JSON.parse(sessionStorage.getItem(cacheKey.root));
    
    if (cacheKey.paths && cacheKey.paths.length > 0) {
      cacheKey.paths.forEach(path => {
        let pathSplit = path.split(":");
        
        //let field = Helper.getDataFromJsonPathQuery(this.props.fieldDefinitions, "$.fields.[?(@.path=='" + pathSplit[1] + "')]");
        let field = _.find(this.props.fieldDefinitions.fields, function(o) {
          return o.path === pathSplit[1];
        });

        //let value = Helper.getDataFromJsonPathQuery(panel, "$." + field.path);
        let value = panel[field.path]; 
        
        if (Helper.isNullOrEmpty(value)) {
          isAllPathFound = false;
          return;
        }
        
        //context.filter(a => a.name == "Demo");
        context = context.find(a => a[pathSplit[0]] == value);
        if (context) {
          context = context[pathSplit[2]];
        }

      });
    }
    
    if (isAllPathFound) {
      
      return context;
    }
    return [];
  }

  render() {
    var widgetPropsDefinition = this.props.fieldDefinitions;
    let fieldSets = [];
    if (widgetPropsDefinition && widgetPropsDefinition.fields) {
      fieldSets = JsonPath.query(
        widgetPropsDefinition.fields,
        "$.*.fieldSet"
      ).unique();
    }
    let widgetPrefix = "";
    return (
      <div className="row">
        {fieldSets &&
          fieldSets.map((fieldSet, fieldSetKey) => {
            let arrayPrefix = "";
            let arrayLength = 1;
            if (widgetPropsDefinition.fieldSets !== undefined) {
              let fieldsetDefinition = widgetPropsDefinition.fieldSets.find({
                name: fieldSet
              });
              if (fieldsetDefinition !== undefined) {
                arrayPrefix = fieldsetDefinition.arrayName;
                let fieldsetArray = Helper.getDataFromJsonPathQuery(
                  this.state.panel,
                  "$." + widgetPrefix + arrayPrefix
                );
                if (fieldsetArray !== undefined) {
                  arrayLength = fieldsetArray.length;
                }
              }
            }

            let fields = JsonPath.query(
              widgetPropsDefinition.fields,
              "$[?(@.fieldSet=='" + fieldSet + "')]"
            );
            return new Array(arrayLength).fill().map((item, index) => {
              let arrayPrefixWithIndex = "";
              if (arrayPrefix !== "")
                arrayPrefixWithIndex = arrayPrefix + "[" + index + "].";
              return (
                <Col md={12} key={index}>
                  {fieldSet === "" && (
                    <div className="form-row">
                      {fields &&
                        fields.map(field => {
                          return this.renderField(
                            field,
                            widgetPrefix,
                            arrayPrefixWithIndex
                          );
                        })}
                    </div>
                  )}
                  {fieldSet !== "" && (
                    <fieldSet className="rounded">
                      <legend>
                        {fieldSet} {arrayPrefix !== "" && index + 1}{" "}
                        {arrayPrefix !== "" && (
                          <span className="pull-right">
                            <i
                              className="fa fa-plus text-info pointerCursor px-2"
                              onClick={() => {
                                this.onAddArrayItem(
                                  widgetPrefix,
                                  arrayPrefix,
                                  index
                                );
                              }}
                            />
                            <i
                              className="fa fa-arrow-right text-info pointerCursor px-2"
                              onClick={() => {
                                this.onMoveArrayItemRight(
                                  widgetPrefix,
                                  arrayPrefix,
                                  index
                                );
                              }}
                            />
                            <i
                              className="fa fa-arrow-left text-info pointerCursor px-2"
                              onClick={() => {
                                this.onMoveArrayItemLeft(
                                  widgetPrefix,
                                  arrayPrefix,
                                  index
                                );
                              }}
                            />
                            <i
                              className="fa fa-trash text-info pointerCursor px-2"
                              onClick={() => {
                                this.onDeleteArrayItem(
                                  widgetPrefix,
                                  arrayPrefix,
                                  index
                                );
                              }}
                            />
                          </span>
                        )}
                      </legend>
                      <div className="form-row">
                        {fields &&
                          fields.map(field => {
                            return this.renderField(
                              field,
                              widgetPrefix,
                              arrayPrefixWithIndex
                            );
                          })}
                      </div>
                    </fieldSet>
                  )}
                </Col>
              );
            });
          })}
      </div>
    );
  }
}

export default FieldRenderer;
